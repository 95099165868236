import React from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import tw, { css } from "twin.macro";
import IAsset from "../../../../data/models/IAsset";
import placeholder from "../../../../assets/images/dynamic-image-placeholder.png";

interface IDraggableImageProps {
  asset: IAsset;
  isActive: boolean;
  onClick: () => void;
}

const styles = {
  imageContainer: tw`w-28 mr-4 mb-4`,
  imageWrapper: (isActive: boolean) => [
    tw`w-28 h-28 overflow-clip flex flex-col`,
    isActive && tw`border-2 border-solid border-border bg-hover`,
  ],
  imageName: tw`text-center`,
  image: [tw`w-full max-w-full block mt-auto mb-auto hover:cursor-pointer`],
};

const NonDraggableImage = ({
  asset,
  isActive,
  onClick,
}: IDraggableImageProps) => {
  return (
    <React.Fragment>
      <div css={styles.imageContainer}>
        <div css={styles.imageWrapper(isActive)}>
          {asset?.extension !== ".svg" && (
            <img
              src={asset?.url}
              css={styles.image}
              alt={asset?.name}
              onClick={onClick}
            />
          )}
          {asset?.extension === ".svg" && null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NonDraggableImage;
