import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { postAsync, putAsync } from "../../../helpers/asyncFetch";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import {
  NewCardValidationErrors,
  creditCardValidation,
} from "../../../shared/validationHelpers/creditCardValidation";
import { IPaymentMethod, Pricing } from "../../Account/models/AccountModel";
import IntegratedTooltip from "../components/IntegratedTooltip";
import LabelSwitch from "../components/LabelSwitch";
import { IntegratedContext } from "../layout/IntegratedLayout";

interface IntegrationSettings {
  allowDuplicates: boolean;
  enableAddressVerification: boolean;
  blockDuplicateRefNbrs: boolean;
  isSandbox: boolean;
  bAccountID?: number;
  email?: string;
}
interface BudgetSettings {
  budgetType: string;
  budgetAmount?: string;
}

interface PaymentSettings {
  pmid: string;
  ccNumber: "";
  ccExpiration: "";
  ccCVV: "";
}

const IntegratedSettings = () => {
  const integrationContext = useContext(IntegratedContext);
  //const data = useGetData("/api/auth/test-token");
  const {
    data: paymentMethods,
    isLoading: isPmLoading,
    error: isPmError,
    getDataAsync: getPaymentMethods,
  } = useGetData<IPaymentMethod[]>("/api/payments/payment-methods-expanded");

  const {
    data: budgetSettings,
    isLoading: isBudgetLoading,
    error: isBudgetError,
  } = useGetData<BudgetSettings>("/api/integration/budget");

  const {
    data: integrationSettings,
    isLoading: isSettingsLoading,
    error: isSettingsError,
  } = useGetData<IntegrationSettings>("/api/integration/advanced-settings");

  const {
    data: pricing,
    isLoading: pricingLoading,
    error: isPricingError,
  } = useGetData<Pricing[]>("/api/pricing");

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [budgetState, setBudgetState] = useState<BudgetSettings>({
    budgetType: "unlimited",
  });

  const [ccValidationErrors, setCCValidationErrors] =
    useState<NewCardValidationErrors>({});

  const [settingsState, setSettingsState] = useState<IntegrationSettings>({
    allowDuplicates: false,
    enableAddressVerification: true,
    blockDuplicateRefNbrs: false,
    isSandbox: true,
  });

  const [paymentSettings, setPaymentSettings] = useState<PaymentSettings>({
    pmid: "new",
    ccNumber: "",
    ccExpiration: "",
    ccCVV: "",
  });

  const [isSubmittingSettings, setIsSubmittingSettings] = useState(false);
  const [isSubmittingCC, setIsSubmittingCC] = useState(false);

  const [fieldsTouched, setFieldsTouched] = useState({
    ccNumber: false,
    ccExpiration: false,
    ccCVV: false,
    budgetAmount: false,
  });

  function handleCcChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPaymentSettings({
      ...paymentSettings,
      [e.target.name]: e.target.value,
    });
  }
  useEffect(() => {
    if (isSuccess) {
      setIsSuccess(false);
      setCCValidationErrors({});
    }
    if (isError) {
      setIsError(false);
    }
  }, [paymentSettings, budgetState, settingsState]);
  useEffect(() => {
    if (paymentSettings.pmid === "new") {
      const cardNumberStripped = paymentSettings.ccNumber?.replace(
        /[^0-9]/g,
        ""
      );
      const cvvStripped = paymentSettings.ccCVV?.replace(/[^0-9]/g, "");
      const validationErrors = creditCardValidation({
        firstName: "",
        lastName: "",
        cardNumber: cardNumberStripped,
        expiration: paymentSettings.ccExpiration,
        cvc: cvvStripped,
      });
      setCCValidationErrors(validationErrors);
    }
  }, [paymentSettings]);

  useEffect(() => {
    if (!isBudgetLoading && budgetSettings) {
      setBudgetState({
        ...budgetSettings,
        budgetAmount: budgetSettings.budgetAmount?.toString(),
      });
    }
  }, [isBudgetLoading]);
  useEffect(() => {
    if (!isSettingsLoading && integrationSettings) {
      setSettingsState(integrationSettings);
    }
  }, [isSettingsLoading]);
  useEffect(() => {
    if (!isPmLoading && paymentMethods) {
      let def = paymentMethods.find((x) => x.isPrimary);
      if (!def && paymentMethods.length > 0) {
        def = paymentMethods[0];
      }
      if (def) {
        setPaymentSettings({
          pmid: def.id?.toString() ?? "",
          ccNumber: "",
          ccExpiration: "",
          ccCVV: "",
        });
      }
    }
  }, [isPmLoading, paymentMethods]);

  function checkDisabledSubmission(isSubmitting = false) {
    let hasError = false;
    let touched = {
      ...fieldsTouched,
    };
    if (
      budgetState.budgetType !== "unlimited" &&
      budgetState.budgetType !== "manual"
    ) {
      const stripped = budgetState.budgetAmount?.replace(/[^0-9]/g, "");
      if (!stripped) {
        if (isSubmitting) {
          touched = { ...touched, budgetAmount: true };
        }
        hasError = true;
      }
    }
    if (paymentSettings.pmid === "new") {
      const cardNumberStripped = paymentSettings.ccNumber?.replace(
        /[^0-9]/g,
        ""
      );
      const expirationStripped = paymentSettings.ccExpiration?.replace(
        /[^0-9]/g,
        ""
      );
      const cvvStripped = paymentSettings.ccCVV?.replace(/[^0-9]/g, "");
      if (!cardNumberStripped || !expirationStripped || !cvvStripped) {
        if (isSubmitting) {
          touched = {
            ...touched,
            ccNumber: true,
            ccExpiration: true,
            ccCVV: true,
          };
        }
        hasError = true;
      }
      if (
        ccValidationErrors.cardNumber ||
        ccValidationErrors.expiration ||
        ccValidationErrors.cvc
      ) {
        if (isSubmitting) {
          touched = {
            ...touched,
            ccNumber: true,
            ccExpiration: true,
            ccCVV: true,
          };
        }
        hasError = true;
      }
    }
    if (isSubmitting) {
      setFieldsTouched({ ...touched });
    }
    return hasError;
  }

  async function handleSubmit() {
    if (checkDisabledSubmission(true)) {
      return;
    }
    setIsSubmittingSettings(true);
    try {
      if (paymentSettings.pmid === "new") {
        await addNewPaymentMethod();
      } else {
        await updatePaymentMethod();
      }
      const budgetAmount = budgetState.budgetAmount?.replace(/[^0-9.]/g, "");

      await putAsync("/integration/advanced-settings", {
        ...settingsState,
        budgetAmount,
        budgetType: budgetState.budgetType,
      });
    } catch (e) {
      console.log(e);
      setIsError(true);
      setIsSubmittingSettings(false);
      return;
    }
    setIsSuccess(true);
    setIsSubmittingSettings(false);
  }

  async function addNewPaymentMethod() {
    setIsSubmittingCC(true);
    const ccNumber = paymentSettings.ccNumber.replace(/[^0-9]/g, "");
    const expDate = paymentSettings.ccExpiration.split("/");
    try {
      await postAsync("/payments?markPrimary=true", {
        cardNumber: ccNumber,
        expirationMonth: expDate[0],
        expirationYear: expDate[1],
      });

      await getPaymentMethods(false);
    } catch (e) {
      setIsSubmittingCC(false);
      throw e;
    }
    setIsSubmittingCC(false);
  }

  async function updatePaymentMethod() {
    setIsSubmittingCC(true);
    await postAsync("/payments/set-default", `${paymentSettings.pmid}`);
    await getPaymentMethods(false);
    setIsSubmittingCC(false);
  }
  if (isBudgetLoading || isSettingsLoading || isPmLoading) {
    return (
      <Box>
        <Box sx={{ width: "100%", maxWidth: 650 }}>
          <Skeleton height={1080} variant="rectangular" />
        </Box>
      </Box>
    );
  }

  function buildPricing() {
    if (pricing) {
      const collection = {
        "46": pricing.find((x) => x.size === "46" && x.inventoryCD === "A46F")
          ?.price,
        "68": pricing.find((x) => x.inventoryCD === "A68S")?.price,
        "611": pricing.find((x) => x.inventoryCD === "A61S")?.price,
        "811": pricing
          .sort((a, b) => a.price - b.price)
          .filter(
            (x) =>
              x.size === "811" &&
              ["FirstClass", "Standard"].includes(x.mailClass)
          )[0]?.price,
      };
      return collection;
    } else {
      return {
        "46": undefined,
        "68": undefined,
        "611": undefined,
        "811": undefined,
      };
    }
  }

  const pricingCollection = buildPricing();

  return (
    <Box>
      <Box sx={{ width: "100%", maxWidth: 650 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Account Information
          </Typography>
          <Typography gutterBottom>
            <strong>Account #:</strong> {integrationSettings?.bAccountID ?? ""}
          </Typography>
          <Typography gutterBottom>
            <strong>Email:</strong> {integrationSettings?.email ?? ""}{" "}
          </Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5">Allow Duplicates</Typography>
          <Typography gutterBottom>
            By default we prevent mailing more than one card per day to the same
            address. Bypass this restriction to allow sending more than one card
            to each address per day by choosing yes below.
          </Typography>
          <LabelSwitch
            label={settingsState.allowDuplicates ? "Yes" : "No"}
            disabled={isSubmittingSettings || isSubmittingCC}
            checked={settingsState.allowDuplicates}
            sx={{ mt: 1 }}
            onChange={(e) => {
              setSettingsState({
                ...settingsState,
                allowDuplicates: e.target.checked,
              });
            }}
          />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography gutterBottom variant="h5">
            Validate Addresses
          </Typography>
          <Typography gutterBottom>
            By default we validate each address to ensure the highest
            deliverability. Addresses that are not able to be verified will be
            automatically rejected. Bypass this restriction by choosing no
            below.
          </Typography>
          <LabelSwitch
            label={settingsState.enableAddressVerification ? "Yes" : "No"}
            disabled={isSubmittingSettings || isSubmittingCC}
            checked={Boolean(settingsState.enableAddressVerification)}
            sx={{ mt: 1 }}
            onChange={(e) => {
              setSettingsState({
                ...settingsState,
                enableAddressVerification: e.target.checked,
              });
            }}
          />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5">
            Block Duplicate Reference Numbers
          </Typography>
          <Typography gutterBottom>
            By default we allow placing orders with the same reference number.
            If you would like to block placing orders with the same reference
            number, choose yes below.
          </Typography>
          <LabelSwitch
            label={settingsState.blockDuplicateRefNbrs ? "Yes" : "No"}
            disabled={isSubmittingSettings || isSubmittingCC}
            checked={settingsState.blockDuplicateRefNbrs}
            sx={{ mt: 1 }}
            onChange={(e) => {
              setSettingsState({
                ...settingsState,
                blockDuplicateRefNbrs: e.target.checked,
              });
            }}
          />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography gutterBottom variant="h5">
            Budget Type
            <IntegratedTooltip
              title={
                <Box>
                  <h5>Unlimited Budget</h5>
                  <p>
                    This option will automatically bill you daily for all orders
                    sent.
                  </p>
                  <h5>Monthly Budget</h5>
                  <p>
                    This option will automatically bill you daily for all orders
                    sent. Once your monthly budget is reached, we will require
                    manual approval daily until the following month.
                  </p>
                  <h5>Daily Budget</h5>
                  <p>
                    This option will automatically bill you daily for all orders
                    sent. Once your daily budget is reached, we will require
                    manual approval until the following day.
                  </p>
                  <h5>Manually Approve Orders</h5>
                  <p>This option will require manual approval daily.</p>
                </Box>
              }
            />
          </Typography>
          <Typography gutterBottom>
            By default we validate each address to ensure the highest
            deliverability. Addresses that are not able to be verified will be
            automatically rejected. Bypass this restriction by choosing no
            below.
          </Typography>
          <Select
            value={budgetState.budgetType}
            disabled={isSubmittingSettings || isSubmittingCC}
            fullWidth
            size="small"
            onChange={(e) => {
              setBudgetState({
                ...budgetState,
                budgetType: e.target.value,
              });
            }}
            sx={{ mt: 1 }}
          >
            <MenuItem value="unlimited">Unlimited Budget</MenuItem>
            <MenuItem value="monthly">Monthly Budget</MenuItem>
            <MenuItem value="daily">Daily Budget</MenuItem>
            <MenuItem value="manual">Manually Approve Orders</MenuItem>
          </Select>
          {budgetState.budgetType !== "unlimited" &&
            budgetState.budgetType !== "manual" && (
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <Typography sx={{ mb: 1 }} gutterBottom variant="h5">
                    Budget Amount
                  </Typography>
                  <NumberFormat
                    disabled={isSubmittingSettings || isSubmittingCC}
                    customInput={TextField}
                    fullWidth
                    thousandSeparator={true}
                    prefix="$"
                    value={budgetState.budgetAmount}
                    decimalSeparator="."
                    decimalScale={2}
                    allowNegative={false}
                    error={
                      (!budgetState.budgetAmount ||
                        !budgetState.budgetAmount.trim() ||
                        Number.isNaN(
                          Number(
                            budgetState.budgetAmount?.replace(/[^0-9]/g, "")
                          )
                        ) ||
                        budgetState.budgetAmount === "0") &&
                      fieldsTouched.budgetAmount
                    }
                    // @ts-ignore
                    size="small"
                    onChange={(e) => {
                      setBudgetState({
                        ...budgetState,
                        budgetAmount: e.target.value,
                      });
                    }}
                  />
                  {(!budgetState.budgetAmount ||
                    !budgetState.budgetAmount.trim() ||
                    Number.isNaN(
                      Number(budgetState.budgetAmount?.replace(/[^0-9]/g, ""))
                    ) ||
                    budgetState.budgetAmount === "0") &&
                    fieldsTouched.budgetAmount && (
                      <FormHelperText error>
                        You must enter a budget amount
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
            )}
          {Boolean(pricingCollection[46]) && (
            <React.Fragment>
              <Typography gutterBottom sx={{ mt: 2 }} variant="h5">
                Your Per Piece Pricing:
              </Typography>
              <Typography>
                4.25&quot;x6&quot; Postcards, Postage Included: as low as $
                {pricingCollection[46]?.toFixed(2)} per piece
              </Typography>
              <Typography>
                6&quot;x8.5&quot; Postcards, Postage Included: as low as $
                {pricingCollection[68]?.toFixed(2)} per piece
              </Typography>
              <Typography>
                6&quot;x11&quot; Postcards, Postage Included: as low as $
                {pricingCollection[611]?.toFixed(2)} per piece
              </Typography>
              {pricingCollection[811] && (
                <Typography>
                  8.5&quot;x11&quot; Letters, Postage Included: as low as $
                  {pricingCollection[811]?.toFixed(2)} per piece
                </Typography>
              )}
              <Typography>
                You will never be charged a monthly fee. There are no minimums
                and you will only be charged daily for the pieces you mail.
              </Typography>
            </React.Fragment>
          )}
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography gutterBottom sx={{ mb: 1 }} variant="h5">
            Payment Method
            <IntegratedTooltip
              title={
                <Box>
                  <p>
                    You will never be charged a monthly fee. There are no
                    minimums and you will only be charged daily for the pieces
                    you mail.{" "}
                    {Boolean(pricingCollection[46]) && (
                      <span>
                        Pricing starts at ${pricingCollection[46]?.toFixed(2)}{" "}
                        per piece, postage included.
                      </span>
                    )}
                  </p>
                </Box>
              }
            />
          </Typography>
          <Select
            value={paymentSettings.pmid}
            fullWidth
            size="small"
            disabled={isSubmittingSettings || isSubmittingCC}
            onChange={(e) => {
              setPaymentSettings({
                pmid: e.target.value,
                ccNumber: "",
                ccExpiration: "",
                ccCVV: "",
              });
            }}
          >
            <MenuItem value="new">New Credit Card</MenuItem>
            {paymentMethods?.map((x) => (
              <MenuItem key={x.id} value={x.id?.toString()}>
                Card Ending In {x.lastFour}
              </MenuItem>
            ))}
          </Select>
          {paymentSettings.pmid === "new" && (
            <Box sx={{ mb: 4, mt: 2 }}>
              <Typography gutterBottom variant="h5">
                New Credit Card
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Card Number</FormLabel>
                    <NumberFormat
                      value={paymentSettings.ccNumber}
                      onChange={handleCcChange}
                      customInput={TextField}
                      fullWidth
                      type="tel"
                      // @ts-ignore
                      size="small"
                      disabled={isSubmittingSettings || isSubmittingCC}
                      mask="_"
                      name="ccNumber"
                      onBlur={() => {
                        setFieldsTouched({ ...fieldsTouched, ccNumber: true });
                      }}
                      format={
                        paymentSettings.ccNumber.startsWith("34") ||
                        paymentSettings.ccNumber.startsWith("37")
                          ? "#### - ###### - #####"
                          : "#### - #### - #### - ####"
                      }
                      error={
                        fieldsTouched.ccNumber &&
                        Boolean(ccValidationErrors.cardNumber)
                      }
                    />
                    {fieldsTouched.ccNumber &&
                      ccValidationErrors.cardNumber && (
                        <FormHelperText error>
                          {ccValidationErrors.cardNumber}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl fullWidth>
                    <FormLabel>Expiration (MM/YY)</FormLabel>
                    <NumberFormat
                      disabled={isSubmittingSettings || isSubmittingCC}
                      value={paymentSettings.ccExpiration}
                      onChange={handleCcChange}
                      customInput={TextField}
                      fullWidth
                      type="tel"
                      // @ts-ignore
                      size="small"
                      mask="_"
                      name="ccExpiration"
                      format={"##/##"}
                      onBlur={() => {
                        setFieldsTouched({
                          ...fieldsTouched,
                          ccExpiration: true,
                        });
                      }}
                      error={
                        fieldsTouched.ccExpiration &&
                        Boolean(ccValidationErrors.expiration)
                      }
                    />
                    {fieldsTouched.ccExpiration &&
                      ccValidationErrors.expiration && (
                        <FormHelperText error>
                          {ccValidationErrors.expiration}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabel>CVC</FormLabel>
                    <NumberFormat
                      disabled={isSubmittingSettings || isSubmittingCC}
                      value={paymentSettings.ccCVV}
                      onChange={handleCcChange}
                      customInput={TextField}
                      fullWidth
                      type="tel"
                      // @ts-ignore
                      size="small"
                      mask="_"
                      name="ccCVV"
                      format={
                        paymentSettings.ccNumber.startsWith("34") ||
                        paymentSettings.ccNumber.startsWith("37")
                          ? "#####"
                          : "###"
                      }
                      onBlur={() => {
                        setFieldsTouched({ ...fieldsTouched, ccCVV: true });
                      }}
                      error={
                        fieldsTouched.ccCVV && Boolean(ccValidationErrors.cvc)
                      }
                    />
                    {fieldsTouched.ccCVV && ccValidationErrors.cvc && (
                      <FormHelperText error>
                        {ccValidationErrors.cvc}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LoadingButton
            variant="contained"
            disabled={isSubmittingSettings || isSubmittingCC}
            onClick={handleSubmit}
            loading={isSubmittingSettings || isSubmittingCC}
          >
            Save Settings
          </LoadingButton>
          <Box sx={{ ml: 2 }}>
            {isSuccess && (
              <FormHelperText>Settings saved successfully</FormHelperText>
            )}
            {isError && (
              <FormHelperText error>
                Something went wrong, and our team has been notified. Please try
                again later
              </FormHelperText>
            )}
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          {integrationContext?.source === "lc" ? (
            <Typography>
              <em>
                Contact{" "}
                <a
                  href="mailto:support@pcmintegrations.com"
                  style={{ color: "#1565c0" }}
                >
                  support@pcmintegrations.com
                </a>{" "}
                for assistance.
              </em>
            </Typography>
          ) : (
            <Typography>
              <em>
                Contact{" "}
                <a
                  href="mailto:support@pcmintegrations.com"
                  style={{ color: "#1565c0" }}
                >
                  support@pcmintegrations.com
                </a>{" "}
                for assistance, or call{" "}
                <a href="tel:+1(888)818-8148" style={{ color: "#1565c0" }}>
                  1-888-818-8148
                </a>
                .
              </em>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default IntegratedSettings;
