import LocalStorage from "./LocalStorage";

interface HttpResponse<T> extends Response {
  parsedJson?: T;
}

export type HttpMethod = "get" | "post" | "put" | "delete";

export default async function nFetchClient<T, B>(
  path: string,
  method: HttpMethod,
  body?: B
): Promise<T | undefined> {
  const request = new Request(path, {
    method,
    headers: {
      Authorization: `bearer ${LocalStorage.getToken()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: body ? JSON.stringify(body) : undefined,
  });

  const response: HttpResponse<T> = await fetch(request);
  if (response.status === 401) {
    handleUnautorized();
  }
  if (!response.ok) {
    const errorMessage = await response.json();

    throw `${errorMessage.message}`;
  }

  try {
    response.parsedJson = await response.json();
  } catch (ex) {
    throw new Error(`Error parsing json`);
  }

  return response.parsedJson;
}

function handleUnautorized() {
  const redirect = window.location.pathname;
  if (redirect === "/" || !redirect || redirect === "/login") {
    window.location.href = "/login";
  } else window.location.href = `/login?referrer=${encodeURI(redirect)}`;
}
