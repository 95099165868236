import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import NewCarousel from "../../../../../../shared/Carousel/NewCarousel";
import LazyLoadImage from "../../../../../../shared/LazyLoadImage";
import { getSizeLabel } from "../../../../../Designs/lookUpSizeFriendlyNames";
import Design from "../../../../../Designs/models/Design";

import "./DesignPreview.scss";
import Img from "../../../../../../shared/BasicHTML/Img";

interface DesignPreviewProps {
  design: Design;
}

const DesignPreview: React.FC<DesignPreviewProps> = ({ design }) => {
  /**
   * State Objects and Refs
   */
  const proofs = [];
  if (design.proofFront) proofs.push(design.proofFront);
  if (design.proofBack) proofs.push(design.proofBack);
  const size = getSizeLabel(design.size);
  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <div className="designPreview">
      <div className="designPreview__header">
        <h4 className="designPreview__header__heading">Design Details</h4>
      </div>
      <div className="designPreview__images">
        {proofs.length > 1 && <NewCarousel images={proofs} />}
        {proofs.length <= 1 && (
          <Img height={265} src={design.proofFront} showLoadingScreen />
        )}
      </div>
      <div className="designPreview__details">
        <Grid container>
          <Grid item md={4}>
            <div className="designPreview__details__detail --first">
              <h6 className="designPreview__details__detail__heading">
                Design Name
              </h6>
              <span className="designPreview__details__detail__body">
                {design.friendlyName}
              </span>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="designPreview__details__detail">
              <h6 className="designPreview__details__detail__heading">
                Design ID
              </h6>
              <span className="designPreview__details__detail__body">
                {design.designID}
              </span>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="designPreview__details__detail --end">
              <h6 className="designPreview__details__detail__heading">Size</h6>
              <span className="designPreview__details__detail__body">
                {size}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DesignPreview;
