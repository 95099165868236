import tw, { css } from "twin.macro";
export default {
  pagePreviewContainer: (isActive: boolean, width: number, height: number) => [
    css({
      display: "block",
      width: width + "px",
      height: height + "px",
      cursor: "pointer",
      transition: "border 0.2s ease-in-out",
      position: "relative",
    }),
    !isActive &&
      tw`border-2 border-solid border-border rounded hover:(border-blue border-opacity-75)`,
    isActive && tw`border-2 border-solid border-blue rounded cursor-default`,
  ],
  pagePreviewOverlay: (width: number, height: number) => [
    css({
      position: "absolute",
      top: 0,
      left: 0,
      width: `${width}px`,
      height: `${height}px`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      transition: "opacity 0.2s ease-in-out",
    }),
    tw`opacity-0 group-hover:(opacity-100)`,
  ],
  pagePreviewEditButton: [
    tw`
    
      flex 
      items-center
      cursor-pointer 
      px-4 
      py-1.5 
      bg-blue 
      rounded 
      text-white 
      font-bold
      hover:bg-opacity-80
      
    `,
  ],
  deleteButton: [
    tw`opacity-0 p-0.5 bg-white fill-error border-2 border-solid border-error cursor-pointer
    hover:(fill-white bg-error) group-hover:(opacity-100)
    `,

    css({
      position: "absolute",
      top: "-4px",
      right: "-4px",
      borderRadius: "50%",
      transition: "all .2s ease-in-out",
    }),
  ],
  pagePreviewCanvasWrapper: (
    width: number,
    height: number,
    isActive: boolean
  ) => [
    css({
      width: width - 4 + "px",
      height: height - 4 + "px",
      overflow: "hidden",
    }),
  ],
  pagePreviewNew: [
    css({
      background: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }),
    tw`hover:border-blue hover:border-opacity-75 border-2 border-solid border-border rounded`,
  ],
  pagePreviewNewIconContainer: [
    tw`p-2 border-labels border-solid border-2 group-hover:(border-blue border-opacity-75 bg-blue) `,
    css({
      borderRadius: "50%",
      transition: "all .2s ease-in-out",
    }),
  ],
  pagePreviewNewIcon: [
    tw`fill-labels group-hover:(fill-white)`,
    css({
      width: "32px",
      height: "32px",
      transition: "all .2s ease-in-out",
    }),
  ],
  dropzone: (hide: boolean, isOver: boolean, width: number) => [
    tw`border-dotted `,
    css({ transition: "all .2s ease-in-out" }),
    hide && tw`hidden`,
    isOver && tw`bg-blue bg-opacity-50`,
    isOver && css({ width: width + "px" }),
  ],
  dragPreview: [],
  pageName: [tw`pt-2`, css({ fontSize: "20px" })],
};
