import { createContext, Reducer, useEffect, useReducer, useState } from "react";
import { fetchClient } from "../../helpers/fetchClient";
import LocalStorage from "../../helpers/LocalStorage";
import ActionAlert from "../models/NeedsAttention";

enum ActionAlertsType {
  UPDATE = "FETCH",
  SET_QUOTE_ID = "SETQUOTEID",
}

interface ActionAlertsDispatch {
  fetch: () => void;
  setActiveQuoteId: (quoteId: string | null) => void;
}

interface IActionAlertsContext {
  alerts: ActionAlert[];
  activeAlertQuoteId: string | null;
  actions: ActionAlertsDispatch;
}

interface ActionAlertsState {
  alerts: ActionAlert[];
  activeAlertQuoteId: string | null;
}

interface ActionAlertsAction {
  type: ActionAlertsType;
  payload?: any;
}

function actionAlertsReducer(
  state: ActionAlertsState,
  action: ActionAlertsAction
) {
  const { type, payload } = action;
  switch (type) {
    case ActionAlertsType.UPDATE: {
      return { ...state, alerts: payload };
    }
    case ActionAlertsType.SET_QUOTE_ID: {
      return { ...state, activeAlertQuoteId: payload };
    }
    default:
      return state;
  }
}

/**
 * Hook that returns an object containing alerts, an alert quoteID and the actions to update this state.
 * To use this state, call useContext(ActionAlertsContext) from any component that is a child of AuthLayout, or any components that are part of AuthLayout.
 * The state is instantiated in AuthLayout
 * @returns IActionAlertsContext
 */
export function useActionAlertsContext(): IActionAlertsContext {
  const [state, dispatch] = useReducer(actionAlertsReducer, {
    alerts: [],
    activeAlertQuoteId: null,
  });

  const currentToken = LocalStorage.getToken();

  const [token, setToken] = useState(currentToken);

  if (currentToken !== token) {
    dispatch({ type: ActionAlertsType.UPDATE, payload: [] });
    setToken(currentToken);
  }

  // this is an object for all of our reducer actions, I figured it would be easier to handle the actions inside here so we don't need to repeat things like fetching the alerts or setting the quoteId.
  const dispatchObj: ActionAlertsDispatch = {
    fetch: fetchAlerts,
    setActiveQuoteId: setActiveQuoteId,
  };

  function fetchAlerts() {
    function onAlertsSuccess(alerts: ActionAlert[]) {
      dispatch({ type: ActionAlertsType.UPDATE, payload: alerts });
    }

    if (!token && window.location) return;
    fetchClient.get({ path: "/alerts", onSuccess: onAlertsSuccess });
  }

  function setActiveQuoteId(quoteId: string | null) {
    dispatch({ type: ActionAlertsType.SET_QUOTE_ID, payload: quoteId });
  }

  /**
   * Dispatches our fetch action, then sets a timeout to repeat every 5 minutes. On destroy, clear the timeout to prevent unmounted state change
   * @returns void
   */
  function initializeState() {
    dispatchObj.fetch();
    const timeout = setTimeout(() => dispatchObj.fetch(), 300000);
    return () => {
      clearTimeout(timeout);
    };
  }

  useEffect(initializeState, [token]);

  return {
    alerts: state.alerts,
    actions: dispatchObj,
    activeAlertQuoteId: state.activeAlertQuoteId,
  };
}

export const ActionAlertsContext = createContext<IActionAlertsContext>(
  {} as IActionAlertsContext
);
