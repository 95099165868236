import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { postAsync } from "../../../helpers/asyncFetch";
import { fetchClient } from "../../../helpers/fetchClient";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import { Link, useHistory } from "react-router-dom";
import IDesignCategory from "../../Designs/models/IDesignCategory";
import Design from "../../Designs/models/Design";
import { TableColumn } from "react-data-table-component";
import moment from "moment";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import IHtmlDesignSize from "../../Html/models/IHtmlDesignSize";
import IActiveCategory from "../models/IActiveCategory";
import isAdminUser from "../../../helpers/isAdminUser";
import hideLiveChat from "../../../helpers/hideLiveChat";
import AdminDesignerTags from "./AdminDesignerTags";
import { useReassign } from "../components/ReassignPanel";
import Img from "../../../shared/BasicHTML/Img";
import DesignerSizes from "../../../data/models/DesignerSizes";

const newDesignBase = {
  isOpen: false,
  name: "",
  size: "",
  category: "",
  subcategory: "",
  isTemplate: true,
};

interface EditState {
  renameOpen: boolean;
  deleteOpen: boolean;
  tagsOpen: boolean;
  design?: Design;
}

const baseEditState: EditState = {
  renameOpen: false,
  deleteOpen: false,
  tagsOpen: false,
  design: undefined,
};

const AdminDesignerDesigns = () => {
  const history = useHistory();
  //hideLiveChat();
  const { data, isLoading, error, getData } = useGetData<Design[]>(
    `/api/designer`,
    []
  );
  const [activeCategory, setActiveCategory] = useState<IActiveCategory>({});
  const [duplicateDesign, setDuplicateDesign] = useState<Design>();
  const reassign = useReassign();
  const [editState, setEditState] = useState(baseEditState);
  const [rename, setRename] = useState("");

  const { data: designCategories, error: categoryError } = useGetData<
    IDesignCategory[]
  >(`/api/design-category`, []);

  const columns: TableColumn<Design>[] = [
    {
      name: "Design Proof",
      width: "300px",
      cell: (row) => (
        <div>
          <Img
            src={row.proofFront}
            width={268}
            height={197}
            maxWidth={268}
            className="design__image img-fluid"
          />

          <div>
            {
              //@ts-ignore
              <Button
                LinkComponent={Link}
                //@ts-ignore
                to={`/admin/designer/${row.designID}/proof`}
                href="#"
                size="small"
                variant="outlined"
              >
                Geneate Proofs
              </Button>
            }{" "}
            <Button onClick={() => reassign.openPanel(row.designID)}>
              Reassign
            </Button>
          </div>
        </div>
      ),
    },
    {
      name: "Design ID",
      selector: (design) => design.designID,
      sortable: true,
    },
    {
      name: "Design Name",
      selector: (design) => design.friendlyName,
      sortable: true,
      cell: (design) => {
        return (
          <React.Fragment>
            {design.friendlyName}{" "}
            <IconButton
              size="small"
              color="info"
              onClick={() => handleOpen("name", design)}
            >
              <i className="fas fa-edit"></i>
            </IconButton>
          </React.Fragment>
        );
      },
    },
    {
      name: "Size",
      selector: (design) => design.size,
      sortable: true,
    },
    {
      name: "Approval Date",
      selector: (cell) => cell.approvalDate,
      sortable: true,
      format: (row) =>
        row.approvalDate
          ? moment(row.approvalDate).format("MM/DD/YYYY")
          : "Not Approved",
    },
    {
      name: "Tags",
      cell: (design) => {
        return (
          <div>
            <div>
              <strong>Occasions: </strong>{" "}
              {design.tags
                .filter((x) => x.tagGroup === "Occasion")
                .map((x) => x.tagName)
                .join(", ")}
            </div>
            <div>
              <strong>Industries: </strong>{" "}
              {design.tags
                .filter((x) => x.tagGroup === "Industry")
                .map((x) => x.tagName)
                .join(", ")}
            </div>
            <div>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleOpen("tags", design)}
              >
                <i className="fas fa-edit"></i>
              </IconButton>
            </div>
          </div>
        );
      },
    },
    {
      cell: (design) => (
        <React.Fragment>
          <Button
            color="primary"
            component={Link}
            to={
              isAdminUser()
                ? `/admin/designer/${design.designID}`
                : `/editor/designer/${design.designID}`
            }
          >
            Edit
          </Button>
          <Button color="primary" onClick={() => handleDuplicateOpen(design)}>
            Duplicate
          </Button>{" "}
          <IconButton
            color="info"
            size="small"
            onClick={() => handleOpen("delete", design)}
          >
            <i className="fas fa-trash"></i>
          </IconButton>
        </React.Fragment>
      ),
    },
  ];

  const [newDesign, setNewDesign] = useState(newDesignBase);

  function handleOpen(type: "name" | "delete" | "tags", design: Design) {
    switch (type) {
      case "name": {
        setEditState({ ...baseEditState, renameOpen: true, design });
        break;
      }
      case "delete": {
        setEditState({ ...baseEditState, deleteOpen: true, design });
        break;
      }
      default: {
        setEditState({ ...baseEditState, tagsOpen: true, design });
        break;
      }
    }
  }

  function handleClose() {
    setEditState(baseEditState);
    setRename("");
  }

  function handleDuplicateOpen(design: Design) {
    setDuplicateDesign(design);
    toggleNewOpen(design);
  }

  function toggleNewOpen(duplicate?: Design) {
    if (duplicate && !newDesign.isOpen) {
      setNewDesign({
        ...newDesignBase,
        size: duplicate.size,
        name: duplicate.friendlyName + " - Copy",
        isOpen: true,
      });
    } else {
      setNewDesign({ ...newDesignBase, isOpen: !newDesign.isOpen });
    }
    if (newDesign.isOpen) {
      setDuplicateDesign(undefined);
    }
  }

  function handleNewDesignChange(
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) {
    setNewDesign({ ...newDesign, [e.target.name]: e.target.value });
  }

  function handleDelete() {
    const id = editState.design?.designID;
    handleClose();
    fetchClient.put({
      path: `/designer/${id}/details`,
      data: {
        isDeleted: true,
      },
      onSuccess: () => {
        getData(false);
      },
    });
  }

  async function createNewDesign() {
    console.log(duplicateDesign);
    const res = await postAsync<{ design: Design }>(`/designer`, {
      ...newDesign,
      category: newDesign.category ? parseInt(newDesign.category) : null,
      subcategory: newDesign.subcategory
        ? parseInt(newDesign.subcategory)
        : null,
      duplicateID: duplicateDesign?.designID,
    });
    if (res) {
      history.push(`/admin/designer/${res.design.designID}`);
    }
    toggleNewOpen();
  }
  function handleCategoryChange(e: SelectChangeEvent) {
    if (e.target.value && designCategories) {
      const categoryID = parseInt(e.target.value);
      const category = designCategories.find(
        (x) => x.categoryID === categoryID
      );
      setActiveCategory({ category });
    } else {
      setActiveCategory({});
    }
  }

  function handleSaveDesign() {
    handleClose();
    fetchClient.put({
      path: `/designer/${editState.design?.designID}/details`,
      data: {
        name: rename,
      },
      onSuccess: () => {
        getData(false);
      },
    });
  }

  function handleSaveTags(occasions: string[], industries: string[]) {
    console.log(occasions, industries);
    handleClose();
    fetchClient.put({
      path: `/designer/${editState.design?.designID}/details`,
      data: {
        occasions,
        industries,
      },
      onSuccess: () => {
        getData(false);
      },
    });
  }

  function handleSubcategoryChange(e: SelectChangeEvent) {
    if (e.target.value && designCategories && activeCategory.category) {
      const subcategoryID = parseInt(e.target.value);
      const subcategory = activeCategory.category.subcategories.find(
        (x) => x.subcategoryID === subcategoryID
      );
      setActiveCategory({ ...activeCategory, subcategory });
    } else {
      setActiveCategory({ category: activeCategory.category });
    }
  }

  function filterDesigns() {
    if (!activeCategory.category || !data) return data;
    return data.filter((x) => {
      if (activeCategory.subcategory) {
        return (
          x.designSubcategory?.subcategoryID ===
            activeCategory.subcategory.subcategoryID &&
          x.designCategory?.categoryID === activeCategory.subcategory.categoryID
        );
      }
      return (
        x.designCategory?.categoryID === activeCategory.category?.categoryID
      );
    });
  }

  useEffect(() => {
    if (reassign.networkState.status === "success") {
      getData(true);
    }
  }, [reassign.networkState.status]);

  const customHeader = (
    <Box>
      <Box sx={{ mb: 3 }}>
        {isAdminUser() && (
          <Link to="/admin">
            <i className="fas fa-chevron-left"></i> Back to Admin
          </Link>
        )}
      </Box>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={6} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Filter Categories</InputLabel>
            <Select
              fullWidth
              label="Filter Categories"
              value={activeCategory.category?.categoryID.toString() ?? ""}
              onChange={handleCategoryChange}
            >
              <MenuItem value="">No Filter</MenuItem>
              {designCategories?.map((category) => (
                <MenuItem key={category.categoryID} value={category.categoryID}>
                  {category.categoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Filter Subcategories</InputLabel>
            <Select
              fullWidth
              label="Filter Subcategories"
              disabled={!activeCategory.category}
              value={activeCategory.subcategory?.subcategoryID.toString() ?? ""}
              onChange={handleSubcategoryChange}
            >
              <MenuItem value="">No Filter</MenuItem>
              {activeCategory.category?.subcategories?.map((category) => (
                <MenuItem
                  key={category.subcategoryID}
                  value={category.subcategoryID}
                >
                  {category.subcategoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={"auto"}>
          <Button
            onClick={() => toggleNewOpen()}
            color="primary"
            variant="contained"
          >
            Add New Design
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <LoadingWrapper
      loading={isLoading}
      errorMessage={error.errorMessage}
      hasError={error.hasError}
    >
      <SearchableDataTable
        tableTitle=""
        customHeaderComponent={customHeader}
        searchableColumns={[
          "designID",
          "name",
          "designCategory.categoryName",
          "designSubcategory.subcategoryName",
        ]}
        columns={columns}
        data={filterDesigns() ?? []}
      />
      {editState.design && editState.tagsOpen && (
        <AdminDesignerTags
          open={editState.tagsOpen}
          design={editState.design}
          onClose={handleClose}
          onSave={handleSaveTags}
        />
      )}
      <Dialog open={editState.renameOpen} maxWidth="xs" fullWidth>
        <DialogTitle>Edit Name</DialogTitle>
        <DialogContent>
          <TextField
            value={rename}
            onChange={(e) => setRename(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveDesign}>Save Changes</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editState.deleteOpen} onClose={handleClose}>
        <DialogTitle>
          Delete Design &quot;{editState.design?.friendlyName}&quot;
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this design?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>Delete</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={newDesign.isOpen}>
        <DialogTitle>
          {duplicateDesign ? "Duplicate Design" : "Add New Design"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="name"
              value={newDesign.name}
              onChange={handleNewDesignChange}
              label="Design Name"
              fullWidth
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Design Size</InputLabel>
              <Select
                name="size"
                value={newDesign.size}
                onChange={handleNewDesignChange}
                label="Design Size"
              >
                <MenuItem value="46">4.25&quot; x 6&quot;</MenuItem>
                <MenuItem value="68">6&quot; x 8.5&quot;</MenuItem>
                <MenuItem value="611">6&quot; x 11&quot;</MenuItem>
                <MenuItem value="811">Letter</MenuItem>
                <MenuItem value={DesignerSizes.BROCHURE}>Brochure</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={newDesign.category}
                onChange={handleNewDesignChange}
                label="Category"
              >
                <MenuItem value="">None</MenuItem>
                {designCategories?.map((category) => (
                  <MenuItem
                    key={category.categoryID}
                    value={category.categoryID.toString()}
                  >
                    {category.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {Boolean(newDesign.category) && (
            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Subcategory</InputLabel>
                <Select
                  name="subcategory"
                  value={newDesign.subcategory}
                  onChange={handleNewDesignChange}
                  label="Subcategory"
                >
                  <MenuItem value="">None</MenuItem>
                  {designCategories
                    ?.find(
                      (x) => x.categoryID.toString() === newDesign.category
                    )
                    ?.subcategories.map((category) => (
                      <MenuItem
                        key={category.subcategoryID}
                        value={category.subcategoryID.toString()}
                      >
                        {category.subcategoryName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={createNewDesign}
            disabled={!newDesign.name || !newDesign.size}
          >
            Create New Design
          </Button>
          <Button color="primary" onClick={() => toggleNewOpen()}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingWrapper>
  );
};

export default AdminDesignerDesigns;
