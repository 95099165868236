import { Object as IObject } from "fabric/fabric-impl";
import { IObjectProperties } from "../../../state/contexts/SelectedObjectContext";
import updateSize from "./updateSize";
import updatePosition from "./updatePosition";
import updateShadowSettings from "./updateShadowSettings";
import { IImageGroup } from "../../useImageMasking/useImageMasking";
import updateImageProperty from "./updateImageProperty";

/**
 * Function for updating object properties across the board - everything for oject updates should be called from here.
 * @todo move the image masking logic to this router
 * @param obj
 * @param property
 * @param value
 */
export default function updateObjectProperty(
  obj: IObject,
  property: keyof IObjectProperties,
  value: string
) {
  const valueNumber = parseFloat(value);

  if (Number.isNaN(valueNumber)) {
    // handle any non-numeric changes
    return;
  }
  if (obj.name?.includes("image")) {
    updateImageProperty(obj as IImageGroup, property, value);
  }
  if (property === "width" || property === "height") {
    updateSize(obj, property, valueNumber);
    return;
  }
  if (property === "left" || property === "top") {
    updatePosition(obj, property, valueNumber);
    return;
  }
  if (property.includes("shadow")) {
    updateShadowSettings(obj, property, valueNumber);
    return;
  }
  if (property === "opacity") {
    let opacity = valueNumber / 100;
    if (opacity > 1) {
      opacity = 1;
    }
    if (opacity < 0) {
      opacity = 0;
    }

    obj.set({ opacity });
    return;
  }
  obj.set({ [property as keyof IObject]: valueNumber });
}
