import {
  IText,
  Object as IObject,
  Path,
  Image,
  Circle,
  Ellipse,
  Group,
  Polygon,
  Textbox,
} from "fabric/fabric-impl";
import React, { createContext, useReducer } from "react";
import { IImageGroup } from "../../hooks/useImageMasking/useImageMasking";

export const propertiesTemplate: IObjectProperties = {
  width: "0",
  height: "0",
  angle: "0",
  skewX: "0",
  skewY: "0",
  left: "0",
  top: "0",
  originX: "left",
  originY: "top",
  strokeWidth: "0",
  lineCap: "butt",
  lineJoin: "miter",
  dashLength: "4",
  dashGap: "4",
  strokeType: "solid",
  miterLimit: "4",
  fontFamily: "Roboto",
  fontWeight: "normal",
  textAlign: "left",
  fontStyle: "regular",
  fontSize: "32",
  lineHeight: "1",
  charSpacing: "0",
  variableName: "",
  cornerRadius: "0",
  opacity: "100",
  shadowBlur: "0",
  shadowOffsetX: "0",
  shadowOffsetY: "0",
  imageFrameType: "rect",
  imageFrameWidth: "0",
  imageFrameHeight: "0",
  imageFrameAngle: "0",
  imageFrameSkewX: "0",
  imageFrameSkewY: "0",
  imageWidth: "0",
  imageHeight: "0",
  imageLeft: "0",
  imageTop: "0",
  uniformScaling: "false",
};

export interface ISelectedObjectState {
  object?: IObject;
  properties: IObjectProperties;
}

interface ISelectedObjectAction {
  type: "properties" | "selectObject" | "clearSelection";
  payload?: IObject | Partial<IObjectProperties>;
}

export const SelectedObjectContext = createContext<ISelectedObjectState>({
  properties: propertiesTemplate,
});

export const SelectedObjectDispatchContext = createContext<{
  updateProperties: (properties: Partial<IObjectProperties>) => void;
  updateSelectedObject: (selection?: IObject) => void;
}>({ updateProperties: (properties) => {}, updateSelectedObject: () => {} });
const intialState = { properties: propertiesTemplate };

function selectedObjectReducer(
  state: ISelectedObjectState,
  action: ISelectedObjectAction
) {
  if (!action.payload && action.type !== "clearSelection") return state;
  if (!action.payload || action.type === "clearSelection") {
    state.object = undefined;
    return { ...state };
  }
  if (action.type === "properties" && !("set" in action.payload)) {
    state.properties = { ...state.properties, ...action.payload };
    return {
      object: state.object,
      properties: { ...state.properties, ...action.payload },
    };
  } else if (action.type === "selectObject" && "set" in action.payload) {
    return {
      object: action.payload,
      properties: { ...state.properties },
    };
  }

  return state;
}

interface IProviderProps {
  children?: React.ReactFragment;
}

const SelectedObjectProvider = ({ children }: IProviderProps) => {
  const [selectedObject, dispatch] = useReducer(
    selectedObjectReducer,
    intialState
  );

  function updateProperties(properties: Partial<IObjectProperties>) {
    dispatch({ type: "properties", payload: properties });
  }

  function updateSelectedObject(selection?: IObject) {
    if (selection) dispatch({ type: "selectObject", payload: selection });
    else dispatch({ type: "clearSelection" });
  }

  return (
    <SelectedObjectContext.Provider value={selectedObject}>
      <SelectedObjectDispatchContext.Provider
        value={{ updateProperties, updateSelectedObject }}
      >
        {children}
      </SelectedObjectDispatchContext.Provider>
    </SelectedObjectContext.Provider>
  );
};

export type UpdateProperties = (properties: Partial<IObjectProperties>) => void;

export interface IObjectProperties {
  width: string;
  height: string;
  angle: string;
  skewX: string;
  skewY: string;
  left: string;
  top: string;
  originX: string;
  originY: string;

  strokeWidth: string;
  lineCap: string;
  lineJoin: string;
  dashLength: string;
  dashGap: string;
  strokeType: string;
  miterLimit: string;
  fontFamily: string;
  fontWeight: string;
  textAlign: string;
  fontStyle: string;
  fontSize: string;
  lineHeight: string;
  charSpacing: string;
  variableName: string;
  cornerRadius: string;
  opacity: string;
  shadowBlur: string;
  shadowOffsetX: string;
  shadowOffsetY: string;
  imageFrameType: string;
  imageFrameWidth: string;
  imageFrameHeight: string;
  imageFrameAngle: string;
  imageFrameSkewX: string;
  imageFrameSkewY: string;
  imageWidth: string;
  imageHeight: string;
  imageLeft: string;
  imageTop: string;
  uniformScaling: string;
}

export interface ICanvasObject
  extends IObject,
    Image,
    Circle,
    Ellipse,
    Group,
    Polygon,
    Textbox,
    IImageGroup {}

export default SelectedObjectProvider;
