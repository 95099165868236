export enum DesignerStatus {
  idle = "idle",
  loading = "loading",
  busy = "busy",
  error = "error",
  success = "success",
}

export enum DesignerError {
  genericError = "Something went wrong, and we didn't account for it.",
}

/**
 * @description The applications current status, error messages, and other vitals.
 */
export default interface IDesignerState {
  status: DesignerStatus;
  error?: DesignerError;
  lastSave?: string;
}
