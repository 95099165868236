import React, { useContext, useEffect, useState } from "react";
import { fetchClient } from "../../../helpers/fetchClient";
import NotificationIcon from "@mui/icons-material/Notifications";
import { BellIcon } from "@heroicons/react/24/outline";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import AlertIcon from "@mui/icons-material/Error";
import theme from "../../../helpers/theme";

import "./Alerts.scss";

import {
  Button,
  Box,
  Menu,
  MenuItem,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import moment from "moment";
import QuotePay from "../../QuotePay";
import ActionAlert from "../../../data/models/NeedsAttention";
import { ActionAlertsContext } from "../../../data/State/ActionAlerts";

const Alerts: React.FC = () => {
  const { alerts, actions, activeAlertQuoteId } =
    useContext(ActionAlertsContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [quote, setQuote] = useState<string | null>(null);

  const open = Boolean(anchorEl);

  function handleDropdownOpen(e: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(e.currentTarget);
  }

  function handleDropdownClose() {
    setAnchorEl(null);
  }

  function openQuotePay(quoteCd: string) {
    setAnchorEl(null);
    actions.setActiveQuoteId(quoteCd);
  }

  function closeQuotePay() {
    setQuote(null);
  }

  if (alerts && alerts.length)
    return (
      <React.Fragment>
        <Box>
          <Box
            onClick={handleDropdownOpen}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="span"
              sx={{
                cursor: "pointer",
                position: "relative",
                marginRight: "4px",
              }}
            >
              <BellAlertIcon
                style={{
                  height: "32px",
                  width: "32px",
                  color: "rgba(0,0,0,.45)",
                }}
              />
              <Box
                sx={{
                  borderRadius: "50%",
                  border: `solid 1px ${theme.palette.primary.light}`,
                  position: "absolute",
                  bottom: "6px",
                  right: "-8px",
                  background: "white",
                  color: theme.palette.primary.light,
                  padding: "4px",
                  fontWeight: 600,
                  fontSize: "10px",
                  lineHeight: "1",
                }}
              >
                {alerts.length}
              </Box>
            </Box>
            {/* <Typography
              variant="body1"
              component="span"
              style={{
                textTransform: "none",
                fontWeight: "500",
              }}
              marginLeft={1}
            >
              {alerts.length} Alerts
            </Typography> */}
          </Box>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleDropdownClose}
            style={{ width: "450px", maxHeight: "500px", maxWidth: "100%" }}
            classes={{
              paper: "scrollbar",
            }}
          >
            {alerts.map((alert, index) => [
              <Alert
                key={alert.entityID}
                alert={alert}
                divider={index !== alerts.length - 1}
                onClick={openQuotePay}
                closeMenu={() => setAnchorEl(null)}
              />,
              index !== alerts.length - 1 ? <Divider /> : null,
            ])}
          </Menu>
        </Box>
        {activeAlertQuoteId !== null && (
          <QuotePay
            quoteNoteId={activeAlertQuoteId}
            handleClose={() => actions.setActiveQuoteId(null)}
            onSuccess={actions.fetch}
          />
        )}
      </React.Fragment>
    );

  return <div></div>;
};

interface AlertProps {
  alert: ActionAlert;
  divider: boolean;
  onClick: (quoteCd: string) => void;
  closeMenu: () => void;
}

const Alert: React.FC<AlertProps> = ({ alert, onClick, closeMenu }) => {
  function handleMyPCMAlertClick() {
    closeMenu();
    fetchClient.post({
      path: "/alerts/generate-url",
      onSuccess: onAlertsSuccess,
      data: alert,
    });
  }

  function onAlertsSuccess(res: { url: string }) {
    const fauxLink = document.createElement("a");
    fauxLink.href = res.url;
    fauxLink.target = "_blank";
    fauxLink.click();
    document.removeChild(fauxLink);
  }

  if (alert.job === "API") {
    return (
      <MenuItem
        style={{ display: "block" }}
        onClick={() => onClick(alert.entityCD)}
      >
        <Box className="alertHeader">
          <Typography
            variant="caption"
            component="span"
            color={theme.palette.secondary.main}
          >
            <AlertIcon color="primary" /> Payment Needed
          </Typography>
        </Box>
        <Box className="alertBody">
          <Typography variant="caption" component="p">
            We need payment for Batch #{alert.entityID} with orders from{" "}
            {moment(alert.date).format("MM/DD")}
          </Typography>
        </Box>
      </MenuItem>
    );
  }
  return (
    <MenuItem style={{ display: "block" }} onClick={handleMyPCMAlertClick}>
      <Box className="alertHeader">
        <Grid container justifyContent="space-between">
          <Grid item xs="auto">
            <Typography
              variant="caption"
              component="span"
              color={theme.palette.secondary.main}
            >
              <AlertIcon color="primary" /> {alert.myPCMStatus}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="caption">PCM Order #{alert.job}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className="alertBody">
        <Typography variant="caption" component="p">
          {alert.alertMessage}
        </Typography>
      </Box>
    </MenuItem>
  );
};

export default Alerts;
