import $ from "jquery";
import json2csv from "json2csv";

import { fetchClient } from "../helpers/fetchClient";

import logger from "../helpers/logger";

export function convertToCurrency(number) {
  return "$" + (number < 0 ? "0" : "") + number.toFixed(2);
}

export function trimPhoneNumber(number) {
  if (number == null || number == "") {
    return "";
  }
  if (!number.match(/[a-z]/i)) {
    if (number.length > 10) {
      return number
        .slice(number.length % 10)
        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return number;
}
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function base64ToArrayBuffer(data) {
  var binaryString = window.atob(data);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}
export function saveByteArray(reportName, byte) {
  var blob = new Blob([byte], {
    type: "application/pdf",
  });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
}

export function sortArrayBy(array, key, direction) {
  var newArray = void 0;
  if (direction == "asc") {
    newArray = array.sort(function (a, b) {
      var keyA = a[key],
        keyB = b[key];
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  } else {
    newArray = array.sort(function (a, b) {
      var keyA = a[key],
        keyB = b[key];
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });
  }
  return newArray;
}

export function loadScript(src) {
  var tag = document.createElement("script");
  tag.async = false;
  tag.src = src;
  document.querySelector("body").appendChild(tag);
}

export function createCSV(data, filename) {
  var JSONparser = json2csv.Parser;
  var parser = new JSONparser();
  var csv = parser.parse(data);

  var download = void 0,
    link = void 0;

  if (csv == null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = "data:text/csv;charset=utf-8," + csv;
  }
  download = encodeURI(csv);

  link = document.createElement("a");
  link.setAttribute("href", download);
  link.setAttribute("download", filename);

  link.click();
}
export function exportCSV(data, filename) {
  var JSONparser = json2csv.Parser;
  var parser = new JSONparser();
  logger.log(data);
  var csv = parser.parse(data);

  var download = void 0,
    link = void 0;

  if (csv == null) return;

  // if (!csv.match(/^data:text\/csv/i)) {
  //     csv = "data:text/csv;charset=utf-8,%EF%BB%BF" + csv;
  // }
  logger.log(csv);
  //download = encodeURIComponent(csv);
  var blob = new Blob([csv], { type: "text/csv" });

  //console.log(download);
  link = document.createElement("a");
  link.setAttribute("href", window.URL.createObjectURL(blob));
  link.setAttribute("download", filename + ".csv");

  link.click();
}
export function sanitizeDomain(domain) {
  if (!domain) return "TBD";
  var sanitizedDomain = domain
    .replace("http://", "")
    .replace("https://", "")
    .replace("www.", "");
  if (sanitizedDomain.charAt(sanitizedDomain.length - 1) == "/") {
    sanitizedDomain = sanitizedDomain.split("");

    sanitizedDomain[sanitizedDomain.length - 1] = "";

    sanitizedDomain.join();
  }
  return sanitizedDomain;
}
/**
 *
 * @param {string} url
 * @param {string} type
 * @param {function} callback
 * @param {object} payload
 * @param {function} error
 */
export function apiCall(url, type, callback, payload, error, body) {
  const urlTrimmed = url.replace("api/", "");
  let req;
  if (type === "GET") {
    req = fetchClient.get({
      path: urlTrimmed,
      onSuccess: callback,
      onError: error,
    });
  }
  if (type === "POST") {
    req = fetchClient.post({
      path: urlTrimmed,
      onSuccess: callback,
      onError: error,
      data: payload,
    });
  }
  if (type === "PUT") {
    req = fetchClient.put({
      path: urlTrimmed,
      onSuccess: callback,
      onError: error,
      data: payload,
    });
  }
  return req;
}

export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

logger.log("using helpers");

window.onbeforeprint = function () {
  logger.log("printing");
  var textareas = document.querySelectorAll("textarea");
  var oldTextareas = document.querySelectorAll(".text-area-mask");

  logger.log(oldTextareas);
  for (var j = 0; j < oldTextareas.length; j++) {
    oldTextareas[j].remove();
  }

  for (var i = 0; i < textareas.length; i++) {
    var textarea = textareas[i];
    var parent = textarea.parentNode.parentNode;

    if (parent && !textarea.getAttribute("aria-hidden")) {
      parent.innerHTML =
        parent.innerHTML +
        "<div class='text-area-mask'>" +
        textarea.value +
        "</div>";
    }
  }
};

export var CALL_NOT_RATED_HOME = "TBD";
export var CALL_NOT_RATED = "Call Not Rated Yet";

export var CALL_RATING_REASONS = {
  R1: "An appointment was scheduled?",
  R2: "Asked prospect to make an appointment?",
  R3: "Asked for the caller’s name and used it twice during the call?",
  R4: "Asked how the caller found out about the practice?",
  R5: "Call was professional?",
  R6: "Proper introduction script was used?",
  R7: "Caller was put on hold for less than 1 minute?",
};
