import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useContext } from "react";
import { OrderRequestContext } from "../../../PlaceAnOrder";

interface MapListToFieldsFieldProps {
  name:
    | "firstName"
    | "lastName"
    | "company"
    | "address"
    | "address2"
    | "city"
    | "state"
    | "zipCode"
    | "extRefNbr";
  error?: boolean;
}

const MapListToFieldsField: React.FC<MapListToFieldsFieldProps> = ({
  name,
  error,
}) => {
  /**
   * State Objects and Refs
   */
  const orderRequestStore = useContext(OrderRequestContext);
  /**
   * Component Methods
   */
  function handleChange(
    e: React.SyntheticEvent<Element, Event>,
    value: string | null
  ) {
    orderRequestStore.updateListMapping(name, value ? value : "");
  }

  function getFieldValue(): string {
    const record = orderRequestStore.orderRequest.listAttributes?.records[0];
    if (record) {
      if (record[value]) return record[value] as string;
    }
    return "";
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */
  const item = orderRequestStore.orderRequest.listMapping.filter(
    (x) => x.fieldName === name
  );
  let value = "";
  if (item && item.length) {
    value = item[0].userListFieldName;
  }

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={6}>
        <Autocomplete
          value={value}
          options={
            orderRequestStore.orderRequest.listAttributes?.listHeaders ?? []
          }
          fullWidth
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              label="Select From Your List"
            />
          )}
        />
        {error && (
          <FormHelperText error>
            {item &&
            item.length > 0 &&
            (item[0].fieldName === "firstName" ||
              item[0].fieldName === "company")
              ? `You must map First & Last name or Company`
              : "You must select an option"}
          </FormHelperText>
        )}
        {/* <FormControl fullWidth>
          {!value && (
            <InputLabel id={name + "-input"}>Select From Your List</InputLabel>
          )}
          <Select
            onChange={handleChange}
            value={value}
            error={error}
            label={value ? undefined : "Select From Your List"}
            labelId={name + "-input"}
          >
            {orderRequestStore.orderRequest.listAttributes?.listHeaders.map(
              (header) => (
                <MenuItem value={header} key={header}>
                  {header}
                </MenuItem>
              )
            )}
          </Select>
         
        </FormControl> */}
      </Grid>
      <Grid item xs={6}>
        {Boolean(value) === true && <em>{getFieldValue()}</em>}
      </Grid>
    </Grid>
  );
};

export default MapListToFieldsField;
