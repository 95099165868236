import { useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import useAssetLibrary from "../../hooks/useAssetLibrary";
import { CanvasContext } from "../../state/contexts/CanvasContext";
import tw, { css } from "twin.macro";
import propertiesStyles from "../DesignerInterface/Properties/propertiesStyles";
import IAsset from "../../../data/models/IAsset";

const styles = {
  container: [
    tw`w-full h-32 border-2 border-dashed border-border bg-inputBackground flex flex-col items-center justify-center mb-6 text-text rounded`,
  ],
  heading: [tw`text-lg capitalize font-semibold`],
  body: [tw`text-sm`],
  imageContainer: tw`w-28 mr-4 mb-4`,
  imageWrapper: (isActive: boolean) => [
    tw`w-28 h-28 overflow-clip flex flex-col`,
    isActive && tw`border-2 border-solid border-border bg-hover`,
  ],
  imageName: tw`text-center`,
  image: [tw`w-full max-w-full block mt-auto mb-auto hover:cursor-pointer`],
};

interface UploadSidebarDropzoneProps {
  isSwap?: boolean;
  callback?: (asset: IAsset) => void;
}

const UploadSidebarDropzone = ({
  isSwap = false,
  callback,
}: UploadSidebarDropzoneProps) => {
  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const newAsset = await onDropFile(acceptedFiles[0], isSwap);
      if (newAsset && callback) {
        callback(newAsset);
      }
    }
  };
  const { onDropFile } = useAssetLibrary();
  const { getRootProps, getInputProps, isDragActive, open, inputRef } =
    useDropzone({
      onDrop,

      noKeyboard: true,
      onError: (err) => {
        console.log(err);
      },

      accept: {
        "image/jpeg": [],
        "image/png": [],
        "image/jpg": [],
        "image/svg": [],
        "image/svg+xml": [],
        "image/bmp": [],
      },
    });

  return (
    <div>
      <div {...getRootProps({ className: "dropzone" })} css={styles.container}>
        <h6 css={styles.heading}>Drop Image Here</h6>
        <p css={styles.body}>Or Click Here To Upload</p>
        <input
          {...getInputProps()}
          onKeyDown={(e) => e.preventDefault()}
          style={{ display: "none" }}
          accept="image/png, image/jpeg, image/jpg, image/svg, image/svg+xml, image/bmp"
        />
      </div>
    </div>
  );
};
export default UploadSidebarDropzone;
