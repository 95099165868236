import { SerializedStyles } from "@emotion/react";
import React, { forwardRef, RefObject } from "react";
import tw, { TwStyle, css } from "twin.macro";
import EditorTooltip from "../EditorTooltip";

interface IDesignerInputWrapperProps {
  small: boolean;
  children: React.ReactFragment;
  isOpen: boolean;
  styleOverride?: (TwStyle | SerializedStyles)[] | (TwStyle | SerializedStyles);
  position?: "left" | "right" | "center";
  tooltip?: string | React.ReactFragment;
  disabled?: boolean;
}

const styles = {
  wrapper: (
    isSmall: boolean,
    isOpen: boolean,
    override?: (TwStyle | SerializedStyles)[] | (TwStyle | SerializedStyles),
    position?: "left" | "right" | "center",
    disabled?: boolean
  ) => [
    tw`bg-inputBackground text-text relative border border-solid border-hover box-border`,
    isSmall && tw`p-1 text-sm h-small`,

    !isSmall && tw`p-2 py-2`,
    isOpen && tw`rounded-b-none`,
    override && override,
    position === undefined && tw`rounded`,
    position === "left" && tw`rounded-l`,
    position === "right" && tw`rounded-r`,
    position === "center" && tw`rounded-none`,
    disabled && tw`bg-buttonTextDisabled cursor-not-allowed`,
  ],
  tooltip: [
    tw`bg-text rounded p-1 whitespace-nowrap bg-opacity-10 text-white`,
    css`
      font-size: 12px;
    `,
  ],
};

const DesignerInputWrapper = forwardRef<
  HTMLDivElement,
  IDesignerInputWrapperProps
>(
  (
    {
      small,
      children,
      isOpen,
      styleOverride,
      position,
      tooltip,
      disabled,
    }: IDesignerInputWrapperProps,
    ref
  ) => {
    if (tooltip !== undefined) {
      return (
        <EditorTooltip
          position="top"
          label={
            <div
              css={styles.wrapper(
                small,
                isOpen,
                styleOverride,
                position,
                disabled
              )}
              ref={ref}
            >
              {children}
            </div>
          }
        >
          <div css={styles.tooltip}>{tooltip}</div>
        </EditorTooltip>
      );
    }
    return (
      <div
        css={styles.wrapper(small, isOpen, styleOverride, position, disabled)}
        ref={ref}
      >
        {tooltip !== undefined ? (
          <EditorTooltip label={children}>
            <div css={styles.tooltip}>{tooltip}</div>
          </EditorTooltip>
        ) : (
          children
        )}
      </div>
    );
  }
);

export default DesignerInputWrapper;
