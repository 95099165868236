import { DependencyList, useEffect, useMemo, useRef } from "react";

const useDebounce = (fn: () => void, ms: number, deps: DependencyList) => {
  const ref = useRef<NodeJS.Timeout>();
  function handleDebounce() {
    if (ref.current) clearTimeout(ref.current);
    ref.current = setTimeout(() => fn(), ms);
  }
  useEffect(handleDebounce, [...deps]);
};

export default useDebounce;
