import IEnvelopeOrder from "./IEnvelopeOrder";

export default interface IEnvelope {
  envelopeID: number;
  friendlyName: string;
  createdDate: string;
  envelopeType: EnvelopeType;
  front?: string;
  back?: string;
  lastOrderDate: string;
  orderQty: number;
  stockQty: number;
  lastOrder: IEnvelopeOrder;
  replenishmentMethod: ReplenishmentMethod;
  replenishmentValue: number;
  envelopeOrderHistory: IEnvelopeOrder[];
  pmid: number;
}

export type EnvelopeType = "#10 Outer Single Window";

export enum ReplenishmentMethod {
  None = "None",
  Percentage = "Percentage",
  Static = "Static",
}
