import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import formattingHelpers from "../../../helpers/formattingHelpers";
import LocalStorage from "../../../helpers/LocalStorage";
import Account from "../../../data/models/Account";
import LoginResponse from "../../../routes/Login/models/LoginResponse";
import { fetchClient } from "../../../helpers/fetchClient";
import SessionInfo from "../../../data/models/SessionInfo";
import { Link } from "react-router-dom";
import isAdminUser from "../../../helpers/isAdminUser";
import DropdownMenu from "./DropdownMenu";
import { MenuItem, Typography } from "@mui/material";

const BusinessSelector: React.FC = (props) => {
  /**
   * State Objects and Refs
   */
  const [availableCompanies, setAvailableCompanies] = useState<Account[]>([]);
  const [currentCompany, setCurrentCompany] = useState<Account>({} as Account);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  /**
   * Component Methods
   */

  function onLoad() {
    getAvailableCompanies();
  }

  function getAvailableCompanies() {
    const lsCompanies = LocalStorage.getCompanies();
    const lsCurrentCompany = LocalStorage.getCurrentCompany();

    if ((lsCurrentCompany === null || lsCompanies === null) && !isAdminUser())
      history.push("/login");
    setAvailableCompanies(lsCompanies);
    setCurrentCompany(
      lsCompanies.filter((x) => x.baccountId === lsCurrentCompany)[0]
    );
    setLoading(false);
  }

  /**
   * Component Effects
   */

  useEffect(onLoad, []);

  /**
   * @Todo remove, debugging only
   */

  /**
   * Component Methods
   */
  function handleChangeCompanies(companyId: number) {
    fetchClient.post({
      path: `/auth/change-companies?companyId=${companyId}`,
      onSuccess: onChangeCompanies,
    });
  }

  function onChangeCompanies(data: LoginResponse) {
    LocalStorage.setToken(data.token);
    LocalStorage.setCompanies(data.companies);
    LocalStorage.setCurrentCompany(data.currentCompanyId);
    fetchClient.get({ path: "/account/session", onSuccess: onSessionResponse });
  }

  function onSessionResponse(data: SessionInfo) {
    LocalStorage.setSession(data);
    history.go(0);
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */

  if (loading) return <div />;
  if (availableCompanies.length === 1) {
    return (
      <Typography sx={{ color: (theme) => theme.palette.info.main }}>
        {formattingHelpers.truncateString(currentCompany.acctName, 50)}
      </Typography>
    );
  }
  if (availableCompanies.length > 1) {
    return (
      <DropdownMenu
        label={formattingHelpers.truncateString(currentCompany.acctName, 50)}
        sx={{ color: (theme) => theme.palette.info.main }}
      >
        {availableCompanies.map((company) => {
          return (
            <MenuItem
              key={company.acctCd}
              onClick={() => handleChangeCompanies(company.baccountId)}
              sx={
                company.baccountId === currentCompany.baccountId
                  ? {
                      background: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.primary.contrastText,
                    }
                  : undefined
              }
            >
              {company.acctName}
            </MenuItem>
          );
        })}
      </DropdownMenu>
    );
  }
  // if (availableCompanies.length > 0)
  //   return (
  //     <div id="business-selector-form" className="dropdown d-inline-block">
  //       <button
  //         className="bAccountSelector__selected dropdown-toggle buttonReset"
  //         data-bs-toggle="dropdown"
  //       >
  //         <span className="name" data-name={currentCompany.acctName}>
  //           {formattingHelpers.truncateString(currentCompany.acctName, 50)}
  //         </span>
  //       </button>
  //       <ul className="bAccountSelector__list dropdown-menu">
  //         {availableCompanies.map((company) => (
  //           <li key={company.baccountId}>
  //             <button
  //               className={`dropdown-item ${
  //                 company.baccountId === currentCompany.baccountId
  //                   ? "active"
  //                   : ""
  //               }`}
  //               onClick={(e) => {
  //                 e.preventDefault();
  //                 handleChangeCompanies(company.baccountId);
  //               }}
  //             >
  //               {company.acctName}
  //             </button>
  //           </li>
  //         ))}
  //         <li>
  //           <Link to="/logout" className={`dropdown-item color-primary`}>
  //             Logout
  //           </Link>
  //         </li>
  //       </ul>
  //     </div>
  //   );

  return <div />;
};

export default BusinessSelector;
