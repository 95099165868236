import { Box, Collapse, Link as ATag } from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { INavbarRoute } from "../routes";
import ExpandMore from "../../../../Utilities/ExpandMore";

interface ISidebarLinkProps {
  route: INavbarRoute;
  paddingOverride?: string;
}

const SidebarLink = ({
  route,
  paddingOverride = "16px",
}: ISidebarLinkProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const location = useLocation();
  const isActive = location.pathname === route.path;

  function handleExpansion(e: React.MouseEvent) {
    setIsExpanded(!isExpanded);
  }

  const hasIcon = Boolean(route.icon);
  const isLink = Boolean(route.path);
  const isExternal = route.path.startsWith("http");
  const hasChildren = Boolean(route.children);
  const sx = {
    paddingRight: "16px",
    pb: 1,
    pt: 1,
    paddingLeft: paddingOverride,
    fontWeight: 400,
    fontSize: 16,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    color: "rgba(255,255,255,1)",
    borderRadius: isActive && "8px",
    transition: "background ease-out .25s",
    ":hover": {
      background: "rgba(0,0,0,.1)",
    },
  };

  if (isLink)
    return (
      <Box>
        <ATag
          sx={{ ...sx, fontWeight: 400 }}
          component={!isExternal ? Link : "a"}
          to={!isExternal ? route.path : undefined}
          href={isExternal ? route.path : undefined}
          target={isExternal ? "_blank" : undefined}
        >
          {hasIcon && route.icon}
          {route.label}
        </ATag>
        {hasChildren && (
          <Box>
            {route.children?.map((child) => (
              <SidebarLink
                route={child}
                key={child.label}
                paddingOverride="50px"
              />
            ))}
          </Box>
        )}
      </Box>
    );

  return (
    <Box>
      <Box sx={{ ...sx, cursor: "pointer" }} onClick={handleExpansion}>
        {hasIcon && route.icon}
        {route.label}
        <ExpandMore isExpanded={isExpanded} onClick={handleExpansion}>
          <ChevronDownIcon
            style={{ height: "20px", width: "20px", color: "rgb(255,255,255)" }}
          />
        </ExpandMore>
      </Box>
      {hasChildren && (
        <Collapse in={isExpanded}>
          {route.children?.map((child) => (
            <SidebarLink
              route={child}
              key={child.label}
              paddingOverride="50px"
            />
          ))}
        </Collapse>
      )}
    </Box>
  );
};

export default SidebarLink;
