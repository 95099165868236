import React, { useEffect, useState } from "react";

import Integration from "./models/Integration";

import "./Admin.scss";

import Integrations from "./components/Integrations";
import {
  Box,
  Button,
  Container,
  Grid,
  Skeleton,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { fetchClient } from "../../helpers/fetchClient";
import { Link, Redirect } from "react-router-dom";
import isAdminUser from "../../helpers/isAdminUser";
import LoadingButton from "../../shared/LoadingButton";
import { PricingPlan } from "../PricingPlans/models/PricingPlanModel";
import PricingPlanModel from "../PricingPlans/models/PricingPlanModel";
import useGetData from "../../hooks/dataFetchers/useGetData";
import LoadingWrapper from "../../shared/LoadingWrapper";
/**
 * Page/Component specific types
 * used for state objects generally
 */

const Admin: React.FC = () => {
  /**
   * State Objects and Refs
   */

  const {
    data: integrations,
    isLoading: loading,
    error,
    updateData: setIntegrations,
  } = useGetData<Integration[]>(`/api/admin/integrations`);

  const [open, setOpen] = useState(false);
  const [planType, setPlanType] = useState<string | null>(null);
  const [pricingPlans, setPricingPlans] = useState<PricingPlan[]>([]);
  const [syncState, setSyncState] = useState({
    status: "none",
    message: "",
  });
  /**
   * Component Methods
   */
  // function getAdminData() {
  //   fetchClient.get({
  //     endpoint: "/admin/integrations",
  //     callback: adminDataCallback
  //   });

  // }

  /**
   * Component Effects
   */

  // Get Data

  const isAdmin = isAdminUser();

  // sync all integrations
  function syncAllIntegrations() {
    setSyncState({ ...syncState, status: "loading" });
    fetchClient.post({
      path: "/admin/integrations/sync-integrations",
      onSuccess: onSyncIntegrationsSuccess,
      onError: onSyncIntegrationsFailure,
    });
  }

  function onSyncIntegrationsFailure(error: { message: string }) {
    let message = "Something went wrong";
    if (error && error.message) {
      message = error.message;
    }
    setSyncState({ ...syncState, status: "error", message });
  }

  function onSyncIntegrationsSuccess(data: Integration[]) {
    setSyncState({ ...syncState, status: "success", message: "" });
    setIntegrations([...data]);
  }

  // sync single integration
  function syncSingleIntegrationCallback(data: Integration) {
    if (integrations) {
      const integrationIndex = integrations.findIndex(
        (x) => x.bAccountID === data.bAccountID,
      );
      if (integrationIndex !== -1) {
        const updatedIntegrations = [...integrations];
        updatedIntegrations[integrationIndex] = data;
        setIntegrations(updatedIntegrations);
      }
    }
  }

  function getPricingPlans() {
    fetchClient.get({
      path: "/pricing/plans",
      onSuccess: (data: PricingPlanModel) => {
        setPricingPlans(data.pricingPlans);
        setPlanType(data.planType);
        setOpen(true);
      },
    });
  }
  /**
   * Render
   */
  return (
    <Box sx={{ mb: 6, mt: 6 }}>
      <div id="adminPage" className="page">
        <div className="page__header">
          <h3 className="page__header__heading">Admin Panel</h3>
        </div>
        <div className="page__content">
          <div className="section" id="integrations">
            <Grid container spacing={3}>
              <Grid item xs="auto">
                <Link to="/admin/integrations/0/designs">
                  <Button variant="contained" color="primary">
                    Manage Uproduce Templates
                  </Button>
                </Link>
              </Grid>
              <Grid item xs="auto">
                <Link to="/admin/html">
                  <Button variant="contained" color="primary">
                    Manage HTML Templates
                  </Button>
                </Link>
              </Grid>
              <Grid item xs="auto">
                <Link to="/admin/design-sizes">
                  <Button variant="contained" color="primary">
                    Manage Design Sizes
                  </Button>
                </Link>
              </Grid>
              <Grid item xs="auto">
                <Link to="/admin/pricing">
                  <Button variant="contained" color="primary">
                    Manage Pricing Plans
                  </Button>
                </Link>
              </Grid>
              <Grid item xs="auto">
                <Link to="/admin/designer">
                  <Button variant="contained" color="primary">
                    Postcard Designer
                  </Button>
                </Link>
              </Grid>
              <Grid item xs="auto">
                <LoadingButton
                  loading={syncState.status === "loading"}
                  success={syncState.status === "success"}
                  variant="contained"
                  color="secondary"
                  onClick={syncAllIntegrations}
                >
                  Sync Integrations With NIMBLE
                </LoadingButton>
                {syncState.status === "error" && (
                  <Typography color="red" variant="caption">
                    {syncState.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <LoadingWrapper
              loading={loading}
              hasError={error.hasError}
              errorMessage={error.errorMessage}
              height={300}
            >
              <Box>
                <Integrations
                  data={integrations}
                  syncCallback={syncSingleIntegrationCallback}
                  getPricingPlans={getPricingPlans}
                />
              </Box>
              <Dialog
                open={open}
                fullWidth={true}
                maxWidth="sm"
                onClose={() => setOpen(false)}
              >
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="plan-type-label">Plan Type</InputLabel>
                        <Select
                          labelId="plan-type-label"
                          id="plan-type-select"
                          value={planType ? planType : ""}
                          fullWidth
                          sx={{ backgroundColor: "white" }}
                          label="Plan Type"
                          onChange={(e) => setPlanType(e.target.value)}
                        >
                          {pricingPlans.map((plan) => (
                            <MenuItem
                              key={plan.priceClass}
                              value={plan.planName ? plan.planName : ""}
                            >
                              {plan.planName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color="secondary" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() =>
                      fetchClient.post({
                        path: `/pricing/plan-type?plan=${planType}`,
                        onSuccess: () => setOpen(false),
                      })
                    }
                    color="primary"
                  >
                    Update
                  </Button>
                </DialogActions>
              </Dialog>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Admin;
