import { Box, BoxProps, Menu, SxProps, Theme } from "@mui/material";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useRef, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import UnstyledButton from "../../BasicHTML/UnstyledButton";

interface DropdownMenuProps {
  label: string;
  sx?: SxProps<Theme>;
}
const DropdownMenu: React.FC<DropdownMenuProps> = ({ label, children, sx }) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef<HTMLElement>();
  return (
    <Box>
      <Box ref={anchorEl}>
        <UnstyledButton
          onClick={() => setIsOpen(!isOpen)}
          sx={
            sx
              ? { display: "flex", alignItems: "center", ...sx }
              : { display: "flex", alignItems: "center" }
          }
        >
          {label}{" "}
          <ChevronDownIcon
            style={{
              color: "inherit",
              height: "18px",
              width: "18px",
              marginLeft: "4px",
            }}
          />
        </UnstyledButton>
      </Box>
      <Menu
        anchorEl={anchorEl.current}
        open={isOpen}
        onClick={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
      >
        {children}
      </Menu>
    </Box>
  );
};

export default DropdownMenu;
