import tw, { css } from "twin.macro";
import useAssetLibrary from "../../../hooks/useAssetLibrary";
import { selectAssetLibrary } from "../../../state/slices/assetLibrary";
import { useDesignerSelector } from "../../../state/store";
import UploadSidebarDropzone from "../../ImageDropzone/UploadsSidebarDropzone";
import ButtonReset from "../shared/ButtonReset";
import propertiesStyles from "../Properties/propertiesStyles";
import PropertyDropdown from "../Properties/PropertyDropdown";
import DraggableImage from "./DraggableImage";
import NonDraggableImage from "./NonDraggableImage";
import IAsset from "../../../../data/models/IAsset";
import { useContext, useState } from "react";
import Modal, { ModalActions, ModalBody, ModalTitle } from "../shared/Modal";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import { Group } from "fabric/fabric-impl";
import asyncImageFromUrl from "../../Canvas/functions/asyncImageFromUrl";
import asyncClone from "../../Canvas/functions/asyncClone";
import { groupCleanup } from "../../../hooks/useImageMasking/functions/changeMaskFunctions";
import { IImageGroup } from "../../../hooks/useImageMasking/useImageMasking";
import ToolButton from "../shared/ToolButton";
import toolbarStyles from "../TopToolbar/toolbarStyles";

const styles = {
  section: [tw`mb-4 mt-4`],
  button: [tw`bg-blue uppercase text-white hover:bg-opacity-60 px-4 py-2`],
  container: [tw`p-4 `],
  dynamicImageWrap: [
    tw`overflow-hidden rounded border-dashed border-4 border-labels inline-block cursor-pointer`,
  ],
};

interface SwapImageProps {
  open: boolean;
  onClose: () => void;
}

const SwapImage = ({ open, onClose }: SwapImageProps) => {
  const assetLibrary = useDesignerSelector(selectAssetLibrary);
  const canvas = useContext(CanvasContext);
  const [selectedImage, setSelectedImage] = useState<IAsset>();
  function handleSelectImage(asset: IAsset) {
    setSelectedImage(asset);
  }

  function handleClose() {
    setSelectedImage(undefined);
    onClose();
  }

  async function handleSwap() {
    if (!canvas || !canvas.getActiveObject() || !selectedImage) return;
    const imageGroup = canvas.getActiveObject() as Group;
    if (imageGroup.type !== "group") return;
    const objects = imageGroup.getObjects();
    const image = objects.find((x) => x.type === "image");

    const frame = objects.find((x) => x.type !== "image");

    if (!image || !frame) return;

    const newImage = await asyncImageFromUrl(selectedImage.url);

    newImage.originX = "center";
    newImage.originY = "center";

    setTimeout(async () => {
      if (newImage) {
        const frameWidth = frame.width ?? 0;
        const frameHeight = frame.height ?? 0;

        if (frameWidth > frameHeight) {
          newImage.scaleToWidth(frameWidth);
        } else {
          newImage.scaleToHeight(frameHeight);
        }
        frame.set({
          originX: "center",
          originY: "center",
          top: 0 - (newImage.top ?? 0) / (newImage.scaleX ?? 1),
          left: 0 - (newImage.left ?? 0) / (newImage.scaleY ?? 1),
          scaleX: 1 / (newImage.scaleX ?? 1),
          scaleY: 1 / (newImage.scaleY ?? 1),
        });
        await groupCleanup(imageGroup as IImageGroup, newImage, frame);
        setSelectedImage(undefined);
        canvas.fire("object:modified");
        onClose();
      }
    }, 500);
  }

  return (
    <Modal width={500} isOpen={open}>
      <ModalTitle>Change Image</ModalTitle>
      <ModalBody>
        <div>
          <UploadSidebarDropzone isSwap callback={handleSelectImage} />

          {assetLibrary.customerAssets.length > 0 && (
            <PropertyDropdown
              label="Your Uploads"
              defaultValue={true}
              maxHeight={300}
            >
              <div tw="flex items-end flex-wrap">
                {assetLibrary.customerAssets.map((asset) => (
                  <NonDraggableImage
                    key={asset.id}
                    asset={asset}
                    isActive={selectedImage?.id === asset.id}
                    onClick={() => handleSelectImage(asset)}
                  />
                ))}
              </div>
            </PropertyDropdown>
          )}
          {assetLibrary.designAssets.length > 0 && (
            <PropertyDropdown
              label="Design Images"
              defaultValue={true}
              maxHeight={300}
            >
              <div tw="flex items-end flex-wrap">
                {assetLibrary.designAssets.map((asset) => (
                  <NonDraggableImage
                    key={asset.id}
                    asset={asset}
                    isActive={selectedImage?.id === asset.id}
                    onClick={() => handleSelectImage(asset)}
                  />
                ))}
              </div>
            </PropertyDropdown>
          )}
        </div>
      </ModalBody>
      <ModalActions>
        <ToolButton
          onClick={handleSwap}
          disabled={!Boolean(selectedImage)}
          css={[toolbarStyles.button, tw`ml-auto`]}
        >
          Confirm
        </ToolButton>{" "}
        <ToolButton
          onClick={handleClose}
          css={[toolbarStyles.utilityButton, tw`ml-1`]}
        >
          Cancel
        </ToolButton>
      </ModalActions>
    </Modal>
  );
};

export default SwapImage;
