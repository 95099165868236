import { ReactComponent as Slash } from "../../../../assets/images/designer-svg/SlashIcon.svg";
import { ReactComponent as SelectInput } from "../../../../assets/images/designer-svg/SelectInputIcon.svg";
import { ReactComponent as X } from "../../../../assets/images/designer-svg/XIcon.svg";
import { ReactComponent as Text } from "../../../../assets/images/designer-svg/TextIcon.svg";
import { ReactComponent as Doodle } from "../../../../assets/images/designer-svg/DoodleIcon.svg";
import { ReactComponent as Image } from "../../../../assets/images/designer-svg/ImageIcon.svg";
import { ReactComponent as Shape } from "../../../../assets/images/designer-svg/ShapeIcon.svg";
import { ReactComponent as Select } from "../../../../assets/images/designer-svg/SelectIcon.svg";
import { ReactComponent as StrokeWidth } from "../../../../assets/images/designer-svg/StrokeWidthIcon.svg";
import { ReactComponent as ZoomPlus } from "../../../../assets/images/designer-svg/ZoomPlus.svg";
import { ReactComponent as ZoomMinus } from "../../../../assets/images/designer-svg/ZoomMinus.svg";
import { ReactComponent as Plus } from "../../../../assets/images/designer-svg/PlusIcon.svg";
import { ReactComponent as Minus } from "../../../../assets/images/designer-svg/MinusIcon.svg";
import { ReactComponent as LeftAlign } from "../../../../assets/images/designer-svg/LeftAlignIcon.svg";
import { ReactComponent as CenterAlign } from "../../../../assets/images/designer-svg/CenterAlignIcon.svg";
import { ReactComponent as RightAlign } from "../../../../assets/images/designer-svg/RightAlignIcon.svg";

import { ReactComponent as Bold } from "../../../../assets/images/designer-svg/BoldIcon.svg";
import { ReactComponent as Italic } from "../../../../assets/images/designer-svg/ItalicIcon.svg";
import { ReactComponent as Underline } from "../../../../assets/images/designer-svg/UnderlineIcon.svg";
import { ReactComponent as Gear } from "../../../../assets/images/designer-svg/GearIcon.svg";

// Shapes
import { ReactComponent as Fill } from "../../../../assets/images/designer-svg/FillIcon.svg";
import { ReactComponent as Rectangle } from "../../../../assets/images/designer-svg/Shapes/rectangle.svg";
import { ReactComponent as Square } from "../../../../assets/images/designer-svg/Shapes/square.svg";
import { ReactComponent as Circle } from "../../../../assets/images/designer-svg/Shapes/circle.svg";
import { ReactComponent as Ellipse } from "../../../../assets/images/designer-svg/Shapes/ellipse.svg";
import { ReactComponent as Triangle } from "../../../../assets/images/designer-svg/Shapes/triangle.svg";

import { ReactComponent as OpenLock } from "../../../../assets/images/designer-svg/OpenLock.svg";
// import { ReactComponent as Lock } from "../../../../assets/images/designer-svg/Lock.svg";

import { ReactComponent as AlignLeft } from "../../../../assets/images/designer-svg/AlignLeft.svg";
import { ReactComponent as AlignRight } from "../../../../assets/images/designer-svg/AlignRight.svg";
import { ReactComponent as AlignTop } from "../../../../assets/images/designer-svg/AlignTop.svg";
import { ReactComponent as AlignBottom } from "../../../../assets/images/designer-svg/AlignBottom.svg";
import { ReactComponent as AlignHCenter } from "../../../../assets/images/designer-svg/AlignHCenter.svg";
import { ReactComponent as AlignVCenter } from "../../../../assets/images/designer-svg/AlignVCenter.svg";

import { ReactComponent as Angle } from "../../../../assets/images/designer-svg/AngleIcon.svg";
import { ReactComponent as SkewX } from "../../../../assets/images/designer-svg/SkewX.svg";
import { ReactComponent as SkewY } from "../../../../assets/images/designer-svg/SkewY.svg";
import { ReactComponent as Layer } from "../../../../assets/images/designer-svg/LayerIcon.svg";
import { ReactComponent as Bars } from "../../../../assets/images/designer-svg/BarsIcon.svg";

import { ReactComponent as Undo } from "../../../../assets/images/designer-svg/UndoIcon.svg";
import { ReactComponent as Redo } from "../../../../assets/images/designer-svg/RedoIcon.svg";

import { ReactComponent as Clipboard } from "../../../../assets/images/designer-svg/ClipboardIcon.svg";
import { ReactComponent as Trash } from "../../../../assets/images/designer-svg/TrashIcon.svg";
import { ReactComponent as Edit } from "../../../../assets/images/designer-svg/EditIcon.svg";
import { ReactComponent as Check } from "../../../../assets/images/designer-svg/CheckIcon.svg";

import { ReactComponent as Lock } from "../../../../assets/images/designer-svg/LayerIcons/lock.svg";
import { ReactComponent as Unlock } from "../../../../assets/images/designer-svg/LayerIcons/lock-open.svg";
import { ReactComponent as Visible } from "../../../../assets/images/designer-svg/LayerIcons/eye.svg";
import { ReactComponent as Hidden } from "../../../../assets/images/designer-svg/LayerIcons/eye-slash.svg";
import { ReactComponent as Info } from "../../../../assets/images/designer-svg/InfoIcon.svg";
import { ReactComponent as Pen } from "../../../../assets/images/designer-svg/PenIcon.svg";
import { ReactComponent as Search } from "../../../../assets/images/designer-svg/SearchIcon.svg";
import { ReactComponent as SortUp } from "../../../../assets/images/designer-svg/SortUpIcon.svg";
import { ReactComponent as SortDown } from "../../../../assets/images/designer-svg/SortDownIcon.svg";

import { ReactComponent as Filter } from "../../../../assets/images/designer-svg/FilterIcon.svg";
import { ReactComponent as Remove } from "../../../../assets/images/designer-svg/RemoveIcon.svg";
import { ReactComponent as Folder } from "../../../../assets/images/designer-svg/FolderIcon.svg";
import { ReactComponent as FolderOpen } from "../../../../assets/images/designer-svg/FolderOpenIcon.svg";
import { ReactComponent as FolderDelete } from "../../../../assets/images/designer-svg/FolderDeleteIcon.svg";
import { ReactComponent as QrCode } from "../../../../assets/images/designer-svg/QrCode.svg";

import { FunctionComponent, SVGProps } from "react";
import { SerializedStyles } from "@mui/styled-engine";
import tw, { TwStyle, css as tmCss } from "twin.macro";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import TwinMacroStyles from "../../../models/types/TwinMacroStyles";

interface ISvgProps {
  size?: number;
  /**
   * @description The Tailwind fill theme color you would like to use for this SVG  OR a standard hex or rgba. Ex: fill-blue
   */
  color?: TwStyle | SerializedStyles;
  styles?: TwinMacroStyles;
}

interface ISvgRendererProps extends ISvgProps {
  Svg: FunctionComponent<SVGProps<SVGSVGElement>>;
  flip?: boolean;
}

export type SvgElement = (props: ISvgProps) => EmotionJSX.Element;

const svgStyles = (
  size: (TwStyle | SerializedStyles)[] | (TwStyle | SerializedStyles),
  color?: (TwStyle | SerializedStyles)[] | (TwStyle | SerializedStyles),
  css?: TwinMacroStyles,
  flip?: boolean,
) => [
  size,
  color && color,
  !color && tw`fill-text`,
  css,
  flip && tw`rotate-180`,
];

export const SvgRenderer = ({
  Svg,
  size,
  color,
  styles,
  flip,
}: ISvgRendererProps) => {
  function getSize() {
    if (!size) return [tw`w-4 h-4`];
    return [tmCss`width: ${size / 4}rem; height: ${size / 4}rem`];
  }

  const css = svgStyles(getSize(), color, styles);

  return <Svg css={css} />;
};

export const SelectInputIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={SelectInput} {...props} />
);
export const ZoomInIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={ZoomPlus} {...props} />
);
export const ZoomOutIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={ZoomMinus} {...props} />
);
export const PlusIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Plus} {...props} />
);
export const MinusIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Minus} {...props} />
);
export const LeftAlignIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={LeftAlign} {...props} />
);
export const CenterAlignIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={CenterAlign} {...props} />
);
export const RightAlignIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={RightAlign} {...props} />
);

export const BoldIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Bold} {...props} />
);
export const ItalicIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Italic} {...props} />
);
export const UnderlineIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Underline} {...props} />
);

export const StrokeWidthIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={StrokeWidth} {...props} />
);

export const FillIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Fill} {...props} />
);

export const RectangleIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Rectangle} {...props} />
);
export const SquareIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Square} {...props} />
);
export const CircleIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Circle} {...props} />
);
export const EllipseIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Ellipse} {...props} />
);
export const TriangleIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Triangle} {...props} />
);

export const ShapeIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Shape} {...props} />
);

export const TextIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Text} {...props} />
);
export const DoodleIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Doodle} {...props} />
);

export const ImageIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Image} {...props} />
);

export const GearIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Gear} {...props} />
);

export const OpenLockIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={OpenLock} {...props} />
);

export const AlignLeftIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={AlignLeft} {...props} />
);
export const AlignRightIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={AlignRight} {...props} />
);
export const AlignTopIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={AlignTop} {...props} />
);
export const AlignBottomIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={AlignBottom} {...props} />
);
export const AlignHCenterIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={AlignHCenter} {...props} />
);
export const AlignVCenterIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={AlignVCenter} {...props} />
);
export const AngleIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Angle} {...props} />
);
export const SkewXIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={SkewX} {...props} />
);
export const SkewYIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={SkewY} {...props} />
);
export const LayerIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Layer} {...props} />
);

export const TimesIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={X} {...props} />
);

export const BarsIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Bars} {...props} />
);

export const UndoIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Undo} {...props} flip />
);

export const RedoIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Redo} {...props} flip />
);
export const ClipboardIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Clipboard} {...props} />
);

export const TrashIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Trash} {...props} />
);

export const EditIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Edit} {...props} />
);

export const LockIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Lock} {...props} />
);
export const UnlockIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Unlock} {...props} />
);
export const VisibleIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Visible} {...props} />
);
export const HiddenIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Hidden} {...props} />
);

export const CheckIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Check} {...props} />
);
export const InfoIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Info} {...props} />
);

export const PenIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Pen} {...props} />
);

export const SearchIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Search} {...props} />
);

export const SortUpIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={SortUp} {...props} />
);
export const SortDownIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={SortDown} {...props} />
);

export const FilterIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Filter} {...props} />
);

export const RemoveIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Remove} {...props} />
);

export const FolderIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={Folder} {...props} />
);

export const FolderOpenIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={FolderOpen} {...props} />
);

export const FolderDeleteIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={FolderDelete} {...props} />
);

export const QrCodeIcon: SvgElement = (props: ISvgProps) => (
  <SvgRenderer Svg={QrCode} {...props} />
);
