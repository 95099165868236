import {
  APPROVE,
  CONFIGURE_DESIGN,
  LEAVE_ORDER,
  MAP_LIST_TO_FIELDS,
  SUCCESS,
  UPLOAD_LIST,
  VALIDATE_LIST,
} from "./stepConstants";

export function goToNextStep(step: number): number {
  let nextStep = step;
  switch (step) {
    case CONFIGURE_DESIGN: {
      nextStep = UPLOAD_LIST;
      break;
    }
    case UPLOAD_LIST: {
      nextStep = MAP_LIST_TO_FIELDS;
      break;
    }
    case MAP_LIST_TO_FIELDS: {
      nextStep = VALIDATE_LIST;
      break;
    }
    case VALIDATE_LIST: {
      nextStep = APPROVE;
      break;
    }
    case APPROVE: {
      nextStep = SUCCESS;
      break;
    }
  }
  return nextStep;
}

export function returnToPreviousStep(step: number): number {
  let nextStep = -1;
  switch (step) {
    case CONFIGURE_DESIGN: {
      nextStep = LEAVE_ORDER;
      break;
    }
    case UPLOAD_LIST: {
      nextStep = CONFIGURE_DESIGN;
      break;
    }
    case VALIDATE_LIST: {
      nextStep = MAP_LIST_TO_FIELDS;
      break;
    }
    case MAP_LIST_TO_FIELDS: {
      nextStep = UPLOAD_LIST;
      break;
    }
    case APPROVE: {
      nextStep = MAP_LIST_TO_FIELDS;
      break;
    }
  }

  return nextStep;
}
