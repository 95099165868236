import { Box, Button, Container, Paper, Typography } from "@mui/material";
import LocalStorage from "../../helpers/LocalStorage";
import { usePostData } from "../../hooks/useFetch";
import SuccessPage from "./components/SuccessPage";
import TermsOfService from "./components/TermsOfService";

const CompleteRegistration = () => {
  const [finalizeRegistration, req] = usePostData(
    `/api/account-setup/finalize-setup`
  );

  async function submit() {
    await finalizeRegistration();
  }
  if (req.success) {
    LocalStorage.completeRegistration();
    return <SuccessPage />;
  }
  return (
    <Box>
      <Typography variant="h5" component="h2" sx={{ fontWeight: 600, mb: 2 }}>
        Complete Your Registration
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Please agree to the terms of service below to complete your
        registration.
      </Typography>
      <Box sx={{ mb: 3 }}>
        <TermsOfService />
      </Box>
      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          disabled={req.status === "loading"}
        >
          I Agree To The Terms Of Service
        </Button>
      </Box>
    </Box>
  );
};

export default CompleteRegistration;
