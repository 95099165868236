import { IText } from "fabric/fabric-impl";
import { useEffect, useRef, useState } from "react";
import IPropertiesProps from "../Properties/IPropertiesProps";
import "twin.macro";
import toolbarStyles from "./toolbarStyles";

import ColorPicker from "../shared/ColorPicker/ColorPicker";
import IncrementInput from "../shared/NuDesignerInputs/IncrementInput";
import { ReactComponent as TextIcon } from "../../../../assets/images/designer-svg/TextColorIcon.svg";
import RoundedInputGroup from "../shared/RoundedInputGroup";
import ToolButton from "../shared/ToolButton";
import {
  BoldIcon,
  CenterAlignIcon,
  ItalicIcon,
  LeftAlignIcon,
  RightAlignIcon,
  UnderlineIcon,
} from "../shared/SvgComponents";
import DuplicateButton from "./DuplicateButton";
import DeleteButton from "./DeleteButton";
import EditTextButton from "./EditTextButton";
import FontSelector from "../../Fonts/FontSelector";
import { Textbox } from "fabric";
import useHistory from "../../../hooks/useHistory";
import { cleanStyles } from "../../Canvas/functions/textStyleHelpers";
import RESOLUTION from "../../../constants/RESOLUTION";
import {
  convertPixelsToPoints,
  convertPointsToPixels,
} from "../../Canvas/functions/convertNumbers";

interface ITextSettings {
  fontFamily: string;
  fontWeight: number | string;
  fontSize: number;
  fontStyle: string;
  textAlign: string;
  underline: boolean;
}

interface ITextPropertiesProps {
  textObj: Textbox;
  openFonts: () => void;
}

const TextProperties = ({ textObj, openFonts }: ITextPropertiesProps) => {
  const [textSettings, setTextSettings] = useState<ITextSettings>({
    fontFamily: textObj.fontFamily ?? "Roboto",
    fontWeight: textObj.fontWeight ?? 400,
    fontSize: textObj.fontSize ? convertPixelsToPoints(textObj.fontSize) : 32,
    textAlign: textObj.textAlign ?? "left",
    fontStyle: textObj.fontStyle ?? "normal",
    underline: textObj.underline ?? false,
  });
  const textRef = useRef(textObj);

  function handleStringChange(property: string, value: string) {
    if (!textObj.isEditing || property === "textAlign") {
      if (property === "weight" && value !== "regular") {
        const val = parseInt(value);
        setTextSettings({ ...textSettings, fontWeight: val });
        textObj.set("fontWeight", val);
        cleanStyles(textObj, "fontWeight", val);
        textObj.canvas?.renderAll();
        return;
      }
      // if (property === "weight" && value === "regular") {
      //   setTextSettings({ ...textSettings, fontWeight: "normal" });
      //   textObj.set("fontWeight", "normal");
      //   return;
      // }
      setTextSettings({ ...textSettings, [property]: value });

      textObj.set(property as keyof Textbox, value);
      if (textObj.__height) {
        textObj.height = textObj.__height;
      }
      cleanStyles(textObj, property, value);
      textObj.canvas?.renderAll();
      textObj.canvas?.fire("object:modified");
    } else {
      const selected = textObj.getSelectedText();
      if (selected) {
        if (property === "weight" && value !== "regular") {
          const val = parseInt(value);
          textObj.setSelectionStyles({ fontWeight: val });
          return;
        }
        textObj.setSelectionStyles({ [property]: value });
        if (textObj.__height) {
          textObj.height = textObj.__height;
        }
        textObj.canvas?.renderAll();
        textObj.canvas?.fire("object:modified");
      }
    }
  }

  function handleNumberChange(property: string, value: string | number) {
    if (!textObj.isEditing) {
      setTextSettings({ ...textSettings, [property]: value.toString() });
      textObj.set(
        property as keyof Textbox,
        property === "fontSize" ? convertPointsToPixels(value) : value
      );
      if (textObj.__height) {
        textObj.height = textObj.__height;
      }
      cleanStyles(
        textObj,
        property,
        property === "fontSize" ? convertPointsToPixels(value) : value
      );
      textObj.canvas?.renderAll();
      textObj.canvas?.fire("object:modified");
    } else {
      const selected = textObj.getSelectedText();
      if (selected) {
        textObj.setSelectionStyles({
          [property]:
            property === "fontSize" ? convertPointsToPixels(value) : value,
        });
        textObj.canvas?.renderAll();
        textObj.canvas?.fire("object:modified");
      }
    }
  }

  function handleToggle(property: string) {
    if (!textObj.isEditing) {
      const value = !textSettings[property as keyof ITextSettings] as boolean;
      setTextSettings({ ...textSettings, [property]: value });

      textObj.set(property as keyof Textbox, value);
      cleanStyles(textObj, property, value);
      if (textObj.__height) {
        textObj.height = textObj.__height;
      }

      textObj.canvas?.renderAll();
    } else {
      const selected = textObj.getSelectedText();
      if (selected) {
      }
    }
  }

  function toggleBold() {
    if (!textObj.isEditing) {
      handleNumberChange(
        "fontWeight",
        textSettings.fontWeight === "bold" || textSettings.fontWeight === 800
          ? 400
          : "bold"
      );
    } else {
      const selected = textObj.getSelectedText();
      if (selected) {
        const styles = textObj.getSelectionStyles();

        if (styles.find((x) => x.fontWeight)) {
          if (
            styles.find((x) => x.fontWeight === "bold" || x.fontWeight === 800)
          ) {
            textObj.setSelectionStyles({ fontWeight: 400 });
          } else {
            textObj.setSelectionStyles({ fontWeight: "bold" });
          }
        } else {
          if (textObj.fontWeight === "bold" || textObj.fontWeight === 800) {
            textObj.setSelectionStyles({ fontWeight: 400 });
          } else {
            textObj.setSelectionStyles({ fontWeight: "bold" });
          }
        }
        textObj.canvas?.fire("object:modified");
        textObj.canvas?.renderAll();
      }
    }
  }

  function toggleItalic() {
    if (!textObj.isEditing) {
      handleStringChange(
        "fontStyle",
        textSettings.fontStyle === "italic" ? "normal" : "italic"
      );
    } else {
      const selected = textObj.getSelectedText();
      if (selected) {
        const styles = textObj.getSelectionStyles();

        if (styles.find((x) => x.fontStyle)) {
          if (styles.find((x) => x.fontStyle === "italic")) {
            textObj.setSelectionStyles({ fontStyle: "normal" });
          } else {
            textObj.setSelectionStyles({ fontStyle: "italic" });
          }
        } else {
          if (textObj.fontWeight === "italic") {
            textObj.setSelectionStyles({ fontStyle: "normal" });
          } else {
            textObj.setSelectionStyles({ fontStyle: "italic" });
          }
        }
        textObj.canvas?.renderAll();
        textObj.canvas?.fire("object:modified");
      }
    }
  }

  function toggleUnderline() {
    if (!textObj.isEditing) {
      handleToggle("underline");
    } else {
      const selected = textObj.getSelectedText();
      if (selected) {
        const styles = textObj.getSelectionStyles();
        if (styles.find((x) => x.underline)) {
          textObj.setSelectionStyles({ underline: false });
        } else {
          textObj.setSelectionStyles({ underline: true });
        }
        textObj.canvas?.renderAll();
        textObj.canvas?.fire("object:modified");
      }
    }
  }

  function handleSync() {
    if (textRef.current.isEditing) {
      const styles = textRef.current.getSelectedText()?.length
        ? textRef.current.getSelectionStyles()
        : //@ts-ignore
          [textRef.current.getStyleAtPosition()];

      const settings = {
        fontFamily: textRef.current.fontFamily ?? "Roboto",
        fontWeight: textRef.current.fontWeight ?? 400,
        fontSize: textRef.current.fontSize
          ? convertPixelsToPoints(textRef.current.fontSize)
          : 32,
        textAlign: textRef.current.textAlign ?? "left",
        fontStyle: textRef.current.fontStyle ?? "normal",
        underline: textRef.current.underline ?? false,
      };

      if (styles.length) {
        const style = styles[0] as ITextSettings;
        Object.keys(style).forEach((key) => {
          // @ts-ignore
          settings[key] =
            // @ts-ignore
            key === "fontSize" ? convertPixelsToPoints(style[key]) : style[key];
        });
      }

      setTextSettings({ ...settings });
    }
  }

  useEffect(() => {
    setTextSettings({
      fontFamily: textObj.fontFamily ?? "Roboto",
      fontWeight: textObj.fontWeight ?? 400,
      fontSize: textObj.fontSize ? convertPixelsToPoints(textObj.fontSize) : 32,
      textAlign: textObj.textAlign ?? "left",
      fontStyle: textObj.fontStyle ?? "normal",
      underline: textObj.underline ?? false,
    });
    if (textObj.name !== textRef.current.name) {
      textRef.current.off("selection:changed", handleSync);
      textRef.current = textObj;
    }
    textRef.current.on("selection:changed", handleSync);
    textObj.canvas?.off("object:modified", handleSync);
    textObj.canvas?.on("object:modified", handleSync);

    return () => {
      textRef.current.off("selection:changed", handleSync);
      textObj.canvas?.off("object:modified", handleSync);
    };
  }, [textObj]);

  return (
    <div>
      <div css={toolbarStyles.section}>
        <div css={toolbarStyles.property}>
          <ColorPicker
            selectedObject={textObj}
            type="fill"
            swatchOnly
            swatchIcon={TextIcon}
          />
        </div>
        <div css={toolbarStyles.seperator}></div>
        <FontSelector textObject={textObj} onClick={openFonts} />
        {/* <div css={toolbarStyles.property}> */}
        {/* <FontSelector
            type="family"
            value={textSettings.fontFamily}
            onChange={(e) => handleStringChange("fontFamily", e.target.value)}
            width={175}
            name="fontFamily"
          /> */}

        {/* </div> */}
        {/* <div css={toolbarStyles.property}>
          <FontSelector
            type="weight"
            value={textSettings.fontWeight}
            onChange={(e) => handleNumberChange("fontWeight", e.target.value)}
            width={175}
            name="fontWeight"
            familyValue={textSettings.fontFamily}
          />
        </div> */}
        <div css={toolbarStyles.property}></div>
        <div css={toolbarStyles.property}>
          <IncrementInput
            value={textSettings.fontSize}
            onChange={handleNumberChange}
            min={1}
            property="fontSize"
            height={7}
            width={48}
          />
        </div>

        <div css={toolbarStyles.property}>
          <RoundedInputGroup>
            <ToolButton
              position="left"
              css={toolbarStyles.button}
              onClick={toggleBold}
              // onClick={() =>
              //   handleNumberChange(
              //     "fontWeight",
              //     textSettings.fontWeight === "bold" ||
              //       textSettings.fontWeight === 800
              //       ? 400
              //       : "bold"
              //   )
              // }
              isActive={
                textSettings.fontWeight === "bold" ||
                textSettings.fontWeight === 800
              }
            >
              <BoldIcon />
            </ToolButton>
            <ToolButton
              position="center"
              onClick={toggleItalic}
              css={toolbarStyles.button}
              isActive={textSettings.fontStyle === "italic"}
            >
              <ItalicIcon />
            </ToolButton>
            <ToolButton
              position="right"
              onClick={toggleUnderline}
              isActive={textSettings.underline}
              css={toolbarStyles.button}
            >
              <UnderlineIcon />
            </ToolButton>
          </RoundedInputGroup>
        </div>
        <div css={toolbarStyles.seperator}></div>
        <div css={toolbarStyles.property}>
          <RoundedInputGroup>
            <ToolButton
              position="left"
              css={toolbarStyles.button}
              onClick={() => handleStringChange("textAlign", "left")}
              isActive={textSettings.textAlign === "left"}
            >
              <LeftAlignIcon />
            </ToolButton>
            <ToolButton
              position="center"
              css={toolbarStyles.button}
              onClick={() => handleStringChange("textAlign", "center")}
              isActive={textSettings.textAlign === "center"}
            >
              <CenterAlignIcon />
            </ToolButton>
            <ToolButton
              position="right"
              css={toolbarStyles.button}
              onClick={() => handleStringChange("textAlign", "right")}
              isActive={textSettings.textAlign === "right"}
            >
              <RightAlignIcon />
            </ToolButton>
          </RoundedInputGroup>
        </div>
        <div css={toolbarStyles.seperator}></div>
        <div css={toolbarStyles.property}>
          <EditTextButton selectedObject={textObj} />
        </div>
        <div css={toolbarStyles.property}>
          <DuplicateButton selectedObject={textObj} />
        </div>
        <div css={toolbarStyles.property}>
          <DeleteButton selectedObject={textObj} />
        </div>
      </div>
    </div>
  );
};

export default TextProperties;
