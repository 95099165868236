import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import propertiesStyles from "../../Properties/propertiesStyles";
import RoundedInputGroup from "../RoundedInputGroup";
import { MinusIcon, PlusIcon } from "../SvgComponents";

interface IIncrementInputProps {
  value: number;
  onChange: (property: string, num: number) => void;
  property: string;
  min?: number;
  max?: number;
  width?: number;
  height?: number;
  amount?: number;
  mask?: (val: number | string) => string;
}

const styles = {
  input: (width?: number) => [
    ...propertiesStyles.input,
    tw`bg-inputBackground border border-solid border-hover text-xs p-2 py-2 rounded-none w-full text-center h-full`,
    css`
      line-height: 1;
    `,
    width !== undefined && css(`width: ${width}px`),
  ],
  button: [
    tw` bg-inputBackground h-full border border-solid border-hover px-1.5 cursor-pointer hover:bg-hover fill-text disabled:fill-buttonTextDisabled disabled:bg-buttonDisabled hover:disabled:bg-buttonDisabled`,
  ],
  buttonIcon: [
    tw` w-3 h-3`,
    css`
      fill: inherit;
    `,
  ],
  buttonLeft: [tw`  rounded-l`],
  buttonRight: [tw`  rounded-r`],
};

const IncrementInput = ({
  value,
  onChange,
  min,
  max,
  property,
  width,
  height,
  amount = 1,
}: IIncrementInputProps) => {
  const [valueInput, setValueInput] = useState(value.toString());

  const stripped = valueInput.replace(/[^0-9$.]/g, "");
  let val = parseFloat(stripped);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (max !== undefined && val > max) {
      val = max;
    }
    if (min !== undefined && val < min) {
      val = min;
    }
    const valueStripped = e.target.value.replace(/[^0-9$.]/g, "");
    const valueFloat = parseFloat(valueStripped);

    if (Number.isNaN(valueFloat)) {
      setValueInput(valueStripped);
    } else {
      setValueInput(
        valueStripped.split("")[valueStripped.length - 1] === "."
          ? valueStripped
          : valueFloat.toString()
      );
      onChange(property, valueFloat);
    }
  }

  function increment() {
    if (max === undefined || val !== max) {
      if (max !== undefined && val + amount > max) {
        onChange(property, max);
        setValueInput(max.toString());
      } else {
        onChange(property, val + amount);
        setValueInput((val + amount).toString());
      }
    }
  }

  function decrement() {
    if (min === undefined || val !== min) {
      if (min !== undefined && val - amount < min) {
        onChange(property, min);
        setValueInput(min.toString());
      } else {
        onChange(property, val - amount);
        setValueInput((val - amount).toString());
      }
    }
  }

  useEffect(() => {
    setValueInput(value.toString());
  }, [value]);

  return (
    <RoundedInputGroup height={height ?? 9}>
      <button
        disabled={min !== undefined && val === min}
        onClick={decrement}
        css={[styles.button, styles.buttonLeft]}
      >
        <MinusIcon size={3} styles={styles.buttonIcon} />
      </button>
      <input
        value={valueInput}
        onChange={handleChange}
        css={styles.input(width)}
      />
      <button
        disabled={max !== undefined && val === max}
        onClick={increment}
        css={[styles.button, styles.buttonRight]}
      >
        <PlusIcon size={3} styles={styles.buttonIcon} />
      </button>
    </RoundedInputGroup>
  );
};

export default IncrementInput;
