import React from "react";
import tw, { css } from "twin.macro";
import propertiesStyles from "../../Properties/propertiesStyles";
import InputReset from "../InputReset";
import { IGradientObject } from "./types";
interface IColorPickerInputProps {
  color: string;
  isGradient: boolean;
  inputValue: string;
  onClick: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toHex: () => string;
  prefix?: React.ReactFragment;
  swatchOnly?: boolean;
  swatchIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  gradient?: IGradientObject;
}

const styles = {
  swatch: (color: string, swatchOnly: boolean) => [
    tw`w-5 h-5 border rounded border-solid border-border cursor-pointer box-border basis-5 block`,
    css(`background: ${color}`),
    swatchOnly &&
      css`
        width: 22px;
        height: 22px;
      `,
  ],
  swatchSvg: (color: string, isGradient: boolean) => [
    !isGradient &&
      css`
        fill: ${color};
        stroke: ${color};
      `,
    isGradient &&
      css`
        fill: url(#gradient)
        stroke: url(#gradinet)
        
      `,
    tw`ml-auto mr-auto h-4 w-4`,
  ],
  colorLine: (color: string) => [
    css`
      background: ${color};
      margin-top: 2px;
      height: 3px;
    `,
  ],
  swatchButton: [
    css`
      width: 28px;
      height: 28px;
    `,
    tw`p-1 hover:bg-hover rounded bg-inputBackground border border-solid border-border`,
  ],
  swatchContainer: [tw`mr-2`],
  container: [tw`flex items-center w-full`],
  prefix: [tw`text-text mr-2 ml-0.5 italic text-xs`],
  inputWrapper: [
    tw`flex items-center h-9 bg-inputBackground border border-solid border-hover rounded pl-1 w-full`,
  ],
};

const ColorPickerInput = ({
  color,
  isGradient,
  onClick,
  onChange,
  toHex,
  prefix,
  swatchOnly = false,
  swatchIcon,
  gradient,
  inputValue,
}: IColorPickerInputProps) => {
  function buildGradient() {
    if (!gradient) return null;
    const stops = gradient.colors.map((color) => (
      <stop
        offset={color.left}
        stopColor={color.value}
        key={color.left + color.value}
      ></stop>
    ));
    if (gradient.gradientType === "radial") {
      return <radialGradient id="gradient">{stops}</radialGradient>;
    }
    return <linearGradient id="gradient">{stops}</linearGradient>;
  }
  if (swatchOnly && swatchIcon) {
    const Svg = swatchIcon;
    return (
      <div
        css={[
          css`
            height: 28px;
          `,
          tw`flex flex-col justify-center`,
        ]}
      >
        <button css={styles.swatchButton} onClick={onClick}>
          {isGradient && gradient && (
            <svg css={styles.swatchSvg(color, true)}>
              <defs>{buildGradient()}</defs>
              <Svg
                css={css`
                  fill: url(#gradient);
                `}
              ></Svg>
            </svg>
          )}
          {!isGradient && <Svg css={styles.swatchSvg(color, isGradient)}></Svg>}
          <div css={styles.colorLine(color)}></div>
        </button>
      </div>
    );
  }
  if (swatchOnly)
    return (
      <div
        css={[
          css`
            height: 28px;
          `,
          tw`flex flex-col justify-center`,
        ]}
      >
        <button
          css={styles.swatch(color, true)}
          onClick={onClick}
          // style={{
          //   width: "20px",
          //   height: "20px",
          //   marginRight: "8px",
          //   background: color,
          //   border: "solid 1px rgba(0,0,0,.125)",
          // }}
        ></button>
      </div>
    );
  return (
    <div css={styles.container}>
      <div css={styles.inputWrapper}>
        <div css={styles.swatchContainer}>
          <button
            css={styles.swatch(color, false)}
            onClick={onClick}
            // style={{
            //   width: "20px",
            //   height: "20px",
            //   marginRight: "8px",
            //   background: color,
            //   border: "solid 1px rgba(0,0,0,.125)",
            // }}
          ></button>
        </div>
        <InputReset
          type="text"
          value={isGradient ? "Gradient" : inputValue}
          onChange={onChange}
          disabled={isGradient}
          css={propertiesStyles.input}
        />
      </div>
    </div>
  );
};

export default ColorPickerInput;
