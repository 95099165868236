import { Group } from "fabric/fabric-impl";
import React, { createContext, useReducer } from "react";

export const SelectedPageContext = createContext<string>("");

export const SelectedPageDispatchContext = createContext<
  React.Dispatch<{
    type: string;
    payload: string;
  }>
>(
  {} as React.Dispatch<{
    type: string;
    payload: string;
  }>
);
const intialState = "";

function selectedPageReducer(
  page: string,
  action: { type: string; payload: string }
) {
  if (action.payload) return action.payload;
  return page;
}

interface IProviderProps {
  children?: React.ReactFragment;
}

const SelectedPageProvider = ({ children }: IProviderProps) => {
  const [selectedPage, dispatch] = useReducer(selectedPageReducer, intialState);

  return (
    <SelectedPageContext.Provider value={selectedPage}>
      <SelectedPageDispatchContext.Provider value={dispatch}>
        {children}
      </SelectedPageDispatchContext.Provider>
    </SelectedPageContext.Provider>
  );
};

export default SelectedPageProvider;
