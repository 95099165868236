import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Paper,
  SelectChangeEvent,
} from "@mui/material";
import { Skeleton } from "@mui/lab";
import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { fetchClient } from "../../../../helpers/fetchClient";
import logger from "../../../../helpers/logger";
import ProofApprovalForm from "../../components/ProofApprovalForm";
import CustomerFile from "../../models/CustomerFile";
import DesignCreationModel from "../../models/DesignCreationModel";
import "./AdminDesignProof.scss";
import BaseErrorResponse from "../../../../data/models/BaseErrorResponse";
import useGetData from "../../../../hooks/dataFetchers/useGetData";
import StandAloneProofGenerator from "../../../Designs/Proof/components/StandAloneProofGenerator";
import Design from "../../../Designs/models/Design";
import { postAsync, putAsync } from "../../../../helpers/asyncFetch";

interface UrlParams {
  designId: string;
  customerId: string;
}

export interface GenerateProofRequest {
  address: string;
  address2?: string;
  city: string;
  state: string;
  zipcode: string;
  firstName: string;
  lastName: string;
  variables: GenerateProofRecipientDesignVariable[];
}

interface GenerateProofRecipientDesignVariable {
  key: string;
  value: string;
  fieldType: string;
  options?: GenerateProofRecipientDesignVariableOption[];
}

interface GenerateProofRecipientDesignVariableOption {
  value: string;
  label: string;
}

interface GenerateProofResponse {
  proofLinks: string[];
}

const AdminDesignProof: React.FC = () => {
  /**
   * State Objects and Refs
   */
  console.log("bad");
  const { designId, customerId } = useParams<UrlParams>();
  const history = useHistory();
  /**
   * Component Methods
   */

  const {
    data: design,
    isLoading,
    error,
  } = useGetData<Design>(
    `/api/admin/integrations/${customerId}/designs/${designId}`,
    [customerId, designId],
    (data) => {
      return null;
    },
  );

  async function saveProofs(proofs: string[]) {
    if (design) {
      if (design.printPDFSource?.toLowerCase().includes("designer")) {
        await putAsync(`/designer/${design.designID}/details`, {
          proofFront: proofs[0],
          proofBack: proofs[1],
        });
      } else {
        await postAsync(
          `/admin/integrations/${customerId}/designs/${designId}/approve`,
          {
            proofLinks: proofs,
          },
        );
      }

      history.push(`/admin/integrations/${customerId}/designs`);
    }
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */
  const d = design as Design;
  return (
    <div>
      <Box sx={{ mb: 3 }}>
        <Link to={`/admin/integrations/${customerId}/designs`}>
          <i className="fas fa-chevron-left"></i> Back to Designs
        </Link>
      </Box>
      {!isLoading && design && (
        <StandAloneProofGenerator
          design={d}
          fields={d.designFields}
          designId={designId}
          htmlVersionId={d.htmlVersionID}
          designerCallback={saveProofs}
          isDesigner
          isAdmin
        />
      )}
    </div>
  );
};
/* isDesigner={design?.printPDFSource
            ?.toLowerCase()
            .includes("designer")} */
export default AdminDesignProof;
