export default `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg width="100%" height="100%" viewBox="0 0 79 79" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.5;">
    <g transform="matrix(1,0,0,1,2,2)">
        <g>
            <rect x="3" y="54" width="13" height="4"/>
            <rect x="4" y="46" width="9" height="4"/>
            <rect x="17" y="46" width="4" height="4"/>
            <rect x="23" y="46" width="6" height="4"/>
            <rect x="34" y="46" width="41" height="4"/>
            <rect x="54" y="52" width="9" height="6"/>
            <rect x="49" y="54" width="5" height="4"/>
            <rect x="71" y="55" width="4" height="3"/>
            <rect x="71" y="63" width="4" height="3"/>
            <rect x="63" y="67" width="4" height="4"/>
            <rect x="58" y="63" width="5" height="3"/>
            <rect x="38" y="63" width="16" height="3"/>
            <rect x="42" y="66" width="4" height="5"/>
            <rect x="25" y="54" width="4" height="17"/>
            <rect x="29" y="50" width="4" height="4"/>
            <rect x="42" y="38" width="8" height="4"/>
            <rect x="59" y="42" width="5" height="4"/>
            <rect x="71" y="38" width="4" height="4"/>
            <rect x="63" y="29" width="4" height="9"/>
            <rect x="58" y="25" width="5" height="4"/>
            <rect x="50" y="25" width="4" height="12"/>
            <rect x="54" y="29" width="4" height="12"/>
            <rect x="34" y="34" width="3" height="7"/>
            <rect x="38" y="30" width="3" height="3"/>
            <rect x="38" y="21" width="4" height="4"/>
            <rect x="33" y="17" width="4" height="4"/>
            <rect x="21" y="17" width="4" height="12"/>
            <rect x="25" y="21" width="4" height="16"/>
            <rect x="21" y="38" width="4" height="4"/>
            <rect x="17" y="29" width="4" height="4"/>
            <rect x="13" y="25" width="4" height="4"/>
            <rect x="8" y="21" width="4" height="4"/>
            <rect x="9" y="13" width="8" height="4"/>
            <rect x="17" y="0" width="4" height="10"/>
            <rect x="25" y="0" width="4" height="4"/>
            <rect x="34" y="0" width="4" height="4"/>
            <rect x="42" y="0" width="3" height="3"/>
            <rect x="51" y="0" width="4" height="4"/>
            <rect x="58" y="1" width="4" height="4"/>
            <g transform="matrix(0.75,0,0,1,18,0)">
                <rect x="72" y="4" width="4" height="4"/>
            </g>
            <rect x="63" y="5" width="3" height="11"/>
            <rect x="67" y="9" width="3" height="3"/>
            <rect x="71" y="13" width="4" height="3"/>
            <rect x="50" y="9" width="4" height="11"/>
            <g transform="matrix(1.33333,0,0,1,-20.6667,0)">
                <rect x="59" y="9" width="3" height="11"/>
            </g>
            <rect x="67" y="21" width="8" height="4"/>
            <rect x="8" y="0" width="5" height="4"/>
            <rect x="37" y="8" width="5" height="5"/>
            <rect x="46" y="8" width="25" height="4"/>
            <rect x="38" y="53" width="4" height="6"/>
            <rect x="33" y="58" width="5" height="5"/>
            <rect x="28" y="9" width="6" height="5"/>
            <rect x="3" y="4" width="4" height="5"/>
            <path d="M2,0L2,75" style="fill:none;stroke:black;stroke-width:4px;"/>
            <path d="M75,73L-0,73" style="fill:none;stroke:black;stroke-width:4px;"/>
            <rect x="4" y="67" width="8" height="6"/>
            <rect x="4" y="34" width="12" height="7"/>
        </g>
    </g>
</svg>
`;
