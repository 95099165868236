/**
 * @TODO Create handler for 401
 * @TODO Create handler for generic errors
 * @TODO Test All ERRORS
 */

import LocalStorage from "./LocalStorage";
import logger from "./logger";

export interface FetchRequest {
  path: string;
  onSuccess: ((data: any) => any) | ((data: any) => void);
  onError?: ((error: any) => any) | ((error: any) => void);
}

export interface FetchErrorResponse {
  statusCode: number;
  message: string;
  data: any;
}

export interface fetchDataRequest extends FetchRequest {
  data?: any;
}

export interface fetchFormDataRequest extends FetchRequest {
  data: FormData;
}
export interface fetchUploadRequest extends Omit<FetchRequest, "path"> {
  files: File[];
  fileType: string;
  isList?: boolean;
  formId?: number;
}

const _baseUrl = "/api";
const NO_CONTENT = 204;
const CREATED_AT = 201;
const UNAUTHORIZED = 401;
const OK = 200;

export const fetchClient = {
  get: (request: FetchRequest) => getRequest(request),
  post: (request: fetchDataRequest) => postRequest(request),
  put: (request: fetchDataRequest) => putRequest(request),
  delete: (request: fetchDataRequest) => deleteRequest(request),
  postForm: (request: fetchFormDataRequest) => postFormRequest(request),
  uploadFile: (request: fetchUploadRequest) => uploadFileRequest(request),
};

function makeRequest(
  method: string,
  req: fetchFormDataRequest,
  isForm: boolean
): AbortController;
function makeRequest(
  method: string,
  req: FetchRequest,
  isForm?: boolean
): AbortController;
function makeRequest(
  method: string,
  req: fetchDataRequest,
  isForm?: boolean
): AbortController {
  const _token = getApiToken();
  const abortSignal = createAbortSignal();
  let headers: HeadersInit = {
    Authorization: `bearer ${_token}`,
    Accept: "application/json",
  };
  if (!isForm)
    headers = {
      ...headers,
      "Content-Type": "application/json",
    };
  fetch(_baseUrl + req.path, {
    method,
    headers: headers,
    body:
      req.data && !isForm
        ? JSON.stringify(req.data)
        : req.data
        ? req.data
        : undefined,
  })
    .then((res) => responseHandler(res))
    .then((data) => {
      return req.onSuccess(data);
    })
    .catch((error: FetchErrorResponse) => {
      if (req.onError) {
        req.onError(error);
      }
      baseErrorHandler(error.message);
    });
  return abortSignal.controller;
}

function getApiToken() {
  let token: string | null = null;
  token = LocalStorage.getToken();
  if (!token) {
    const query = new URLSearchParams(window.location.search);
    token = query.get("token");
  }
  return token;
}

function baseErrorHandler(error: string) {
  logger.log("Error: " + error);
}

function createAbortSignal() {
  const abort = new AbortController();
  return { controller: abort, signal: abort.signal };
}

function getRequest(req: FetchRequest) {
  return makeRequest("GET", req);
}

function postRequest(req: fetchDataRequest) {
  return makeRequest("POST", req);
}

function putRequest(req: fetchDataRequest) {
  return makeRequest("PUT", req);
}

function deleteRequest(req: fetchDataRequest) {
  return makeRequest("DELETE", req);
}

function postFormRequest(req: fetchFormDataRequest) {
  return makeRequest("POST", req, true);
}

function uploadFileRequest(req: fetchUploadRequest) {
  const data = new FormData();
  for (const file of req.files) {
    data.append("file_upload", file, file.name);
  }
  //data.append("file_upload", req.files);
  data.append("EntityCD", req.fileType);
  data.append("EntityType", req.fileType);
  data.append("FormID", req.formId ? String(req.formId) : "0");

  const formDataRequest: fetchFormDataRequest = {
    path: `/files/upload${req.isList ? "?list=true" : ""}`,
    onSuccess: req.onSuccess,
    onError: req.onError,
    data: data,
  };
  return makeRequest("POST", formDataRequest, true);
}

async function responseHandler(res: Response) {
  if (res.status === OK || res.status === CREATED_AT) return res.json();
  if (res.status === NO_CONTENT) return true;
  if (res.status === UNAUTHORIZED) {
    const redirectPath = window.location.pathname;
    logger.log(redirectPath);
    if (redirectPath === "/" || !redirectPath || redirectPath === "/login")
      window.location.href = "/login";
    else window.location.href = `/login?referrer=${encodeURI(redirectPath)}`;
    throw "Unauthorized";
  }

  if (!res.ok) throw await res.json();
}
