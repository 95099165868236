import { useLayoutEffect, useState } from "react";

function useLockBodyScroll(shouldBeLocked: boolean) {
  // useLaoutEffect callback return type is "() => void" type

  useLayoutEffect((): (() => void) => {
    const originalStyle: string = window.getComputedStyle(
      document.body
    ).overflow;
    if (shouldBeLocked) {
      // Prevent scrolling on mount
      document.body.style.overflow = "hidden";
      // Re-enable scrolling when component unmounts
    } else {
      document.body.style.overflow = originalStyle;
    }
    return () => (document.body.style.overflow = originalStyle);
  }, [shouldBeLocked]); // Empty array ensures effect is only run on mount and unmount
}

export default useLockBodyScroll;
