import IDesign from "../../data/models/IDesign";
import Design from "../../routes/Designs/models/Design";

export default function buildDesignVariables(design: Design | IDesign) {
  let fields = `[`;
  if (design.designFields && design.designFields.length) {
    design.designFields.forEach((field, index, arr) => {
      fields += `
                {
                  "key": "${field.listFieldKey}",
                  "value": "${
                    field.fieldType === "image" ? "image url" : field.fieldType
                  }"
                ${!arr[index + 1] ? `}` : `},`}`;
    });
  }
  fields += `
           ]`;
  return fields;
}
