import React, { forwardRef } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import "./DropzoneUploader.scss";

interface DropzoneUploaderProps {
  onUpload: (files: any[]) => any;
  onRejections?: (files: any[]) => any;
  fileTypes: string[];
  multiUpload?: boolean;
  disabled: boolean;
  style?: React.CSSProperties;
  additionalHelpText?: React.FC | string;
  allowedExtensions?: string[];
}

const DropzoneUploader = forwardRef<HTMLInputElement, DropzoneUploaderProps>(
  (
    {
      onUpload,
      onRejections,
      fileTypes,
      multiUpload = false,
      disabled = true,
      style = {},
      additionalHelpText,
      allowedExtensions = [],
    },
    ref
  ) => {
    /**
     * State Objects and Refs
     */
    const onDrop = <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      if (acceptedFiles && acceptedFiles.length) onUpload(acceptedFiles);
      if (fileRejections && fileRejections.length && onRejections) {
        onRejections(fileRejections);
      }
    };

    const accept = fileTypes.reduce((acc, type) => {
      acc[type] = [];
      return acc;
    }, {} as Record<string, []>);

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
      useDropzone({
        onDrop,
        // useFsAccessApi: false,
        maxFiles: multiUpload ? undefined : 1,
        accept: accept,
        noDragEventsBubbling: true,
      });
    /**
     * Component Methods
     */

    /**
     * Component Effects
     */

    /**
     * Render
     */
    const dropzoneClassName = `dropzoneUploader ${
      isDragActive ? "--active" : ""
    } ${disabled ? "--disabled" : ""}`;

    return (
      <div
        {...getRootProps({
          className: dropzoneClassName,
        })}
        style={style}
      >
        <div className="dropzoneUploader__textContainer">
          {!disabled && (
            <div>
              <span className="dropzoneUploader__heading">
                {multiUpload
                  ? "Drag your files here to upload"
                  : "Drag your file here to upload"}
              </span>
              <span className="dropzoneUploader__validFormats">
                Valid files: {allowedExtensions.join(", ")}
              </span>
              {Boolean(additionalHelpText) && (
                <strong className="d-block">{additionalHelpText}</strong>
              )}
            </div>
          )}
          {disabled && (
            <div>
              <span className="dropzoneUploader__heading">Uploading...</span>
              <span className="dropzoneUploader__validFormats">
                {acceptedFiles.map(
                  (file, index) =>
                    `${file.name}${index !== acceptedFiles.length - 1 && ", "}`
                )}
              </span>
            </div>
          )}
        </div>
        <input {...getInputProps()} disabled={disabled} />
      </div>
    );
  }
);

export default DropzoneUploader;
