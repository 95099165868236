import {
  Action,
  CaseReducer,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import IDesignerHistory, {
  IDesignerHistoryObject,
} from "../models/IDesignerHistory";
import ILayers from "../models/ILayers";
import ILayersObject from "../models/layers/ILayersObject";

import { DesignerState } from "../store";

const initialState: IDesignerHistory = {
  cursorPosition: -1,
  canvasObjects: [] as IDesignerHistoryObject[],
  layerHistory: [],
};

interface IHistoryUpdatePayload {
  historyObject: IDesignerHistoryObject;
  layerObject: ILayersObject;
}

const insertHistoryReducer: CaseReducer<
  IDesignerHistory,
  PayloadAction<IHistoryUpdatePayload>
> = (state, action) => {
  if (state.cursorPosition === -1) {
    state.canvasObjects = [action.payload.historyObject];
    state.layerHistory = [action.payload.layerObject];
  } else {
    const newCanvasObjects = [...state.canvasObjects].slice(
      0,
      state.cursorPosition + 1
    );

    state.canvasObjects = [...newCanvasObjects, action.payload.historyObject];
    const newLayerHistory = [...state.layerHistory].slice(
      0,
      state.cursorPosition + 1
    );
    state.layerHistory = [...newLayerHistory, action.payload.layerObject];
  }
  state.cursorPosition += 1;
};

const moveCursorBackReducer = (state: IDesignerHistory) => {
  if (state.cursorPosition !== 0) state.cursorPosition -= 1;
};

const moveCursorForwardReducer = (state: IDesignerHistory) => {
  if (state.cursorPosition < state.canvasObjects.length - 1)
    state.cursorPosition += 1;
};

export const historySlice = createSlice({
  name: "history",
  initialState: initialState as IDesignerHistory,
  reducers: {
    insertHistory: insertHistoryReducer,
    moveCursorBack: moveCursorBackReducer,
    moveCursorForward: moveCursorForwardReducer,
    resetHistory: () => initialState,
  },
});

export const {
  insertHistory,
  moveCursorBack,
  moveCursorForward,
  resetHistory,
} = historySlice.actions;
export const selectHistory = (state: DesignerState) => state.history;
export default historySlice.reducer;
