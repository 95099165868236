import useHistory from "../../hooks/useHistory";
import ButtonReset from "./shared/ButtonReset";
import tw from "twin.macro";
import ToolSelectButton from "./ToolMenu/ToolSelectButton";

import { ReactComponent as UndoIcon } from "../../../assets/images/designer-svg/UndoIcon.svg";
import { ReactComponent as RedoIcon } from "../../../assets/images/designer-svg/RedoIcon.svg";
import React, { useEffect } from "react";
const styles = {
  container: [tw`h-full border-0 border-solid border-r border-border`],
  button: (isDisabled: boolean) => [
    tw`px-4 cursor-pointer h-full`,
    !isDisabled && tw`hover:bg-hover`,
  ],
};

const UndoRedo = ({ forceDisabled = false }: { forceDisabled?: boolean }) => {
  const { undo, redo, undoDisabled, redoDisabled, initHistory } = useHistory();

  useEffect(() => {
    initHistory(true);
  }, []);

  return (
    <React.Fragment>
      <ToolSelectButton
        onClick={undo}
        disabled={forceDisabled ? true : undoDisabled}
        svg={UndoIcon}
        isActive={false}
        tooltip="Undo (ctrl+z)"
        flip
        label="Undo"
      />
      <ToolSelectButton
        onClick={redo}
        disabled={forceDisabled ? true : redoDisabled}
        svg={RedoIcon}
        isActive={false}
        tooltip="Redo (ctrl+y)"
        label="Redo"
        flip
      />
    </React.Fragment>
  );
};

export default UndoRedo;
