import QRCodeStyling from "qr-code-styling";
import { QrCodeStylingOptions } from "../../../../shared/QrCodeEditor/types/QrCodeStylingTypes";
import { Canvas } from "fabric/fabric-impl";
import FabricQrCode from "../../../models/types/FabricQrCode";
import blobToBase64 from "../../../../helpers/blobToBase64";
import { createBleedClipPath } from "../../Canvas/functions/createBackgroundClipPath";
import asyncImageFromUrl from "../../Canvas/functions/asyncImageFromUrl";
import generateGuid from "../../../../helpers/generateGuid";

export async function createCanvasQrCodeObject(
  config: QrCodeStylingOptions,
  canvas: Canvas,
  qrCodeId?: number,
  preview?: string,
) {
  const img = preview ? preview : await getQrCodeBase64(config);

  if (img) {
    const obj = (await asyncImageFromUrl(img)) as FabricQrCode;
    const background = canvas._objects.find((x) => x.name === "background");
    if (!background) return;
    obj.set({
      top: background.top ?? 0,
      left: background.left ?? 0,
      width: 400,
      height: 400,
      clipPath: createBleedClipPath(canvas),
      __qrCodeId: qrCodeId,
      name: `qrcode-${generateGuid()}`,
    });
    obj.setControlsVisibility({
      mtr: false,
      ml: false,
      mr: false,
      mb: false,
      mt: false,
    });
    return obj;
  } else return undefined;
}

export async function getQrCodeBase64(config: QrCodeStylingOptions) {
  const qrCode = new QRCodeStyling({
    ...config,
    width: 400,
    height: 400,
  });

  const imageBlob = await qrCode.getRawData("svg");

  if (imageBlob) {
    const img = await blobToBase64(imageBlob);
    return img;
  }
  return "";
}
export async function getQrCodeSvg(config: QrCodeStylingOptions) {
  const qrCode = new QRCodeStyling({
    ...config,
    width: 400,
    height: 400,
  });

  const imageBlob = await qrCode.getRawData("svg");

  if (imageBlob) {
    const img = imageBlob.text();
    return img;
  }
  return "";
}
