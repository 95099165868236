import { useContext, useEffect, useRef, useState } from "react";
import { CanvasContext } from "../state/contexts/CanvasContext";
import { fabric } from "fabric";
import { Polygon } from "fabric/fabric-impl";
import IPosition from "../features/Canvas/models/IPosition";
import { ISvgDrop } from "../features/DesignerInterface/Shapes/Shape";

import generateGuid from "../../helpers/generateGuid";
import { getShape } from "../features/Canvas/functions/shapeFunctions/getShape";
import useLayers from "./useLayers";
import { createBleedClipPath } from "../features/Canvas/functions/createBackgroundClipPath";
import { useDesignerDispatch } from "../state/store";
import { setCurrentTool } from "../state/slices/toolSettings";
import { Tool } from "../state/models/ICanvasTool";
import useHistory from "./useHistory";
import QrCode from "../../shared/QrCodeEditor/models/QrCode";
import QRCodeStyling from "qr-code-styling";
import asyncSvgFromElement from "../features/Canvas/functions/asyncSvgFromElement";
import blobToBase64 from "../../helpers/blobToBase64";
import asyncImageFromUrl from "../features/Canvas/functions/asyncImageFromUrl";
import { getBackground } from "../features/Canvas/functions/dimensionHelpers";

const useShapeDrop = () => {
  const canvas = useContext(CanvasContext);
  const mousePos = useRef({ x: 0, y: 0 });

  const { createLayer } = useLayers();
  const dispatch = useDesignerDispatch();

  function addShapeToCanvas(drop: ISvgDrop) {
    if (!canvas || !drop.svg) return;

    const shape = getShape(drop.shape.name, mousePos.current, canvas);
    shape.clipPath = createBleedClipPath(canvas);

    canvas.add(shape);
    canvas.renderAll();
    canvas.setActiveObject(shape);
    createLayer(shape.name ?? "");
    dispatch(setCurrentTool(Tool.select));
  }

  async function addQrCodeToCanvas(drop: QrCode) {
    if (!canvas) return;
    const qrCode = new QRCodeStyling({
      ...drop.config,
      width: 200,
      height: 200,
    });
    const imgBlob = await qrCode.getRawData("svg");
    if (imgBlob) {
      const img = await blobToBase64(imgBlob);
      const obj = await asyncImageFromUrl(img);

      const background = canvas.getObjects().find((x) => x.name === "bleed");

      let top: number;
      let left: number;
      if (
        background &&
        background.left &&
        background.top &&
        background.width &&
        background.height
      ) {
        if (mousePos.current.x > background.left + background.width) {
          left = background.left + background.width - obj.getScaledWidth();
        } else if (mousePos.current.x < background.left) {
          left = background.left;
        } else {
          left = mousePos.current.x - obj.getScaledWidth() / 2;
        }
        if (mousePos.current.y > background.top + background.height) {
          top = background.top + background.height - obj.getScaledHeight();
        } else if (mousePos.current.y < background.top) {
          top = background.top;
        } else {
          top = mousePos.current.y - obj.getScaledHeight() / 2;
        }
      } else {
        left = mousePos.current.x - obj.getScaledWidth() / 2;
        top = mousePos.current.y - obj.getScaledHeight() / 2;
      }

      obj.set({
        top,
        left,
        width: 200,
        height: 200,
        clipPath: createBleedClipPath(canvas),

        name: `qrcode-${generateGuid()}`,
      });
      obj.setControlsVisibility({
        mtr: false,
        ml: false,
        mr: false,
        mb: false,
        mt: false,
      });

      canvas.add(obj);
      canvas.renderAll();
      createLayer(obj.name ?? "");
      dispatch(setCurrentTool(Tool.select));
    }
  }

  function initializeMousePos() {
    if (!canvas) return;
    canvas.on("dragover", function (e) {
      const pos = canvas.getPointer(e.e);

      mousePos.current = { x: pos.x, y: pos.y };
    });
  }
  useEffect(initializeMousePos, [canvas]);
  return { addShapeToCanvas, addQrCodeToCanvas, mousePos: mousePos.current };
};

export default useShapeDrop;
