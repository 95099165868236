import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DesignerState } from "../store";
import QrCode, { newQrCode } from "../../../shared/QrCodeEditor/models/QrCode";

export enum LetterFoldTypes {
  TRIFOLD = "Tri",
  BIFOLD = "Bi",
}
export interface LetterSettingsState {
  envelopeType?: string;
  margins?: LetterSettingsMargins;
  foldType?: LetterFoldTypes;
}

export interface LetterSettingsMargins {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

const initialState: LetterSettingsState = {
  envelopeType: undefined,
  margins: undefined,
};

export const letterSettingsSlice = createSlice({
  name: "letterSettings",
  initialState,
  reducers: {
    initializeLetterSettings: (
      state,
      action: PayloadAction<LetterSettingsState>
    ) => {
      return action.payload;
    },
    setEnvelopeType: (state, action: PayloadAction<string>) => {
      state.envelopeType = action.payload;
    },
    setMargins: (state, action: PayloadAction<LetterSettingsMargins>) => {
      state.margins = { ...action.payload };
    },
    setFoldType: (state, action: PayloadAction<LetterFoldTypes>) => {
      state.foldType = action.payload;
    },
  },
});

export const { initializeLetterSettings, setEnvelopeType, setFoldType } =
  letterSettingsSlice.actions;

export const selectLetterSettings = (state: DesignerState) =>
  state.letterSettings;

export default letterSettingsSlice.reducer;
