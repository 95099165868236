import DesignerSizes from "../../data/models/DesignerSizes";

interface ISizes {
  [key: string]: ISafeAreaObject;
}
interface ISafeAreaObject {
  addressBlock: {
    width: number;
    height: number;
    left: number;
    top: number;
  };
  postage?: {
    width: number;
    height: number;
    left: number;
    top: number;
  };
  height: number;
  width: number;
}

const Sizes: ISizes = {
  "46": {
    addressBlock: {
      width: 3.945,
      height: 2.5826,
      top: 1.9174,
      left: 2.305,
    },
    postage: {
      width: 0.5504,
      height: 0.7336,
      left: 5.3246,
      top: 0.125,
    },
    height: 4.5,
    width: 6.25,
  },
  "68": {
    addressBlock: {
      width: 3.945,
      height: 2.5826,
      top: 3.6674,
      left: 4.805,
    },
    postage: {
      width: 0.5504,
      height: 0.7336,
      left: 7.8246,
      top: 0.125,
    },
    height: 6.25,
    width: 8.75,
  },
  "611": {
    addressBlock: {
      width: 4.1368,
      height: 2.3928,
      top: 3.7322,
      left: 6.9882,
    },
    postage: {
      width: 0.5504,
      height: 0.7336,
      left: 10.1996,
      top: 0.125,
    },
    height: 6.25,
    width: 11.25,
  },
  "46S": {
    addressBlock: {
      width: 3.2835,
      height: 2.375,
      left: 2.719,
      top: 1.65,
    },

    height: 4,
    width: 6,
  },
  "69": {
    addressBlock: {
      width: 4,
      height: 2.375,
      left: 5.25,
      top: 3.65,
    },

    height: 6,
    width: 9,
  },
  "651": {
    addressBlock: {
      width: 4,
      height: 2.375,
      left: 7.25,
      top: 3.65,
    },

    height: 6,
    width: 11,
  },
  "811": {
    addressBlock: {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    },
    height: 11,
    width: 8.5,
  },
  [DesignerSizes.BROCHURE]: {
    addressBlock: {
      width: 6.3432,
      height: 2.1208,
      top: 0,
      left: 0,
    },
    postage: {
      width: 0.5504,
      height: 0.7336,
      left: 5.3246,
      top: 0.125,
    },
    height: 8.5,
    width: 11,
  },
};

export default Sizes;
