import tw, { css } from "twin.macro";
import useCamera from "../../../hooks/useCamera";
import {
  PlusIcon,
  MinusIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from "../shared/SvgComponents";
import ToolButton from "../shared/ToolButton";
import { useState } from "react";
import ZoomOptions from "./ZoomOptions";
import { DesignerSelect } from "../shared/NuDesignerInputs/DesignerSelect";
import { SelectOption } from "../shared/DesignerInput/SelectOption";

const styles = {
  button: [
    tw`bg-glacier border-border border-solid border border-r-0 text-labels last:border-r hover:bg-border`,
  ],
  textButton: [tw`font-semibold`],
  container: [tw`flex items-center absolute bottom-4 right-4`],
};

const Zoom = () => {
  const { zoomIn, zoomOut, displayZoom, fitToScreen, fillScreen, zoomTo } =
    useCamera();

  return (
    <div>
      <div tw="flex items-center">
        <ToolButton
          small
          position="left"
          onClick={zoomIn}
          styles={styles.button}
        >
          <ZoomInIcon color={tw`fill-labels`} />
        </ToolButton>
        <ZoomOptions
          onZoom={zoomTo}
          onFill={fillScreen}
          onFit={fitToScreen}
          currentZoom={displayZoom}
        />
        <ToolButton
          small
          position="right"
          onClick={zoomOut}
          css={styles.button}
        >
          <ZoomOutIcon color={tw`fill-labels`} />
        </ToolButton>
      </div>
    </div>
  );
};

export default Zoom;
