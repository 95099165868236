import { useRef } from "react";
import useClickOutside from "../../../../../hooks/useClickOutside";
import tw, { css } from "twin.macro";
import DesignerButton from "../DesignerButton";
interface IColorPickerPopoutProps {
  onClose: () => void;
  children: React.ReactFragment;
  isOpen: boolean;
  swatchOnly: boolean;
  positionOverride?: {
    left?: number | string;
    top?: number | string;
    bottom?: number | string;
    right?: number | string;
  };
}

const styles = {
  container: (isOpen: boolean, swatchOnly: boolean) => [
    tw`border border-border border-solid rounded`,
    css`
      position: absolute;
      left: 284px;
      top: -217px;
      width: 216px;
      padding: 8px;

      background: white;
      z-index: 1000;
      #rbgcp-input {
        height: 28px !important;
        font-size: 12px !important;
      }
    `,
    !isOpen && tw`hidden`,
    swatchOnly &&
      css`
        top: 46px;
        left: 20px;
      `,
  ],
};

const ColorPickerPopout = ({
  onClose,
  children,
  isOpen,
  swatchOnly,
  positionOverride,
}: IColorPickerPopoutProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => onClose());
  return (
    <div
      ref={ref}
      css={[
        styles.container(isOpen, swatchOnly),
        positionOverride && css(positionOverride),
      ]}
    >
      {children}
      <div css={tw`mt-2 text-sm`}>
        <DesignerButton onClick={onClose} css={tw`text-xs `}>
          OK
        </DesignerButton>
      </div>
    </div>
  );
};

export default ColorPickerPopout;
