import QrCodeElement from "../../../../shared/QrCodeEditor/components/QrCodeElement";
import QrCode from "../../../../shared/QrCodeEditor/models/QrCode";
import DesignerButton from "../../DesignerInterface/shared/DesignerButton";
import tw, { css } from "twin.macro";

interface SelectableQrCodeProps {
  onSelect: (qrCode: QrCode) => void;
  onEdit: (qrCode: QrCode) => void;
  qrCode: QrCode;
  isActive: boolean;
}

const styles = {
  container: [tw`border-solid border-b border-border pb-2 pt-2`],
  wrap: (active = false) => [
    tw`flex items-center  w-full p-2`,
    active && tw`border-border border-solid bg-buttonDisabled rounded`,
  ],
};

const SelectableQrCode = ({
  onSelect,
  onEdit,
  qrCode,
  isActive = false,
}: SelectableQrCodeProps) => {
  return (
    <div css={styles.container}>
      <div css={styles.wrap(isActive)}>
        <div css={[tw`pl-2 pr-2`]}>
          <QrCodeElement {...qrCode.config} width={150} height={150} />
        </div>
        <div css={[tw`pl-2 pr-2`]}>
          <div css={tw`mb-2`}>
            <strong>URL: </strong>

            {qrCode.url}
          </div>
          <div>
            <DesignerButton
              onClick={() => onSelect(qrCode)}
              css={[
                css`
                  font-size: 11px;
                `,
                tw`px-2.5 py-1.5`,
              ]}
            >
              Select
            </DesignerButton>{" "}
            {!qrCode.published && (
              <DesignerButton
                onClick={() => onEdit(qrCode)}
                css={[
                  css`
                    font-size: 11px;
                  `,
                  tw`px-2.5 py-1.5`,
                ]}
              >
                Edit
              </DesignerButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectableQrCode;
