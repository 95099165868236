import { Group, Rect } from "fabric/fabric-impl";
import { useEffect, useState } from "react";
import ColorPicker from "../shared/ColorPicker/ColorPicker";
import DesignerInputWrapper from "../shared/DesignerInput/DesignerInputWrapper";
import {
  DesignerSelect,
  DesignerSelectOption,
} from "../shared/NuDesignerInputs/DesignerSelect";
import IncrementInput from "../shared/NuDesignerInputs/IncrementInput";
import RoundedInputGroup from "../shared/RoundedInputGroup";
import ToolButton from "../shared/ToolButton";
import IPropertiesProps from "./IPropertiesProps";
import tw, { css } from "twin.macro";

import propertiesStyles from "./propertiesStyles";
import PropertyDropdown from "./PropertyDropdown";

const styles = {
  corner: tw`text-xs italic`,
  strokeExample: tw`flex items-center py-2`,
};

interface IAdvancedStrokeProperties extends IPropertiesProps {
  showStroke: boolean;
}

const AdvancedStrokeProperties = ({
  selectedObject,
  showStroke,
}: IAdvancedStrokeProperties) => {
  const isImage = Boolean(selectedObject.name?.includes("image"));
  const frame = isImage
    ? (selectedObject as Group)._objects?.find((x) => x.type !== "image") ??
      selectedObject
    : selectedObject;
  const [isOpen, setIsOpen] = useState(false);
  const [strokeProperties, setStrokeProperties] = useState(updateProperties());

  function handleChange(name: string, value: string | number) {
    const obj = isImage
      ? (selectedObject as Group)._objects?.find((x) => x.type !== "image")
      : selectedObject;

    if (!obj) return;
    if (name === "type" && typeof value === "string") {
      if (value === "dashed") {
        obj.strokeDashArray = [
          strokeProperties.dashLength,
          strokeProperties.dashGap,
        ];
      } else {
        obj.strokeDashArray = undefined;
      }
    }
    if (typeof value === "number") {
      if (name === "dashLength" || name === "dashGap") {
        const dashArray = [
          strokeProperties.dashLength,
          strokeProperties.dashGap,
        ];
        if (name === "dashLength") dashArray[0] = value;
        if (name === "dashGap") dashArray[1] = value;

        obj.set("strokeDashArray", dashArray);
      }
      if (name === "miterLimit") {
        obj.set("strokeMiterLimit", value);
      }
      if (name === "width") {
        obj.set("strokeWidth", value);
      }
    } else {
      if (name === "lineJoin") {
        obj.set("strokeLineJoin", value);
      }
      if (name === "lineCap") {
        obj.set("strokeLineCap", value);
      }
    }
    setStrokeProperties({ ...strokeProperties, [name]: value });
    selectedObject.canvas?.renderAll();
    selectedObject.canvas?.fire("object:modified");
  }

  function updateProperties() {
    if (isImage) {
      const frame = (selectedObject as Group)._objects.find(
        (x) => x.type !== "image"
      );
      if (frame) {
        return {
          type: frame.strokeDashArray ? "dashed" : "solid",
          dashLength:
            frame.strokeDashArray && frame.strokeDashArray.length
              ? frame.strokeDashArray[0]
              : 4,
          dashGap:
            frame.strokeDashArray && frame.strokeDashArray.length > 1
              ? frame.strokeDashArray[1]
              : 4,
          lineCap: frame.strokeLineCap ?? "butt",
          lineJoin: frame.strokeLineJoin ?? "miter",

          miterLimit: frame.strokeMiterLimit ?? 4,
          width: frame.strokeWidth ?? 0,
        };
      }
    }
    return {
      type: selectedObject.strokeDashArray ? "dashed" : "solid",
      dashLength:
        selectedObject.strokeDashArray && selectedObject.strokeDashArray.length
          ? selectedObject.strokeDashArray[0]
          : 4,
      dashGap:
        selectedObject.strokeDashArray &&
        selectedObject.strokeDashArray.length > 1
          ? selectedObject.strokeDashArray[1]
          : 4,
      lineCap: selectedObject.strokeLineCap ?? "butt",
      lineJoin: selectedObject.strokeLineJoin ?? "miter",

      miterLimit: selectedObject.strokeMiterLimit ?? 4,
      width: selectedObject.strokeWidth ?? 0,
    };
  }

  useEffect(() => {
    setStrokeProperties(updateProperties());
  }, [selectedObject, selectedObject.strokeWidth, frame.strokeWidth]);

  return (
    <div style={{ width: "100%" }}>
      <PropertyDropdown label="Border Settings" isPopout>
        <div>
          <div style={{ width: "100%" }}>
            <div css={propertiesStyles.propertiesGroup}>
              <div css={propertiesStyles.property}>
                <div style={{ width: "100%" }}>
                  <ColorPicker
                    selectedObject={selectedObject}
                    type="stroke"
                    debug
                  />
                </div>
              </div>
              <div css={propertiesStyles.property}>
                <div css={propertiesStyles.label}>Width</div>
                <IncrementInput
                  min={0}
                  max={100}
                  property={"width"}
                  value={strokeProperties.width}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div>
            <div css={propertiesStyles.propertiesGroup}>
              <div
                css={[
                  propertiesStyles.property,
                  css`
                    width: 48%;
                  `,
                ]}
              >
                <div css={propertiesStyles.label}>Line Type</div>
                <DesignerSelect
                  value={strokeProperties.type}
                  onChange={handleChange}
                  name="type"
                >
                  <DesignerSelectOption value="solid">
                    <div css={styles.strokeExample}>
                      <div
                        css={css({
                          borderTopWidth: 2 + "px",
                          borderColor: "#000000",
                          width: "80px",
                        })}
                      ></div>
                    </div>
                  </DesignerSelectOption>
                  <DesignerSelectOption value="dashed">
                    <div css={styles.strokeExample}>
                      <div
                        css={css({
                          borderTopWidth:
                            strokeProperties.width !== 0
                              ? strokeProperties.width + "px"
                              : "2px",
                          borderColor: "#000000",
                          borderStyle: "dashed",
                          width: "80px",
                        })}
                      ></div>
                    </div>
                  </DesignerSelectOption>
                </DesignerSelect>
              </div>
              <div css={propertiesStyles.property}>
                <div css={propertiesStyles.label}>Line Cap</div>
                <RoundedInputGroup>
                  <ToolButton
                    position="left"
                    isActive={strokeProperties.lineCap === "butt"}
                    onClick={() => handleChange("lineCap", "butt")}
                  >
                    Butt
                  </ToolButton>
                  <ToolButton
                    position="center"
                    isActive={strokeProperties.lineCap === "round"}
                    onClick={() => handleChange("lineCap", "round")}
                  >
                    Round
                  </ToolButton>
                  <ToolButton
                    position="right"
                    isActive={strokeProperties.lineCap === "square"}
                    onClick={() => handleChange("lineCap", "square")}
                  >
                    Square
                  </ToolButton>
                </RoundedInputGroup>
              </div>
            </div>

            {strokeProperties.type === "dashed" && (
              <div css={propertiesStyles.propertiesGroup}>
                <div css={propertiesStyles.property}>
                  <div css={propertiesStyles.label}>Dash Length</div>
                  <IncrementInput
                    min={0}
                    max={100}
                    value={strokeProperties.dashLength}
                    onChange={handleChange}
                    property="dashLength"
                  />
                </div>
                <div css={propertiesStyles.property}>
                  <div css={propertiesStyles.label}>Dash Gap</div>
                  <IncrementInput
                    min={0}
                    max={100}
                    value={strokeProperties.dashGap}
                    onChange={handleChange}
                    property="dashGap"
                  />
                </div>
              </div>
            )}

            <div css={propertiesStyles.propertiesGroup}>
              <div
                css={[
                  propertiesStyles.property,
                  css`
                    width: 53%;
                  `,
                ]}
              >
                <div css={propertiesStyles.label}>Line Join</div>
                <RoundedInputGroup>
                  <ToolButton
                    position="left"
                    isActive={strokeProperties.lineJoin === "round"}
                    onClick={() => handleChange("lineJoin", "round")}
                  >
                    Round
                  </ToolButton>
                  <ToolButton
                    position="center"
                    isActive={strokeProperties.lineJoin === "bevel"}
                    onClick={() => handleChange("lineJoin", "bevel")}
                  >
                    Bevel
                  </ToolButton>
                  <ToolButton
                    position="right"
                    isActive={strokeProperties.lineJoin === "miter"}
                    onClick={() => handleChange("lineJoin", "miter")}
                  >
                    Miter
                  </ToolButton>
                </RoundedInputGroup>
              </div>
              {strokeProperties.lineJoin === "miter" && (
                <div
                  css={[
                    propertiesStyles.property,
                    css`
                      width: 47%;
                    `,
                  ]}
                >
                  <div css={propertiesStyles.label}>Miter Limit</div>
                  <IncrementInput
                    property="miterLimit"
                    value={strokeProperties.miterLimit}
                    min={1}
                    max={100}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </PropertyDropdown>
    </div>
  );
};

export default AdvancedStrokeProperties;
