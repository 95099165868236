import { ReactComponent as Ribbon } from "./svg/ribbon.svg";
import { ReactComponent as Circle } from "./svg/circle.svg";
import { ReactComponent as Square } from "./svg/square.svg";
import { ReactComponent as Parallelogram } from "./svg/parallelogram.svg";
import { ReactComponent as Rectangle } from "./svg/rectangle.svg";
import { ReactComponent as Ellipse } from "./svg/ellipse.svg";
import { fabric } from "fabric";
import {
  forwardRef,
  ForwardRefExoticComponent,
  FunctionComponent,
  RefAttributes,
  SVGProps,
} from "react";
import tw, { css } from "twin.macro";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { Shape } from "../../Canvas/functions/shapeFunctions/getShape";

interface IShapeContainerProps {
  svg: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const styles = {
  container: [
    css`
      width: 85px;
      height: 85px;
    `,
    tw`mr-4 last:mr-0 cursor-pointer `,
  ],
  svg: [
    css`
      fill: #000000;
      width: 85px;
      background: transparent;
      margin: auto;
      cursor: pointer;
    `,
  ],
};

const ShapeContainer = forwardRef<SVGSVGElement, IShapeContainerProps>(
  ({ svg }, ref) => {
    const Svg = svg;
    return <Svg css={styles.svg} ref={ref} />;
  }
);

const RibbonShape = forwardRef<SVGSVGElement>((props, ref) => (
  <ShapeContainer svg={Ribbon} ref={ref} />
));
const CircleShape = forwardRef<SVGSVGElement>((props, ref) => (
  <ShapeContainer svg={Circle} ref={ref} />
));
const SquareShape = forwardRef<SVGSVGElement>((props, ref) => (
  <ShapeContainer svg={Square} ref={ref} />
));
const ParallelogramShape = forwardRef<SVGSVGElement>((props, ref) => (
  <ShapeContainer svg={Parallelogram} ref={ref} />
));

const RectangleShape = forwardRef<SVGSVGElement>((props, ref) => (
  <ShapeContainer svg={Rectangle} ref={ref} />
));

const EllipseShape = forwardRef<SVGSVGElement>((props, ref) => (
  <ShapeContainer svg={Ellipse} ref={ref} />
));

export interface IAvailableShape {
  name: Shape;
  Svg: ForwardRefExoticComponent<RefAttributes<SVGSVGElement>>;
}

const svgShapes: IAvailableShape[] = [
  { name: "square", Svg: SquareShape },
  { name: "rectangle", Svg: RectangleShape },
  { name: "circle", Svg: CircleShape },
  { name: "ellipse", Svg: EllipseShape },
  { name: "ribbon", Svg: RibbonShape },
];

export default svgShapes;
