import { Box } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

interface UnstyledButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  sx?: SxProps<Theme>;
}

const UnstyledButton: React.FC<UnstyledButtonProps> = (props) => {
  let sx: SxProps<Theme> = {
    background: "none",
    color: "inherit",
    border: "none",
    padding: 0,
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
  };

  if (props.sx) sx = { ...sx, ...props.sx };

  return (
    <Box {...props} component="button" sx={sx}>
      {props.children}
    </Box>
  );
};

export default UnstyledButton;
