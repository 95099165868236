import React, { useState, useEffect } from "react";
import Slides from "./components/Slides";

interface CarouselProps {
  images: string[];
  speed?: number;
  showCount?: boolean;
  slide?: number;
  setSlide?: (step: number) => void;
}

const NewCarousel: React.FC<CarouselProps> = ({
  images,
  speed = 300,
  showCount = false,

  slide,
  setSlide,
}) => {
  /**
   * State Objects and Refs
   */
  const [currentSlide, setCurrentSlide] = useState<number>(
    slide !== undefined && slide !== null ? slide : 0
  );
  const numberOfSlides = images.length - 1;

  /**
   * Component Methods
   */
  function nextSlide(): void {
    const newStep = currentSlide === numberOfSlides ? 0 : currentSlide + 1;
    setCurrentSlide(newStep);
    if (setSlide) setSlide(newStep);
  }

  function prevSlide(): void {
    const newStep = currentSlide === 0 ? numberOfSlides : currentSlide - 1;
    setCurrentSlide(newStep);
    if (setSlide) setSlide(newStep);
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <div>
      <Slides
        current={slide !== undefined && slide !== null ? slide : currentSlide}
        slides={images}
        speed={speed}
        next={nextSlide}
        prev={prevSlide}
        showCount={showCount}
      />
    </div>
  );
};

export default NewCarousel;
