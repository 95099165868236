import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import IDesignerHistory from "../models/IDesignerHistory";
import IDesignerState, {
  DesignerError,
  DesignerStatus,
} from "../models/IDesignerState";

import { DesignerState } from "../store";

const initialState: IDesignerState = {
  status: DesignerStatus.idle,
};

const updateStatusReducer = (
  state: IDesignerState,
  action: PayloadAction<DesignerStatus>
) => {
  state.status = action.payload;
};

const createErrorReducer = (
  state: IDesignerState,
  action: PayloadAction<DesignerError>
) => {
  state.error = action.payload;
  state.status = DesignerStatus.error;
};

const updateLastSaveReducer = (state: IDesignerState, action: Action) => {
  const date = new Date();
  state.lastSave = date.toString();
};

export const designerStateSlice = createSlice({
  name: "designerState",
  initialState: initialState,
  reducers: {
    updateStatus: updateStatusReducer,
    createError: createErrorReducer,
    updateLastSave: updateLastSaveReducer,
  },
});

export const { updateStatus, createError, updateLastSave } =
  designerStateSlice.actions;
export const selectDesignerState = (state: DesignerState) =>
  state.designerState;
export default designerStateSlice.reducer;
