import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useState } from "react";

const DesignFriendlyName = ({
  designID,
  friendlyName,
  callback,
}: {
  designID: number;
  friendlyName: string;
  callback: (designID: number, newName: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rename, setRename] = useState(friendlyName);

  const handleRename = () => {
    callback(designID, rename);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setRename(friendlyName);
  };

  return (
    <Box sx={{ mr: 2 }}>
      <Button onClick={() => setIsOpen(true)} size="small">
        Rename
      </Button>

      <Dialog open={isOpen} onClose={handleCancel}>
        <DialogTitle>Rename Design</DialogTitle>
        <DialogContent>
          <TextField
            value={rename}
            onChange={(e) => setRename(e.target.value)}
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRename} color="primary" variant="contained">
            Rename
          </Button>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DesignFriendlyName;
