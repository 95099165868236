import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import App from "../../../../data/models/App";
import { fetchClient } from "../../../../helpers/fetchClient";
import LoadingButton from "../../../../shared/LoadingButton";
import GridRow from "../../../../shared/MaterialWrappers/GridRow";
import GridItem from "../../../../shared/MaterialWrappers/GridItem";

interface ApiSecretProps {
  app: App;
}

const ApiSecret: FC<ApiSecretProps> = ({ app }) => {
  const [copied, setCopied] = useState(false);
  const [apiSecret, setApiSecret] = useState("");
  const [focused, setFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  function handleClick(e: React.MouseEvent<HTMLInputElement>) {
    setCopied(true);
    const input = e.currentTarget.querySelector("input");
    if (input) {
      input.select();
    }
      navigator.clipboard.writeText(apiSecret).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 6000);
    });
  }

  function handleDialog() {
    setOpen(!open);
  }

  function handleGenerateSecret() {
    setIsLoading(true);
    fetchClient.post({
      path: "/auth/generate-secret",
      onSuccess: (data) => {
        setApiSecret(data);
        setIsLoading(false);
        setFocused(true);
        handleDialog();
      },
      data: app.apiKey,
    });
  }

  return (
      <Box sx={{ mt: 3 }}>
      <Typography
        variant="h6"
        sx={{ textTransform: "capitalize" }}
        gutterBottom
      >
        {app.appType} Secret Key
      </Typography>
      {apiSecret && (
        <>
          <Typography variant="body1" sx={{ mb: 1 }}>
            You will not be able to retrieve this key after this session. Please
            store it somewhere safe.
          </Typography>
          <TextField
            value={apiSecret}
            onChange={(e) => e.preventDefault()}
            fullWidth
            variant="outlined"
            onClick={handleClick}
            focused={focused}
            sx={{ mb: 2 }}
          />
          {copied === true && (
            <Typography variant="caption" sx={{ fontStyle: "italic" }}>
              Copied to clipboard
            </Typography>
          )}
        </>
      )}
      <Typography variant="body1">
        For security reasons, we do not retain an unencrypted version of your
        API secret key. You can generate a new key whenever required.
      </Typography>
      <Button
        variant="outlined"
        onClick={handleDialog}
        disabled={isLoading}
        sx={{ mt: 2 }}
      >
        Generate API Secret
      </Button>
      <Dialog open={open} onClose={handleDialog} fullWidth maxWidth="sm">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Generating a new key will invalidate your current API secret key.
            Any existing integrations using that key will no longer function.
          </Typography>
          <Typography variant="body1">
            Are you certain you wish to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <GridRow justifyContent="flex-end">
            <GridItem>
              <Button variant="outlined" onClick={handleDialog}>
                Cancel
              </Button>
            </GridItem>
            <GridItem>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                onClick={handleGenerateSecret}
              >
                Generate API Secret
              </LoadingButton>
            </GridItem>
          </GridRow>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ApiSecret;
