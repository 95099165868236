import IEnvelope from "./IEnvelope";

export default interface IEnvelopeTabProps {
  envelope: IEnvelope;
  activeTab: EnvelopeDetailsTabStates;
  tabValue: EnvelopeDetailsTabStates;
}

export enum EnvelopeDetailsTabStates {
  info = "info",
  orderHistory = "orderHistory",
}
