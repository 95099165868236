import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useRef, useState } from "react";
import { TableColumn } from "react-data-table-component";
import SyntaxHighlighter from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ApiApp from "../../data/models/App";
import useLoadData from "../../hooks/useLoadData";
import Heading from "../../shared/BasicHTML/Heading";
import Section from "../../shared/BasicHTML/Section";
import LoadingWrapper from "../../shared/LoadingWrapper";
import GridItem from "../../shared/MaterialWrappers/GridItem";
import GridRow from "../../shared/MaterialWrappers/GridRow";
import SearchableDataTable from "../../shared/SearchableDataTable";
import TabPanel from "../../shared/TabPanel";
import NewWebhookDialog from "./components/NewWebhookDialog";
import WebhookSandboxDialog from "./components/WebhookSandboxDialog";

import { Webhook, WebhookDataResponse } from "./models/Webhook";
import LogsDialog from "./components/LogsDialog";
import DeleteWebhookConfirmationDialog from "./components/DeleteWebhookConfirmationDialog";
import getDocsUrl from "../../helpers/getDocsUrl";
import useGetData from "../../hooks/dataFetchers/useGetData";

enum JsonTab {
  processing = "processing",
  mailing = "mailing",
  delivered = "delivered",
  requiresPayment = "requiresPayment",
  undeliverable = "undeliverable",
  mailTracking = "mailTracking",
  issues = "issues",
  qrCode = "qrCode",
}

const Webhooks = () => {
  const [jsonTab, setJsonTab] = useState<JsonTab>(JsonTab.processing);

  const [newWebhookOpen, setNewWebhookOpen] = useState(false);
  const [sandboxOpen, setSandboxOpen] = useState(false);
  const [activeLogs, setActiveLogs] = useState(-1);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [activeTestWebhook, setActiveTestWebhook] = useState<Webhook>();

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement>();
  const [menuOpen, setMenuOpen] = useState(-1);

  const {
    data: webhookData,
    isLoading,
    updateData: setWebhookData,
    error,
  } = useGetData<WebhookDataResponse>(`/api/webhooks`);

  const webhookColumns: TableColumn<Webhook>[] = [
    {
      selector: (webhook) => webhook.friendlyName,
      sortable: true,
      name: "Name",
      maxWidth: "150px",
    },
    {
      selector: (webhook) => webhook.event,
      sortable: true,
      name: "Event",
    },
    {
      name: "URL",
      selector: (webhook) => webhook.subConfig,
      sortable: true,
      format: (webhook) => {
        const obj = JSON.parse(webhook.subConfig);
        return obj.url;
      },
    },
    {
      selector: (webhook) => webhook.secret,
      sortable: true,
      name: "Signature Secret",
    },
    {
      selector: (webhook) => {
        const app = webhookData?.apps.find((x) => x.appID === webhook.appID);
        if (app) return app.appType;
        return webhook.appID;
      },
      sortable: true,
      name: "Environment",
      maxWidth: "140px",
    },
    {
      maxWidth: "150px",
      cell: (webhook) => {
        function openLogs() {
          setActiveLogs(webhook.id);
          handleMenuClose();
        }

        function openTest() {
          setActiveTestWebhook(webhook);
          setSandboxOpen(true);
          handleMenuClose();
        }

        function openDelete() {
          setActiveDelete(webhook.id);
          handleMenuClose();
        }

        return (
          <Box>
            <Button
              color="primary"
              onClick={(e) => handleMenuOpen(e, webhook.id)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Menu
            </Button>
            <Menu
              open={menuOpen === webhook.id}
              anchorEl={menuAnchor}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={openLogs}>View Logs</MenuItem>
              <MenuItem onClick={openTest}>Test This Webhook</MenuItem>
              <MenuItem
                onClick={openDelete}
                sx={{ color: (theme) => theme.palette.error.main }}
              >
                Delete This Webhook
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  function handleMenuOpen(
    e: React.MouseEvent<HTMLButtonElement>,
    webhookId: number
  ) {
    setMenuAnchor(e.currentTarget);
    setMenuOpen(webhookId);
  }

  function handleMenuClose() {
    setMenuAnchor(undefined);
    setMenuOpen(-1);
  }

  function handleAddWebhooks(newHooks: Webhook[]) {
    if (webhookData)
      setWebhookData({
        ...webhookData,
        subscriptions: [...newHooks, ...webhookData.subscriptions],
      });
  }

  function handleRemoveWebhook(webhookId: number) {
    if (webhookData)
      setWebhookData({
        ...webhookData,
        subscriptions: webhookData.subscriptions.filter(
          (hook) => hook.id !== webhookId
        ),
      });
  }

  function handleSandboxClose() {
    setActiveTestWebhook(undefined);
    setSandboxOpen(false);
  }

  return (
    <Box sx={{ py: 6 }}>
      <Box>
        <Section>
          <Heading variant="h2">Webhooks</Heading>
          <Typography variant="body1">
            A webhook is a user-defined callback. They are triggered by an event
            and can facilitate integrating different applications or third-party
            APIs. PostcardMania uses webhooks to let your application know when
            certain events happen, like when an order requires payment or when
            your order has mailed. For more information about Webhooks,{" "}
            <Link href={getDocsUrl()} color="primary" target="_blank">
              check out our Docs!
            </Link>
          </Typography>
        </Section>
        <Section>
          <Heading variant="h3">Active Webhook Subscriptions</Heading>
          <LoadingWrapper
            loading={isLoading}
            container={Paper}
            height={348}
            hasError={error.hasError}
            errorMessage={error.errorMessage}
          >
            <Section>
              <SearchableDataTable
                columns={webhookColumns}
                data={webhookData?.subscriptions ?? []}
                title=""
                searchableColumns={[]}
                hideSearchBar
                paginationRowsPerPageOptions={[5, 10, 25, 50]}
                paginationPerPage={5}
              />
            </Section>
            <GridRow spacing={2}>
              <GridItem xs="auto">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setNewWebhookOpen(true)}
                >
                  Create Webhook
                </Button>
              </GridItem>
              <GridItem xs="auto">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setSandboxOpen(true)}
                >
                  Webhook Sandbox
                </Button>
              </GridItem>
            </GridRow>
          </LoadingWrapper>
        </Section>
        <Section>
          <Heading variant="h3">Webhook JSON Examples</Heading>
          <Paper elevation={0} sx={{ border: "solid 1px rgba(0,0,0,.185)" }}>
            <AppBar
              position="relative"
              color="transparent"
              style={{
                boxShadow: "none",
                borderBottom: "solid 1px rgba(0,0,0,.125)",
              }}
            >
              <Tabs
                value={jsonTab}
                onChange={(e, val: JsonTab) => setJsonTab(val)}
              >
                <Tab label="Order Processing" value={JsonTab.processing} />
                <Tab label="Order Mailing" value={JsonTab.mailing} />
                <Tab label="Order Delivered" value={JsonTab.delivered} />
                <Tab
                  label="Order Requires Payment"
                  value={JsonTab.requiresPayment}
                />
                <Tab
                  label="Order Undeliverable"
                  value={JsonTab.undeliverable}
                />
                <Tab label="Mail Tracking" value={JsonTab.mailTracking} />
                <Tab label="Order Issues" value={JsonTab.issues} />
                <Tab label="QR Code Scan" value={JsonTab.qrCode} />
              </Tabs>
            </AppBar>

            <TabPanel value={JsonTab.processing} activeTab={jsonTab}>
              <Typography variant="body1">
                This webhook will be fired once your order has begun processing.
              </Typography>
              <Heading variant="h6">JSON</Heading>
              <Paper>
                <SyntaxHighlighter language="json" style={nord}>
                  {`{
  "Event": "OrderProcessing",
  "Data": {
    "Status": "Processing",
    "BatchID": 0
  }  
}`}
                </SyntaxHighlighter>
              </Paper>
            </TabPanel>
            <TabPanel value={JsonTab.mailing} activeTab={jsonTab}>
              <Typography variant="body1">
                This webhook will be fired once your order has begun mailing.
              </Typography>
              <Heading variant="h6">JSON</Heading>
              <Paper>
                <SyntaxHighlighter language="json" style={nord}>
                  {`{
  "Event": "OrderMailing",
  "Data": {
    "Status": "Mailing",
    "BatchID": 0
  }  
}`}
                </SyntaxHighlighter>
              </Paper>
            </TabPanel>
            <TabPanel activeTab={jsonTab} value={JsonTab.delivered}>
              <Typography variant="body1">
                This webhook will be fired once your order has started hitting
                mailboxes.
              </Typography>
              <Heading variant="h6">JSON</Heading>
              <Paper>
                <SyntaxHighlighter language="json" style={nord}>
                  {`{
  "Event": "OrderDelivered",
  "Data": {
    "Status": "Delivered",
    "BatchID": 0
  }  
}`}
                </SyntaxHighlighter>
              </Paper>
            </TabPanel>
            <TabPanel value={JsonTab.requiresPayment} activeTab={jsonTab}>
              <Typography variant="body1">
                This webhook will be fired if an order requires payment.
              </Typography>
              <Heading variant="h6">JSON</Heading>
              <Paper>
                <SyntaxHighlighter language="json" style={nord}>
                  {`{
  "Event": "OrderRequiresPayment",
  "Data": {
    "Status": "Pending Payment",
    "BatchID": 0
  }  
}`}
                </SyntaxHighlighter>
              </Paper>
            </TabPanel>
            <TabPanel value={JsonTab.undeliverable} activeTab={jsonTab}>
              <Typography variant="body1">
                This webhook will be fired if every recpient in your order is
                undeliverable.
              </Typography>
              <Heading variant="h6">JSON</Heading>
              <Paper>
                <SyntaxHighlighter
                  language="json"
                  style={nord}
                  customStyle={{ marign: 0 }}
                >
                  {`{
  "Event": "OrderUndeliverable",
  "Data": {
    "Status": "Undeliverable",
    "BatchID": 0
  }  
}`}
                </SyntaxHighlighter>
              </Paper>
            </TabPanel>
            <TabPanel value={JsonTab.mailTracking} activeTab={jsonTab}>
              <Typography variant="body1">
                This webhook will be fired every time there are mail tracking
                updates for your order. Check out our docs for more information
                on{" "}
                <a
                  href={`${getDocsUrl()}/help/docs/webhooks/mail-tracking`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Mail Tracking Webhooks
                </a>
              </Typography>
              <Heading variant="h6">JSON</Heading>
              <Paper>
                <SyntaxHighlighter language="json" style={nord}>
                  {`{
  "Event": "MailTracking",
  "Data": {
    "RecipientRecordID": 0,
    "Status": "Delivered",
    "LastFacility": "34655",
    "ReasonCode": null,
    "ActionCode": null,
    "ExtRefNbr": ""
  }
}`}
                </SyntaxHighlighter>
              </Paper>
            </TabPanel>
            <TabPanel activeTab={jsonTab} value={JsonTab.issues}>
              <Typography variant="body1">
                This webhook will be fired once your order has started mailing
                and there are undeliverable recipients.
              </Typography>
              <Heading variant="h6">JSON</Heading>
              <Paper>
                <SyntaxHighlighter language="json" style={nord}>
                  {`{
  "Event": "OrderIssues",
  "Data": {
    "BatchID": 0,
    "Status": "Mailing",
    "failedRecipients": [
      {
        "RecordID": 0,
        "OrderID": 0,
        "Address": "",
        "Address2": null,
        "City": "",
        "State": "",
        "ZipCode": "",
        "FirstName": "",
        "LastName": "",
        "ExternalRefNbr": null,
        "Undeliverable": true,
        "UndeliverableReason": "",
        "MailingStatus": null,
        "RecipientListID": 0,
        "DeliveryDate": null,
        "LastScanDate": null,
        "LastFacilityID": null,
        "RecipientListRecordsExt": null
      }
    ]
  }  
}`}
                </SyntaxHighlighter>
              </Paper>
            </TabPanel>
            <TabPanel activeTab={jsonTab} value={JsonTab.qrCode}>
              <Typography variant="body1">
                This webhook will be fired whenever someone scans your QR Codes.
              </Typography>
              <Heading variant="h6">JSON</Heading>
              <Paper>
                <SyntaxHighlighter language="json" style={nord}>
                  {`{
  "Event": "QRCodeScan",
  "Data": {
    "Url": "https://example.com",
    "IpAddress": "1.1.1.1",
    "ScannedAt": "2023-01-01T00:00:00Z",
    "OrderID": 1,
    "OrderExternalRefNbr": "123456789",
    "Recipient": {
      "ExternalRefNbr": "123456789",
      "FirstName": "John",
      "LastName": "Doe",
      "Address": "123 Main St",
      "Address2": "Apt 1",
      "City": "New York",
      "State": "NY",
      "ZipCode": "10001"
    }
  }  
}`}
                </SyntaxHighlighter>
              </Paper>
            </TabPanel>
          </Paper>
        </Section>
        <NewWebhookDialog
          apps={webhookData?.apps ?? []}
          successCallback={handleAddWebhooks}
          open={newWebhookOpen}
          onClose={() => setNewWebhookOpen(false)}
        />
        <WebhookSandboxDialog
          open={sandboxOpen}
          onClose={handleSandboxClose}
          subscriptionID={activeTestWebhook?.id}
          initialSubConfig={activeTestWebhook?.subConfig}
          initialEvent={activeTestWebhook?.event}
        />

        {activeLogs !== -1 && webhookData && (
          <LogsDialog
            open={true}
            webhook={webhookData.subscriptions.find((x) => x.id === activeLogs)}
            onClose={() => setActiveLogs(-1)}
          />
        )}

        <DeleteWebhookConfirmationDialog
          open={activeDelete !== -1}
          onClose={() => setActiveDelete(-1)}
          webhook={webhookData?.subscriptions.find(
            (x) => x.id === activeDelete
          )}
          onSuccessCallback={handleRemoveWebhook}
        />
      </Box>
    </Box>
  );
};

export default Webhooks;
