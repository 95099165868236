import { fabric } from "fabric";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { CanvasContext } from "../state/contexts/CanvasContext";
import {
  CanvasObject,
  IPage,
  PageActions,
  PagesContext,
  PagesDispatchContext,
} from "../state/contexts/PagesContext";
import {
  SelectedPageContext,
  SelectedPageDispatchContext,
} from "../state/contexts/SelectedPageContext";
import { Tool } from "../state/models/ICanvasTool";
import { ILayersCollection } from "../state/models/ILayers";
import ILayer from "../state/models/layers/ILayer";
import ILayersObject from "../state/models/layers/ILayersObject";
import {
  addNewLayer,
  addNewPage,
  removePage,
  movePage as movePageAction,
  updateLayerName,
  updateLayerState,
  updateLayers,
  updateSelectedLayers,
} from "../state/slices/layers";
import { useDesignerDispatch, useDesignerSelector } from "../state/store";
import {
  findLayerIdByDistance,
  getLayerSeekObject,
  moveLayer,
  renameLayer,
} from "../features/Canvas/functions/layerOrderHelpers";
import reArrangeObjects from "../features/Canvas/functions/reArrangeObjects";
import useHistory from "./useHistory";
import useSaveData from "./useSaveData";
import {
  ActiveSelection,
  Canvas,
  Group,
  Object as IObject,
} from "fabric/fabric-impl";
import generateGuid from "../../helpers/generateGuid";
import createGroupFromSelection from "../features/Canvas/functions/customFabricFunctionality/createGroupFromSelection";
import getQrCodeId from "../features/QrCodes/functions/getQrCodeId";
import { setQrCodeId } from "../state/slices/qrCode";
export interface ILayerAndObject extends ILayer {
  object?: CanvasObject;
  layerIndex: string | number;
}
// returns a collection of helper functions that interact with Layers and the Canvas
const useLayers = () => {
  const canvas = useContext(CanvasContext);
  const selectedPage = useContext(SelectedPageContext);
  const selectedPageRef = useRef(selectedPage);
  selectedPageRef.current = selectedPage;
  const [pageToAdd, setPageToAdd] = useState<string>();
  const [pageToDelete, setPageToDelete] = useState<string>();
  const [pageToChangeTo, setPageToChangeTo] = useState<{
    newPage: string;
    prevPage: string;
    from: number;
    destination: number;
  }>();
  const [pageMoveTo, setPageMoveTo] = useState<{
    from?: number;
    destination?: number;
    page: string;
  }>();
  const pages = useContext(PagesContext);
  const pagesRef = useRef(pages);
  pagesRef.current = pages;
  const initialized = useRef(false);
  const selectedPageDispatch = useContext(SelectedPageDispatchContext);
  const pagesDispatch = useContext(PagesDispatchContext);
  const { handleUpdate, saveData } = useSaveData();
  const { updateHistory } = useHistory();
  const { layers, currentTool, pageLayers, selectedLayers } =
    useDesignerSelector((state) => ({
      layers: state.layers,
      currentTool: state.toolSettings.currentTool,
      pageLayers: state.layers.pageLayers,
      selectedLayers: state.layers.selectedLayers,
    }));
  // @ts-ignore
  window.debugLayers = layers;
  const dispatch = useDesignerDispatch();
  const shiftKeyDown = useRef(false);

  const [selectedPageLayers, setSelectedPageLayers] = useState(getLayers());

  // useEffect(updatePage, [layers]);

  // function updatePage() {
  //   if (!canvas) return;
  //   const objects = canvas.getObjects();
  //   if (layers.pageLayers.length !== pages.length) {
  //     if (pages.length < layers.pageLayers.length) {
  //       const backgroundAndOverlayObjects = canvas.getObjects().filter(
  //         (x) =>
  //           (x.name === "background" ||
  //             x.name === "overlay" ||
  //             x.name === "bleed") &&
  //           // @ts-ignore
  //           !x.__addressBlock
  //       );
  //       const name = `Page ${pages.length + 1}`;
  //       pagesDispatch({
  //         type: PageActions.setPages,
  //         payload: [
  //           ...pages,
  //           {
  //             name,
  //             objects: backgroundAndOverlayObjects,
  //           },
  //         ],
  //       });
  //       setPageToAdd(name);
  //     }
  //   } else
  //     pagesDispatch({
  //       type: PageActions.updatePage,
  //       payload: { name: selectedPage, objects: objects },
  //     });
  // }

  function getLayers(): { page: string; layers: ILayerAndObject[] } {
    if (
      !selectedPage ||
      !layers ||
      !layers.pageLayers ||
      layers.pageLayers.length === 0
    ) {
      return { page: selectedPageRef.current ?? "", layers: [] };
    }
    const layerPage = layers.pageLayers.find(
      (x) => x.name === selectedPageRef.current,
    );
    const page = pages.find((x) => x.name === selectedPageRef.current);
    if (!layerPage || !page)
      return { page: selectedPageRef.current, layers: [] };
    const keys = Object.keys(layerPage.layers);
    const obj = keys.reduce(
      (prev, curr: keyof ILayersObject) => {
        const layer = layerPage.layers[curr];
        if (layer) {
          const object = page.objects.find((x) => x.name === layer.id);
          if (!object) return prev;
          prev.layers.push({
            ...layerPage.layers[curr],
            object,
            layerIndex: curr,
          });
        }
        return prev;
      },
      { page: selectedPageRef.current, layers: [] as ILayerAndObject[] },
    );
    obj.layers = obj.layers.reverse();
    return obj;
  }

  /*
 function handleChangePages() {
    if (!canvas || !initialized.current) return;

    if (selectedPage !== currentRenderedPage.current) {
      canvas.remove(...canvas.getObjects());
      currentRenderedPage.current = selectedPage;
      const page = pages.find((x) => x.name === selectedPage);
      if (page) {
        canvas.add(...page.objects);
        const pageLayers = layers.pageLayers.find(
          (x) => x.name === selectedPage
        );
        if (pageLayers) {
          reArrangeObjects(pageLayers.layers, canvas);
        }

        canvas.renderAll();
      }
    }
  }
  */

  function changePage(
    page: string,
    prevPage?: string,
    from?: number,
    destination?: number,
  ) {
    if (!canvas) return;
    canvas.discardActiveObject();

    handleUpdate();

    const pageObj = pages.find((x) => x.name === page);
    const layerPage = prevPage ? prevPage : page;
    const pageLayers = layers.pageLayers.find((x) => x.name === layerPage);
    if (!pageObj || !pageLayers) return;
    canvas.remove(...canvas.getObjects());
    canvas.add(...pageObj.objects);

    reArrangeObjects(pageLayers.layers, canvas);
    canvas.renderAll();
    selectedPageDispatch({ type: "", payload: page });

    if (prevPage && from !== undefined && destination !== undefined) {
      setPageMoveTo({ from, destination, page });
      return;
    }
  }

  function addPage() {
    if (pages.length < 5 && canvas) {
      const pageNum = pages.length + 1;
      const name = `Page ${pageNum}`;

      dispatch(addNewPage({ name }));
    }
  }

  function movePage(page: string, destination: number) {
    const index = pages.findIndex((x) => x.name === page);
    if (index === -1 || index === destination) return;
    const newPages = pages.filter((x) => x.name !== page);
    if (index === pages.length) {
      newPages.push(pages[index]);
    } else {
      newPages.splice(destination, 0, pages[index]);
    }
    const selectedPageIndex = newPages.findIndex(
      (x) => x.name === selectedPage,
    );
    const finalNewPages = newPages.map((x, i) => {
      return { ...x, name: `Page ${i + 1}` };
    });
    pagesDispatch({ type: PageActions.setPages, payload: finalNewPages });
    if (selectedPageIndex !== -1) {
      setPageToChangeTo({
        newPage: finalNewPages[selectedPageIndex].name,
        prevPage: page,
        from: index,
        destination,
      });
    }
  }

  function deletePage(page: string) {
    let index = pages.findIndex((x) => x.name === page);
    const deleting = pages[index];
    if (index === -1) return;
    const newPages = pages
      .filter((x) => x.name !== page)
      .map((x, i) => {
        if (page.toLowerCase() === "page 1") {
          const overlay = deleting.objects.filter((x) => x.name === "overlay");
          x.objects = [
            ...x.objects.filter((x) => x.name !== "overlay"),
            ...overlay,
          ];
        }
        return { ...x, name: `Page ${i + 1}` };
      });
    if (index > newPages.length - 1) index = newPages.length - 1;

    dispatch(removePage({ name: page }));
    pagesDispatch({
      type: PageActions.setPages,
      payload: newPages,
    });
    if (selectedPage === page) {
      changePage(newPages[index].name);
      setPageToDelete(page);
    }
  }

  useEffect(() => {
    if (pageToAdd) {
      setPageToAdd(undefined);
      changePage(pageToAdd);
    }
  }, [pageToAdd]);

  useEffect(() => {
    if (pageToChangeTo) {
      setPageToChangeTo(undefined);
      changePage(
        pageToChangeTo.newPage,
        pageToChangeTo.prevPage,
        pageToChangeTo.from,
        pageToChangeTo.destination,
      );
    }
  }, [pageToChangeTo]);

  useEffect(() => {
    if (
      pageMoveTo &&
      pageMoveTo.from !== undefined &&
      pageMoveTo.destination !== undefined
    ) {
      setPageMoveTo({ page: pageMoveTo.page });
      dispatch(
        movePageAction({ from: pageMoveTo.from, to: pageMoveTo.destination }),
      );
    }
    if (
      pageMoveTo &&
      pageMoveTo.page &&
      pageMoveTo.from === undefined &&
      pageMoveTo.destination == undefined
    ) {
      setPageMoveTo(undefined);
      changePage(pageMoveTo.page);
    }
  }, [pageMoveTo]);

  useEffect(() => {
    if (pageToDelete) {
      setPageToDelete(undefined);
      changePage(pageToDelete);
    }
  }, [pageToDelete]);

  function createLayer(
    id: string,
    layerName?: string,
    disableHistory?: boolean,
  ) {
    if (canvas) {
      const object = canvas._objects.find((x) => x.name === id);
      let layerType = "";
      if (object) {
        switch (object.type) {
          case "group": {
            const group = object as Group;
            const img = group._objects.find((x) => x.type === "image");
            if (img) layerType = "Image";
            else layerType = "Layer";
            break;
          }
          case "rect":
          case "ellipse":
          case "polygon":
          case "circle": {
            layerType = "Shape";
            break;
          }
          case "textbox": {
            layerType = "Text";
            break;
          }
          case "path": {
            layerType = "Path";
            break;
          }
          case "image": {
            if (object.name?.includes("qrcode")) {
              layerType = "QR Code";
            }
            break;
          }
          default:
            layerType = "Layer";
            break;
        }
      }
      dispatch(
        addNewLayer({
          page: selectedPageRef.current,
          name: id,
          layerName,
          layerType,
        }),
      );
      const overlay = canvas._objects.filter(
        (x) => x.name === "overlay" || x.name === "bleed",
      );
      if (overlay.length) {
        overlay.forEach((obj) => {
          obj.bringToFront();
          obj.perPixelTargetFind = true;
          obj.selectable = false;
        });
      }
      if (object) {
        canvas.setActiveObject(object);
      }
      setTimeout(() => {
        if (canvas && !disableHistory) {
          updateHistory(canvas.getObjects(), "layer created");
        }
      }, 1);
    }
  }

  function groupingGroups(selection: ActiveSelection | IObject) {
    const groupsFound: { index: string; group: ILayer }[] = [];
    const pageLayers = layers.pageLayers.find(
      (x) => x.name === selectedPageRef.current,
    );

    if (!pageLayers || !selectedLayers.length) return true;
    const keys = Object.keys(pageLayers.layers) as (keyof ILayersObject)[];
    const groups = keys.reduce((layers, key, index) => {
      const layer = pageLayers.layers[key];
      if (layer.layers) {
        return [...layers, { [key]: layer }];
      }
      return layers;
    }, [] as ILayersObject[]);

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const key = Object.keys(group)[0];

      const groupObj = group[key];
      if (groupObj) {
        if (selectedLayers.find((x) => x.index === groupObj.id)) {
          groupsFound.push({
            index: key,
            group: groupObj,
          });
        } else if (groupObj.layers) {
          const layer = Object.keys(groupObj.layers).find(
            (x: keyof ILayersObject) =>
              selectedLayers.find(
                (y) => groupObj.layers && y.index === groupObj.layers[x].id,
              ),
          );
          if (layer) {
            groupsFound.push({
              index: key,
              group: groupObj,
            });
          }
        }
      }
    }

    // if we have more than one group selected, just return out, we shouldn't group these objects.
    if (groupsFound.length > 1) return true;
    // if we have one group in our selection, we need to insert the rest of the selection into the group and return out.
    if (groupsFound.length === 1) {
      const group = groupsFound[0];
      const groupObj = group.group;
      if (!groupObj.layers) return true;
      const groupLayers = Object.keys(groupObj.layers)
        .map((x) => (groupObj.layers ? groupObj.layers[x] : undefined))
        .filter((x) => x !== undefined) as ILayer[];
      let sel: ActiveSelection;
      if (selection.type === "activeSelection") {
        sel = selection as ActiveSelection;
      } else {
        sel = new fabric.ActiveSelection([selection as IObject], { canvas });
        canvas?.setActiveObject(sel);
      }
      if (sel.type === "activeSelection") {
        const selectedLayers = sel._objects
          .filter((x) => !groupLayers.find((y) => y.id === x.name))
          .map((x) => {
            return (
              Object.keys(pageLayers.layers).find(
                (y) => pageLayers.layers[y].id === x.name,
              ) ?? ""
            );
          });
        const highestIndex = group.index;

        const newLayers = { ...pageLayers.layers };
        const id = group.group.id;
        let index = groupLayers.length;
        newLayers[highestIndex] = {
          ...group.group,
          layers: {
            ...group.group.layers,
            ...selectedLayers.reduce((prev, curr) => {
              prev[index] = pageLayers.layers[curr];
              index++;
              return prev;
            }, {} as ILayersObject),
          },
        };

        selectedLayers.forEach((x) => {
          const index = parseInt(x);
          if (index !== parseInt(highestIndex)) {
            delete newLayers[index];
          }
        });
        const newPageLayers = Object.keys(newLayers).reduce(
          (prev, curr, index) => {
            prev[index] = newLayers[curr];
            return prev;
          },
          {} as ILayersObject,
        );
        dispatch(
          updateLayers({
            name: selectedPageRef.current,
            layers: newPageLayers,
          }),
        );
        dispatch(
          updateSelectedLayers([{ page: selectedPageRef.current, index: id }]),
        );
        updateHistory([], "layer grouped");
      }

      return true;
    }
    return false;
  }

  function groupLayers(selection: ActiveSelection | IObject) {
    const pageLayers = layers.pageLayers.find(
      (x) => x.name === selectedPageRef.current,
    );
    if (!pageLayers) return;
    if (groupingGroups(selection)) return;

    let sel: ActiveSelection;
    if (selection.type === "activeSelection") {
      sel = selection as ActiveSelection;
    } else {
      sel = new fabric.ActiveSelection([selection as IObject], { canvas });
      canvas?.setActiveObject(sel);
    }
    if (sel.type === "activeSelection") {
      const selectedLayers = sel._objects.map((x) => {
        return (
          Object.keys(pageLayers.layers).find(
            (y) => pageLayers.layers[y].id === x.name,
          ) ?? ""
        );
      });
      const highestIndex = selectedLayers.reduce((prev, curr) => {
        const index = parseInt(curr);
        if (index > prev) return index;
        return prev;
      }, 0);

      const newLayers = { ...pageLayers.layers };
      const id = `group-${generateGuid()}`;
      newLayers[highestIndex] = {
        name: "New Group",
        id: id,
        layers: selectedLayers.reduce((prev, curr, index) => {
          prev[index] = pageLayers.layers[curr];
          return prev;
        }, {} as ILayersObject),
      };

      selectedLayers.forEach((x) => {
        const index = parseInt(x);
        if (index !== highestIndex) {
          delete newLayers[index];
        }
      });
      const newPageLayers = Object.keys(newLayers).reduce(
        (prev, curr, index) => {
          prev[index] = newLayers[curr];
          return prev;
        },
        {} as ILayersObject,
      );
      dispatch(
        updateLayers({ name: selectedPageRef.current, layers: newPageLayers }),
      );
      dispatch(
        updateSelectedLayers([{ page: selectedPageRef.current, index: id }]),
      );
      updateHistory([], "layer grouped");
    } else {
    }
  }

  function ungroupLayers() {
    if (
      selectedLayers &&
      selectedLayers.length === 1 &&
      selectedLayers[0].index.includes("group")
    ) {
      const layerId = selectedLayers[0].index;

      const pageLayers = layers.pageLayers.find(
        (x) => x.name === selectedPageRef.current,
      );
      if (!pageLayers) return;
      const index = Object.keys(pageLayers.layers).find(
        (x) => pageLayers.layers[x].id === layerId,
      );
      if (!index) return;
      const layer = pageLayers.layers[index];

      if (layer.layers && layer.id.includes("group")) {
        const layersArr = Object.keys(pageLayers.layers).map(
          (x) => pageLayers.layers[x],
        );
        const layerGroupArr = Object.keys(layer.layers).map(
          (x) => layer.layers && layer.layers[x],
        ) as ILayer[];
        const newLayers: ILayer[] = [];
        layersArr.forEach((l, i) => {
          if (i === parseInt(index)) {
            layerGroupArr.forEach((j) => {
              newLayers.push(j);
            });
          } else {
            newLayers.push(l);
          }
        });

        dispatch(
          updateLayers({
            name: selectedPageRef.current,
            layers: newLayers.reduce(
              (prev, curr, i) => ({ ...prev, [i]: curr }),
              {} as ILayersObject,
            ),
          }),
        );
        dispatch(
          updateSelectedLayers(
            layerGroupArr.map((x) => ({
              index: x.id,
              page: selectedPageRef.current,
            })),
          ),
        );
      }
      updateHistory([], "layer ungrouped");
    }
  }

  function selectLayers(id: string, forceUnlock = false) {
    if (!canvas || canvas.selection === false) return;
    let selectedLayers = [...layers.selectedLayers];
    const page = selectedPageRef.current;
    // // if we're not currently viewing this page, we need to switch pages.
    // if (page !== selectedPage) {
    //   changePage(page);
    //   selectedLayers = [];
    // }
    // if we're holding the shift key, then we need to add/remove selections
    if (shiftKeyDown.current) {
      const indexOfExistingSelection = selectedLayers.findIndex(
        (x) => x.index === id && x.page === selectedPageRef.current,
      );
      // if we found this layer in our collection, it means we're deselecting it.
      if (indexOfExistingSelection !== -1) {
        selectedLayers.splice(indexOfExistingSelection, 1);
      } else {
        selectedLayers.push({ page, index: id });
      }
    }
    // otherwise we need to select only one layer
    else {
      selectedLayers = [{ page, index: id }];
    }
    // if we are using the select tool currently, we need to select the appropriate objects on the canvas
    if (canvas) {
      // remove any current selection
      canvas.discardActiveObject();

      const names = selectedLayers.map((x) => x.index);

      // find the layers
      const selectedObjects = canvas._objects
        .filter((x) => x.name && names.includes(x.name))
        .map((object) => {
          // object.set("selectable", true);
          // @ts-ignore
          // object.set("active", true);
          return object;
        });

      const pageLayers = layers.pageLayers.find((x) => x.name === page);

      if (pageLayers) {
        const pageLayersArr = Object.keys(pageLayers.layers).map(
          (key) => pageLayers.layers[key],
        );
        selectedLayers.forEach((layer) => {
          if (layer.index.includes("group")) {
            const groupLayer = pageLayersArr.find((x) => x.id === layer.index);

            if (groupLayer && groupLayer.layers) {
              Object.keys(groupLayer.layers).forEach((l) => {
                if (groupLayer.layers) {
                  const layerObj = groupLayer.layers[l];
                  const obj = canvas._objects.find(
                    (x) => x.name === layerObj.id,
                  );
                  if (obj) {
                    selectedObjects.push(obj);
                  }
                }
              });
            }
          }
        });
        const layer = findLayer(pageLayers.layers, id);

        if (layer && !layer.isHidden && (!layer.isLocked || forceUnlock)) {
          if (selectedObjects.length > 1) {
            // create the selection from layers
            const activeSelection = new fabric.ActiveSelection(
              selectedObjects,
              {
                canvas: canvas,
              },
            );
            // set the selection
            canvas.setActiveObject(activeSelection);
          } else {
            // if we only have one layer selected, we can directly set that object as our active selection.
            canvas.setActiveObject(selectedObjects[0]);
          }
        } else {
          canvas.discardActiveObject();
        }
      } else {
        canvas.discardActiveObject();
      }

      // render all of the changes we made.
      canvas.renderAll();

      dispatch(updateSelectedLayers(selectedLayers));
      return;
    }

    // dispatch(updateSelectedLayers(selectedLayers));
    // const ids = selectedLayers.map((x) => x.index);
    // const selectedObjects = canvas._objects.filter(
    //   (x) => x.name && ids.includes(x.name),
    // );
    // canvas.discardActiveObject();
    // const selection = new fabric.ActiveSelection(selectedObjects, {
    //   canvas: canvas,
    // });
    // canvas.setActiveObject(selection);
  }

  function moveLayerXSpaces(page: string, id: string, distance: number) {
    // get the layers for the page we are targeting.
    const pageLayers = layers.pageLayers.find((x) => x.name === page);
    if (pageLayers && canvas) {
      // find the id of the destination by the number of spaces we want to move this layer
      const destination = findLayerIdByDistance(
        id,
        distance,
        pageLayers.layers,
      );
      // reorder the layers
      const reorderedLayers = moveLayer(id, destination, pageLayers.layers);
      // run through the canvas and reorder the objects
      reArrangeObjects(reorderedLayers, canvas);

      dispatch(updateLayers({ name: page, layers: reorderedLayers }));
    }
  }

  function toggleLock(layer: ILayer, id: string) {
    if (!canvas) return;
    const pageLayers = layers.pageLayers.find(
      (x) => x.name === selectedPageRef.current,
    );
    if (!pageLayers || !pageLayers.layers[id]) return;

    const isLocked = layer.isLocked ? false : true;
    const update = updateLayerProperty(
      layers.pageLayers,
      selectedPage,
      layer.id,
      "isLocked",
      isLocked,
    );
    canvas.discardActiveObject();
    if (update) {
      if (layer.id.includes("group") && layer.layers) {
        update.layers[id].layers = Object.keys(layer.layers).reduce(
          (prev, curr) => {
            const layers = layer.layers;
            if (layers) {
              prev[curr] = { ...layers[curr], isLocked };
              const obj = canvas?._objects.find(
                (x) => x.name === layers[curr].id,
              );
              if (obj) {
                obj.selectable = !isLocked;
                // @ts-ignore
                obj.__locked = isLocked;
              }
            }

            return prev;
          },
          {} as ILayersObject,
        );
        if (!isLocked) {
          if (selectedLayers.find((x) => x.index === layer.id)) {
            selectLayers(layer.id, true);
          }
        }
      } else {
        const object = canvas._objects.find((x) => x.name === layer.id);

        if (object) {
          object.selectable = !isLocked;
          // @ts-ignore
          object.__locked = isLocked;
          if (object.selectable && selectedLayers.length) {
            if (selectedLayers.find((x) => x.index === object.name)) {
              const activeSelection = canvas.getActiveObject();
              if (!activeSelection) {
                canvas.setActiveObject(
                  new fabric.ActiveSelection([object], { canvas }),
                );
              } else if (activeSelection.type === "activeSelection") {
                (activeSelection as ActiveSelection).addWithUpdate(object);
              } else {
                canvas.setActiveObject(
                  new fabric.ActiveSelection([activeSelection, object], {
                    canvas,
                  }),
                );
              }
            }
          } else if (!object.selectable) {
            const activeSelection = canvas.getActiveObject();
            if (activeSelection) {
              if (
                activeSelection.type !== "activeSelection" &&
                activeSelection.name === object.name
              ) {
                canvas.discardActiveObject();
              }
              if (activeSelection.type === "activeSelection") {
                const objects = (
                  activeSelection as ActiveSelection
                )._objects.filter((x) => x.name !== object.name);

                canvas.discardActiveObject();
                canvas.setActiveObject(
                  new fabric.ActiveSelection(objects, { canvas }),
                );
              }
            }
          }
          //canvas.discardActiveObject();
        }
      }
      canvas.renderAll();
      dispatch(updateLayers(update));
    }
  }
  function toggleCollapse(layer: ILayer, id: string) {
    const pageLayers = layers.pageLayers.find(
      (x) => x.name === selectedPageRef.current,
    );
    if (!pageLayers || !pageLayers.layers[id]) return;
    const update: ILayersCollection = { ...pageLayers };
    const updatedLayers = Object.keys(update.layers).reduce((prev, curr) => {
      prev[curr] = {
        ...update.layers[curr],
        isCollapsed:
          update.layers[curr].isCollapsed !== undefined
            ? update.layers[curr].isCollapsed
            : false,
      };
      return prev;
    }, {} as ILayersObject);
    update.layers = updatedLayers;

    update.layers[id].isCollapsed = pageLayers.layers[id].isCollapsed
      ? false
      : true;

    dispatch(updateLayers(update));
  }
  function toggleVisibility(layer: ILayer, id: string) {
    if (!canvas) return;

    const pageLayers = layers.pageLayers.find(
      (x) => x.name === selectedPageRef.current,
    );
    if (!pageLayers || !pageLayers.layers[id]) return;
    const value = !layer.isHidden;
    const update = updateLayerProperty(
      layers.pageLayers,
      selectedPageRef.current,
      layer.id,
      "isHidden",
      value,
    );
    if (layer.layers) {
      Object.keys(layer.layers).forEach((key) => {
        // @ts-ignore
        const l = layer.layers[key];
        if (l) {
          const obj = canvas.getObjects().find((x) => x.name === l.id);
          if (obj) {
            obj.visible = !value;
          }
        }
      });
      canvas.renderAll();
    } else {
      const obj = canvas.getObjects().find((x) => x.name === layer.id);
      if (obj) {
        obj.visible = !value;
        canvas.renderAll();
      }
    }
    if (update) dispatch(updateLayers(update));
  }

  function moveLayerToDestination(
    page: string,
    originId: string,
    destinationId: string,
  ) {
    const pageLayers = layers.pageLayers.find((x) => x.name === page);
    if (pageLayers && canvas) {
      const reorderedLayers = moveLayer(
        originId,
        destinationId,
        pageLayers.layers,
      );

      reArrangeObjects(reorderedLayers, canvas);
      dispatch(updateLayers({ name: page, layers: reorderedLayers }));
    }
  }

  function changeLayerName(
    page: string,
    key: string,
    name: string,
    index: string,
  ) {
    const pageLayers = layers.pageLayers.find((x) => x.name === page);
    if (pageLayers) {
      const newLayers = renameLayer(pageLayers.layers, key, name, index);

      dispatch(updateLayers({ name: page, layers: newLayers }));
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", shiftKeyListener);
    window.addEventListener("keyup", shiftKeyListener);
    function dispose() {
      window.removeEventListener("keydown", shiftKeyListener);
      window.removeEventListener("keyup", shiftKeyListener);
    }
    return dispose;
  }, []);

  function removeSelectedObjectLayers() {
    if (!canvas) return;
    const selectedObjects = canvas.getActiveObjects();

    if (!selectedObjects) return;
    const ids = selectedObjects.map((obj) => obj.name ?? "");
    if (selectedObjects.find((x) => x.name?.includes("qrcode"))) {
      const otherPages = pagesRef.current.filter(
        (x) => x.name !== selectedPageRef.current,
      );
      if (otherPages.length) {
        const qrCodeId = getQrCodeId(
          pagesRef.current.filter((x) => x.name !== selectedPageRef.current),
        );
        if (!qrCodeId) {
          dispatch(setQrCodeId(null));
        }
      } else {
        dispatch(setQrCodeId(null));
      }
    }
    const layersCollection = [...layers.pageLayers];
    const pageIndex = layersCollection.findIndex(
      (x) => x.name === selectedPageRef.current,
    );

    if (pageIndex === -1) return;
    const pageLayers = { ...layersCollection[pageIndex] };
    const remainingLayers = { ...pageLayers.layers };

    Object.keys(pageLayers.layers).forEach((key) => {
      let hasDeleted = false;
      if (ids.includes(remainingLayers[key].id)) {
        delete remainingLayers[key];
        hasDeleted = true;
      }

      if (!hasDeleted && remainingLayers[key].layers != undefined) {
        const group = { ...remainingLayers[key].layers } as ILayersObject;
        Object.keys(group).forEach((iKey) => {
          if (ids.includes(group[iKey].id)) {
            delete group[iKey];
          }
        });
        if (Object.keys(group).length === 0) {
          delete remainingLayers[key];
        } else {
          remainingLayers[key] = { ...pageLayers.layers[key] };
          remainingLayers[key].layers = Object.keys(group).reduce(
            (prev, curr, index) => {
              prev[index] = group[curr];
              return prev;
            },
            {} as ILayersObject,
          );
        }
      }
    });

    canvas.discardActiveObject();
    canvas.remove(...selectedObjects);
    pageLayers.layers = Object.keys(remainingLayers).reduce(
      (prev, curr, index) => {
        prev[index] = remainingLayers[curr];
        return prev;
      },
      {} as ILayersObject,
    );
    layersCollection[pageIndex] = pageLayers;

    dispatch(
      updateLayerState({
        selectedLayers: [],
        pageLayers: layersCollection,
        pageOrder: layers.pageOrder,
      }),
    );

    setTimeout(() => {
      updateHistory(canvas.getObjects(), "object deleted");
    }, 1);
  }

  function shiftKeyListener(e: KeyboardEvent) {
    if (e.shiftKey) shiftKeyDown.current = true;
    else shiftKeyDown.current = false;
  }

  useEffect(() => {
    setSelectedPageLayers(getLayers());
  }, [layers, canvas]);

  return {
    selectLayers,
    moveLayerXSpaces,
    moveLayerToDestination,
    changeLayerName,
    changePage,
    createLayer,
    selectedPageLayers,
    selectedPage,
    removeSelectedObjectLayers,
    selectedLayers,
    layers,
    toggleCollapse,
    toggleVisibility,
    toggleLock,
    groupLayers,
    ungroupLayers,
    addPage,
    deletePage,
    movePage,
    pages,
  };
};

function findLayer(layerObj: ILayersObject, id: string): ILayer | undefined {
  const layers = Object.keys(layerObj).map((x) => layerObj[x]);
  let layer = layers.find((x) => x.id === id);
  if (layer) return layer;
  const layerGroups = layers.filter((x) => x.id.includes("group"));
  for (let i = 0; i < layerGroups.length; i++) {
    const group = layerGroups[i];
    if (!group.layers) continue;
    if (group.layers) {
      layer = findLayer(group.layers, id);
      if (layer) break;
    }
  }
  return layer;
}

function updateLayerProperty(
  layers: ILayersCollection[],
  page: string,
  layerId: string,
  property: keyof ILayer,
  value: any,
) {
  const pageLayers = layers.find((x) => x.name === page);
  if (pageLayers) {
    // rebuild the layers object as a new object so we can actually modify properties...
    const newPageLayers = { ...pageLayers } as ILayersCollection;

    newPageLayers.layers = Object.keys(newPageLayers.layers).reduce(
      (prev, curr) => {
        prev[curr] = { ...newPageLayers.layers[curr] };

        if (prev[curr].id === layerId) {
          // @ts-ignore
          prev[curr][property] = value;
          if (prev[curr].layers) {
            // @ts-ignore
            prev[curr].layers = Object.keys(prev[curr].layers).reduce(
              (a, b: string) => {
                // @ts-ignore
                a[b] = { ...prev[curr].layers[b] };
                // @ts-ignore
                a[b][property] = value;

                return a;
              },
              {} as ILayersObject,
            );
          }
        } else {
          if (prev[curr].layers) {
            // @ts-ignore
            prev[curr].layers = Object.keys(prev[curr].layers).reduce(
              (a, b: string) => {
                // @ts-ignore
                a[b] = { ...prev[curr].layers[b] };
                if (a[b]?.id === layerId) {
                  // @ts-ignore
                  a[b][property] = value;
                }
                return a;
              },
              {} as ILayersObject,
            );
          }
        }

        return prev;
      },
      {} as ILayersObject,
    );
    return newPageLayers;
  }

  return pageLayers;
}

export default useLayers;
