import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { fetchClient } from "../../helpers/fetchClient";
import LocalStorage, { setTokenExpires } from "../../helpers/LocalStorage";
import useQuery from "../../hooks/useQuery";

import SessionInfo from "../../data/models/SessionInfo";
import LoginResponse from "./models/LoginResponse";
import logger from "../../helpers/logger";
import LoadingWrapper from "../../shared/LoadingWrapper";
import { Container } from "@mui/material";

const AutoLogin: React.FC = (props) => {
  /**
   * State Objects and Refs
   */
  const history = useHistory();
  const query = useQuery();
  const bAccountId = query.get("baccountID");
  const token = query.get("token");
  const path = query.get("path");
  const email = query.get("email");
  const canListen = query.get("CanListen");

  const [loading, setLoading] = useState(true);
  const [loginSuccess, setLoginSuccess] = useState<boolean | null>(null);

  /**
   * Component Methods
   */

  function getSessionInfo() {
    fetchClient.get({
      path: "/account/session",
      onSuccess: onSessionInfoResponse,
    });
  }

  function onSessionInfoResponse(data: SessionInfo) {
    LocalStorage.setSession(data);
    if (path) {
      history.push(path);
    } else history.push("/");
  }

  function onLogin(data: LoginResponse) {
    LocalStorage.setToken(data.token);
    setTokenExpires(data.expires);
    LocalStorage.setCompanies(data.companies);
    LocalStorage.setCurrentCompany(data.currentCompanyId);
    getSessionInfo();
  }

  function handleError(data: any) {
    logger.log(data);
    setLoginSuccess(false);
  }

  if (bAccountId && token && email) {
    if (canListen) LocalStorage.setCanListen(canListen);

    // login and redirect to path
    fetchClient.post({
      path: `/auth/auto-login?token=${encodeURIComponent(
        token
      )}&email=${email}&baccountID=${bAccountId}`,
      onSuccess: onLogin,
      onError: handleError,
    });
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */
  if (loginSuccess === false) {
    history.push("/login");
    return <div />;
  }
  if (loading)
    return (
      <Container sx={{ mb: 6, mt: 6 }}>
        <LoadingWrapper loading={loading} height={600}></LoadingWrapper>
      </Container>
    );

  return <div />;
};

export default AutoLogin;
