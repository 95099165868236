import { Box, FormLabel, Grid } from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";
import React, { useEffect, useMemo, useRef, useState } from "react";
import getVars, { EmptyVarError, getVarsWithErrors } from "../helpers/getVars";

interface IHtmlEditorProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name: "html" | "htmlBack";
  value: string;
  label: string;
  errorCallBack: (hasErrors: boolean) => void;
  disabled?: boolean;
}

const HtmlEditor = ({
  onChange,
  name,
  value,
  label,
  disabled,
  errorCallBack,
}: IHtmlEditorProps) => {
  // const [variables, setVariables] = useState(getVarsWithErrors(value));
  const timeoutRef = useRef<NodeJS.Timeout>();
  const variables = useMemo(() => {
    const vars = getVarsWithErrors(value);
    if (vars.errors.length) errorCallBack(true);
    else errorCallBack(false);
    return vars;
  }, [value]);

  // useEffect(() => {
  //   // @ts-ignore
  //   onChange({ target: { value: val } });
  // }, [val]);
  // useEffect(() => {
  //   if (timeoutRef.current) clearTimeout(timeoutRef.current);
  //   timeoutRef.current = setTimeout(() => {
  //     const vars = getVarsWithErrors(value);
  //     if (vars.errors.length) errorCallBack(true);
  //     else errorCallBack(false);
  //     setVariables(vars);
  //   }, 1000);
  // }, [value]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={8} lg={9}>
        <FormLabel>{label}</FormLabel>
        <Box sx={{ maxHeight: "275px", overflowY: "auto" }}>
          <CodeEditor
            value={value}
            language="html"
            placeholder="Enter your html"
            onChange={onChange}
            name={name}
            padding={16}
            minHeight={275}
            disabled={disabled}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Box sx={{ mb: 3 }}>
          <FormLabel>Variable Fields</FormLabel>
          {variables.vars.filter((x) => x !== "QrCode").length === 0 && (
            <Box sx={{ fontStyle: "italic" }}>
              No {variables.errors.length > 0 && "valid"} variables found
            </Box>
          )}

          <ul
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            {variables.vars
              .filter((x) => x !== "QrCode")
              .map((v) => (
                <li
                  key={v}
                  style={{
                    padding: 0,
                    marginTop: "6px",
                    fontStyle: "italic",
                    listStyle: "none",
                  }}
                >
                  {v}
                </li>
              ))}
          </ul>
        </Box>
        {variables.errors.length > 0 && (
          <Box sx={{ mb: 3 }}>
            <FormLabel>Variable Errors</FormLabel>
            {variables.errors.includes(EmptyVarError) && (
              <Box sx={{ color: (theme) => theme.palette.error.main, mb: 2 }}>
                Remove any empty bracket fields: {`{{}}`}
              </Box>
            )}
            {variables.errors.find((x) => x !== EmptyVarError) && (
              <Box sx={{ fontStyle: "italic" }}>
                Variables must be valid handlebars variable syntax, remove any
                invalid characters such as spaces before continuing.
              </Box>
            )}

            <ul
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              {variables.errors
                .filter((x) => x !== EmptyVarError)
                .map((v) => (
                  <li
                    key={v}
                    style={{
                      padding: 0,
                      marginTop: "6px",
                      fontStyle: "italic",
                      listStyle: "none",
                    }}
                  >
                    <Box sx={{ color: (theme) => theme.palette.error.main }}>
                      {v}
                    </Box>
                  </li>
                ))}
            </ul>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default HtmlEditor;
