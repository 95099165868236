import { useEffect } from "react";
import LocalStorage, { checkIfTokenExpired } from "../../helpers/LocalStorage";
import {
  ActionAlertsContext,
  useActionAlertsContext,
} from "../../data/State/ActionAlerts";
import { Redirect } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/material";
import LayoutHeader from "./components/LayoutHeader";
import theme from "../../helpers/theme";
import CanvasErrorBoundary from "../../postcard-designer/features/DesignerInterface/CanvasErrorBoundary";

const DesignerLayout: React.FC = ({ children }) => {
  const alertContext = useActionAlertsContext();
  const token = LocalStorage.getToken();
  const tokenExpired = checkIfTokenExpired();

  function checkIfCompletedRegistration() {
    if (token) {
    }

    const interval = setInterval(removeLiveChat, 200);

    function removeLiveChat() {
      let liveChat = document.querySelector("div[title='Contact us button']");
      if (!liveChat) {
        liveChat = document.querySelector("div[title='Live chat button']");
      }
      if (liveChat) {
        document.body.removeChild(liveChat);
        clearInterval(interval);
      }
    }
  }

  useEffect(checkIfCompletedRegistration, [token]);
  if ((!token || tokenExpired) && window.location) {
    return <Redirect to="/login" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <ActionAlertsContext.Provider value={alertContext}>
        <Box
          sx={{
            maxHeight: "100vh",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            overflowX: "hidden",
          }}
        >
          <Box sx={{ zIndex: 100 }}>
            <LayoutHeader />
          </Box>
          <Box sx={{ height: "100%" }}>
            <CanvasErrorBoundary>{children}</CanvasErrorBoundary>
          </Box>
        </Box>
      </ActionAlertsContext.Provider>
    </ThemeProvider>
  );
};

export default DesignerLayout;
