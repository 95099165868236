import React, { useContext } from "react";
import tw, { css } from "twin.macro";
import { Textbox, fabric } from "fabric";
import DesignerButton from "../shared/DesignerButton";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import useTools from "../../../hooks/useTools";
import generateGuid from "../../../../helpers/generateGuid";
import { createBleedClipPath } from "../../Canvas/functions/createBackgroundClipPath";
import createBulletedList from "../../Canvas/functions/createBulletedList";
import { DragSourceMonitor, useDrag } from "react-dnd";
export type TextVariant = "body" | "heading" | "subheading" | "bulletedList";

interface DraggableTextProps {
  variant: TextVariant;
  children?: React.ReactNode;
  styles: any;
}

export interface DraggableTextDrop {
  variant: TextVariant;
  callback: (x?: number, y?: number) => void;
}

const DraggableText = ({ variant, children, styles }: DraggableTextProps) => {
  const canvas = useContext(CanvasContext);
  const { addTextToCanvas, addBulletedListToLayers } = useTools();

  const [collected, drag, dragPreview] = useDrag({
    type: "text",
    item: { variant, callback: addText } as DraggableTextDrop,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function addText(x?: number, y?: number) {
    if (collected.isDragging) return;
    if (!canvas) return;

    const background = canvas._objects.find((x) => x.name === "background");

    if (
      !background ||
      background.left === undefined ||
      background.left === null ||
      background.top === undefined ||
      background.top === null
    )
      return;
    if (variant === "bulletedList") {
      addBulletedList(background, x, y);
      return;
    }

    const textObj = new fabric.Textbox("Enter your text", {
      left: x ?? background.left + background.getScaledWidth() / 3,
      top: y ?? background.top + background.getScaledHeight() / 3,
      fontFamily: "Roboto",
      fontSize: 36,
      fontStyle: "normal",
      fontWeight: 400,
      stroke: "#000000",
      strokeWidth: 0,
      fill: "#000000",

      editable: true,
      name: generateGuid(),
      shadow: new fabric.Shadow({}),
      clipPath: createBleedClipPath(canvas),
      strokeUniform: true,
    });
    if (variant === "heading") {
      textObj.fontWeight = "bold";
      textObj.fontSize = 66;
    }
    if (variant === "subheading") {
      textObj.fontWeight = 500;
      textObj.fontSize = 54;
    }

    addTextToCanvas(textObj as Textbox);
    if (textObj.left && textObj.top && x === undefined && y === undefined) {
      // textObj.left -= (textObj.width ?? 0) * 2;
      // textObj.top -= (textObj.height ?? 0) * 2;
    } else {
      if (x && y) {
        if (x > background.left + background.getScaledWidth()) {
          textObj.left =
            background.left +
            background.getScaledWidth() -
            textObj.getScaledWidth();
        }
        if (x < background.left) {
          textObj.left = background.left;
        }
        if (y > background.top + background.getScaledHeight()) {
          textObj.top =
            background.top +
            background.getScaledHeight() -
            textObj.getScaledHeight();
        }
        if (y < background.top) {
          textObj.top = background.top;
        }
      }
    }
    canvas.renderAll();
  }

  async function addBulletedList(
    background: fabric.Object,
    x?: number,
    y?: number
  ) {
    if (!canvas) return;
    const list = await createBulletedList(canvas);
    if (!list) return;
    addBulletedListToLayers(list);
    if (x && y) {
      list.left = x;
      list.top = y;
    } else if (list.left && list.top) {
      list.left = (background.left ?? 0) + background.getScaledWidth() / 3;
      list.top = (background.top ?? 0) + background.getScaledHeight() / 3;
    }
    canvas.renderAll();
  }

  return (
    <React.Fragment>
      <DesignerButton onMouseUp={() => addText()} css={styles} ref={drag}>
        {children}
      </DesignerButton>
    </React.Fragment>
  );
};

export default DraggableText;
