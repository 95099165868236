import { Object as IObject, Shadow } from "fabric/fabric-impl";
import { IObjectProperties } from "../../../state/contexts/SelectedObjectContext";
import { fabric } from "fabric";

export default function updateShadowSettings(
  obj: IObject,
  property: keyof IObjectProperties,
  value: number
) {
  const shadowProperties: Record<string, keyof Shadow> = {
    shadowBlur: "blur",
    shadowOffsetX: "offsetX",
    shadowOffsetY: "offsetY",
  };
  const shadowProperty = shadowProperties[property];
  if (!obj.shadow) {
    obj.shadow = new fabric.Shadow({
      color: "#000000",
      [shadowProperty as keyof Shadow]: value,
    });
  } else if (obj.shadow !== undefined && typeof obj.shadow !== "string") {
    // @ts-ignore
    obj.set(`shadow`, { ...obj.shadow, [shadowProperty]: value });
  }
}
