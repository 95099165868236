import { AlignGuidelines } from "fabric-guideline-plugin";
import React, { createContext, useReducer } from "react";

export const GuidesContext = createContext<AlignGuidelines | undefined>(
  undefined
);

export const GuidesDispatchContext = createContext<
  React.Dispatch<{
    type: string;
    payload: AlignGuidelines;
  }>
>(
  {} as React.Dispatch<{
    type: string;
    payload: AlignGuidelines;
  }>
);
const intialState: AlignGuidelines | undefined = undefined;

function guidesReducer(
  guides: AlignGuidelines | undefined,
  action: { type: string; payload: AlignGuidelines }
) {
  if (action.payload) return action.payload;
  return guides;
}

interface IGuidesProviderProps {
  children?: React.ReactFragment;
}

const GuidesProvider = ({ children }: IGuidesProviderProps) => {
  const [guides, dispatch] = useReducer(
    guidesReducer,
    intialState as AlignGuidelines | undefined
  );

  return (
    <GuidesContext.Provider value={guides}>
      <GuidesDispatchContext.Provider value={dispatch}>
        {children}
      </GuidesDispatchContext.Provider>
    </GuidesContext.Provider>
  );
};

export default GuidesProvider;
