import { Gradient, Shadow } from "fabric/fabric-impl";
import { useContext } from "react";
import tinycolor from "tinycolor2";
import { CanvasContext } from "../../../../state/contexts/CanvasContext";
import { colorSwatches } from "./colorSwatches";
import tw, { css } from "twin.macro";
import getCssFromGradient from "./helpers/getCssFromGradient";

interface IColorSwatchesProps {
  onClick: (color: string) => void;
  hideGradients?: boolean;
}

const ColorPalette = ({ onClick, hideGradients }: IColorSwatchesProps) => {
  const canvas = useContext(CanvasContext);

  const canvasColors: string[] = canvas ? getCanvasColors() : [];

  function getCanvasColors() {
    if (!canvas) return [];
    const colors: string[] = [];
    canvas._objects
      .filter((x) => x.name !== "background")
      .forEach((obj) => {
        const fill = obj.fill;
        const stroke = obj.stroke;
        const shadow = obj.shadow as Shadow | undefined;
        if (fill && typeof fill === "string") {
          if (!colors.includes(fill)) {
            colors.push(fill);
          }
        }
        if (
          !hideGradients &&
          fill &&
          typeof fill === "object" &&
          "coords" in fill
        ) {
          const css = getCssFromGradient(obj, fill as Gradient);
          if (!colors.includes(css)) colors.push(css);
        }
        if (stroke && typeof stroke === "string") {
          if (!colors.includes(stroke)) {
            colors.push(stroke);
          }
        }
        if (shadow) {
          const color = shadow.color;
          if (color && typeof color === "string") {
            if (!colors.includes(color)) {
              colors.push(color);
            }
          }
        }
      });
    return colors;
  }

  return (
    <div>
      <div css={[tw`flex items-center flex-wrap w-full mt-4`]}>
        {colorSwatches.map((swatch) => (
          <div
            key={swatch.hex}
            style={{ marginRight: "8px", marginBottom: "8px" }}
          >
            <div
              style={{
                background: `${swatch.hex}`,
                width: "20px",
                height: "20px",
                borderRadius: "4px",
                border: "solid 1px rgba(0,0,0,.125)",
              }}
              onClick={() => onClick(swatch.hex)}
            ></div>
          </div>
        ))}
      </div>
      <div>Your colors</div>
      <div css={[tw`flex items-center flex-wrap`]}>
        {canvasColors.map((color) => (
          <div style={{ marginRight: "8px", marginBottom: "8px" }} key={color}>
            <div
              style={{
                background: `${color}`,
                width: "20px",
                height: "20px",
                borderRadius: "4px",
                border: "solid 1px rgba(0,0,0,.125)",
              }}
              onClick={() => onClick(color)}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorPalette;
