import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useState, useEffect, createContext, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { fetchClient } from "../../../helpers/fetchClient";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import Design from "../../Designs/models/Design";
import Approve from "./components/Approve/Approve";

import ConfigureDesign from "./components/ConfigureDesign/ConfigureDesign";
import MapListToFields from "./components/MapListToFields/MapListToFields";
import UploadList from "./components/UploadList/UploadList";
import ListValidationError from "./components/ListValidationError";
import { goToNextStep, returnToPreviousStep } from "./helpers/handleSwitching";
import {
  APPROVE,
  CONFIGURE_DESIGN,
  LEAVE_ORDER,
  MAP_LIST_TO_FIELDS,
  SUCCESS,
  UPLOAD_LIST,
  VALIDATE_LIST,
} from "./helpers/stepConstants";
import { validateStep } from "./helpers/validateFields";

import "./PlaceAnOrder.scss";

import useOrderRequestStore, {
  OrderRequestStore,
} from "./useOrderRequestStore";
import { Recipient } from "./models/OrderRequest";
import getVars from "../../Html/helpers/getVars";
import createCsvDownload from "../../../helpers/createCsvDownload";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import ILetterOptions from "./models/ILetterOptions";

export const OrderRequestContext = createContext<OrderRequestStore>(
  {} as OrderRequestStore
);

const PlaceAnOrder: React.FC = () => {
  /**
   * State Objects and Refs
   */

  // Order step constants

  const hasDesignError = useRef(false);
  const history = useHistory();

  const { designId } = useParams<{ designId: string }>();

  const designIdInt = parseInt(designId) || 0;
  if (designIdInt === 0 || designIdInt === null) hasDesignError.current = true;

  /**
   * @note 
   *  This store contains all of the functionality needed to access and update an orderRequest object.
   *  If you need to update the request we are sending to the server, you MUST do so with this store.
   *  If you need to add new functionality, it must be added in useOrderRequestStore.
   *  We are passing it to components further down the tree via Context
   * https://reactjs.org/docs/context.html
   * https://reactjs.org/docs/hooks-reference.html#usecontext
   */
  const orderRequestStore = useOrderRequestStore(designIdInt ? designIdInt : 0);

  const [step, setStep] = useState(CONFIGURE_DESIGN);
  const {
    data: design,
    isLoading,
    error,
  } = useGetData<Design>(`/api/designs/${designId}/get-fields`);
  const [confirmExitOpen, setConfirmExitOpen] = useState<boolean>(false);
  const [backButtonIsDisabled, setBackButtonIsDisabled] = useState(false);
  const [stepValidationErrors, setStepValidationErrors] = useState<
    Record<string, boolean>
  >({});
  const [hasAttemptedToContinue, setHasAttemptedToContinue] =
    useState<boolean>(false);

  const [listValidationError, setListValidationError] = useState(false);
  const [invalidListRecipients, setInvalidListRecipients] = useState<
    Recipient[]
  >([]);

  const { data: letterOptions, isLoading: letterOptionsLoading } =
      useGetData<ILetterOptions>(`/api/place-an-order/letter-options`);

  /**
   * Component Methods
   */

  function handleNextStep() {
    const errors = validateStep(
      step,
      orderRequestStore.orderRequest,
      design?.designFields || [],
      design?.printPDFSource?.includes("LETTER") ||
        design?.printPDFSource === "LAS"
    );
    setHasAttemptedToContinue(true);
    setStepValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      const nextStep = goToNextStep(step);
      setStep(nextStep);
      setHasAttemptedToContinue(false);
    }
  }

  function handlePreviousStep() {
    const nextStep = returnToPreviousStep(step);
    if (nextStep === LEAVE_ORDER) return setConfirmExitOpen(true);
    setStep(nextStep);
  }

  function setupBaseDesignFields() {
    if (design) {
      let mailClass = "";
      if (design.mailClasses && design.mailClasses.length)
        mailClass = design.mailClasses[0].mailClassID;
      orderRequestStore.createBaseDesignVariables(
        design.designFields,
        mailClass
      );
      // if (design.mailClasses && design.mailClasses.length)
      //   orderRequestStore.changeMailClass(design.mailClasses[0].mailClassID);
    }
  }

  function createSampleCSV() {
    let csvText = `firstname,lastname,company,address,address2,city,state,zipcode`;
    if (design?.designFields && design.designFields.length > 0) {
      const fields = design.designFields.map((x) => x.fieldKey).join(",");
      csvText += `,${fields}`;
    }
    csvText += `\r\n[firstname],[lastname],[company],[address],[address2],[city],[state],[zipcode]`;
    if (design?.designFields && design.designFields.length > 0) {
      const fields = design.designFields
        .map((x) => `[${x.fieldKey}]`)
        .join(",");
      csvText += `,${fields}`;
    }
    console.log(csvText);
    createCsvDownload(csvText, "sample-order-csv");
  }

  function handleDesignError() {
    hasDesignError.current = true;
  }

  function handleConfirmExitClose() {
    setConfirmExitOpen(false);
  }

  function handleConfirmExitConfirm() {
    if (design?.htmlVersionID) {
      history.push("/designs/html");
    } else history.push("/designs/postcards");
  }

  function validateAfterAttemptingToContinue() {
    if (hasAttemptedToContinue) {
      const errors = validateStep(
        step,
        orderRequestStore.orderRequest,
        design?.designFields || [],
        design?.printPDFSource?.includes("LETTER") ||
          design?.printPDFSource === "LAS"
      );
      setStepValidationErrors({ ...errors });
    }
  }

  function validateList() {
    if (step === VALIDATE_LIST) {
      setListValidationError(false);
      setInvalidListRecipients([]);
      orderRequestStore.addRecipients([]);
      if (design !== null) {
        const request = fetchClient.post({
          path: `/place-an-order/${design?.designID}/validate-list`,
          data: orderRequestStore.orderRequest,
          onError: onListValidationFailure,
          onSuccess: onListValidationSuccess,
        });
      }
    }
  }
  function onListValidationFailure() {
    setListValidationError(true);
  }

  function onListValidationSuccess(data: {
    validRecipients: Recipient[];
    invalidRecipients: Recipient[];
  }) {
    if (data.invalidRecipients && data.invalidRecipients.length) {
      setListValidationError(true);
      setInvalidListRecipients(data.invalidRecipients);
    }
    if (data.validRecipients && data.validRecipients.length) {
      orderRequestStore.addRecipients(data.validRecipients);
    }
    if (!data.invalidRecipients || data.invalidRecipients.length === 0) {
      setStep(APPROVE);
    }
  }

  /**
   * Component Effects
   */

  useEffect(setupBaseDesignFields, [design]);

  useEffect(validateAfterAttemptingToContinue, [
    orderRequestStore.orderRequest,
  ]);

  useEffect(validateList, [step]);

  /**
   * Render
   */

  if (hasDesignError.current) return <div>Error handler</div>;
  return (
    <OrderRequestContext.Provider value={orderRequestStore}>
      <h2 className="section--heading">Place An Order</h2>
      <Paper style={{ padding: "32px" }}>
        <LoadingWrapper
          loading={
            isLoading ||
            (step === VALIDATE_LIST && listValidationError === false)
          }
          message="Loading"
          height={700}
          hasError={error.hasError}
        >
          <Stepper activeStep={step} style={{ paddingBottom: "32px" }}>
            <Step index={CONFIGURE_DESIGN}>
              <StepLabel>Configure Design</StepLabel>
            </Step>
            <Step index={UPLOAD_LIST}>
              <StepLabel>Upload List</StepLabel>
            </Step>
            <Step index={MAP_LIST_TO_FIELDS}>
              <StepLabel>Map List</StepLabel>
            </Step>
            <Step index={VALIDATE_LIST}>
              <StepLabel>List Validation</StepLabel>
            </Step>
            <Step index={APPROVE}>
              <StepLabel>Approve Order</StepLabel>
            </Step>
          </Stepper>
          {design !== null && (
            <div className="stepContainer">
              {step === CONFIGURE_DESIGN && design && (
                <ConfigureDesign
                  design={design}
                  letterOptions={letterOptions}
                  errors={stepValidationErrors}
                />
              )}
              {step === UPLOAD_LIST && (
                <UploadList
                  errors={stepValidationErrors}
                  generateCsvSample={createSampleCSV}
                />
              )}
              {step === MAP_LIST_TO_FIELDS && design && (
                <MapListToFields
                  errors={stepValidationErrors}
                  design={design}
                />
              )}
              {step === VALIDATE_LIST && listValidationError && (
                <ListValidationError
                  invalidListRecipients={invalidListRecipients}
                />
              )}
              {step === APPROVE && design && (
                <Approve
                  design={design}
                  submitCallback={(isDisabled = true) =>
                    setBackButtonIsDisabled(isDisabled)
                  }
                />
              )}
              <div className="placeAnOrder__stepFooter">
                <Grid container justifyContent="space-between">
                  {step !== SUCCESS && !backButtonIsDisabled && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handlePreviousStep}
                    >
                      Back
                    </Button>
                  )}
                  {step !== SUCCESS &&
                    step !== APPROVE &&
                    step !== VALIDATE_LIST && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextStep}
                      >
                        Continue
                      </Button>
                    )}
                  {step === VALIDATE_LIST &&
                    orderRequestStore.orderRequest.recipients.length > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextStep}
                      >
                        Continue
                      </Button>
                    )}
                </Grid>
              </div>
            </div>
          )}
        </LoadingWrapper>
      </Paper>

      <Dialog open={confirmExitOpen}>
        <DialogTitle>
          Are you sure you want to return to the designs?
        </DialogTitle>
        <DialogContent>
          Your progress will be lost and you will have to start over
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmExitClose} color="primary">
            Return To Order
          </Button>
          <Button
            onClick={handleConfirmExitConfirm}
            color="primary"
            variant="contained"
          >
            Back To Designs
          </Button>
        </DialogActions>
      </Dialog>
    </OrderRequestContext.Provider>
  );
};

export default PlaceAnOrder;
