import tw, { css } from "twin.macro";
import useAssetLibrary from "../../../hooks/useAssetLibrary";
import { selectAssetLibrary } from "../../../state/slices/assetLibrary";
import { useDesignerSelector } from "../../../state/store";
import UploadSidebarDropzone from "../../ImageDropzone/UploadsSidebarDropzone";
import ButtonReset from "../shared/ButtonReset";
import propertiesStyles from "../Properties/propertiesStyles";
import PropertyDropdown from "../Properties/PropertyDropdown";
import DraggableImage from "./DraggableImage";
import { useState } from "react";

const styles = {
  section: [tw`mb-4 mt-4`],
  button: [tw`bg-blue uppercase text-white hover:bg-opacity-60 px-4 py-2`],
  container: [tw`p-4 `],
  dynamicImageWrap: [
    tw`overflow-hidden rounded border-dashed border-4 border-labels inline-block cursor-pointer`,
  ],
};

const Images = () => {
  const assetLibrary = useDesignerSelector(selectAssetLibrary);
  const [openAccordion, setOpenAccordion] = useState<string | undefined>(
    "customer"
  );

  return (
    <div css={styles.container}>
      <div css={styles.section}>
        <UploadSidebarDropzone />

        <div tw="mb-4">
          <PropertyDropdown label="Add a Dynamic Image" defaultValue={true}>
            <div css={[styles.section]}>
              <DraggableImage isVariable />
            </div>
          </PropertyDropdown>
        </div>

        {assetLibrary.customerAssets.length > 0 && (
          <PropertyDropdown
            label="Your Uploads"
            defaultValue={true}
            maxHeight={500}
          >
            <div tw="flex items-end flex-wrap">
              {assetLibrary.customerAssets.map((asset) => (
                <DraggableImage key={asset.id} asset={asset} />
              ))}
            </div>
          </PropertyDropdown>
        )}
        {assetLibrary.designAssets.length > 0 && (
          <PropertyDropdown
            label="Design Images"
            defaultValue={assetLibrary.customerAssets.length > 0 ? false : true}
            maxHeight={500}
          >
            <div tw="flex items-end flex-wrap">
              {assetLibrary.designAssets.map((asset) => (
                <DraggableImage key={asset.id} asset={asset} />
              ))}
            </div>
          </PropertyDropdown>
        )}
      </div>
      {/* {images.map((image) => (
        <DraggableImage key={image.id} image={image} />
      ))} */}
    </div>
  );
};

export default Images;
