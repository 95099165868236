import { useEffect, useRef, useState } from "react";
import tw, { css } from "twin.macro";
import SharedColorPickerGradient from "./types/SharedColorPickerGradient";
import GradientColorPicker, {
  useColorPicker,
  // @ts-ignore
} from "react-best-gradient-color-picker";
import { IGradientObject } from "../../postcard-designer/features/DesignerInterface/shared/ColorPicker/types";
import { ReactComponent as FillIcon } from "../../assets/images/designer-svg/FillIcon.svg";
import DesignerButton from "../../postcard-designer/features/DesignerInterface/shared/DesignerButton";
interface SharedColorPickerProps {
  value: string;
  onChange: (value: string | IGradientObject) => void;
  inputType?: "text" | "color" | "text-and-color";
  openOverride?: boolean;
  openOverrideCallback?: (open: boolean) => void;
}

const SharedColorPicker = ({
  openOverride = false,
  openOverrideCallback,
  value,
  onChange,
  inputType = "color",
}: SharedColorPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState(value);

  const { getGradientObject, valueToHex } = useColorPicker(color, setColor);

  function handleChange(val: string) {
    setColor(val);
  }

  useEffect(() => {
    setIsOpen(openOverride);
  }, [openOverride]);

  useEffect(() => {
    if (openOverrideCallback) {
      if (isOpen !== openOverride) {
        openOverrideCallback(isOpen);
      }
    }
  }, [isOpen]);

  function buildGradient() {
    const gradient = getGradientObject() as IGradientObject;
    const stops = gradient.colors.map((color) => (
      <stop
        offset={color.left}
        stopColor={color.value}
        key={color.left + color.value}
      ></stop>
    ));
    if (gradient.gradientType === "radial") {
      return <radialGradient id="gradient">{stops}</radialGradient>;
    }
    return <linearGradient id="gradient">{stops}</linearGradient>;
  }

  useEffect(() => {
    const gradient = getGradientObject();
    if (gradient.isGradient) {
      onChange(gradient);
    } else {
      onChange(color);
    }
  }, [color]);

  useEffect(() => {
    if (value !== color) {
      setColor(value);
    }
  }, [value]);

  return (
    <div style={{ position: "relative" }}>
      {inputType === "color" && (
        <button
          css={[
            css`
              width: 34px;
              height: 34px;
            `,
            tw`p-1 hover:bg-hover rounded bg-inputBackground border border-solid border-border`,
          ]}
          onClick={() => setIsOpen(!isOpen)}
        >
          {getGradientObject().isGradient ? (
            <svg
              css={[
                css`
                fill: url(#gradient)
                stroke: url(#gradinet)
          `,
                tw`ml-auto mr-auto h-4 w-4`,
              ]}
            >
              <defs>{buildGradient()}</defs>
              <FillIcon
                css={css`
                  fill: url(#gradient);
                `}
              />
            </svg>
          ) : (
            <FillIcon
              css={[
                css`
                  fill: ${color};
                  stroke: ${color};
                `,
                tw`ml-auto mr-auto h-4 w-4`,
              ]}
            />
          )}
          <div
            css={[
              css`
                background: ${color};
                margin-top: 2px;
                height: 5px;
                border: solid 1px rgba(0, 0, 0, 1);
              `,
            ]}
          ></div>
        </button>
      )}
      {isOpen && (
        <div
          style={{
            position: "absolute",
            left: -270,
            top: "50%",
            transform: "translateY(-50%)",
            background: "white",
            borderRadius: "3px",
            border: "1px solid rgba(0,0,0,.125)",
            padding: "10px",
            zIndex: "1202",
          }}
        >
          <div>
            <div tw="mb-2">
              <GradientColorPicker
                onChange={handleChange}
                value={color}
                hideAdvancedSliders
                hideColorGuide
                hideInputType
                width={250}
                height={250}
              />
            </div>
            <div>
              <DesignerButton onClick={() => setIsOpen(false)}>
                Ok
              </DesignerButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SharedColorPicker;
