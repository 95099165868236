import ComponentActivityStatus from "../../../constants/ComponentActivityStatus";
import { INewPaymentMethod } from "../../Account/models/AccountModel";
import INewEnvelopeReq from "../models/INewEnvelopeReq";

export interface INewEnvelopeState {
  newEnvelope: INewEnvelopeForm;
  hasSubmitted: boolean;
  errors: string[];
  status: ComponentActivityStatus;
}

export interface INewEnvelopeForm {
  friendlyName: string;
  front: string;
  back: string;
  envelopeType: string;
  replenishmentMethod: string;
  replenishmentValue: string;
  orderQty: string;
  paymentMethod: string;
  friendlyPaymentMethod: string;
}

export const initialNewEnvelopeFormState: INewEnvelopeForm = {
  friendlyName: "",
  front: "",
  back: "",
  envelopeType: "#10 Outer Single Window",
  replenishmentMethod: "",
  replenishmentValue: "",
  orderQty: "",
  paymentMethod: "",
  friendlyPaymentMethod: "",
};

export const initialNewEnvelopeState: INewEnvelopeState = {
  newEnvelope: initialNewEnvelopeFormState,
  hasSubmitted: false,
  errors: [],
  status: ComponentActivityStatus.Idle,
};

export enum NewEnvelopeActions {
  UPDATE_NEW_PAYMENT_METHOD = "UPDATE_NEW_PAYMENT_METHOD",
  UPDATE_FIELD = "UPDATE_FIELD",
  UPDATE_STATUS = "UPDATE_STATUS",
  UPDATE_ERRORS = "UPDATE_ERRORS",
  UPDATE_SUBMISSION = "UPDATE_SUBMISSION",
}

type NewEnvelopeAction =
  | {
      type: NewEnvelopeActions.UPDATE_FIELD;
      payload: Partial<INewEnvelopeForm>;
    }
  | {
      type: NewEnvelopeActions.UPDATE_STATUS;
      payload: ComponentActivityStatus;
    }
  | {
      type: NewEnvelopeActions.UPDATE_ERRORS;
      payload: string[];
    }
  | {
      type: NewEnvelopeActions.UPDATE_SUBMISSION;
      payload: undefined;
    };

export function newEnvelopeReducer(
  state: INewEnvelopeState,
  action: NewEnvelopeAction
): INewEnvelopeState {
  const { type, payload } = action;
  switch (type) {
    case NewEnvelopeActions.UPDATE_FIELD: {
      return {
        ...state,
        newEnvelope: {
          ...state.newEnvelope,
          ...(payload as Partial<INewEnvelopeForm>),
        },
      };
    }
    case NewEnvelopeActions.UPDATE_STATUS: {
      return { ...state, status: payload as ComponentActivityStatus };
    }
    case NewEnvelopeActions.UPDATE_ERRORS: {
      return { ...state, errors: payload as string[] };
    }
    case NewEnvelopeActions.UPDATE_SUBMISSION: {
      return { ...state, hasSubmitted: true };
    }
    default:
      return state;
  }
}
