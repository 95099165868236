import IPropertiesProps from "./IPropertiesProps";
import { fabric } from "fabric";
import styles from "./propertiesStyles";

import React, { useEffect, useState } from "react";

import { UpdateToolSetting } from "../../../hooks/useTools";
import {
  Circle,
  Group,
  Image,
  Object as IObject,
  Point,
  Polygon,
  Rect,
} from "fabric/fabric-impl";

import InputReset from "../shared/InputReset";
import IncrementInput from "../shared/NuDesignerInputs/IncrementInput";
import useImageMasking, {
  IImageGroup,
} from "../../../hooks/useImageMasking/useImageMasking";
import ImageFrames from "./ImageFrames";
import { IObjectProperties } from "../../../state/contexts/SelectedObjectContext";
import generateGuid from "../../../../helpers/generateGuid";

const ImageProperties = ({ selectedObject }: IPropertiesProps) => {
  const [imageSettings, setImageSettings] = useState({
    strokeWidth: 0,
    stroke: "",
    cornerRadius: 0,
  });
  const [change, setChange] = useState(generateGuid());

  const [variableName, setVariableName] = useState(getVariableName());
  const [showRadius, setShowRadius] = useState(
    getImageFrame()?.type === "rect"
  );
  const frame = getImageFrame();
  function getVariableName() {
    const image = getImage();
    if (image) {
      return image.name ?? "";
    }
    return "";
  }

  function handleVariableName(val: string) {
    val = val.replaceAll(/^[^a-zA-Z_$]|[^0-9a-zA-Z_$]/g, "");
    setVariableName(val);
    const image = getImage();
    if (!image) return;
    image.name = val;
    image.canvas?.renderAll();
  }

  function updateStroke(property: string, value: string | number) {
    updateImage(property, value);
  }

  const updateImage: UpdateToolSetting = (property, value) => {
    if (!selectedObject) return;
    const group = selectedObject as Group;
    const frame = getImageFrame();
    const img = getImage();
    if (!frame || !img) return;
    if (property === "cornerRadius") {
      frame.set("rx", parseInt(value as string));
      frame.set("ry", parseInt(value as string));
      setImageSettings({
        ...imageSettings,
        cornerRadius: parseInt(value as string),
      });
      img.set("clipPath", createMask(frame));
    } else {
      if (property === "strokeWidth") {
        value = parseInt(value as string);
      }
      frame.set(property as keyof Rect, value);

      const newWidth = (frame.width ?? 0) + (frame.strokeWidth ?? 0);
      const newHeight = (frame.height ?? 0) + (frame.strokeWidth ?? 0);
      group.set("width", newWidth);
      group.set("height", newHeight);
      frame.set("top", -newHeight / 2);
      frame.set("left", -newWidth / 2);
      img.set("top", -newHeight / 2 + (frame.strokeWidth ?? 0) / 2);
      img.set("left", -newWidth / 2 + (frame.strokeWidth ?? 0) / 2);
      setImageSettings({ ...imageSettings, [property]: value });
    }
    frame.canvas?.renderAll();
  };

  function getImage() {
    if (!selectedObject) return undefined;
    const group = selectedObject as Group;
    const img = group._objects.find((x) => x.type === "image");
    if (!img) return img;
    return img as Image;
  }

  function getImageFrame() {
    if (!selectedObject) return undefined;
    const group = selectedObject as Group;
    const frame = group._objects.find((x) => x.type !== "image");
    if (!frame) return frame;
    return frame as Rect;
  }

  useEffect(() => {
    if (!selectedObject) return;
    const frame = getImageFrame();
    if (!frame) return;
    setImageSettings({
      stroke: frame.stroke ?? "#000000",
      strokeWidth: frame.strokeWidth ?? 0,
      cornerRadius: frame.rx ?? 0,
    });
    setVariableName(getVariableName());
    // @ts-ignore
  }, [selectedObject, selectedObject._objects, showRadius, change]);

  if (!selectedObject) return null;
  return (
    <div>
      <div>
        <div css={styles.label}>Variable Name</div>
        <div css={styles.inputWrapper}>
          <InputReset
            type="text"
            css={styles.input}
            onChange={(e) => handleVariableName(e.target.value)}
            value={variableName}
          />
        </div>
      </div>
      {showRadius && (
        <div css={styles.propertiesGroup}>
          <div css={styles.property}>
            <div css={styles.label}>Corner Radius</div>
            <IncrementInput
              onChange={updateStroke}
              min={0}
              max={100}
              property="cornerRadius"
              value={imageSettings.cornerRadius}
            />
          </div>
        </div>
      )}
      <ImageFrames
        image={selectedObject as IImageGroup}
        updateRadiusBool={setShowRadius}
        forceUpdate={setChange}
      />
    </div>
  );
};

export function createMask(frame: Rect | Circle | Polygon) {
  const type = frame.type;
  const top = -(frame.height ?? 0) / 2;
  const left = -(frame.width ?? 0) / 2;
  switch (type) {
    case "rect": {
      frame = frame as Rect;
      return new fabric.Rect({
        top: top,
        left: left,
        width: frame.width,
        height: frame.height,
        rx: frame.rx,
        ry: frame.ry,
      });
    }
    case "circle": {
      frame = frame as Circle;
      return new fabric.Circle({
        radius: frame.radius,
        top: top,
        left: left,
      });
    }
    default:
    case "polygon": {
      frame = frame as Polygon;
      return new fabric.Polygon(frame.points as Point[], {
        top: top,
        left: left,
      });
    }
  }
}

export default ImageProperties;
