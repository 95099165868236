import { IEvent, Object as IObject } from "fabric/fabric-impl";
import { fabric } from "fabric";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CanvasContext } from "../state/contexts/CanvasContext";
import { useDesignerDispatch, useDesignerSelector } from "../state/store";
import { selectDesignInformation } from "../state/slices/designInformation";
import getDefaultZoom from "../features/Canvas/functions/getDefaultZoom";
import { Tool } from "../state/models/ICanvasTool";
import { Textbox } from "fabric";
import RESOLUTION from "../constants/RESOLUTION";
import centerAllObjectsOnCanvas from "../helpers/centerAllObjectsOnCanvas";
import { SelectedPageContext } from "../state/contexts/SelectedPageContext";
import { group } from "console";

const useCamera = () => {
  const canvas = useContext(CanvasContext);
  const selectedPage = useContext(SelectedPageContext);

  const hasInitialized = useRef(false);

  const defaultZoom = 0.22;

  const [zoom, setZoom] = useState(defaultZoom);
  const modeRef = useRef("fit");
  const resizeOverflowTimeout = useRef<NodeJS.Timeout>();

  const displayZoom = getDisplayZoomVal();

  function getDisplayZoomVal() {
    return Math.floor(zoom * (RESOLUTION / 72) * 100);
  }

  function zoomIn() {
    if (!canvas) return;
    modeRef.current = "custom";
    setZoom(zoom + 0.1);
    canvas.zoomToPoint(getCanvasCenterPoint(), zoom + 0.1);
    fitCanvasToContent();
  }

  function zoomOut() {
    if (!canvas) return;
    modeRef.current = "custom";
    if (zoom - 0.1 < 0) return;
    setZoom(zoom - 0.1);
    canvas.zoomToPoint(getCanvasCenterPoint(), zoom - 0.1);
    fitCanvasToContent();
  }

  function fitCanvasToContent() {
    const parent = document.querySelector("#designer-canvas-parent");
    if (!parent || !canvas) return;
    const bleed = canvas.getObjects().find((x) => x.name === "bleed");
    if (!bleed) return;

    // Setup the scroll trackers before we resize the canvas
    const originalScrollWidth = parent.scrollWidth;
    const currentXScroll = parent.scrollLeft;

    const originalScrollHeight = parent.scrollHeight;
    const currentYScroll = parent.scrollTop;

    const canvasWidth =
      bleed.getScaledWidth() * canvas.getZoom() > parent.clientWidth
        ? bleed.getScaledWidth() * canvas.getZoom() + 250
        : parent.clientWidth;
    const canvasHeight =
      bleed.getScaledHeight() * canvas.getZoom() > parent.clientHeight
        ? bleed.getScaledHeight() * canvas.getZoom() + 250
        : parent.clientHeight;
    canvas.setDimensions({
      width: canvasWidth,
      height: canvasHeight,
    });
    centerAllObjectsOnCanvas(canvas);

    canvas.zoomToPoint(bleed.getCenterPoint(), canvas.getZoom());

    // Update the scroll bars to keep the canvas centered and/or keep them in the same spot when zooming.
    if (parent.scrollLeft === 0 && parent.scrollWidth > parent.clientWidth) {
      // center the X scroll bar if the user has not moved it already
      const scrollX = (parent.scrollWidth - parent.clientWidth) / 2;
      parent.scrollLeft = scrollX;
    } else if (parent.scrollLeft !== 0) {
      // otherwise, figure out the percentage of horizontal scroll the user has moved, then apply it to the new dimensions
      const percentage =
        currentXScroll / (originalScrollWidth - parent.clientWidth);

      parent.scrollLeft =
        (parent.scrollWidth - parent.clientWidth) * percentage;
    }

    if (parent.scrollTop !== 0) {
      // figure out the percentage of vertical scroll the user has moved, then apply it to the new dimensions
      const percentage =
        currentYScroll / (originalScrollHeight - parent.clientHeight);
      parent.scrollTop =
        (parent.scrollHeight - parent.clientHeight) * percentage;
    }
  }

  function fitToScreen2() {
    const parent = document.getElementById("designer-canvas-parent");
    if (!canvas || !parent) return 0.5;
    const bleed = canvas.getObjects().find((x) => x.name === "bleed");
    if (!bleed) return 0.5;
    modeRef.current = "fit";
    parent.style.overflow = "hidden";

    let newZoom = 0.22;

    const width = bleed.width ?? 0;
    const height = bleed.height ?? 0;
    const x1 = bleed.left ?? 0;
    const y1 = bleed.top ?? 0;

    const x = x1 + width / 2 - parent.clientWidth / 2;
    const y = y1 + height / 2 - parent.clientHeight / 2;

    const heightDist = parent.clientHeight - height;
    const widthDist = parent.clientWidth - width;

    let groupDimension = 0;
    let canvasDimension = 0;
    if (heightDist < widthDist) {
      groupDimension = height;
      canvasDimension = parent.clientHeight;
    } else {
      groupDimension = width;
      canvasDimension = parent.clientWidth;
    }

    newZoom = (canvasDimension / groupDimension) * 0.95;

    canvas.zoomToPoint(
      { x: parent.clientWidth / 2, y: parent.clientHeight / 2 },
      newZoom,
    );

    return newZoom;
  }

  function fitToScreen() {
    const parent = document.getElementById("designer-canvas-parent");

    if (!canvas || !parent) return 0.5;
    const bleed = canvas.getObjects().find((x) => x.name === "bleed");
    if (!bleed) return 0.5;
    // set our modeRef to fit so our resize listener knows to use this function
    modeRef.current = "fit";
    // remove the scrollbars so the dimensions are perfect
    parent.style.overflow = "hidden";

    let newZoom = 0;
    const x1 = bleed.left ?? 0;
    const y1 = bleed.top ?? 0;
    const height = bleed.getScaledHeight();
    const width = bleed.getScaledWidth();

    canvas.setZoom(1);
    const x = x1 + width / 2 - canvas.width! / 2;
    const y = y1 + height / 2 - canvas.height! / 2;
    canvas.absolutePan({ x, y });

    let groupDimension = 0;
    let canvasDimension = 0;
    const bleedRatio = bleed.width! / bleed.height!;
    const parentRatio = parent.clientWidth / parent.clientHeight;
    if (bleedRatio < parentRatio) {
      groupDimension = height;
      canvasDimension = parent.clientHeight;
    } else {
      groupDimension = width;
      canvasDimension = parent.clientWidth;
    }

    newZoom = (canvasDimension / groupDimension) * 0.95;

    canvas.zoomToPoint(
      { x: parent.clientWidth / 2, y: parent.clientHeight / 2 },
      newZoom,
    );

    // resize the canvas to fit the parent window
    const newWidth =
      parent.clientWidth > bleed.getScaledWidth() * newZoom
        ? parent.clientWidth
        : bleed.getScaledWidth() * newZoom;
    const newHeight =
      parent.clientHeight > bleed.getScaledHeight() * newZoom
        ? parent.clientHeight
        : bleed.getScaledHeight() * newZoom;
    canvas.setDimensions({
      width: newWidth,
      height: newHeight,
    });

    if (resizeOverflowTimeout.current) {
      clearTimeout(resizeOverflowTimeout.current);
    }

    // change the overflow back to auto on a delay so our canvas doesn't jump around while resizing
    resizeOverflowTimeout.current = setTimeout(() => {
      parent.style.overflow = "auto";
    }, 200);

    // center everything according to the new canvas dimensions
    centerAllObjectsOnCanvas(canvas);
    // const center = bleed.getCenterPoint();
    // canvas.zoomToPoint({ x: center.x, y: center.y }, newZoom);
    setZoom(newZoom);

    return newZoom;
  }

  function getCanvasCenterPoint() {
    const parent = document.querySelector("#designer-canvas-parent");
    if (!parent) return { x: 0, y: 0 };
    return { x: parent.clientWidth / 2, y: parent.clientHeight / 2 };
  }

  function fillScreen() {
    const parent = document.getElementById("designer-canvas-parent");

    if (!canvas || !parent) return 0.5;
    const bleed = canvas.getObjects().find((x) => x.name === "bleed");
    if (!bleed) return 0.5;
    // set our modeRef to fill so our resize listener knows to use this function
    modeRef.current = "fill";

    let newZoom = 0;
    const x1 = bleed.left ?? 0;
    const y1 = bleed.top ?? 0;
    const height = bleed.getScaledHeight();
    const width = bleed.getScaledWidth();

    canvas.setZoom(1);
    const x = x1 + width / 2 - canvas.width! / 2;
    const y = y1 + height / 2 - canvas.height! / 2;
    canvas.absolutePan({ x, y });

    newZoom = (parent.clientWidth / width) * 0.95;

    canvas.zoomToPoint(
      { x: parent.clientWidth / 2, y: parent.clientHeight / 2 },
      newZoom,
    );

    fitCanvasToContent();
    setZoom(newZoom);
    return newZoom;
  }

  function zoomTo(val: number) {
    const normalized = (72 * (val / 100)) / RESOLUTION;
    // return Math.floor(zoom * (RESOLUTION / 72) * 100);

    zoomToRawValue(normalized);
  }

  function zoomToRawValue(val: number) {
    if (!canvas) return;
    modeRef.current = "custom";
    setZoom(val);
    canvas.zoomToPoint(getCanvasCenterPoint(), val);
    fitCanvasToContent();
  }

  function handleWheelZoom(e: IEvent<WheelEvent>) {
    if (!canvas) return;
    if (!e.e.ctrlKey && !e.e.metaKey) return;
    e.e.preventDefault();
    e.e.stopPropagation();
    const delta = e.e.deltaY;
    let canvasZoom = canvas.getZoom() * 0.999 ** delta;
    if (canvasZoom > 20) canvasZoom = 20;
    if (canvasZoom < 0.01) canvasZoom = 0.01;
    zoomToRawValue(canvasZoom);
  }

  function initCamera() {
    if (!canvas || hasInitialized.current) return;
    centerAllObjectsOnCanvas(canvas);
    window.onresize = () => {
      if (modeRef.current === "fit") fitToScreen();
      else if (modeRef.current === "fill") fillScreen();
      else fitCanvasToContent();
    };
    canvas.on("mouse:wheel", handleWheelZoom);

    const center = canvas.getVpCenter();
    const initialZoomPercent = fitToScreen() ?? 0.22;
    setZoom(initialZoomPercent);
    canvas.zoomToPoint({ x: center.x, y: center.y }, initialZoomPercent);
    hasInitialized.current = true;
  }

  function resetViewPort() {
    if (hasInitialized.current && canvas) {
      if (modeRef.current === "fit") fitToScreen();
      else if (modeRef.current === "fill") fillScreen();
      else zoomToRawValue(canvas.getZoom());
    }
  }

  useEffect(initCamera, [canvas]);
  useEffect(resetViewPort, [selectedPage]);

  return {
    zoomIn,
    zoomOut,
    fitToScreen,
    fillScreen,
    zoomTo,
    displayZoom,
  };
};

export default useCamera;
