import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import LoadingButton from "../../../shared/LoadingButton";
import { fetchClient } from "../../../helpers/fetchClient";

// ICONS
import DeleteIcon from "@mui/icons-material/Delete";

interface IDesignDelete {
  designId: number;
  callback: () => void;
}
const DesignDelete: React.FC<IDesignDelete> = ({ designId, callback }) => {
  // Delete design state
  const [dialog, setDialog] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDialog = () => {
    setDialog(!dialog);
    if (!dialog) {
      setShowError(false);
      setLoading(false);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    fetchClient.delete({
      path: `/integrations/designs/delete?DesignID=${designId}`,
      onSuccess: () => {
        handleDialog();
        setLoading(false);
        callback();
      },
      onError: () => {
        setShowError(true);
        setLoading(false);
      },
    });
  };

  return (
    <React.Fragment>
      <Dialog open={dialog} onClose={() => handleDialog()}>
        <DialogContent>
          {showError
            ? "There was a problem deleting this design. Please try again."
            : "Are you sure you want to delete this design?"}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialog()}>
            {showError ? "Close" : "Cancel"}
          </Button>
          {!showError && (
            <LoadingButton
              onClick={handleDelete}
              color="primary"
              loading={loading}
            >
              Confirm
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <IconButton onClick={handleDialog} color="primary" size="small">
        <DeleteIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default DesignDelete;
