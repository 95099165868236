import useQuery from "../../../hooks/useQuery";
import StateProvider from "../../../postcard-designer/state/StateProvider";
import EditDesignerDesign from "../../Designer/EditDesignerDesign";

const IntegratedEditor = ({ mode = "iframe" }: { mode?: string }) => {
  const query = useQuery();

  function onSaveCallback(designID: number, envelopeType: string | undefined) {
    const origin = query.get("origin") ?? "*";
    window.parent?.postMessage(
      {
        designID: designID,
        envelopeType,
      },
      "*",
    );
  }

  return (
    <StateProvider>
      <EditDesignerDesign mode={mode} onSaveCallback={onSaveCallback} />
    </StateProvider>
  );
};

export default IntegratedEditor;
