import IPropertiesProps from "../Properties/IPropertiesProps";
import DeleteButton from "./DeleteButton";
import DuplicateButton from "./DuplicateButton";
import toolbarStyles from "./toolbarStyles";

const GroupProperties = ({ selectedObject }: IPropertiesProps) => {
  return (
    <div>
      <div css={toolbarStyles.section}>
        <div css={toolbarStyles.property}>
          <DuplicateButton selectedObject={selectedObject} />
        </div>
        <div css={toolbarStyles.property}>
          <DeleteButton selectedObject={selectedObject} />
        </div>
      </div>
    </div>
  );
};

export default GroupProperties;
