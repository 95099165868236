import { useCallback, useMemo, useRef } from "react";
import tw, { css } from "twin.macro";
import useClickOutside from "../../../../../hooks/useClickOutside";
import propertiesStyles from "../../Properties/propertiesStyles";
import { ISelectOptionProps } from "./SelectOption";

interface IDesignerSelectOptionsProps {
  children:
    | React.ReactElement<ISelectOptionProps>
    | React.ReactElement<ISelectOptionProps>[];
  onClick: (value: string) => void;
  onClose: () => void;
  isOpen: boolean;
  small: boolean;
}

const styles = {
  container: (isSmall: boolean) => [
    tw`absolute left-0  z-50 bg-white border border-border border-solid rounded-b rounded-tr whitespace-nowrap min-w-full`,
    isSmall &&
      css`
        top: 29px;
        max-height: 300px;
        overflow-y: auto;
      `,
  ],
  item: [tw`px-1.5 py-1.5 hover:bg-hover cursor-pointer w-full`],
};

const DesignerSelectOptions = ({
  children,
  onClick,
  isOpen,
  onClose,
  small,
}: IDesignerSelectOptionsProps) => {
  function handleClick(val: string) {
    onClick(val);
    onClose();
  }
  const options = useMemo(() => {
    if (!children) return undefined;
    if (Array.isArray(children)) {
      return children.map((child) => {
        if (child.props && "value" in child.props) {
          return (
            <div
              onClick={() => handleClick(child.props.value as string)}
              css={styles.item}
              key={child.props.value}
            >
              {child}
            </div>
          );
        }
        return null;
      });
    } else {
      if (
        typeof children === "object" &&
        "props" in children &&
        "value" in children.props
      ) {
        return (
          <div
            onClick={() => handleClick(children.props.value as string)}
            css={styles.item}
          >
            {children}
          </div>
        );
      }
    }
    return undefined;
  }, [children]);
  if (!isOpen) return null;
  return (
    <div css={[styles.container(small), propertiesStyles.scrollbar]}>
      {options}
    </div>
  );
};

export default DesignerSelectOptions;
