import { getNumberFromString } from "../../../helpers/getNumberFromString";
import { IEnvelopeSettings } from "../EnvelopeDetails/EnvelopeSettingsReducer";
import { ReplenishmentMethod } from "../models/IEnvelope";
import { INewEnvelopeForm } from "../NewEnvelope/NewEnvelopeReducer";

export default function envelopeSettingsValidation(
  values: INewEnvelopeForm | IEnvelopeSettings
): string[] {
  const errors: string[] = [];
  const val = getNumberFromString(values.replenishmentValue);
  const qty = getNumberFromString(values.orderQty);
  if (values.replenishmentMethod === ReplenishmentMethod.Percentage) {
    if (!val || val < 1 || val > 100) {
      errors.push("replenishmentValue");
    }
  }
  if (values.replenishmentMethod === ReplenishmentMethod.Static) {
    if (!val || val < 1) {
      errors.push("replenishmentValue");
    } else if (val >= qty) {
      errors.push("replenishmentValue");
      errors.push("greaterThanQty");
    }
  }
  if (values.replenishmentMethod !== ReplenishmentMethod.None) {
    if (!qty || qty < 5000) {
      errors.push("orderQty");
    }
  }
  if (!values.paymentMethod) {
    errors.push("paymentMethod");
  }
  // validate for INewEnvelopeForm
  if (Object.keys(values).includes("front")) {
    const newEnvValues = values as INewEnvelopeForm;
    if (!newEnvValues.front) {
      errors.push("front");
    }
    if (!newEnvValues.friendlyName || !newEnvValues.friendlyName.trim()) {
      errors.push("friendlyName");
    }
    if (!newEnvValues.paymentMethod || !newEnvValues.paymentMethod.trim()) {
      errors.push("paymentMethod");
    }
    if (!values.replenishmentMethod) {
      errors.push("replenishmentMethod");
    }
  }
  return errors;
}
