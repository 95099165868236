import {
  Box,
  useMediaQuery,
  Link as ATag,
  useTheme,
  Chip,
} from "@mui/material";

import isAdminUser from "../../../helpers/isAdminUser";
import { getAdminToken } from "../../../helpers/LocalStorage";
import GridItem from "../../MaterialWrappers/GridItem";
import GridRow from "../../MaterialWrappers/GridRow";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Alerts from "./Alerts";
import MobileNav from "./Navbar/MobileNav";
import BusinessSelector from "./BusinessSelector";
import React from "react";
import isEditorUser from "../../../helpers/isEditorUser";
import LocalStorage from "../../../helpers/LocalStorage";

const LayoutHeader = () => {
  const history = useHistory();
  const isAdmin = isAdminUser();
  const adminToken = getAdminToken();
  const isEditor = isEditorUser();
  const theme = useTheme();
  const isMediumScreenSize = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmallerThanXLScreenSize = useMediaQuery(theme.breakpoints.down("xl"));

  function returnToAdminPanel() {
    LocalStorage.clearPrimaryToken();
    LocalStorage.setToken(adminToken ?? "");
    history.push("/admin");
  }

  const returnToAdminLabel = !isSmallerThanXLScreenSize
    ? "You are currently viewing this account as an administrator Click here to return to the Admin Overview."
    : "Click here to return to the Admin Overview.";

  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: "16px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
      }}
    >
      <GridRow alignItems="center">
        {!isMediumScreenSize && (!isAdmin || adminToken !== null) && (
          <GridItem>
            <MobileNav />
          </GridItem>
        )}

        {((!isAdmin && !isEditor) || adminToken !== null) && (
          <>
            {isMediumScreenSize && isAdmin && (
              <GridItem>
                <Chip
                  label={returnToAdminLabel}
                  color="primary"
                  variant="outlined"
                  onClick={returnToAdminPanel}
                />
              </GridItem>
            )}
            <GridItem sx={{ marginLeft: "auto" }}>
              <GridRow spacing={3} alignItems="center">
                {isMediumScreenSize && (
                  <React.Fragment>
                    <GridItem md="auto">
                      <BusinessSelector />
                    </GridItem>
                    <GridItem md="auto">
                      <ATag
                        component={Link}
                        to="/logout"
                        sx={{
                          color: "rgba(0,0,0,.65)",
                          textDecoration: "none",
                          ":hover": {
                            color: "rgba(0,0,0,.85)",
                          },
                        }}
                      >
                        Logout
                      </ATag>
                    </GridItem>
                  </React.Fragment>
                )}
                <GridItem xs="auto">
                  <Alerts />
                </GridItem>
              </GridRow>
            </GridItem>
          </>
        )}

        {((isAdmin && adminToken === null) || isEditor) && (
          <GridItem sx={{ marginLeft: "auto" }}>
            <ATag
              to="/logout"
              component={Link}
              sx={{
                color: "rgba(0,0,0,.65)",
                textDecoration: "none",
                ":hover": {
                  color: "rgba(0,0,0,.85)",
                },
              }}
            >
              Logout
            </ATag>
          </GridItem>
        )}
      </GridRow>
    </Box>
  );
};

export default LayoutHeader;
