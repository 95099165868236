import { Canvas } from "fabric/fabric-impl";
import DATALESS_PROPERTIES from "../../../constants/DATALESS_PROPERTIES";

export default function getCleanCanvasData(canvas: Canvas) {
  const json = canvas.toDatalessJSON(DATALESS_PROPERTIES);
  json.objects = json.objects.filter((obj) => {
    if (obj.name && !obj.name.includes("PathControl")) return true;
    return false;
  });
  return json;
}
