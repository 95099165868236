import { Group, Object as IObject } from "fabric/fabric-impl";
import { fabric } from "fabric";
import { useContext } from "react";
import generateGuid from "../../../../helpers/generateGuid";
import RESOLUTION from "../../../constants/RESOLUTION";
import useLayers from "../../../hooks/useLayers";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import { ClipboardIcon } from "../shared/SvgComponents";
import ToolButton from "../shared/ToolButton";
import IPropertiesProps from "../Properties/IPropertiesProps";
import toolbarStyles from "./toolbarStyles";
import textResizer from "../../Canvas/functions/textResizer";
import { Textbox } from "fabric";
import duplicateObjects from "../../Canvas/functions/duplicateObjects";

const DuplicateButton = ({ selectedObject }: IPropertiesProps) => {
  const { createLayer } = useLayers();
  const canvas = useContext(CanvasContext);

  async function onDuplicate() {
    if (!canvas || !selectedObject) return;
    let clones: IObject[] = [];
    if (selectedObject.type === "activeSelection") {
      const objects = (selectedObject as Group)._objects;
      clones = await duplicateObjects(objects, createLayer, canvas);
    } else {
      clones = await duplicateObjects([selectedObject], createLayer, canvas);
    }
    canvas.setActiveObject(new fabric.ActiveSelection(clones, { canvas }));
    canvas.fire("object:modified");
  }

  return (
    <ToolButton
      css={toolbarStyles.utilityButton}
      title="Duplicate [ctrl+c / ctrl+v]"
      onClick={onDuplicate}
    >
      <ClipboardIcon size={5} />
    </ToolButton>
  );
};

export default DuplicateButton;
