import React, { useEffect, useState } from "react";
import QrCode, {
  QrCodeTracking,
} from "../../shared/QrCodeEditor/models/QrCode";
import LoadingWrapper from "../../shared/LoadingWrapper";
import {
  deleteAsync,
  getAsync,
  postAsync,
  putAsync,
} from "../../helpers/asyncFetch";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import QrCodeEditor from "../../shared/QrCodeEditor/QrCodeEditor";
import Modal, {
  ModalActions,
  ModalBody,
  ModalTitle,
} from "../../postcard-designer/features/DesignerInterface/shared/Modal";
import QrCodeElement from "../../shared/QrCodeEditor/components/QrCodeElement";
import { QrCodeStylingOptions } from "../../shared/QrCodeEditor/types/QrCodeStylingTypes";
import QrCodeResults from "./components/QrCodeResults";
import LoadingButton from "../../shared/LoadingButton";
import { TableColumn } from "react-data-table-component";
import SearchableDataTable from "../../shared/SearchableDataTable";

interface QrCodesState {
  loading: boolean;
  newOpen: boolean;
  resultsOpen: boolean;
  resultsQrCode?: QrCode;
  deleteOpen: boolean;
  deleteId?: number;
  editOpen: boolean;
  editing?: QrCode;
}

const QrCodes = () => {
  const [state, setState] = useState<QrCodesState>({
    loading: true,
    newOpen: false,
    resultsOpen: false,
    resultsQrCode: undefined,
    editOpen: false,
    deleteOpen: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [qrCodes, setQrCodes] = useState<QrCode[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function getQrCodes() {
    const res = await getAsync<QrCode[]>(`/qr-codes`);
    if (res) {
      setQrCodes(
        res.map((x) => {
          return {
            ...x,
            // @ts-ignore
            config: JSON.parse(x.config as string) as QrCodeStylingOptions,
          };
        })
      );
      setIsLoading(false);
    }
  }
  function openEdit(qrCode?: QrCode) {
    setState({
      ...state,
      editing: qrCode,
      editOpen: Boolean(qrCode),
    });
  }
  function toggleNewOpen() {
    setState({ ...state, newOpen: !state.newOpen });
  }

  async function handleDelete(id: number) {
    setIsSubmitting(true);
    await deleteAsync(`/qr-codes/${id}`);
    await getQrCodes();
    setState({ ...state, deleteOpen: false, deleteId: undefined });
    setIsSubmitting(false);
  }

  async function handleNewSave({
    url,
    config,
    preview,
  }: {
    url: string;
    config: string;
    preview: string;
  }) {
    const reqBody = {
      url,
      config,
      preview,
    };
    setIsSubmitting(true);
    await postAsync(`/qr-codes`, reqBody);
    await getQrCodes();
    setState({ ...state, newOpen: false });
    setIsSubmitting(false);
  }

  async function handleEditSave({
    url,
    config,
    preview,
  }: {
    url: string;
    config: string;
    preview: string;
  }) {
    const reqBody = {
      url,
      config,
      preview,
    };
    setIsSubmitting(true);
    await putAsync(`/qr-codes/${state.editing?.qrCodeID}`, reqBody);
    await getQrCodes();
    setState({ ...state, editOpen: false });
    setIsSubmitting(false);
  }

  useEffect(() => {
    getQrCodes();
  }, []);

  function getUniqueScans(results: QrCodeTracking[]) {
    const unique = results.reduce((u, curr) => {
      if (!u.find((x) => x.recordID === curr.recordID)) {
        u.push(curr);
      }
      return u;
    }, [] as QrCodeTracking[]);
    return unique;
  }

  function openResults(qrCode: QrCode) {
    setState({
      ...state,
      resultsOpen: true,
      resultsQrCode: qrCode,
    });
  }

  function openDelete(id: number) {
    setState({
      ...state,
      deleteOpen: true,
      deleteId: id,
    });
  }

  const columns: TableColumn<QrCode>[] = [
    {
      name: "",
      cell: (qrCode) => {
        return <QrCodeElement {...qrCode.config} width={150} height={150} />;
      },
    },
    {
      name: "QR Code ID",
      selector: (qrCode) => qrCode.qrCodeID,
      sortable: true,
    },
    {
      name: "URL",
      selector: (qrCode) => qrCode.url,
      sortable: true,
    },
    {
      name: "Total Scans",
      selector: (qrCode) => qrCode.results?.length ?? 0,
      sortable: true,
    },
    {
      name: "Unique Scans",
      selector: (qrCode) => getUniqueScans(qrCode.results ?? []).length,
      sortable: true,
    },
    {
      name: "",
      cell: (qrCode) => {
        return (
          <React.Fragment>
            <Button
              size="small"
              color="primary"
              onClick={() => openResults(qrCode)}
            >
              View Results
            </Button>{" "}
            <Button color="primary" onClick={() => openEdit(qrCode)}>
              Edit
            </Button>
            <Button color="info" onClick={() => openDelete(qrCode.qrCodeID)}>
              <i className="fas fa-trash"></i>
            </Button>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <LoadingWrapper loading={isLoading}>
        <SearchableDataTable
          data={qrCodes}
          columns={columns}
          searchableColumns={["url", "qrCodeID"]}
          customHeaderComponent={
            <Box>
              <Button
                onClick={toggleNewOpen}
                color="primary"
                variant="outlined"
                size="small"
              >
                Create New
              </Button>
            </Box>
          }
        />
      </LoadingWrapper>
      <Modal isOpen={state.deleteOpen} width={400}>
        <ModalTitle>Are You Sure?</ModalTitle>
        <ModalBody>This action cannot be undone.</ModalBody>

        <ModalActions>
          <LoadingButton
            color="primary"
            size="small"
            loading={isSubmitting}
            onClick={() =>
              state.deleteId ? handleDelete(state.deleteId) : null
            }
          >
            Delete QR Code
          </LoadingButton>
          <Button
            color="info"
            size="small"
            onClick={() =>
              setState({
                ...state,
                deleteOpen: false,
                deleteId: undefined,
              })
            }
          >
            Cancel
          </Button>
        </ModalActions>
      </Modal>

      <Modal isOpen={state.resultsOpen} width={1440}>
        <ModalTitle>Results For QR Code</ModalTitle>
        <ModalBody>
          <Box>
            {state.resultsQrCode && (
              <QrCodeResults qrCodeID={state.resultsQrCode.qrCodeID} />
            )}
          </Box>
        </ModalBody>
        <ModalActions>
          <Button
            onClick={() => {
              setState({ ...state, resultsOpen: false });
            }}
            color="info"
          >
            Close
          </Button>
        </ModalActions>
      </Modal>
      <Modal isOpen={state.editOpen} width={650}>
        <ModalTitle>Edit QR Code</ModalTitle>
        <ModalBody key={state.editing ? state.editing.qrCodeID : 1}>
          <QrCodeEditor
            saveCallback={handleEditSave}
            loading={isSubmitting}
            qrCodeOverride={state.editing}
          />
        </ModalBody>
        <ModalActions>
          <Button
            color="info"
            onClick={() =>
              setState({ ...state, editOpen: false, editing: undefined })
            }
          >
            Cancel
          </Button>
        </ModalActions>
      </Modal>
      <Modal isOpen={state.newOpen} width={650}>
        <ModalTitle>Create A QR Code</ModalTitle>
        <ModalBody>
          <QrCodeEditor saveCallback={handleNewSave} loading={isSubmitting} />
        </ModalBody>
        <ModalActions>
          <Button
            color="info"
            onClick={() => setState({ ...state, newOpen: false })}
          >
            Cancel
          </Button>
        </ModalActions>
      </Modal>
    </React.Fragment>
  );
};

export default QrCodes;
