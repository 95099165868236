import { Tool } from "./ICanvasTool";
import ISvgShape from "./ISvgShape";

export default interface IToolSettings {
  currentTool: Tool;
  shape: Shape;
  svgShape?: ISvgShape;
  fill?: string;
  stroke?: string;
  strokeWidth: number;
  fontFamily: string;
  fontWeight: number | "bold";
  textAlign: TextAlign;
  fontSize: number;
  fontStyle: FontStyle;
  lineHeight: number;
  kerning: number;
  cornerRadius: number;
  shadow: IShadowSettings;
  showGuides: boolean;
  showOverlay: boolean;
}

export interface IShadowSettings {
  offsetX: number;
  offsetY: number;
  blur: number;
  color?: string;
}

export enum Shape {
  rect = "Rect",
  circle = "Circle",
  ellipse = "Ellipse",
  polygon = "Polygon",
  svg = "SVG",
  parallelogram = "Parallelogram",
  triangle = "Triangle",
}
export enum FontStyle {
  italic = "italic",
  normal = "normal",
}

export enum TextAlign {
  left = "left",
  center = "center",
  right = "right",
  justify = "justify",
  justifyLeft = "justify-left",
  justifyCenter = "justify-center",
  justifyRight = "justify-right",
}
