import { Canvas } from "fabric/fabric-impl";
import { fabric } from "fabric";
import RESOLUTION from "../../../constants/RESOLUTION";
import asyncLoadSvgFromString from "./asyncLoadSvgFromString";
import { CanvasObject } from "../../../state/contexts/PagesContext";
import { LetterFoldTypes } from "../../../state/slices/letterSettings";
import { svgBarcode } from "./getAddressBlockOverlay";

export default async function getBrochureOverlay(
  type: LetterFoldTypes,
  canvas: Canvas,
  isAddressBlock: boolean
) {
  const background = canvas.getObjects().find((x) => x.name === "background");
  if (!background) return [];
  const barcodeObj = await asyncLoadSvgFromString(svgBarcode);
  barcodeObj.name = "overlay";
  // @ts-ignore
  barcodeObj.__addressBlock = true;
  const addressBlock = {
    left: 0,
    top: (background.top ?? 0) + 0.5118 * RESOLUTION,
    height: 3.8432 * RESOLUTION,
    width: 2.1208 * RESOLUTION,
    address: {
      left: 0,
      top: 0,
    },
  };
  const objs: CanvasObject[] = [];
  if (type === LetterFoldTypes.TRIFOLD) {
    addressBlock.left = (background.left ?? 0) + 4.6865 * RESOLUTION;
    const fold1 = new fabric.Rect({
      width: 1,
      strokeWidth: 2,
      stroke: "purple",
      height: background.getScaledHeight(),
      originX: "center",
      top: background.top,
      left: (background.left ?? 0) + 3.72 * RESOLUTION,
      fill: "purple",
      selectable: false,

      name: "overlay",
      //@ts-ignore
      __foldLine: true,
    });
    const fold1Text = new fabric.Text("Fold", {
      fill: "purple",
      originX: "center",
      originY: "center",
      left: fold1.getCenterPoint().x - 20,
      top: fold1.getCenterPoint().y - fold1.getScaledHeight() / 2 + 110,
      name: "overlay",
      fontSize: 26,
      evented: false,
      fontFamily: "Roboto",
      //@ts-ignore
      __foldLine: true,
    });
    fold1Text.rotate(-90);
    const fold2 = new fabric.Rect({
      width: 1,
      strokeWidth: 2,
      stroke: "purple",
      height: background.getScaledHeight(),
      originX: "center",
      top: background.top,
      left: (background.left ?? 0) + 3.72 * RESOLUTION + 3.72 * RESOLUTION,
      fill: "purple",
      selectable: false,

      name: "overlay",
      //@ts-ignore
      __foldLine: true,
    });

    const fold2Text = new fabric.Text("Fold", {
      fill: "purple",
      originX: "center",
      originY: "center",
      left: fold2.getCenterPoint().x - 20,
      top: fold2.getCenterPoint().y - fold2.getScaledHeight() / 2 + 110,
      name: "overlay",
      fontSize: 26,
      evented: false,
      fontFamily: "Roboto",
      //@ts-ignore
      __foldLine: true,
    });
    fold2Text.rotate(-90);
    objs.push(fold1, fold1Text, fold2, fold2Text);
  } else {
    addressBlock.left = (background.left ?? 0) + 2.7465 * RESOLUTION;
    const fold1 = new fabric.Rect({
      width: 1,
      strokeWidth: 2,
      stroke: "purple",
      height: background.getScaledHeight(),
      originX: "center",
      top: background.top,
      left: (background.left ?? 0) + 5.5 * RESOLUTION,
      fill: "purple",
      selectable: false,

      name: "overlay",
      //@ts-ignore
      __foldLine: true,
    });
    const fold1Text = new fabric.Text("Fold", {
      fill: "purple",
      originX: "center",
      originY: "center",
      left: fold1.getCenterPoint().x - 20,
      top: fold1.getCenterPoint().y - fold1.getScaledHeight() / 2 + 110,
      name: "overlay",
      fontSize: 26,
      evented: false,
      fontFamily: "Roboto",
      //@ts-ignore
      __foldLine: true,
    });
    fold1Text.rotate(-90);
    objs.push(fold1, fold1Text);
  }
  if (isAddressBlock) {
    objs.push(
      new fabric.Rect({
        width: addressBlock.width - 2,
        height: addressBlock.height - 2,
        strokeWidth: 2,
        stroke: "rgba(0,0,0,.6)",
        fill: "#FFFFFF",
        selectable: false,
        name: "overlay",
        left: addressBlock.left,
        top: addressBlock.top,
        // @ts-ignore
        __addressBlock: true,
      })
    );
    barcodeObj.originX = "center";
    barcodeObj.originY = "center";
    barcodeObj.scaleX = 3;
    barcodeObj.scaleY = 3;
    barcodeObj.selectable = false;
    barcodeObj.evented = false;
    barcodeObj.rotate(-90);
    barcodeObj.left =
      addressBlock.left +
      0.9925 * RESOLUTION +
      barcodeObj.getScaledHeight() / 2;
    barcodeObj.top =
      addressBlock.top +
      addressBlock.height -
      0.145 * RESOLUTION -
      barcodeObj.getScaledWidth() / 2;
    objs.push(barcodeObj);
    const addressBlockText = new fabric.Text(
      "<<firstName>> <<lastName>>\n<<address>> <<address2>>\n<<city>>, <<state>> <<zipCode>>",
      {
        fontSize: 30,
        fontFamily: "Roboto",
        left: 0,
        top: 0,
        fill: "black",
        name: "overlay",
        originX: "center",
        originY: "center",
        evented: false,
        selectable: false,
        // @ts-ignore
        __addressBlock: true,
      }
    );

    addressBlockText.rotate(-90);
    addressBlockText.left =
      barcodeObj.left +
      barcodeObj.getScaledHeight() / 2 +
      0.1197 * RESOLUTION +
      addressBlockText.getScaledHeight() / 2;
    addressBlockText.top =
      addressBlock.top +
      addressBlock.height -
      0.145 * RESOLUTION -
      +addressBlockText.getScaledWidth() / 2;
    objs.push(addressBlockText);
  }

  return objs;
}
