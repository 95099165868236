import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SearchableDataTable from "../../shared/SearchableDataTable";
import { fetchClient } from "../../helpers/fetchClient";
import { PricingModel, Pricing, Size } from "./models/PricingPlanModel";
import { TableColumn } from "react-data-table-component";
import LoadingWrapper from "../../shared/LoadingWrapper";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  TextField,
  Paper,
  Grid,
  Select,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Container,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import useGetData from "../../hooks/dataFetchers/useGetData";

interface URLParams {
  priceClass: string;
}

const Pricings: React.FC = () => {
  const params = useParams<URLParams>();

  const {
    data: pricingModel,
    isLoading: loading,
    error,
    updateData: setPricingModel,
  } = useGetData<PricingModel>(
    `/api/pricing/pricing-for-plan/${params.priceClass}`
  );

  const [activePricing, setActivePricing] = useState<Pricing>({
    inventoryCD: "",
    breakQty: 0,
    priceClass: params.priceClass,
    price: 0.0,
    size: "",
    mailClass: "",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);

  function handleDelete() {
    if (pricingModel) {
      fetchClient.post({
        path: `/pricing/pricing-for-plan/remove`,
        data: activePricing,
        onSuccess: () => {
          setPricingModel({
            ...pricingModel,
            pricings: pricingModel.pricings.filter(
              (x) => x.priceClass === activePricing.priceClass
            ),
          });
          setDeleteModalOpen(false);
        },
      });
    }
  }

  function handleChange(
    e: React.ChangeEvent<
      | HTMLInputElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >
  ) {
    if (e.target.name) {
      setActivePricing({
        ...activePricing,
        [e.target.name]: e.target.value,
      });
    }
  }

  function handleSelectChange(e: SelectChangeEvent) {
    if (e.target.name) {
      setActivePricing({
        ...activePricing,
        [e.target.name]: e.target.value,
      });
    }
  }

  function handleAdd() {
    if (pricingModel) {
      fetchClient.post({
        path: `/pricing/pricing-for-plan/add`,
        data: activePricing,
        onSuccess: (data: Pricing) =>
          setPricingModel({
            ...pricingModel,
            pricings: [...pricingModel.pricings, data],
          }),
      });
      setAddModalOpen(false);
    }
  }

  function handleUpdate() {
    if (pricingModel) {
      fetchClient.put({
        path: `/pricing/pricing-for-plan/update`,
        data: activePricing,
        onSuccess: (data: Pricing) =>
          setPricingModel({
            ...pricingModel,
            pricings: [...pricingModel.pricings, data],
          }),
      });
      setEditModalOpen(false);
    }
  }

  function handleDeleteOpen(pricing: Pricing) {
    setActivePricing(pricing);
    setDeleteModalOpen(!deleteModalOpen);
  }

  function handleEditOpen(pricing: Pricing) {
    setActivePricing(pricing);
    setEditModalOpen(!editModalOpen);
  }

  function handleAddOpen(pricing: Pricing) {
    setActivePricing(pricing);
    setAddModalOpen(!addModalOpen);
  }

  function closeModal() {
    setDeleteModalOpen(false);
    setEditModalOpen(false);
    setAddModalOpen(false);
  }

  const columns: TableColumn<Pricing>[] = [
    {
      name: "Inventory CD",
      selector: (row) => row.inventoryCD,
      sortable: true,
    },
    {
      name: "Break Qty",
      selector: (row) => row.breakQty,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price.toFixed(4),
      sortable: true,
    },
    {
      name: "Size",
      selector: (row) => row.size,
      sortable: true,
    },
    {
      name: "Mail Class",
      selector: (row) => row.mailClass,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          <IconButton color="primary" onClick={() => handleEditOpen(row)}>
            <i className="fas fa-edit"></i>
          </IconButton>{" "}
          <IconButton color="warning" onClick={() => handleDeleteOpen(row)}>
            <i className="fas fa-trash"></i>
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Container sx={{ pt: 8, pb: 8 }}>
      <LoadingWrapper loading={loading} height={600} container={Paper}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Button variant="outlined" color="primary" href="/pricing">
              Go Back
            </Button>{" "}
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleAddOpen({
                  inventoryCD: "",
                  breakQty: 0,
                  priceClass: params.priceClass,
                  price: 0.0,
                  size: "",
                  mailClass: "",
                })
              }
            >
              Add New Pricing
            </Button>
          </Grid>
        </Grid>
        {pricingModel && (
          <SearchableDataTable
            tableTitle={`Pricing for Price Class ${params.priceClass}`}
            searchableColumns={[
              "inventoryCD",
              "breakQty",
              "price",
              "size",
              "mailClass",
            ]}
            columns={columns}
            data={pricingModel.pricings}
          />
        )}
        <Dialog
          open={deleteModalOpen || addModalOpen || editModalOpen}
          onClose={closeModal}
        >
          <DialogContent>
            {deleteModalOpen && (
              <Box>Are you sure you want to delete this pricing?</Box>
            )}
            {(addModalOpen || editModalOpen) && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  Invetory CD
                  <TextField
                    required
                    name="inventoryCD"
                    label="Required"
                    value={activePricing?.inventoryCD}
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  Break Qty
                  <TextField
                    required
                    name="breakQty"
                    type="number"
                    value={activePricing?.breakQty}
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  Price
                  <TextField
                    required
                    name="price"
                    label="Required"
                    type="number"
                    value={activePricing?.price}
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="size-label">Size</InputLabel>
                    <Select
                      labelId="size-label"
                      id="size-select"
                      name="size"
                      value={activePricing?.size}
                      fullWidth
                      label="Size"
                      onChange={handleSelectChange}
                    >
                      {pricingModel?.sizes.map((size) => (
                        <MenuItem
                          key={size.sizeInfoSize}
                          value={size.sizeInfoSize}
                        >
                          {size.sizeLabel}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="mail-class-label">Mail Class</InputLabel>
                    <Select
                      labelId="mail-class-label"
                      id="mail-class-select"
                      value={activePricing?.mailClass}
                      fullWidth
                      name="mailClass"
                      label="Mail Class"
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="FirstClass">First Class</MenuItem>
                      <MenuItem value="Standard">Standard</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            {deleteModalOpen && (
              <Button
                onClick={handleDelete}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
            )}
            {editModalOpen && (
              <Button
                onClick={handleUpdate}
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            )}
            {addModalOpen && (
              <Button onClick={handleAdd} variant="contained" color="primary">
                Create
              </Button>
            )}
            <Button color="primary" onClick={closeModal}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </LoadingWrapper>
    </Container>
  );
};

export default Pricings;
