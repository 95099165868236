import { useState } from "react";
import Design, { DesignTag } from "../../Designs/models/Design";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

interface AdminDesignerTagsProps {
  open: boolean;
  design: Design;
  onClose: () => void;
  onSave: (occasions: string[], industries: string[]) => void;
}

const AdminDesignerTags = ({
  design,
  onSave,
  onClose,
  open,
}: AdminDesignerTagsProps) => {
  const [occasions, setOccasions] = useState<string[]>(
    design.tags.filter((x) => x.tagGroup === "Occasion").map((x) => x.tagName)
  );
  const [industries, setIndustries] = useState<string[]>(
    design.tags.filter((x) => x.tagGroup === "Industry").map((x) => x.tagName)
  );

  const [occasionInput, setOccasionInput] = useState("");

  const [industryInput, setIndustryInput] = useState("");

  function handleSave() {
    onSave(occasions, industries);
  }

  const { data: tags, isLoading } =
    useGetData<DesignTag[]>(`/api/designer/tags`);

  function addOccassion() {
    const alreadyTagged = Boolean(
      occasions.find((x) => x.toLowerCase() === occasionInput.toLowerCase())
    );
    const existsInDb = tags?.find(
      (x) =>
        x.tagGroup === "Occasion" &&
        x.tagName.toLowerCase() === occasionInput.toLowerCase()
    );
    setOccasionInput("");
    if (alreadyTagged) return;
    if (existsInDb) {
      setOccasions([...occasions, existsInDb.tagName]);
    } else setOccasions([...occasions, occasionInput]);
  }

  function addIndustry() {
    const alreadyTagged = Boolean(
      industries.find((x) => x.toLowerCase() === industryInput.toLowerCase())
    );
    const existsInDb = tags?.find(
      (x) =>
        x.tagGroup === "Industry" &&
        x.tagName.toLowerCase() === industryInput.toLowerCase()
    );
    setIndustryInput("");
    if (alreadyTagged) return;
    if (existsInDb) {
      setIndustries([...industries, existsInDb.tagName]);
    } else setIndustries([...industries, industryInput]);
  }

  function renderTags(tags: string[], onRemove: (tag: string) => void) {
    return tags.map((tag) => (
      <Box
        key={tag}
        sx={{
          mr: 1,
          background: "#eee",
          p: 1,
          borderRadius: "6px",
          fontSize: "14px",
        }}
      >
        {tag}{" "}
        <IconButton size="small" color="error" onClick={() => onRemove(tag)}>
          <i className="fas fa-times"></i>
        </IconButton>
      </Box>
    ));
  }
  if (!tags) return null;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Tags</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Occasions</Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", width: "100%", mb: 2 }}
          >
            <Box sx={{ width: "50%" }}>
              <Autocomplete
                size="small"
                autoComplete
                freeSolo
                options={tags
                  .filter((x) => x.tagGroup === "Occasion")
                  .map((x) => x.tagName)}
                value={occasionInput}
                onInputChange={(_, value) => setOccasionInput(value)}
                onChange={(_, value) => value && setOccasionInput(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Add Occasions" />
                )}
              />
            </Box>
            <Box sx={{ ml: 2 }}>
              <Button onClick={addOccassion} variant="contained">
                Add
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {renderTags(occasions, (tag) => {
              setOccasions(occasions.filter((x) => x !== tag));
            })}
          </Box>
        </Box>
        <Box>
          <Typography variant="h6">Industries</Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", width: "100%", mb: 2 }}
          >
            <Box sx={{ width: "50%" }}>
              <Autocomplete
                size="small"
                autoComplete
                freeSolo
                options={tags
                  .filter((x) => x.tagGroup === "Industry")
                  .map((x) => x.tagName)}
                value={industryInput}
                onInputChange={(_, value) => setIndustryInput(value)}
                onChange={(_, value) => value && setIndustryInput(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Add Industries" />
                )}
              />
            </Box>
            <Box sx={{ ml: 2 }}>
              <Button onClick={addIndustry} variant="contained">
                Add
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {renderTags(industries, (tag) => {
              setIndustries(industries.filter((x) => x !== tag));
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminDesignerTags;
