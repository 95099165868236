import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import React, { useState } from "react";
import DesignCreationModel, {
  DesignCreationField,
  DesignSize,
} from "../models/DesignCreationModel";
import Fuse from "fuse.js";
import logger from "../../../helpers/logger";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import IDesignCategory from "../../Designs/models/IDesignCategory";
import { getAsync } from "../../../helpers/asyncFetch";
import NewCategoryModal from "../Designs/Design/components/NewCategoryModal";
import NewSubcategoryModal from "../Designs/Design/components/NewSubcategoryModal";
import IDesignSubcategory from "../../Designs/models/IDesignSubcategory";

interface DesignFormProps {
  isTemplate: boolean;
  formData: DesignCreationModel;
  onSubmit: (e: React.FormEvent) => void;
  onGeneralChange: (
    e:
      | SelectChangeEvent<string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFieldChange: (
    e:
      | SelectChangeEvent<string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  onAutoCompleteFieldChange: (value: string | null, index: number) => void;
  onFieldOptionChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldIndex: number,
    optionIndex: number
  ) => void;
  onNewFieldClick: () => void;
  onNewFieldOptionClick: (fieldIndex: number) => void;
  onRemoveFieldClick: (index: number) => void;
  onRemoveFieldOptionClick: (fieldIndex: number, optionIndex: number) => void;
  onMandatoryClick: (index: number) => void;
  designFields: DesignCreationField[];
  designSizes: DesignSize[];
  disabled: boolean;
  onNewCategory: (categoryID: number) => void;
  onNewSubcategory: (subcategoryID: number) => void;
}

const DesignForm: React.FC<DesignFormProps> = ({
  formData,
  onSubmit,
  onGeneralChange,
  onFieldChange,
  onFieldOptionChange,
  onNewFieldClick,
  onNewFieldOptionClick,
  onRemoveFieldClick,
  onRemoveFieldOptionClick,
  onMandatoryClick,
  designFields,
  onAutoCompleteFieldChange,
  designSizes,
  disabled,
  isTemplate,
  onNewCategory,
  onNewSubcategory,
}: DesignFormProps) => {
  const {
    data: designCategories,
    updateData: setDesignCategories,
    error,
  } = useGetData<IDesignCategory[]>(`/api/design-category`, []);
  const [state, setState] = useState({
    newCategoryOpen: false,
    newSubcategoryOpen: false,
  });

  const currentCategory = designCategories?.find(
    (x) => x.categoryID === formData.categoryID
  );

  function handleNewCategoryClose() {
    setState({ ...state, newCategoryOpen: false });
  }

  function handleNewSubcategoryClose() {
    setState({ ...state, newSubcategoryOpen: false });
  }

  function handleCategoryAdd(category: IDesignCategory) {
    if (designCategories) {
      const updated = [...designCategories, category].sort((a, b) =>
        a.categoryName.localeCompare(b.categoryName)
      );
      setDesignCategories(updated);
      onNewCategory(category.categoryID);
    }
  }

  function handleSubcategoryAdd(subcategory: IDesignSubcategory) {
    if (designCategories) {
      const index = designCategories.findIndex(
        (x) => x.categoryID === subcategory.categoryID
      );
      if (index !== -1) {
        const updated = [
          ...designCategories[index].subcategories,
          subcategory,
        ].sort((a, b) => a.subcategoryName.localeCompare(b.subcategoryName));
        designCategories[index].subcategories = updated;
        setDesignCategories([...designCategories]);
        onNewSubcategory(subcategory.subcategoryID);
      }
    }
  }
  /**
   * Render
   */

  return (
    <Box>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={onGeneralChange}
              disabled={disabled}
              label="Design Name"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <TextField required fullWidth variant="outlined" name="size" value={formData.size} onChange={onGeneralChange} label="Design Size" /> */}
            <FormControl fullWidth variant="outlined" disabled={disabled}>
              <InputLabel>Size</InputLabel>
              <Select
                required
                name="size"
                onChange={onGeneralChange}
                value={formData.size}
                fullWidth
                disabled={disabled}
              >
                <MenuItem value="" disabled>
                  Select an option
                </MenuItem>
                {designSizes.map((size) => (
                  <MenuItem key={size.sizeInfoSize} value={size.sizeInfoSize}>
                    {size.sizeLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {error.hasError && (
              <FormHelperText error>
                There was an error loading the categories
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              required
              disabled={disabled}
              fullWidth
              variant="outlined"
              name="documentID"
              value={formData.documentID}
              onChange={onGeneralChange}
              label="UProduce Document ID"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              required
              disabled={disabled}
              fullWidth
              variant="outlined"
              name="nimbleDesignID"
              value={formData.nimbleDesignID}
              onChange={onGeneralChange}
              label="Nimble Design ID"
            />
          </Grid>
          {isTemplate && (
            <React.Fragment>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Design Category (optional)</InputLabel>
                  <Select
                    value={
                      formData.categoryID ? formData.categoryID.toString() : ""
                    }
                    onChange={onGeneralChange}
                    name="categoryID"
                    label="Design Category (optional)"
                  >
                    <MenuItem value="">No Category</MenuItem>
                    {designCategories?.map((category) => (
                      <MenuItem
                        key={category.categoryID}
                        value={category.categoryID.toString()}
                      >
                        {category.categoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={"auto"}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setState({ ...state, newCategoryOpen: true })}
                >
                  Add New Category
                </Button>
              </Grid>
              {formData.categoryID && (
                <React.Fragment>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <InputLabel>Design Subcategory (optional)</InputLabel>
                      <Select
                        value={
                          formData.subcategoryID
                            ? formData.subcategoryID.toString()
                            : ""
                        }
                        onChange={onGeneralChange}
                        name="subcategoryID"
                        label="Design Subcategory (optional)"
                      >
                        <MenuItem value="">No Subcategory</MenuItem>
                        {currentCategory &&
                          currentCategory.subcategories.map((subcategory) => (
                            <MenuItem
                              key={subcategory.subcategoryID}
                              value={subcategory.subcategoryID.toString()}
                            >
                              {subcategory.subcategoryName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={"auto"}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setState({ ...state, newSubcategoryOpen: true })
                      }
                    >
                      Add New Subcategory
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          <Grid item container spacing={2} xs={12} alignItems="center">
            {formData.designFields?.map((field, index) => (
              <React.Fragment key={index + "-field"}>
                <Grid item xs={12}>
                  <h5>Field {index + 1}</h5>
                </Grid>
                <Grid item md={3}>
                  <Autocomplete
                    value={field.fieldKey}
                    // eslint-disable-next-line @typescript-eslint/ban-types
                    /* tslint:disable-next-line */
                    freeSolo
                    onChange={(event: any, newValue: string | null) => {
                      newValue !== null &&
                        onAutoCompleteFieldChange(
                          newValue.toLowerCase(),
                          index
                        );
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        label="Plan File Field Key"
                        name="fieldKey"
                        onChange={(
                          e: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => {
                          onFieldChange(e, index);
                        }}
                      />
                    )}
                    options={designFields.map((field) => field.fieldKey)}
                    disabled={disabled}
                    filterOptions={(options: string[]) => {
                      const fuse = new Fuse(options);
                      const results: string[] = fuse
                        .search(field.fieldKey)
                        .map((option) => option.item);
                      return results;
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    name="fieldLabel"
                    value={field.fieldLabel}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => onFieldChange(e, index)}
                    label="Friendly Label"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControl fullWidth variant="outlined" disabled={disabled}>
                    <InputLabel>Field Type</InputLabel>
                    <Select
                      required
                      name="fieldType"
                      onChange={(e) => onFieldChange(e, index)}
                      value={field.fieldType}
                      fullWidth
                      disabled={disabled}
                    >
                      <MenuItem value="" disabled>
                        Select an option
                      </MenuItem>
                      <MenuItem value="text">Text</MenuItem>
                      <MenuItem value="image">Image</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabled}
                        name="mandatory"
                        checked={field.mandatory}
                        onClick={() => onMandatoryClick(index)}
                      />
                    }
                    label="Required?"
                  />
                </Grid>
                <Grid item md={1}>
                  <a
                    role="button"
                    href="javascript:void(0)"
                    onClick={(e) => {
                      e.preventDefault();
                      onRemoveFieldClick(index);
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => onNewFieldOptionClick(index)}
                    size="small"
                    color="primary"
                    disabled={disabled}
                    type="button"
                  >
                    Add Field Option
                  </Button>
                </Grid>
                {field.options?.length > 0 && (
                  <Grid container spacing={2} item xs={12}>
                    {field.options.map((option, index2) => (
                      <React.Fragment key={index2 + "-option"}>
                        <Grid item xs={12}>
                          <h6>Field Option {index2 + 1}</h6>
                        </Grid>
                        <Grid item md={5}>
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="optionValue"
                            value={option.optionValue}
                            onChange={(
                              e: React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >
                            ) => onFieldOptionChange(e, index, index2)}
                            label="Option Value"
                            size="small"
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item md={7}>
                          <Button
                            onClick={() =>
                              onRemoveFieldOptionClick(index, index2)
                            }
                            type="button"
                            color="primary"
                            disabled={disabled}
                          >
                            Remove Option
                          </Button>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={onNewFieldClick}
              variant="outlined"
              type="button"
              color="primary"
            >
              Add New Field
            </Button>{" "}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={disabled}
            >
              Save Design
            </Button>
          </Grid>
        </Grid>
      </form>
      <NewCategoryModal
        isOpen={state.newCategoryOpen}
        onClose={handleNewCategoryClose}
        onCategoryAdd={handleCategoryAdd}
        categories={designCategories ?? []}
      />
      {currentCategory && (
        <NewSubcategoryModal
          isOpen={state.newSubcategoryOpen}
          onClose={handleNewSubcategoryClose}
          onSubcategoryAdd={handleSubcategoryAdd}
          category={currentCategory}
        />
      )}
    </Box>
  );
};

export default DesignForm;
