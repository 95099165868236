export function getNumberFromString(val: string) {
  let value: string | number = val.toString();

  value = value.replaceAll(/[^0-9.\-]/g, "");

  value = Number(value);

  if (Number.isNaN(value)) {
    value = 0;
  } else {
    value = Math.floor(value);
  }

  return value;
}
