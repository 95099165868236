import React, { createContext, useReducer } from "react";

export const CanvasContext = createContext<fabric.Canvas | undefined>(
  undefined
);

export const CanvasDispatchContext = createContext<
  React.Dispatch<{
    type: string;
    payload: fabric.Canvas;
  }>
>(
  {} as React.Dispatch<{
    type: string;
    payload: fabric.Canvas;
  }>
);
const intialState: fabric.Canvas | undefined = undefined;

function canvasReducer(
  canvas: fabric.Canvas | undefined,
  action: { type: string; payload: fabric.Canvas }
) {
  if (action.payload) return action.payload;
  return canvas;
}

interface ICanvasProviderProps {
  children?: React.ReactFragment;
}

const CanvasProvider = ({ children }: ICanvasProviderProps) => {
  const [canvas, dispatch] = useReducer(
    canvasReducer,
    intialState as fabric.Canvas | undefined
  );

  return (
    <CanvasContext.Provider value={canvas}>
      <CanvasDispatchContext.Provider value={dispatch}>
        {children}
      </CanvasDispatchContext.Provider>
    </CanvasContext.Provider>
  );
};

export default CanvasProvider;
