import { IBulletedList, IListItem, Textbox } from "fabric";
import tw, { css } from "twin.macro";
import { useEffect, useState } from "react";
import toolbarStyles from "./toolbarStyles";
import ColorPicker from "../shared/ColorPicker/ColorPicker";
import { ReactComponent as TextIcon } from "../../../../assets/images/designer-svg/TextColorIcon.svg";
import { ReactComponent as ListIcon } from "../../../../assets/images/designer-svg/List.svg";
import FontSelector from "../../Fonts/FontSelector";
import IncrementInput from "../shared/NuDesignerInputs/IncrementInput";
import { ITextOptions } from "fabric/fabric-impl";
import DuplicateButton from "./DuplicateButton";
import DeleteButton from "./DeleteButton";
import {
  bulletOptions,
  bulletSizeDivisor,
  updateBullets,
  updateListAndListItems,
} from "../../Canvas/functions/createBulletedList";
import { DesignerSelect } from "../shared/NuDesignerInputs/DesignerSelect";
import { SelectOption } from "../shared/DesignerInput/SelectOption";
import {
  convertPixelsToPoints,
  convertPointsToPixels,
} from "../../Canvas/functions/convertNumbers";

interface IBulletedListTopbarProps {
  list: IBulletedList;
  openFonts: () => void;
  openIcons: () => void;
}

const BulletedListTopbar = ({ list, openFonts }: IBulletedListTopbarProps) => {
  const [fontSettings, setFontSettings] = useState(list.__fontSettings);
  const [bulletSettings, setBulletSettings] = useState(list.__bulletSettings);

  function updateSettingsState() {
    setFontSettings({
      ...list.__fontSettings,
      fontSize: list.__fontSettings.fontSize
        ? convertPixelsToPoints(list.__fontSettings.fontSize)
        : 32,
    });
    setBulletSettings({
      ...list.__bulletSettings,
      fontSize: list.__bulletSettings.fontSize
        ? convertPixelsToPoints(list.__bulletSettings.fontSize)
        : 32,
    });
  }

  function handleFontSizeChange(val: number) {
    const newFontSettings = { ...list.__fontSettings, fontSize: val };
    const newBulletSettings = {
      ...list.__bulletSettings,
      fontSize: val / bulletSizeDivisor,
    };
    setFontSettings(newFontSettings);
    setBulletSettings(newBulletSettings);
    updateListAndListItems(list, "text", {
      fontSize: convertPointsToPixels(val),
    });
  }

  function handleBulletChange(property: string, value: string) {
    const bullet = bulletOptions.find((x) => x.unicode === value);
    if (bullet) updateBullets(list, value, bullet.hexUni);
  }

  function getBulletOptions() {
    return bulletOptions.map((option) => {
      return (
        <SelectOption value={option.unicode} key={option.unicode}>
          <div
            css={css`
              &:after {
                content: "\\${option.unicode}";
                font-family: "FontAwesome";
                font-size: 13px;
                font-weight: ${option.weight};
              }
            `}
          />
        </SelectOption>
      );
    });
  }

  function getTextItem() {
    return (list._objects[0] as IListItem)._objects.find(
      (x) => x.name === "text"
    ) as Textbox;
  }

  useEffect(updateSettingsState, [list]);

  return (
    <div>
      <div css={toolbarStyles.section}>
        <div css={toolbarStyles.property}>
          <ColorPicker
            selectedObject={list}
            type="fill"
            listProperty="text"
            swatchOnly
            swatchIcon={TextIcon}
          />
        </div>

        <div css={toolbarStyles.property}>
          <ColorPicker
            selectedObject={list}
            type="fill"
            listProperty="bullet"
            swatchOnly
            swatchIcon={ListIcon}
          />
        </div>

        <div css={toolbarStyles.seperator}></div>
        <FontSelector
          textObject={getTextItem()}
          onClick={openFonts}
          bulletedList={list}
        />
        <div css={toolbarStyles.property}>
          <IncrementInput
            value={fontSettings.fontSize ?? 32}
            onChange={(p, val) => handleFontSizeChange(val)}
            min={1}
            property="fontSize"
            height={7}
            width={48}
          />
        </div>
        <div css={toolbarStyles.property}>
          <DesignerSelect
            name="bulletType"
            onChange={handleBulletChange}
            value={list.__bullet.value.toString()}
            width={65}
          >
            {getBulletOptions()}
          </DesignerSelect>
        </div>
        <div css={toolbarStyles.seperator}></div>
        <div css={toolbarStyles.property}>
          <DuplicateButton selectedObject={list} />
        </div>
        <div css={toolbarStyles.property}>
          <DeleteButton selectedObject={list} />
        </div>
      </div>
    </div>
  );
};

export default BulletedListTopbar;
