import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useContext, ChangeEvent } from "react";
import getMailClassLabel from "../../../../../helpers/getMailClassLabel";
import { numberWithCommas } from "../../../../../LegacyReactSite/helpers";
import NewCarousel from "../../../../../shared/Carousel/NewCarousel";
import Design from "../../../../Designs/models/Design";
import ILetterOptions from "../../models/ILetterOptions";
import { OrderRequestContext } from "../../PlaceAnOrder";
import ConfigureDesignVariables from "./components/ConfigureDesignVariables";
import DesignPreview from "./components/DesignPreview";
import { ReturnAddress } from "../../models/OrderRequest";
import StateSelector from "../../../../../shared/StateSelector";
import { LetterFoldTypes } from "../../../../../postcard-designer/state/slices/letterSettings";

const ConfigureDesign: React.FC<{
  design: Design;
  errors: Record<string, boolean>;
  letterOptions?: ILetterOptions;
}> = ({ design, errors, letterOptions }) => {
  /**
   * State Objects and Refs
   */
  const orderRequestStore = useContext(OrderRequestContext);
  const orderRequest = orderRequestStore.orderRequest;
  const isLetter = design.printPDFSource
    ? design.printPDFSource.toLowerCase().includes("letter") ||
      design.printPDFSource.toLowerCase() === "las"
    : false;
  const isBrochure = design.printPDFSource
    ? design.printPDFSource?.toLowerCase().includes("bro")
    : false;
  /**
   * Component Methods
   */

  function handleExtRefChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    orderRequestStore.changeExtRefNbr(e.target.value);
  }

  function handleFoldTypeChange(e: SelectChangeEvent) {
    orderRequestStore.changeFoldType(e.target.value);
  }

  function handleReturnAddressChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    orderRequestStore.updateReturnAddress(
      e.target.name as keyof ReturnAddress,
      e.target.value
    );
  }

  function handleMailClassChange(e: SelectChangeEvent<string>) {
    orderRequestStore.changeMailClass(e.target.value);
  }

  const letterConfig = orderRequest.orderconfig.letterConfig;
  const activeEnvelope =
    letterConfig && letterConfig.envelopeID && letterOptions
      ? letterOptions.customEnvelopes.find(
          (x) => x.envelopeID === letterConfig.envelopeID
        )
      : undefined;
  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <div className="configureDesign">
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography variant="h5" sx={{ mb: 3 }}>
            Configure Your Design
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: 500 }}>
              Select A Mail Class
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              The postage class for the order. The final cost of your order will
              be determined by the mail class you select.
            </Typography>

            <FormControl fullWidth error={errors.mailClass} size="small">
              <InputLabel id="mail-class-label">Mail Class</InputLabel>
              <Select
                labelId="mail-class-label"
                label="Mail Class"
                value={orderRequest.orderconfig.mailClass}
                onChange={handleMailClassChange}
              >
                {design.mailClasses.map((mailClass) => (
                  <MenuItem
                    key={mailClass.inventoryCD}
                    value={mailClass.mailClassID}
                  >
                    {getMailClassLabel(mailClass.mailClassID)}
                  </MenuItem>
                ))}
              </Select>
              {errors.mailClass && (
                <FormHelperText>You must select a mail class</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: 500 }}>
              External Reference (Optional)
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              If you have an ID or reference number in your CRM that you&apos;d
              like to use for this order, you can enter that here.{" "}
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="extRefNbr"
              label="External Reference"
              value={orderRequest.extRefNbr}
              onChange={handleExtRefChange}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: 500 }}>
              Return Address (Optional)
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              If you would like include a return address on your mail pieces,
              please enter it here.{" "}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  size="small"
                  name="firstName"
                  label="Full Name"
                  value={orderRequest.returnAddress?.firstName ?? ""}
                  onChange={handleReturnAddressChange}
                  error={Boolean(errors.returnaddressfirstname)}
                />
                {errors.returnaddressfirstname && (
                  <FormHelperText error>
                    You must provide a valid name or company
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  size="small"
                  name="company"
                  label="Company Name"
                  value={orderRequest.returnAddress?.company ?? ""}
                  onChange={handleReturnAddressChange}
                  error={Boolean(errors.returnaddresscompany)}
                />
                {errors.returnaddresscompany && (
                  <FormHelperText error>
                    You must provide a valid name or company
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={8}>
                <TextField
                  fullWidth
                  size="small"
                  label="Address"
                  name="address"
                  value={orderRequest.returnAddress?.address ?? ""}
                  onChange={handleReturnAddressChange}
                  error={Boolean(errors.returnaddress)}
                />
                {errors.returnaddress && (
                  <FormHelperText error>
                    You must provide a valid return address
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Address 2"
                  name="address2"
                  value={orderRequest.returnAddress?.address2 ?? ""}
                  onChange={handleReturnAddressChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="City"
                  name="city"
                  value={orderRequest.returnAddress?.city ?? ""}
                  onChange={handleReturnAddressChange}
                  error={Boolean(errors.returnaddresscity)}
                />
                {errors.returnaddresscity && (
                  <FormHelperText error>
                    You must provide a valid city
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <StateSelector
                  value={orderRequest.returnAddress?.state ?? ""}
                  onChange={handleReturnAddressChange}
                  size="small"
                  label="State"
                  name="state"
                  fullWidth
                  error={Boolean(errors.returnaddressstate)}
                />
                {errors.returnaddressstate && (
                  <FormHelperText error>
                    You must provide a valid state
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Zip Code"
                  name="zipCode"
                  value={orderRequest.returnAddress?.zipCode ?? ""}
                  onChange={handleReturnAddressChange}
                  error={Boolean(errors.returnaddresszipcode)}
                />
                {errors.returnaddresszipcode && (
                  <FormHelperText error>
                    You must provide a valid zip code
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
          {isLetter && (
            <Box>
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 500 }}>Print Color</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Select whether you would like to print your letter in color or
                  black and white.{" "}
                </Typography>
                <FormControl fullWidth error={errors.color} size="small">
                  <InputLabel>Print Color</InputLabel>

                  <Select
                    label="Print Color"
                    value={
                      letterConfig && letterConfig.color !== undefined
                        ? String(letterConfig.color)
                        : ""
                    }
                    name="color"
                    onChange={orderRequestStore.updateLetterConfig}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    <MenuItem value="false">Print in black and white</MenuItem>
                    <MenuItem value="true">Print in color</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 500 }}>Print Method</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Select whether you would like to print your letter double
                  sided or single sided.{" "}
                </Typography>
                <FormControl
                  fullWidth
                  error={errors.printOnBothSides}
                  size="small"
                >
                  <InputLabel>Print Method</InputLabel>

                  <Select
                    label="Print Method"
                    value={
                      letterConfig &&
                      letterConfig.printOnBothSides !== undefined
                        ? String(letterConfig.printOnBothSides)
                        : ""
                    }
                    name="printOnBothSides"
                    onChange={orderRequestStore.updateLetterConfig}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    <MenuItem value="true">Print double sided</MenuItem>
                    <MenuItem value="false">Print single sided</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 500 }}>Addressing</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Select whether you would like to print the addressing page
                  separate from your letter.{" "}
                </Typography>
                <FormControl
                  fullWidth
                  error={errors.insertAddressingPage}
                  size="small"
                >
                  <InputLabel>Addressing</InputLabel>

                  <Select
                    label="Addressing"
                    value={
                      letterConfig &&
                      letterConfig.insertAddressingPage !== undefined
                        ? String(letterConfig.insertAddressingPage)
                        : ""
                    }
                    name="insertAddressingPage"
                    onChange={orderRequestStore.updateLetterConfig}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    <MenuItem value="true">Print an addressing page</MenuItem>
                    <MenuItem value="false">
                      Do not print an addressing page
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 500 }}>Envelope Type</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Select the type of envelope you would like to use.{" "}
                </Typography>
                <FormControl fullWidth error={errors.envelopeType} size="small">
                  <InputLabel>Envelope Type</InputLabel>

                  <Select
                    label="Envelope Type"
                    value={
                      letterConfig && letterConfig.envelopeType
                        ? letterConfig.envelopeType
                        : ""
                    }
                    name="envelopeType"
                    onChange={orderRequestStore.updateLetterConfig}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    {(letterOptions?.envelopeTypes ?? []).map((envelopeType) => (
                        <MenuItem value={envelopeType.key} key={envelopeType.key}>
                            {envelopeType.label}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {letterConfig?.envelopeType === "Regular" && (
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Live Stamping
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    You can choose to have a live stamp placed on the envelope
                    instead of the standard printed postage.{" "}
                  </Typography>
                  <FormControl
                    fullWidth
                    error={errors.liveStamping}
                    size="small"
                  >
                    <InputLabel>Live Stamping</InputLabel>

                    <Select
                      label="Live Stamping"
                      value={
                        letterConfig && letterConfig.liveStamping !== undefined
                          ? String(letterConfig.liveStamping)
                          : ""
                      }
                      name="liveStamping"
                      onChange={orderRequestStore.updateLetterConfig}
                    >
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="true">Use live stamping</MenuItem>
                      <MenuItem value="false">
                        Do not use live stamping
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {letterConfig?.envelopeType === "Custom" && (
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ fontWeight: 500, mb: 2 }}>
                    Select Your Custom Envelope
                  </Typography>

                  <FormControl fullWidth error={errors.envelopeID} size="small">
                    <InputLabel>Custom Envelope</InputLabel>

                    <Select
                      label="Custom Envelope"
                      value={
                        letterConfig && letterConfig.envelopeID
                          ? letterConfig.envelopeID?.toString()
                          : ""
                      }
                      name="envelopeID"
                      onChange={orderRequestStore.updateLetterConfig}
                    >
                      <MenuItem value="">Select an option</MenuItem>
                      {letterOptions?.customEnvelopes.map((x) => (
                        <MenuItem
                          value={x.envelopeID.toString()}
                          key={x.envelopeID}
                        >
                          {x.friendlyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {letterConfig?.envelopeID && activeEnvelope && (
                    <FormHelperText>
                      <em>
                        You have {numberWithCommas(activeEnvelope.stockQty)}{" "}
                        envelopes remaining.
                      </em>
                    </FormHelperText>
                  )}
                </Box>
              )}
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 500 }}>Envelope Font (Optional)</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Select the font you would like to use for your envelope.{" "}
                </Typography>
                <FormControl fullWidth size="small">
                  <InputLabel>Envelope Font</InputLabel>
                  <Select
                    label="Envelope Font"
                    value={
                      letterConfig && letterConfig.envelopeFont
                        ? letterConfig.envelopeFont
                        : ""
                    }
                    name="envelopeFont"
                    onChange={orderRequestStore.updateLetterConfig}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    {(letterOptions?.envelopeFonts ?? []).map((x) => (
                        <MenuItem value={x} key={x}>
                            {x}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {letterConfig?.envelopeType !== "Regular" &&
                letterConfig?.color && (
                  <Box sx={{ mb: 2 }}>
                    <Typography sx={{ fontWeight: 500 }}>
                      Envelope Font Color
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Select the font color you would like to use for your
                      envelope.{" "}
                    </Typography>
                    <FormControl
                      fullWidth
                      error={errors.envelopeFontColor}
                      size="small"
                    >
                      <InputLabel>Envelope Font Color</InputLabel>

                      <Select
                        label="Envelope Font Color"
                        value={
                          letterConfig && letterConfig.envelopeFontColor
                            ? letterConfig.envelopeFontColor
                            : ""
                        }
                        name="envelopeFontColor"
                        onChange={orderRequestStore.updateLetterConfig}
                      >
                        <MenuItem value="">Select an option</MenuItem>
                        {letterOptions?.envelopeFontColors.map((x) => (
                          <MenuItem value={x} key={x}>
                            {x}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
            </Box>
          )}
          {isBrochure && (
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 500 }}>Fold Type</Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Select whether you would like a Bi Fold or Tri Fold brochure.{" "}
              </Typography>
              <FormControl fullWidth error={errors.color} size="small">
                <InputLabel>Fold Type</InputLabel>

                <Select
                  label="Print Color"
                  value={orderRequest.foldType}
                  name="foldType"
                  onChange={handleFoldTypeChange}
                >
                  <MenuItem value={LetterFoldTypes.TRIFOLD}>Tri Fold</MenuItem>
                  <MenuItem value={LetterFoldTypes.BIFOLD}>Bi Fold</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}

          <ConfigureDesignVariables design={design} errors={errors} />
        </Grid>
        <Grid item md={6}>
          <DesignPreview design={design} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfigureDesign;
