import { Box, Container } from "@mui/material";
import React, { ErrorInfo, ReactNode } from "react";
import { fetchClient } from "../helpers/fetchClient";

interface Props {
  children: ReactNode;
}

interface State {
  error: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const currentAddress = window.location.href;

    const req = {
      message: error.message,
      name: "React Error",
      stack: errorInfo.componentStack,
    };
    fetchClient.post({
      path: "/error-log",
      data: req,
      onSuccess: () => null,
    });

    this.setState({ error: true });
  }

  render() {
    if (this.state.error) {
      return (
        <Box className="app" sx={{ mb: 6, mt: 6 }}>
          <h1>Something went wrong.</h1>
          <p className="h3">Our team has been alerted.</p>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
