import eo from "date-fns/esm/locale/eo/index.js";
import {
  IEvent,
  IShadowOptions,
  IText,
  Path,
  Rect,
  Shadow,
  Object as IObject,
  Image,
  Circle,
  Ellipse,
  Group,
  Polygon,
  Textbox,
} from "fabric/fabric-impl";
import { fabric } from "fabric";
import { useContext, useEffect, useRef, useState } from "react";

import { CanvasContext } from "../../state/contexts/CanvasContext";
import IToolSettings from "../../state/models/IToolSettings";
import useHistory from "../useHistory";
import RESOLUTION from "../../constants/RESOLUTION";
import { IImageGroup } from "../useImageMasking/useImageMasking";
import {
  getPositionInInches,
  getSizeInInches,
} from "../../features/Canvas/functions/dimensionHelpers";
import {
  ICanvasObject,
  IObjectProperties,
  SelectedObjectContext,
  SelectedObjectDispatchContext,
  propertiesTemplate,
} from "../../state/contexts/SelectedObjectContext";
import updateObjectProperty from "./functions/updateObjectProperty";
import { useDesignerDispatch, useDesignerSelector } from "../../state/store";
import {
  selectObjectProperties,
  updateManyProperties,
  updateSingleProperty,
} from "../../state/slices/objectProperties";

/**
 * What this hook should do
 * 1. Collect the selected object and return it
 * 2. Update properties shared across the properties window and the top toolbar
 * 3. Update the history any time one of the properties changes via keyinput
 * 4. Event listeners that track object changes so the mouse interaction and keyboard interaction remain synced.
 * 5. Clean up a lot of bad code.
 */

const useProperties = (selectedObject?: IObject) => {
  const canvas = useContext(CanvasContext);
  const { updateHistory } = useHistory();
  const selectedObjectRef = useRef<IObject>();

  const properties = useDesignerSelector(selectObjectProperties);
  const dispatch = useDesignerDispatch();

  const changeDebounceRef = useRef<NodeJS.Timeout>();

  function handlePropertyChange(property: string, value: string) {
    if (selectedObject && canvas) {
      if (!(property in properties)) {
        return;
      }
      if (
        !selectedObject.name?.includes("bulletedList") &&
        (property === "width" || property === "height") &&
        (selectedObject.type === "group" || selectedObject.type === "image")
      ) {
        const width = properties.width;
        const height = properties.height;
        if (property === "width") {
          const change = parseFloat(value) / (parseFloat(width) ?? 1);
          dispatch(
            updateManyProperties({
              width: value,
              height: (parseFloat(height) * change).toFixed(3),
            })
          );
        }
        if (property === "height") {
          const change = parseFloat(value) / (parseFloat(height) ?? 1);
          dispatch(
            updateManyProperties({
              width: (parseFloat(width) * change).toFixed(3),
              height: value,
            })
          );
        }

        //updateProperties({ width: value, height: value });
      } else {
        //updateProperties({ [property]: value });
        dispatch(
          updateSingleProperty({
            property: property as keyof IObjectProperties,
            value,
          })
        );
      }
      updateObjectProperty(
        selectedObject,
        property as keyof IObjectProperties,
        value
      );
      canvas.renderAll();
      if (changeDebounceRef.current) clearTimeout(changeDebounceRef.current);
      changeDebounceRef.current = setTimeout(() => {
        updateHistory([], "property manually changed");
      }, 300);
    }
  }

  function syncSettings() {
    if (
      selectedObjectRef.current &&
      selectedObjectRef.current.scaleX &&
      selectedObjectRef.current.scaleY
    ) {
      const updates = getObjectProperties(
        selectedObjectRef.current as ICanvasObject
      );

      const keys = Object.keys(updates) as (keyof Partial<IObjectProperties>)[];
      keys.forEach((key) => {
        if (updates[key]) {
          const input = document.querySelector(`input[name=${key}]`) as
            | HTMLInputElement
            | undefined;
          if (input) {
            input.value = updates[key] as string;
          }
        }
      });
      if (changeDebounceRef.current) clearTimeout(changeDebounceRef.current);
      changeDebounceRef.current = setTimeout(() => {
        dispatch(updateManyProperties(updates));
      }, 500);
    }
  }

  function clearObjectListeners() {
    if (selectedObjectRef.current) {
      selectedObjectRef.current.off("moving", syncSettings);
      selectedObjectRef.current.off("scaling", syncSettings);
      selectedObjectRef.current.off("skewing", syncSettings);
      selectedObjectRef.current.off("rotating", syncSettings);
    }
  }

  function setupObjectEventListeners() {
    clearObjectListeners();
    if (selectedObject) {
      selectedObject?.on("moving", syncSettings);
      selectedObject?.on("scaling", syncSettings);
      selectedObject?.on("skewing", syncSettings);
      selectedObject?.on("rotating", syncSettings);
    }
  }

  function handleNewSelectedObject(object: IObject) {
    const properties: IObjectProperties = {
      ...propertiesTemplate,
      ...getObjectProperties(object as ICanvasObject),
    };
    //updateSelectedObject({ object, properties });
    dispatch(updateManyProperties(properties));
  }

  function syncRefAndState() {
    if (selectedObject) {
      handleNewSelectedObject(selectedObject);
      setupObjectEventListeners();
      selectedObjectRef.current = selectedObject;
    } else selectedObjectRef.current = undefined;
  }

  useEffect(syncRefAndState, [selectedObject]);
  return {
    selectedObject,
    properties,
    updateProperties: handlePropertyChange,
  };
};

function roundProperty(property?: number, round = 3) {
  if (!property) return undefined;
  if (property % 1 === 0) return property.toString();
  return property.toFixed(round);
}

function getObjectProperties(
  object: ICanvasObject
): Partial<IObjectProperties> {
  let properties: Partial<IObjectProperties> = {};
  const background = object.canvas?._objects.find(
    (x) => x.name === "background"
  );
  if (!background) return properties;
  properties = {
    width: (object.getScaledWidth() / RESOLUTION).toFixed(3),
    height: (object.getScaledHeight() / RESOLUTION).toFixed(3),
    left: (((object.left ?? 0) - (background.left ?? 0)) / RESOLUTION).toFixed(
      3
    ),
    top: (((object.top ?? 0) - (background.top ?? 0)) / RESOLUTION).toFixed(3),
    angle:
      object.angle && object.angle >= 360
        ? roundProperty(object.angle - 360, 0)
        : object.angle
        ? roundProperty(object.angle, 0)
        : "0",
    skewX: roundProperty(object.skewX, 1),
    skewY: roundProperty(object.skewY, 1),
    strokeWidth: object.strokeWidth?.toString(),
    lineCap: object.strokeLineCap?.toString(),
    lineJoin: object.strokeLineJoin?.toString(),
    dashLength: object.strokeDashArray
      ? object.strokeDashArray[0].toString()
      : undefined,
    dashGap: object.strokeDashArray
      ? object.strokeDashArray[1].toString()
      : undefined,
    strokeType: object.strokeDashArray ? "dashed" : "solid",
    miterLimit: object.strokeMiterLimit?.toString(),
    fontFamily: object.fontFamily?.toString(),
    fontWeight: object.fontWeight?.toString(),
    textAlign: object.textAlign?.toString(),
    fontStyle: object.fontStyle?.toString(),
    fontSize: object.fontSize?.toString(),
    lineHeight: object.lineHeight?.toString(),
    charSpacing: object.charSpacing?.toString(),
    cornerRadius:
      object.type === "rect" || object.type === "polygon"
        ? object.rx?.toString()
        : undefined,
    originX: object.originX,
    originY: object.originY,
    uniformScaling: object.lockUniScaling?.toString(),
    opacity:
      object.opacity !== undefined ? (object.opacity * 100).toString() : "100",
  };
  if (object.shadow) {
    const shadow = object.shadow as Shadow;
    properties = {
      ...properties,
      shadowBlur: shadow.blur?.toString(),
      shadowOffsetX: shadow.offsetX?.toString(),
      shadowOffsetY: shadow.offsetY?.toString(),
    };
  }

  if (object.name?.includes("image")) {
    const imageGroup = object as IImageGroup;
    const frame = object._objects.find((x) => x.type !== "image");
    const img = object._objects.find((x) => x.type === "image");

    if (frame && img) {
      const imgWidth = img.width ?? 0;
      const imgHeight = img.height ?? 0;
      const imgScaleX = img.scaleX ?? 1;
      const imgScaleY = img.scaleY ?? 1;
      const groupScaleX = imageGroup.scaleX ?? 1;
      const groupScaleY = imageGroup.scaleY ?? 1;
      const frameSize = getSizeInInches(frame, imageGroup);
      const imgPosition = getPositionInInches(img);
      properties = {
        ...properties,
        strokeWidth: frame.strokeWidth?.toString(),
        lineCap: frame.strokeLineCap?.toString(),
        lineJoin: frame.strokeLineJoin?.toString(),
        dashLength: frame.strokeDashArray
          ? frame.strokeDashArray[0].toString()
          : undefined,
        dashGap: frame.strokeDashArray
          ? frame.strokeDashArray[1].toString()
          : undefined,
        strokeType: frame.strokeDashArray ? "dashed" : "solid",
        miterLimit: frame.strokeMiterLimit?.toString(),
        imageFrameType: object.__maskType,
        imageFrameWidth: frameSize.width,
        imageFrameHeight: frameSize.height,
        imageFrameAngle: frame.angle?.toString(),
        imageFrameSkewX: frame.skewX?.toString(),
        imageFrameSkewY: frame.skewY?.toString(),
        imageWidth: ((imgWidth * imgScaleX * groupScaleX) / RESOLUTION).toFixed(
          3
        ),
        imageHeight: (
          (imgHeight * imgScaleY * groupScaleY) /
          RESOLUTION
        ).toFixed(3),
        imageLeft: imgPosition.x,
        imageTop: imgPosition.y,
      };
    }
  }

  return properties;
}

export default useProperties;
