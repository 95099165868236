import { fabric } from "fabric";
import { Canvas, Group, Object as IObject } from "fabric/fabric-impl";
import RESOLUTION from "../../../constants/RESOLUTION";
import Sizes from "../../../constants/Sizes";
import getAddressBlockOverlay from "./getAddressBlockOverlay";
import { LetterFoldTypes } from "../../../state/slices/letterSettings";
import getBrochureOverlay from "./getBrochureOverlay";

async function getOverlayObjects(
  size: string,
  canvas: Canvas,
  background: IObject,
  envelopeType?: string,
  fold?: LetterFoldTypes,
  isAddressBlock?: boolean
) {
  const sizeObj = Sizes[size];
  if (envelopeType) {
    const overlays = await getAddressBlockOverlay(envelopeType, canvas);
    if (overlays.length) {
      canvas.add(...overlays);
    }
    return;
  }
  if (fold && !envelopeType) {
    const overlays = await getBrochureOverlay(
      fold,
      canvas,
      Boolean(isAddressBlock)
    );
    if (overlays.length) {
      canvas.add(...overlays);
    }
    return;
  }

  if (
    !sizeObj ||
    Number.isNaN(background.width) ||
    Number.isNaN(background.height) ||
    Number.isNaN(background.left) ||
    Number.isNaN(background.top)
  )
    return [];
  const objects: IObject[] = [];

  const addressBlockBackground = new fabric.Rect({
    width: sizeObj.addressBlock.width * RESOLUTION - 2,
    height: sizeObj.addressBlock.height * RESOLUTION - 2,
    strokeWidth: 2,
    stroke: "rgba(0,0,0,.6)",
    fill: "#FFFFFF",
    selectable: false,
    name: "overlay",
    left:
      (background.left ?? 0) + (sizeObj.addressBlock.left - 0.125) * RESOLUTION,
    top:
      (background.top ?? 0) + (sizeObj.addressBlock.top - 0.125) * RESOLUTION,
    // @ts-ignore
    __addressBlock: true,
  });
  objects.push(addressBlockBackground);
  const addressBlockCenter = addressBlockBackground.getCenterPoint();
  const addressBlockText = new fabric.Text("Address Block", {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 50,
    fill: "#000000",
    originX: "center",
    name: "overlay",
    originY: "center",
    left: addressBlockCenter.x,
    top: addressBlockCenter.y,
    selectable: false,
    evented: false,
    // @ts-ignore
    __addressBlock: true,
  });
  objects.push(addressBlockText);
  //if (sizeObj.postage) {
  //  const postageBlockBackground = new fabric.Rect({
  //    name: "overlay",
  //    width: sizeObj.postage.width * RESOLUTION - 2,
  //    height: sizeObj.postage.height * RESOLUTION - 2,
  //    left: background.left + (sizeObj.postage.left - 0.125) * RESOLUTION,
  //    top: background.top + (sizeObj.postage.top + 0.125) * RESOLUTION,
  //    fill: "#FFFFFF",
  //    stroke: "rgba(0,0,0,.125)",
  //    strokeWidth: 2,
  //    selectable: false,
  //  });
  //  const postageBlockCenter = postageBlockBackground.getCenterPoint();
  //  objects.push(postageBlockBackground);
  //  const postageblockText = new fabric.Text("Postage", {
  //    fontFamily: "Roboto",
  //    fontWeight: "bold",
  //    fontSize: 30,
  //    fill: "#000000",
  //    originX: "center",
  //    name: "overlay",
  //    originY: "center",
  //    left: postageBlockCenter.x,
  //    top: postageBlockCenter.y,
  //    selectable: false,
  //    evented: false,
  //  });
  //  objects.push(postageblockText);
  //}
  canvas.add(...objects);
  objects.forEach((object) => {
    object.bringToFront();
  });
}
export default getOverlayObjects;
