import {
  IBulletOptions,
  IBulletedList,
  IListItem,
  Textbox,
  fabric,
} from "fabric";
import {
  createNewListItem,
  reAlignListAndItems,
} from "../../Canvas/functions/createBulletedList";
import React, { useEffect, useRef, useState } from "react";
import DesignerInput from "../shared/DesignerInput/DesignerInput";
import ToolButton from "../shared/ToolButton";

import tw, { css } from "twin.macro";
import { TrashIcon } from "../shared/SvgComponents";
import toolbarStyles from "../TopToolbar/toolbarStyles";
import propertiesStyles from "./propertiesStyles";
import DesignerButton from "../shared/DesignerButton";
import IncrementInput from "../shared/NuDesignerInputs/IncrementInput";

interface IBulletedListPropertiesProps {
  list: IBulletedList;
}

const BulletedListProperties = ({ list }: IBulletedListPropertiesProps) => {
  const [listItems, setListItems] = useState<IListItem[]>([]);
  const [listItemsText, setListItemsText] = useState<string[]>([]);
  const [vSpacing, setVSpacing] = useState(list.__settings.vSpacing);
  const [hSpacing, setHSpacing] = useState(list.__settings.hSpacing);
  const [bulletSize, setBulletSize] = useState(
    list._objects[0]._objects.find((x) => x.name !== "text")?.scaleX ?? 1
  );
  const debounceTextInput = useRef<NodeJS.Timeout>();

  function handleAddBullet() {
    createNewListItem(list);
    setListItems([...list._objects]);
    setListItemsText(
      list._objects.map((item) => {
        const textObj = item._objects.find((x) => x.name === "text") as Textbox;
        return textObj.text ?? "";
      })
    );
    list.fire("modified");
  }

  function handleListSync() {
    list.canvas?.fire("object:modified");
  }

  function handleDelete(item: IListItem) {
    list.remove(item);

    list._objects.forEach((item, index) => {
      item.__index = index;
      item.name = `listItem-${index}`;
    });

    reAlignListAndItems(list);

    list.canvas?.renderAll();

    setListItems([...list._objects]);
    setListItemsText(
      list._objects.map((item) => {
        const textObj = item._objects.find((x) => x.name === "text") as Textbox;
        return textObj.text ?? "";
      })
    );
  }

  function handleSpacing(property: string, value: number) {
    if (property === "hSpacing") {
      setHSpacing(value);
      list.__settings.hSpacing = value;
    }
    if (property === "vSpacing") {
      setVSpacing(value);
      list.__settings.vSpacing = value;
    }
    reAlignListAndItems(list);
    list.canvas?.renderAll();
    list.fire("modified");
  }

  function handleBulletSize(p: string, v: number) {
    setBulletSize(v);
    list._objects.forEach((obj) => {
      const bullet = obj._objects.find((x) => x.name !== "text");
      if (bullet) {
        bullet.scaleX = v;
        bullet.scaleY = v;
      }
    });
    reAlignListAndItems(list);
    list.canvas?.renderAll();
    list.fire("modified");
  }

  function handleTextChange(
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const text = listItems[index]._objects.find(
      (x) => x.name === "text"
    ) as Textbox;

    text.text = event.target.value;
    text.set({
      text: event.target.value,
      dirty: true,
    });
    listItemsText[index] = event.target.value;
    setListItemsText([...listItemsText]);
    reAlignListAndItems(list);
    list.canvas?.renderAll();
    if (debounceTextInput.current) {
      clearTimeout(debounceTextInput.current);
    }
    debounceTextInput.current = setTimeout(() => {
      list.fire("modified");
    }, 300);
  }

  // setup our sync events
  useEffect(() => {
    setListItems([...list._objects]);
    setListItemsText(
      list._objects.map((item) => {
        const textObj = item._objects.find((x) => x.name === "text") as Textbox;
        return textObj.text ?? "";
      })
    );
    setBulletSize(
      list._objects[0]._objects.find((x) => x.name !== "text")?.scaleX ?? 1
    );
    list.off("modified", handleListSync);
    list.on("modified", handleListSync);
    return () => {
      list.off("modified", handleListSync);
    };
  }, [list]);

  return (
    <div>
      <div
        css={[
          css`
            max-height: 245px;
            overflow: hidden;
          `,
          tw`p-1.5 border border-border border-solid mb-2`,
        ]}
      >
        <div
          css={[
            propertiesStyles.scrollbar,
            css`
              max-height: 189px;
              overflow-y: auto;
            `,
            listItems.length >= 5 &&
              css`
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
              `,
          ]}
        >
          {listItems.map((item, index) => {
            return (
              <div
                key={item.name}
                css={[
                  css`
                    display: flex;
                    align-items: center;
                  `,
                  tw`mb-1.5`,
                ]}
              >
                <div
                  css={[
                    css`
                      width: 250px;
                    `,
                    tw`mr-1`,
                  ]}
                >
                  <DesignerInput
                    name="text"
                    value={listItemsText[index]}
                    type="text"
                    onChange={(e) => {
                      handleTextChange(index, e);
                    }}
                    disableAutoSelect
                  />
                </div>
                <div>
                  <ToolButton
                    name="delete"
                    onClick={() => handleDelete(item)}
                    disabled={listItems.length === 1}
                    css={[
                      toolbarStyles.utilityButton,
                      listItems.length === 1 && tw`hover:bg-white`,
                    ]}
                  >
                    <TrashIcon
                      size={5}
                      styles={
                        listItems.length === 1 ? [tw`fill-buttonDisabled`] : []
                      }
                    />
                  </ToolButton>
                </div>
              </div>
            );
          })}
        </div>
        <div css={[tw`pt-1.5`]}>
          <DesignerButton onClick={handleAddBullet}>Add Bullet</DesignerButton>
        </div>
      </div>
      <div css={propertiesStyles.propertiesGroup}>
        <div css={propertiesStyles.property}>
          <div css={propertiesStyles.label}>Bullet Size</div>
          <IncrementInput
            value={parseFloat(bulletSize.toFixed(2))}
            onChange={handleBulletSize}
            amount={0.1}
            property="bulletSize"
          />
        </div>
      </div>
      <div css={propertiesStyles.propertiesGroup}>
        <div css={propertiesStyles.property}>
          <div css={propertiesStyles.label}>Vertical Spacing</div>
          <IncrementInput
            value={vSpacing}
            onChange={handleSpacing}
            property="vSpacing"
          />
        </div>
        <div css={propertiesStyles.property}>
          <div css={propertiesStyles.label}>Bullet Spacing</div>
          <IncrementInput
            value={hSpacing}
            onChange={handleSpacing}
            property="hSpacing"
          />
        </div>
      </div>
    </div>
  );
};

export default BulletedListProperties;
