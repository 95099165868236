import React from "react";
import { TableColumn } from "react-data-table-component";
import { IRecipientListRecord } from "../../../data/models/IRecipientList";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import BatchRecipient from "../models/BatchRecipient";
import { RecipientListRecord } from "../models/RecipientListRecord";
import BatchOrder from "../models/BatchOrder";

interface RecipientListTableProps {
  data: BatchRecipient[] | IRecipientListRecord[];
  orders?: BatchOrder[];
  showOrderID?: boolean;
}

const RecipientListTable: React.FC<RecipientListTableProps> = ({
  data,
  showOrderID,
  orders,
}) => {
  let searchableColumns = [
    "firstName",
    "lastName",
    "company",
    "address",
    "address2",
    "city",
    "state",
    "zipCode",
  ];
  if (orders && orders.length) {
    (data as BatchRecipient[]).forEach((rec) => {
      const order = orders.find((o) => rec.orderID === o.orderID);
      if (order) {
        rec.orderStatus = order.status;
      }
    });
  }
  let columns: TableColumn<RecipientListRecord>[] = [
    {
      name: "Name",
      selector: (row) => row.firstName + row.lastName,
      sortable: true,
      format: (row) => row.firstName + " " + row.lastName,
    },
    {
      name: "Company",
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Address 2",
      selector: (row) => row.address2,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: "Zip",
      selector: (row) => row.zipCode,
      sortable: true,
    },
    {
      name: "Undeliverable Reason",
      sortable: true,
      selector: (row) =>
        row.undeliverableReason == null ? " " : row.undeliverableReason,
    },
    {
      name: "Ext. Ref Number",
      sortable: true,
      selector: (row) => row.externalRefNbr,
    },
  ];
  if (showOrderID && orders?.length) {
    columns = [
      {
        name: "Order ID",
        selector: (row) => (row.orderID ? row.orderID : 0),
        sortable: true,
      },
      {
        name: "Mailing Status",
        selector: (row) =>
          row.orderStatus === "Canceled"
            ? "Canceled"
            : row.orderStatus === "Delivered"
              ? row.mailingStatus
                ? row.mailingStatus
                : "Pending Scan"
              : "Pending",
        sortable: true,
      },

      ...columns,
    ];
    searchableColumns = [...searchableColumns, "orderID"];
  }

  return (
    <SearchableDataTable
      data={data}
      columns={columns}
      tableTitle="Recipients"
      conditionalRowStyles={[
        {
          when: (row) => {
            return row.undeliverable;
          },
          style: {
            background: "rgba(255, 0,0,.4)",
            borderRadius: 0,
          },
        },
      ]}
      searchableColumns={searchableColumns}
      straightEdge
      responsive
      dense
    />
  );
};

export default RecipientListTable;
