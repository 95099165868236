import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Box, display } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { postAsync } from "../../../../helpers/asyncFetch";
import { usePostData } from "../../../../hooks/useFetch";
import useLoadData from "../../../../hooks/useLoadData";
import Img from "../../../../shared/BasicHTML/Img";
import LoadingButton from "../../../../shared/LoadingButton";
import LoadingWrapper from "../../../../shared/LoadingWrapper";
import GridItem from "../../../../shared/MaterialWrappers/GridItem";
import GridRow from "../../../../shared/MaterialWrappers/GridRow";
import {
  OrderDesignVariable,
  Recipient,
} from "../../../PlaceAnOrder/PlaceAnOrder/models/OrderRequest";
import Design, { DesignField } from "../../models/Design";
import QrCodeSelect from "../../../../shared/QrCodeEditor/components/QrCodeSelect";
import downloadFile from "../../../../helpers/downloadFile";
import { LetterFoldTypes } from "../../../../postcard-designer/state/slices/letterSettings";

enum screen {
  form = "form",
  proof = "proof",
  loading = "loading",
}

interface IStandAloneProofGeneratorProps {
  designId: string;
  fields: DesignField[];
  htmlVersionId?: string;
  design: Design;
  isDesigner?: boolean;
  designerCallback?: (proofs: string[]) => void;
  isAdmin?: boolean;
  isOrder?: boolean;
}

function buildDesignVariables(
  designFields: DesignField[],
  design: Design,
  addReturnAddress = false,
) {
  const recipientDesignVariables: OrderDesignVariable[] = designFields.reduce<
    OrderDesignVariable[]
  >((prev, curr) => {
    let val = `[${curr.fieldKey}]`;
    if (curr.fieldKey === "frontcard") {
      if (design.proofFront) val = design.proofFront;
    }
    if (curr.fieldKey === "backcard") {
      if (design.proofBack) val = design.proofBack;
    }
    const field = {
      key: curr.fieldKey,
      value: val,
    };
    prev.push(field);
    return prev;
  }, []);

  return recipientDesignVariables;
}

interface ProofRequest extends Recipient {
  envelopeType: string;
}

const StandAloneProofGenerator = ({
  designId,
  fields,
  htmlVersionId,
  design,
  isDesigner = false,
  designerCallback = (proofs) => {},
  isAdmin = false,
  isOrder = false,
}: IStandAloneProofGeneratorProps) => {
  const variables = useMemo(
    () => buildDesignVariables(fields, design),
    [fields],
  );

  const [generatePreviewProofReq, generatePreviewProofRes] = usePostData<{
    front?: string;
    back?: string;
    pdf?: string;
  }>(
    `/api/designs/${designId}/generate-proof?isPreview=true${
      Boolean(htmlVersionId) ? `&htmlVersionID=${htmlVersionId}` : ""
    }`,
  );
  const [generateProofReq, generateProofRes] = usePostData<{
    front?: string;
    back?: string;
    pdf?: string;
  }>(
    `/api/designs/${designId}/generate-proof${
      Boolean(htmlVersionId) ? `?htmlVersionID=${htmlVersionId}` : ""
    }`,
  );

  const [hasQrCode, setHasQrCode] = useState(
    design.qrCodeID
      ? true
      : design.currentHtmlVersion?.html?.includes("{{QrCode}}") ||
          design.currentHtmlVersion?.htmlBack?.includes("{{QrCode}}")
        ? true
        : false,
  );

  const [returnAddress, setReturnAddress] = useState({
    returnaddress: "[ReturnAddress]",
    returnaddress2: "[ReturnAddress2]",
    returnaddresscity: "[ReturnCity]",
    returnaddressstate: "[ReturnState]",
    returnaddresszipcode: "[ReturnZip]",
    returnaddressfirstname: "[ReturnFirstName]",
    returnaddresslastname: "[ReturnLastName]",
    returnaddresscompany: "",
  });
  const [recipient, setRecipient] = useState<ProofRequest>({
    firstName: "[FirstName]",
    lastName: "[LastName]",
    company: "",
    address: "[Address]",
    address2: "[Address2]",
    city: "[City]",
    state: "[State]",
    zipCode: "[ZipCode]",
    extRefNbr: "[ExtRefNbr]",
    envelopeType: design.printPDFSource?.includes("BRO")
      ? LetterFoldTypes.TRIFOLD
      : "doubleWindow",

    recipientDesignVariables: variables,
  });

  const [qrCodeID, setQrCodeID] = useState(
    design.qrCodeID ? design.qrCodeID.toString() : "-1",
  );

  const [proofs, setProofs] = useState<string[]>([]);
  const [previewProofs, setPreviewProofs] = useState<string[]>([]);
  const [activeScreen, setActiveScreen] = useState(screen.form);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, boolean>
  >({});
  const [displayValidation, setDisplayValidation] = useState(false);

  function changeRecipientValue(
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent,
  ) {
    setRecipient({
      ...recipient,
      [e.target.name]: e.target.value,
    });
  }

  function changeReturnAddressValue(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setReturnAddress({
      ...returnAddress,
      [e.target.name]: e.target.value,
    });
  }

  function validateFields() {
    const errors = Object.keys(recipient).reduce<Record<string, boolean>>(
      (prev, curr) => {
        if (
          curr === "recipientDesignVariables" ||
          curr === "firstName" ||
          curr === "lastName" ||
          curr === "company" ||
          curr === "address2" ||
          curr === "extRefNbr"
        )
          return prev;

        if (!recipient[curr]) return { ...prev, [curr]: true };
        return prev;
      },
      {},
    );
    if (!recipient.firstName && !recipient.company) errors.firstName = true;
    setValidationErrors(errors);
  }

  function handleDesignVariableChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const recipientDesignVariables = recipient.recipientDesignVariables.map(
      (val) => {
        if (val.key === e.target.name) val.value = e.target.value;
        return val;
      },
    );
    setRecipient({ ...recipient, recipientDesignVariables });
  }

  async function handleProofGeneration(isPdf = false) {
    if (Object.keys(validationErrors).length) {
      setDisplayValidation(true);
      return;
    }
    setDisplayValidation(false);
    const { envelopeType, ...rec } = { ...recipient };

    // if (envelopeType !== "Regular") {
    //   rec.recipientDesignVariables.push({
    //     key: "returnaddress",
    //     value: `${returnAddress.returnaddress} ${returnAddress.returnaddress2}`,
    //   });

    //   rec.recipientDesignVariables.push({
    //     key: "returnaddresscity",
    //     value: returnAddress.returnaddresscity,
    //   });
    //   rec.recipientDesignVariables.push({
    //     key: "returnaddressstate",
    //     value: returnAddress.returnaddressstate,
    //   });
    //   rec.recipientDesignVariables.push({
    //     key: "returnaddresszip",
    //     value: returnAddress.returnaddresszipcode,
    //   });
    //   rec.recipientDesignVariables.push({
    //     key: "returnaddressname",
    //     value: `${returnAddress.returnaddressfirstname} ${returnAddress.returnaddresslastname}`,
    //   });
    //   rec.recipientDesignVariables.push({
    //     key: "returnaddresscompany",
    //     value: `${returnAddress.returnaddresscompany}`,
    //   });
    // }
    const requestObj = {
      format: isPdf ? "PDF" : undefined,
      recipient: {
        ...rec,
        variables: recipient.recipientDesignVariables.map((x) => {
          if (!design.printPDFSource?.toLowerCase().includes("designer")) {
            const original = fields.find((y) => y.fieldKey === x.key);
            if (original && original.fieldType === "image") {
              let url: URL | undefined;
              try {
                url = new URL(x.value);
              } catch (e) {}
              if (!url) {
                x.value =
                  "https://portal.pcmintegrations.com/static/media/dynamic-image-placeholder.8007e0243a19c5aece7e.png";
              }
            }
          }
          return {
            ...x,
          };
        }),
      },
      envelopeConfig: {
        type: envelopeType,
      },
      qrCodeID: hasQrCode ? qrCodeID : null,
      htmlVersionID: design.htmlVersionID,
      returnAddress: {
        firstName: returnAddress.returnaddressfirstname,
        lastName: returnAddress.returnaddresslastname,
        company: returnAddress.returnaddresscompany,
        address: returnAddress.returnaddress,
        address2: returnAddress.returnaddress2,
        city: returnAddress.returnaddresscity,
        state: returnAddress.returnaddressstate,
        zipCode: returnAddress.returnaddresszipcode,
      },
    };
    if (!isAdmin) {
      const promises = [generateProofReq(requestObj)];
      if (!isOrder) promises.push(generatePreviewProofReq(requestObj));
      const responses = await Promise.all(promises);
      const res = responses[0];
      if (!isOrder) {
        const previewRes = responses[1];
        if (previewRes) {
          const previewLinks: string[] = [];
          if (previewRes.front) {
            previewLinks.push(previewRes.front);
          }
          if (previewRes.back) {
            previewLinks.push(previewRes.back);
          }
          if (previewRes.pdf) {
            previewLinks.push(previewRes.pdf);
          }
          setPreviewProofs(previewLinks);
        }
      }
      if (res) {
        const proofLinks: string[] = [];
        if (res.front) {
          proofLinks.push(res.front);
        }
        if (res.back) {
          proofLinks.push(res.back);
        }
        if (res.pdf) {
          proofLinks.push(res.pdf);
        }

        setProofs(proofLinks);
        if (!isPdf) setActiveScreen(screen.proof);
      }
    } else {
      const res = await generatePreviewProofReq(requestObj);
      if (res) {
        const proofLinks: string[] = [];
        if (res.pdf) {
          proofLinks.push(res.pdf);
        }
        if (res.front) {
          proofLinks.push(res.front);
        }
        if (res.back) {
          proofLinks.push(res.back);
        }

        setProofs(proofLinks);
        setPreviewProofs(proofLinks);
        if (!isPdf) setActiveScreen(screen.proof);
      }
    }
  }

  useEffect(validateFields, [recipient]);
  useEffect(() => {
    if (recipient.envelopeType !== "Regular") {
    } else {
    }
  }, [recipient.envelopeType]);

  return (
    <LoadingWrapper
      height={600}
      loading={
        generateProofRes.status === "loading" ||
        generatePreviewProofRes.status === "loading"
      }
      container={Paper}
      message="Generating Your Proof"
    >
      <Box>
        <Paper sx={{ p: 3 }}>
          <Typography
            variant="h4"
            component="h1"
            sx={{ mb: 3, fontWeight: 600 }}
          >
            Proof generation for design #{designId}
          </Typography>
          {activeScreen === screen.proof && (
            <Box>
              <Box>
                <Typography variant="body1" gutterBottom>
                  Your proof was successfully generated! Use the button below to
                  return to the form if needed.
                </Typography>
              </Box>
              {proofs.length === 1 && (
                <Box>
                  <Box sx={{ width: "100%" }}>
                    <Img src={proofs[0]} maxWidth={1200} />
                  </Box>
                </Box>
              )}
              {proofs.length > 1 && (
                <GridRow sx={{ mb: 3 }}>
                  {proofs.length > 1 &&
                    proofs.map((proof) => (
                      <GridItem breakPoints={[12, 6]} key={proof}>
                        <Img src={proof} />
                      </GridItem>
                    ))}
                </GridRow>
              )}

              {/* {isDesigner && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => designerCallback(proofs)}
                  sx={{ mr: 2 }}
                >
                  Save Proof
                </Button>
              )} */}

              {(design.printPDFSource?.toLowerCase().includes("html") ||
                design.printPDFSource?.toLowerCase().includes("designer") ||
                isDesigner) &&
                Boolean(designerCallback) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => designerCallback(previewProofs)}
                    sx={{ mr: 2 }}
                  >
                    Save Proof
                  </Button>
                )}
              {proofs.length === 1 && proofs[0].endsWith("pdf") && (
                <Button
                  color="primary"
                  variant="contained"
                  href={proofs[0]}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ mr: 2 }}
                >
                  View PDF
                </Button>
              )}
              <Button
                color="primary"
                variant="contained"
                onClick={() => setActiveScreen(screen.form)}
              >
                Return to Form
              </Button>
            </Box>
          )}
          {activeScreen === screen.form && (
            <Box>
              {" "}
              <Typography variant="body1" gutterBottom>
                Use the form below to generate a proof for your design. Fields
                are marked as required where necessary. Any image field values
                must be valid, hosted image links.
              </Typography>
              <Typography variant="h6" component="h2" gutterBottom>
                Address
              </Typography>
              <Box sx={{ mb: 3 }}>
                <GridRow spacing={2}>
                  <GridItem breakPoints={[12, 4]}>
                    <TextField
                      fullWidth
                      label="First Name"
                      value={recipient.firstName}
                      name="firstName"
                      size="small"
                      onChange={changeRecipientValue}
                      required
                      error={validationErrors["firstName"] && displayValidation}
                    />
                  </GridItem>
                  <GridItem breakPoints={[12, 4]}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      value={recipient.lastName}
                      name="lastName"
                      size="small"
                      onChange={changeRecipientValue}
                    />
                  </GridItem>
                  <GridItem breakPoints={[12, 4]}>
                    <TextField
                      fullWidth
                      label="Company"
                      value={recipient.company}
                      name="company"
                      size="small"
                      onChange={changeRecipientValue}
                    />
                  </GridItem>
                  <GridItem breakPoints={[12, 6, 8]}>
                    <TextField
                      fullWidth
                      label="Address"
                      value={recipient.address}
                      name="address"
                      size="small"
                      onChange={changeRecipientValue}
                      error={validationErrors["address"] && displayValidation}
                      required
                    />
                  </GridItem>
                  <GridItem breakPoints={[12, 6, 4]}>
                    <TextField
                      fullWidth
                      label="Address 2"
                      value={recipient.address2}
                      name="address2"
                      size="small"
                      onChange={changeRecipientValue}
                    />
                  </GridItem>
                  <GridItem breakPoints={[12, 5, 5, 5]}>
                    <TextField
                      fullWidth
                      label="City"
                      value={recipient.city}
                      name="city"
                      size="small"
                      onChange={changeRecipientValue}
                      required
                      error={validationErrors["city"] && displayValidation}
                    />
                  </GridItem>
                  <GridItem breakPoints={[12, 3, 3]}>
                    <TextField
                      fullWidth
                      label="State"
                      value={recipient.state}
                      name="state"
                      size="small"
                      onChange={changeRecipientValue}
                      error={validationErrors["state"] && displayValidation}
                      required
                    />
                  </GridItem>
                  <GridItem breakPoints={[12, 4, 4]}>
                    <TextField
                      fullWidth
                      label="Zip"
                      value={recipient.zipCode}
                      name="zipCode"
                      size="small"
                      onChange={changeRecipientValue}
                      error={validationErrors["zipCode"] && displayValidation}
                      required
                    />
                  </GridItem>
                </GridRow>
              </Box>
              {design.printPDFSource?.toLowerCase().includes("letter") && (
                <Box sx={{ mb: 3 }}>
                  <Box>
                    <Typography variant="h6" component="h2" gutterBottom>
                      Envelope
                    </Typography>
                    <FormControl>
                      <InputLabel>Envelope Type</InputLabel>
                      <Select
                        label="Envelope Type"
                        value={recipient.envelopeType}
                        name={"envelopeType"}
                        size="small"
                        onChange={changeRecipientValue}
                      >
                        <MenuItem value="Regular">Regular Envelope</MenuItem>
                        <MenuItem value="BiFold">
                          Bi-Fold Regular Envelope
                        </MenuItem>
                        <MenuItem value="Custom">
                          Single Window Envelope
                        </MenuItem>
                        <MenuItem value="doubleWindow">Double Window</MenuItem>
                        <MenuItem value="fullWindow">Full Window</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {recipient.envelopeType !== "Regular" &&
                    recipient.envelopeType !== "Custom" && (
                      <Box sx={{ mt: 3 }}>
                        <Typography variant="h6" component="h2" gutterBottom>
                          Return Address
                        </Typography>
                        <GridRow spacing={2}>
                          <GridItem breakPoints={[12, 4]}>
                            <TextField
                              fullWidth
                              label="First Name"
                              value={returnAddress.returnaddressfirstname}
                              name="returnaddressfirstname"
                              size="small"
                              onChange={changeReturnAddressValue}
                            />
                          </GridItem>
                          <GridItem breakPoints={[12, 4]}>
                            <TextField
                              fullWidth
                              label="Last Name"
                              value={returnAddress.returnaddresslastname}
                              name="returnaddresslastname"
                              size="small"
                              onChange={changeReturnAddressValue}
                            />
                          </GridItem>

                          <GridItem breakPoints={[12, 4]}>
                            <TextField
                              fullWidth
                              label="Company"
                              value={returnAddress.returnaddresscompany}
                              name="returnaddresscompany"
                              size="small"
                              onChange={changeReturnAddressValue}
                            />
                          </GridItem>
                          <GridItem breakPoints={[12, 6, 8]}>
                            <TextField
                              fullWidth
                              label="Address"
                              value={returnAddress.returnaddress}
                              name="returnaddress"
                              size="small"
                              onChange={changeReturnAddressValue}
                            />
                          </GridItem>
                          <GridItem breakPoints={[12, 6, 4]}>
                            <TextField
                              fullWidth
                              label="Address 2"
                              value={returnAddress.returnaddress2}
                              name="returnaddress2"
                              size="small"
                              onChange={changeReturnAddressValue}
                            />
                          </GridItem>
                          <GridItem breakPoints={[12, 5, 5, 5]}>
                            <TextField
                              fullWidth
                              label="City"
                              value={returnAddress.returnaddresscity}
                              name="returnaddresscity"
                              size="small"
                              onChange={changeReturnAddressValue}
                            />
                          </GridItem>
                          <GridItem breakPoints={[12, 3, 3]}>
                            <TextField
                              fullWidth
                              label="State"
                              value={returnAddress.returnaddressstate}
                              name="returnaddressstate"
                              size="small"
                              onChange={changeReturnAddressValue}
                            />
                          </GridItem>
                          <GridItem breakPoints={[12, 4, 4]}>
                            <TextField
                              fullWidth
                              label="Zip"
                              value={returnAddress.returnaddresszipcode}
                              name="returnaddresszipcode"
                              size="small"
                              onChange={changeReturnAddressValue}
                            />
                          </GridItem>
                        </GridRow>
                      </Box>
                    )}
                </Box>
              )}
              {design.printPDFSource?.includes("BRO") && (
                <Box sx={{ mb: 3 }}>
                  <Box>
                    <Typography variant="h6" component="h2" gutterBottom>
                      Fold Type
                    </Typography>
                    <FormControl>
                      <InputLabel>Fold Type</InputLabel>
                      <Select
                        label="Fold Type"
                        value={recipient.envelopeType}
                        name={"envelopeType"}
                        size="small"
                        onChange={changeRecipientValue}
                      >
                        <MenuItem value={LetterFoldTypes.TRIFOLD}>
                          Tri-fold
                        </MenuItem>
                        <MenuItem value={LetterFoldTypes.BIFOLD}>
                          Bi-fold
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              )}
              {(design.printPDFSource?.toLowerCase().includes("pc") ||
                design.printPDFSource?.toLowerCase().includes("bro")) && (
                <Box sx={{ mt: 3, mb: 3 }}>
                  <Typography variant="h6" component="h2" gutterBottom>
                    Return Address
                  </Typography>
                  <GridRow spacing={2}>
                    <GridItem breakPoints={[12, 4]}>
                      <TextField
                        fullWidth
                        label="First Name"
                        value={returnAddress.returnaddressfirstname}
                        name="returnaddressfirstname"
                        size="small"
                        onChange={changeReturnAddressValue}
                      />
                    </GridItem>
                    <GridItem breakPoints={[12, 4]}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        value={returnAddress.returnaddresslastname}
                        name="returnaddresslastname"
                        size="small"
                        onChange={changeReturnAddressValue}
                      />
                    </GridItem>

                    <GridItem breakPoints={[12, 4]}>
                      <TextField
                        fullWidth
                        label="Company"
                        value={returnAddress.returnaddresscompany}
                        name="returnaddresscompany"
                        size="small"
                        onChange={changeReturnAddressValue}
                      />
                    </GridItem>
                    <GridItem breakPoints={[12, 6, 8]}>
                      <TextField
                        fullWidth
                        label="Address"
                        value={returnAddress.returnaddress}
                        name="returnaddress"
                        size="small"
                        onChange={changeReturnAddressValue}
                      />
                    </GridItem>
                    <GridItem breakPoints={[12, 6, 4]}>
                      <TextField
                        fullWidth
                        label="Address 2"
                        value={returnAddress.returnaddress2}
                        name="returnaddress2"
                        size="small"
                        onChange={changeReturnAddressValue}
                      />
                    </GridItem>
                    <GridItem breakPoints={[12, 5, 5, 5]}>
                      <TextField
                        fullWidth
                        label="City"
                        value={returnAddress.returnaddresscity}
                        name="returnaddresscity"
                        size="small"
                        onChange={changeReturnAddressValue}
                      />
                    </GridItem>
                    <GridItem breakPoints={[12, 3, 3]}>
                      <TextField
                        fullWidth
                        label="State"
                        value={returnAddress.returnaddressstate}
                        name="returnaddressstate"
                        size="small"
                        onChange={changeReturnAddressValue}
                      />
                    </GridItem>
                    <GridItem breakPoints={[12, 4, 4]}>
                      <TextField
                        fullWidth
                        label="Zip"
                        value={returnAddress.returnaddresszipcode}
                        name="returnaddresszipcode"
                        size="small"
                        onChange={changeReturnAddressValue}
                      />
                    </GridItem>
                  </GridRow>
                </Box>
              )}
              {!isAdmin && hasQrCode && (
                <Box sx={{ mb: 3 }}>
                  <Box>
                    <Typography variant="h6" component="h2" gutterBottom>
                      QR Code
                    </Typography>
                    <QrCodeSelect
                      value={qrCodeID}
                      onChange={(id) => setQrCodeID(id)}
                      bAccountID={isAdmin ? design.bAccountID : undefined}
                    />
                  </Box>
                </Box>
              )}
              {fields.length > 0 && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" component="h2" gutterBottom>
                    Design variables
                  </Typography>
                  <GridRow spacing={2}>
                    {fields.map((field) => {
                      const fieldValue =
                        recipient.recipientDesignVariables.find(
                          (val) => val.key === field.fieldKey,
                        );
                      if (fieldValue)
                        return (
                          <GridItem breakPoints={[12, 6]} key={field.fieldKey}>
                            <TextField
                              name={field.fieldKey}
                              label={field.fieldLabel}
                              value={fieldValue.value}
                              size="small"
                              onChange={handleDesignVariableChange}
                              fullWidth
                            />
                            {field.fieldType === "image" && (
                              <FormHelperText>
                                Use valid, hosted image links for this field.
                              </FormHelperText>
                            )}
                          </GridItem>
                        );
                      return null;
                    })}
                  </GridRow>
                </Box>
              )}
              <Box sx={{ mb: 2 }}>
                <GridRow spacing={2} alignItems="center">
                  <GridItem xs="auto">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleProofGeneration(false)}
                    >
                      Generate Proof
                    </Button>
                  </GridItem>
                  {isAdmin && (
                    <GridItem xs="auto">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleProofGeneration(true)}
                      >
                        Download PDF Proof
                      </Button>
                    </GridItem>
                  )}
                  {proofs.length > 0 && (
                    <GridItem xs="auto">
                      <Button
                        color="primary"
                        onClick={() => setActiveScreen(screen.proof)}
                      >
                        Return To Proofs
                      </Button>
                    </GridItem>
                  )}
                </GridRow>
              </Box>
              <Box>
                {proofs.length !== 0 &&
                  isAdmin &&
                  proofs.map((proof) => (
                    <Box key={proof}>
                      <a href={proof} target="_blank" rel="noreferrer">
                        {proof}
                      </a>
                    </Box>
                  ))}
              </Box>
              {generateProofRes.status === "error" && (
                <Box>
                  <Typography
                    variant="error"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    There was an error generating your proof
                  </Typography>
                  {/* {generateProofRes.message !== undefined &&
                  generateProofRes.message !== "undefined"
                    ? generateProofRes.message
                    : null} */}
                </Box>
              )}
              {displayValidation &&
                Object.keys(validationErrors).length > 0 && (
                  <Box>
                    <Typography variant="error">
                      You must fill out all required fields
                    </Typography>
                  </Box>
                )}
            </Box>
          )}
        </Paper>
      </Box>
    </LoadingWrapper>
  );
};

export default StandAloneProofGenerator;
