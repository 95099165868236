import { useRef } from "react";
import useClickOutside from "../../../../hooks/useClickOutside";
import { IFilter } from "./FontSidebar";
import tw, { css } from "twin.macro";
import { Category } from "../../Fonts/googleFontsService/types";
import { Variant } from "@mui/material/styles/createTypography";

interface IFontFiltersProps {
  filters: IFilter[];
  onClose: () => void;
  onChange: (filter: IFilter) => void;
  isOpen: boolean;
}

const styles = {
  container: [
    css({ right: 0, top: "36px" }),
    tw`rounded border-border border-solid border bg-glacier absolute p-4 whitespace-nowrap`,
  ],
  title: tw`font-bold mb-2`,
  checkbox: [tw`text-sm mb-1`, css({ input: { marginRight: "6px" } })],
};

const FontFilters = ({
  filters,
  onClose,
  onChange,
  isOpen,
}: IFontFiltersProps) => {
  const ref = useRef<HTMLDivElement>(null);

  if (!isOpen) return <div />;
  return (
    <div ref={ref} css={styles.container}>
      <div css={styles.title}>Font Category</div>
      <div>
        <div css={styles.checkbox}>
          <input
            id="sans-serif"
            type="checkbox"
            value="sans-serif"
            checked={Boolean(filters.find((x) => x.value === "sans-serif"))}
            onChange={() =>
              onChange({ property: "category", value: "sans-serif" })
            }
          />
          <label htmlFor="sans-serif">Sans-Serif</label>
        </div>
        <div css={styles.checkbox}>
          <input
            id="serif"
            type="checkbox"
            value="serif"
            checked={Boolean(filters.find((x) => x.value === "serif"))}
            onChange={() => onChange({ property: "category", value: "serif" })}
          />
          <label htmlFor="serif">Serif</label>
        </div>
        <div css={styles.checkbox}>
          <input
            id="display"
            type="checkbox"
            value="display"
            checked={Boolean(filters.find((x) => x.value === "display"))}
            onChange={() =>
              onChange({ property: "category", value: "display" })
            }
          />
          <label htmlFor="display">Display</label>
        </div>
        <div css={styles.checkbox}>
          <input
            id="handwriting"
            type="checkbox"
            value="handwriting"
            checked={Boolean(filters.find((x) => x.value === "handwriting"))}
            onChange={() =>
              onChange({ property: "category", value: "handwriting" })
            }
          />
          <label htmlFor="handwriting">Handwriting</label>
        </div>
        <div css={styles.checkbox}>
          <input
            id="monospace"
            type="checkbox"
            value="monospace"
            checked={Boolean(filters.find((x) => x.value === "monospace"))}
            onChange={() =>
              onChange({ property: "category", value: "monospace" })
            }
          />
          <label htmlFor="monospace">Monospace</label>
        </div>
      </div>
    </div>
  );
};

export default FontFilters;
