import { Box, Card, CardContent, Container, Grid } from "@mui/material";
import Img from "../BasicHTML/Img";
import Logo from "../../assets/images/logo-redesign.svg";
import LiveChatAndTagManager from "../LiveChatAndTagManager";
const LoginLayout: React.FC = ({ children }) => {
  return (
    <Box
      className="LoginBackground"
      style={{
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container fixed style={{ marginTop: "auto", marginBottom: "auto" }}>
        <Box mb={3} style={{ display: "flex", justifyContent: "center" }}>
          <Img src={Logo} style={{ maxWidth: "300px", margin: "auto" }} />
        </Box>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Card elevation={3}>
              <CardContent>{children}</CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <LiveChatAndTagManager />
    </Box>
  );
  return <div>{children}</div>;
};

export default LoginLayout;
