import QRCodeStyling, { Options } from "qr-code-styling";
import { useEffect, useMemo, useRef, useState } from "react";
import { QrCodeStylingOptions } from "../types/QrCodeStylingTypes";

interface QrCodeElementProps extends QrCodeStylingOptions {
  debounce?: boolean;
}

const QrCodeElement = ({ debounce = false, ...props }: QrCodeElementProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const debounceRef = useRef<NodeJS.Timeout>();
  const qrCodeRef = useRef(new QRCodeStyling({ ...props }));
  function generateQrCode() {
    if (ref.current) {
      ref.current.innerHTML = "";
      const qrCode = qrCodeRef.current;
      qrCode.append(ref.current);
      if (!isLoaded) setIsLoaded(true);
    }
  }

  useEffect(() => {
    if (!props.image) {
      //@ts-ignore
      props.image = null;
    }
    const qrCode = qrCodeRef.current;
    if (debounce) {
      if (ref.current) {
        if (debounceRef.current) {
          clearTimeout(debounceRef.current);
        }
        debounceRef.current = setTimeout(() => {
          qrCodeRef.current = new QRCodeStyling({
            ...props,
          });
          generateQrCode();
        }, 200);
      }
    } else {
      qrCodeRef.current = new QRCodeStyling({
        ...props,
      });
      generateQrCode();
    }
  }, [props]);

  useEffect(() => {
    if (!isLoaded) {
      generateQrCode();
    }
  }, [ref.current]);

  async function testclick() {
    const test = qrCodeRef.current._svg;
    console.log(test, ref.current);
  }

  return <div ref={ref} onClick={testclick} />;
};

export default QrCodeElement;
