import { Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

interface ButtonsProps {
  next: () => void;
  prev: () => void;
  showCount: boolean;
  count: number;
  current: number;
}

const Buttons: React.FC<ButtonsProps> = ({
  next,
  prev,
  showCount,
  count,
  current,
}) => {
  /**
   * Render
   */

  function handlePrev(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    prev();
  }

  function handleNext(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    next();
  }

  return (
    <div style={{ marginTop: "8px" }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Button
            onClick={handlePrev}
            color="primary"
            variant="outlined"
            size="large"
          >
            <ChevronLeft />
          </Button>
        </Grid>
        {showCount && (
          <Grid item>
            {current} of {count}
          </Grid>
        )}
        <Grid item>
          <Button
            onClick={handleNext}
            color="primary"
            variant="outlined"
            size="large"
          >
            <ChevronRight />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Buttons;
