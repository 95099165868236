import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { postAsync } from "../../../../../helpers/asyncFetch";
import LoadingButton from "../../../../../shared/LoadingButton";
import IDesignCategory from "../../../../Designs/models/IDesignCategory";
import IDesignSubcategory from "../../../../Designs/models/IDesignSubcategory";

interface INewSubcategoryModalProps {
  isOpen: boolean;
  onSubcategoryAdd: (category: IDesignSubcategory) => void;
  onClose: () => void;
  category: IDesignCategory;
}

const NewSubcategoryModal = ({
  isOpen,
  onSubcategoryAdd,
  onClose,
  category,
}: INewSubcategoryModalProps) => {
  const [newSubcategory, setNewCategory] = useState("");
  const [status, setStatus] = useState({
    hasError: false,
    isSubmitting: false,
    isSuccess: false,
    errorSubmitting: false,
  });

  function validateCategory() {
    if (
      category.subcategories.find(
        (x) =>
          x.subcategoryName.toLocaleLowerCase().replaceAll(" ", "").trim() ===
          newSubcategory.replaceAll(" ", "").trim().toLowerCase()
      )
    ) {
      setStatus({ ...status, hasError: true });
    } else {
      setStatus({ ...status, hasError: false });
    }
  }

  async function handleSubmit() {
    setStatus({ ...status, isSubmitting: true, errorSubmitting: false });
    const result = await postAsync<IDesignSubcategory>(
      `/design-category/${category.categoryID}`,
      {
        categoryName: newSubcategory,
      }
    );
    if (result) {
      onSubcategoryAdd(result);
      setStatus({
        hasError: false,
        isSubmitting: false,
        isSuccess: true,
        errorSubmitting: false,
      });
    } else {
      setStatus({ ...status, isSubmitting: false, errorSubmitting: true });
    }
  }

  function handleCancel() {
    setNewCategory("");
    setStatus({
      hasError: false,
      isSubmitting: false,
      isSuccess: false,
      errorSubmitting: false,
    });
    onClose();
  }

  useEffect(validateCategory, [newSubcategory]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle>Add Design Subcategory</DialogTitle>
      <DialogContent>
        <InputLabel>Enter the subcategory name: </InputLabel>
        <TextField
          value={newSubcategory}
          disabled={status.isSubmitting || status.isSuccess}
          onChange={(e) => setNewCategory(e.target.value)}
          fullWidth
        />
        {status.hasError && (
          <FormHelperText error>
            Design subcategory already exists
          </FormHelperText>
        )}
        {status.errorSubmitting && (
          <FormHelperText error>
            Something went wrong with your submission
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={status.isSubmitting}
          disabled={status.hasError || !newSubcategory.trim()}
          success={status.isSuccess}
          onClick={handleSubmit}
        >
          Create Subcategory
        </LoadingButton>
        <Button onClick={handleCancel} disabled={status.isSubmitting}>
          {status.isSuccess ? "Close Window" : "Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewSubcategoryModal;
