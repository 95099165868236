import React, { useContext, useRef, useState } from "react";
import { useDrag, DragSourceMonitor, useDrop } from "react-dnd";

import tw, { css } from "twin.macro";
import { ILayerAndObject } from "../../../hooks/useLayers";
import {
  BarsIcon,
  CheckIcon,
  DoodleIcon,
  FolderIcon,
  FolderOpenIcon,
  HiddenIcon,
  ImageIcon,
  LockIcon,
  QrCodeIcon,
  ShapeIcon,
  TextIcon,
  UnlockIcon,
  VisibleIcon,
} from "../shared/SvgComponents";
import isShape from "../../Canvas/functions/isShape";
import { Group, IText } from "fabric/fabric-impl";
import truncateString from "../../Canvas/functions/truncateString";
import useDoubleClick from "../../../../hooks/useDoubleClick";
import useClickOutside from "../../../../hooks/useClickOutside";
import ILayer from "../../../state/models/layers/ILayer";
import ILayersObject from "../../../state/models/layers/ILayersObject";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import Layers from "./Layers";

interface ILayerProps {
  layer: ILayerAndObject;
  onDrop: (itemId: string, destinationId: string) => void;
  isActive: boolean;
  onChange: (key: string, name: string, index: string) => void;
  toggleCollapse: (layer: ILayer, id: string) => void;
  toggleHide: (layer: ILayer, id: string) => void;
  toggleLock: (layer: ILayer, id: string) => void;
  parent?: ILayerAndObject;
  isChild?: boolean;
  disabled?: boolean;
}

const styles = {
  layer: (isActive: boolean, isChild: boolean) => [
    tw`flex items-center w-full px-3 py-2 text-sm border-b border-solid border-border`,
    isActive && tw`bg-hover`,
    isChild && tw`py-0.5 text-xs`,
  ],
  layerText: [
    css`
      font-size: 11px;
      margin-top: 2px;
    `,
  ],
  layerIcon: [tw`mr-4`],
  dragIcon: (isDragging: boolean) => [
    tw`cursor-grab`,
    isDragging && tw`cursor-grabbing`,
  ],
  renameInput: [
    tw`rounded p-0.5 text-xs border border-solid border-border w-28 mr-0.5`,
  ],
  renameContainer: [tw`flex align-middle`],
  buttonContainer: [tw`ml-auto flex items-center mr-0.5`],
  button: (isActive: boolean, disabled = false) => [
    tw`p-2`,
    !disabled && tw`cursor-pointer hover:bg-hover`,
    (!isActive || disabled) &&
      css`
        svg {
          fill: #bbb;
        }
      `,
    disabled && tw`cursor-default`,
  ],
};

const Layer = ({
  layer,
  onDrop,
  isActive,
  onChange,
  toggleHide,
  toggleLock,
  toggleCollapse,
  isChild = false,
  disabled = false,
  parent,
}: ILayerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const renameRef = useRef<HTMLInputElement>(null);
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: "layer",
    item: layer,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState(layer.name);
  const [collapsed, setCollapsed] = useState(false);
  const canvas = useContext(CanvasContext);
  const onLayerDoubleClick = useDoubleClick(toggleEdit);

  useClickOutside(renameRef, handleLayerRename);

  function toggleEdit() {
    setIsEditingName(!isEditingName);
  }

  function handleLayerRename() {
    if (isEditingName) {
      if (newName && newName.trim().length > 0) {
        onChange(layer.id, newName, layer.layerIndex.toString());
        setIsEditingName(false);
      } else {
        setNewName(layer.name);
        setIsEditingName(false);
      }
    }
  }
  function clearLayerRename() {
    setNewName(layer.name);
    setIsEditingName(false);
  }

  function getLayerIcon() {
    if (isShape(layer.object)) {
      return <ShapeIcon size={2.5} />;
    } else if (layer.object?.type?.includes("text")) {
      return <TextIcon size={2.5} />;
    } else if (layer.object?.name?.includes("image")) {
      return <ImageIcon size={2.5} />;
    } else if (layer.object?.type === "path") {
      return <DoodleIcon size={2.5} />;
    } else if (layer.id.includes("group") && !layer.isCollapsed) {
      return <FolderOpenIcon size={2.5} />;
    } else if (layer.id.includes("group") && layer.isCollapsed) {
      return <FolderIcon size={2.5} />;
    } else if (layer.id.includes("qrcode")) {
      return <QrCodeIcon size={2.5} />;
    }
  }

  const [{ canDrop, isOver, dropType }, drop] = useDrop({
    canDrop: (item: { id: string }) =>
      item && item.id && item.id !== layer.id ? true : false,
    accept: ["layer"],
    drop: (item) => {
      onDrop(item.id, layer.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      dropType: monitor.getItemType(),
    }),
  });
  drop(ref);

  drag(ref);

  function getImageName(obj: Group, fallback: string) {
    if (!fallback.startsWith("Layer")) {
      return fallback;
    }
    const image = obj._objects.find((x) => x.type === "image");
    if (!image) return fallback;
    if (!image.name) return fallback;
    return `{{${image.name}}}`;
  }

  function getLayers() {
    if (layer.layers !== undefined && canvas) {
      const layersArr: ILayerAndObject[] = Object.keys(layer.layers).map(
        (key) => {
          // @ts-ignore
          const layerObj = layer.layers[key];
          const obj = canvas._objects.find((x) => x.name === layerObj.id);
          return {
            ...layerObj,
            layerIndex: key,
            object: obj,
          };
        }
      );
      return layersArr;
    }
    return [];
  }

  function handleHideClick(e: React.MouseEvent) {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
    toggleHide(layer, layer.layerIndex.toString());
  }

  function handleLockClick(e: React.MouseEvent) {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
    toggleLock(layer, layer.layerIndex.toString());
  }

  function handleIconClick(e: React.MouseEvent) {
    if (layer.id.includes("group")) {
      e.preventDefault();
      e.stopPropagation();
      toggleCollapse(layer, layer.layerIndex.toString());
    }
  }

  return (
    <React.Fragment>
      {collected.isDragging && <div ref={dragPreview} />}

      <div
        ref={disabled ? undefined : ref}
        css={styles.layer(isActive, isChild)}
      >
        <div css={styles.layerIcon} onClick={handleIconClick}>
          {getLayerIcon()}
        </div>
        {!isEditingName && (
          <div
            onClick={() => {
              if (!disabled) onLayerDoubleClick();
            }}
          >
            {layer.name}
            {layer.object?.type?.includes("text") && (
              <div css={styles.layerText}>
                &ldquo;{truncateString((layer.object as IText).text ?? "", 20)}
                &rdquo;
              </div>
            )}
          </div>
        )}
        {isEditingName && !disabled && (
          <div>
            <div
              draggable
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              css={styles.renameContainer}
            >
              <input
                draggable
                onDragStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                type="text"
                css={styles.renameInput}
                ref={renameRef}
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
              <div css={styles.button(true)} onClick={handleLayerRename}>
                <CheckIcon size={3} />
              </div>
            </div>
            {layer.object?.type?.includes("text") && (
              <div css={styles.layerText}>
                &ldquo;{truncateString((layer.object as IText).text ?? "", 20)}
                &rdquo;
              </div>
            )}
          </div>
        )}
        <div css={styles.buttonContainer}>
          <div
            css={styles.button(
              Boolean(layer.isHidden),
              Boolean(parent?.isHidden)
            )}
            onClick={!parent?.isHidden ? handleHideClick : undefined}
          >
            {layer.isHidden || parent?.isHidden ? (
              <HiddenIcon size={3} />
            ) : (
              <VisibleIcon size={3} />
            )}
          </div>
          <div
            css={styles.button(
              Boolean(layer.isLocked),
              Boolean(parent?.isLocked)
            )}
            onClick={!parent?.isLocked ? handleLockClick : undefined}
          >
            {layer.isLocked || parent?.isLocked ? (
              <LockIcon size={3} />
            ) : (
              <UnlockIcon size={3} />
            )}
          </div>
        </div>
        <div css={styles.dragIcon(collected.isDragging)}>
          <BarsIcon size={3} />
        </div>
      </div>
      {layer.layers !== undefined && !layer.isCollapsed && (
        <Layers layersArray={getLayers()} disabled={disabled} parent={layer} />
      )}
      {/* <LayersDropzone id={layer.id} destination={layer.id} /> */}
    </React.Fragment>
  );
};

export default Layer;
