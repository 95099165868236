import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import IDesign from "../../data/models/IDesign";
import useHtmlDesigns from "../../hooks/dataFetchers/useHtmlDesigns";
import Img from "../../shared/BasicHTML/Img";
import LoadingWrapper from "../../shared/LoadingWrapper";
import SearchableDataTable from "../../shared/SearchableDataTable";
import DesignFriendlyName from "../Designs/components/DesignFriendlyName";
import { putAsync } from "../../helpers/asyncFetch";

const Html = () => {
  const [htmlDesigns, isLoading, getDesigns, updateDesigns] = useHtmlDesigns();
  const [filterCol, setFilterCol] = useState<"all" | "letter" | "postcard">(
    "all"
  );

  const columns: TableColumn<IDesign>[] = [
    {
      name: "Proof",
      cell: (design) => (
        <Box
          sx={{
            maxWidth: "200px",
            maxHeight: "200px",
            overflow: "hidden",
            width: "200px",
            pt: 1,
            pb: 1,
          }}
        >
          <Img src={design.proofFront} style={{ maxWidth: "200px" }} />
        </Box>
      ),
    },
    {
      name: "Design ID",
      selector: (design) => design.designID,
      sortable: true,
    },
    {
      name: "Name",
      selector: (design) => design.friendlyName,
      sortable: true,
    },
    {
      name: "Current Version",
      selector: (design) => design.htmlVersionID ?? 0,
      format: (design) => design.htmlVersionID,
      sortable: true,
    },
    {
      name: "Format",
      selector: (design) => design.type ?? "Postcard",
      sortable: true,
    },
    {
      name: "",
      width: "300px",
      cell: (design) => (
        <>
          <DesignFriendlyName
            designID={design.designID}
            friendlyName={design.friendlyName}
            callback={handleRenameDesign}
          />
          <Button
            variant="outlined"
            size="small"
            color="primary"
            component={Link}
            to={`/designs/html/${design.designID}`}
          >
            View Details
          </Button>
        </>
      ),
    },
  ];

  async function handleRenameDesign(designID: number, newName: string) {
    await putAsync(`/designs/${designID}/name`, { name: newName });
    updateDesigns(
      htmlDesigns.map((design) => {
        if (design.designID === designID) {
          return { ...design, friendlyName: newName };
        }
        return design;
      })
    );
  }

  return (
    <LoadingWrapper loading={isLoading} height={500}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4">HTML</Typography>
      </Box>

      <SearchableDataTable
        data={htmlDesigns}
        columns={columns}
        searchableColumns={["friendlyName", "designID"]}
        filters={["Postcard", "Letter", "Brochure"]}
        filterCol={"type"}
        customHeaderComponent={
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/designs/html/new/edit"
              size="small"
            >
              Create New
            </Button>
          </Box>
        }
      />
    </LoadingWrapper>
  );
};

export default Html;
