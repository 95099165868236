import { IText, Textbox } from "fabric/fabric-impl";
import { getVarsFromString } from "./variableHelpers";

export function cleanStyles(
  textObject: Textbox | IText,
  property: string,
  value: any
) {
  if (textObject.styles) {
    Object.values(textObject.styles).forEach((style: any) => {
      Object.values(style).forEach((v: any) => {
        if (v[property]) {
          v[property] = value;
        }
      });
    });
  }
  textObject.cleanStyle(property);
}

interface VariableObject {
  correctedStr: string;
  originalStr: string;
}
