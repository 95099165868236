import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { fetchClient } from "../../../helpers/fetchClient";
import useLoadData from "../../../hooks/useLoadData";
import LoadingButton from "../../../shared/LoadingButton";
import DialogProps from "../models/DialogProps";
import { SubscriptionEvent } from "../models/Webhook";

interface WebhookSandboxProps extends DialogProps {
  initialEvent?: string;
  initialSubConfig?: string;
  subscriptionID?: number;
}

interface SubConfig {
  url: string;
}

const WebhookSandbox: React.FC<WebhookSandboxProps> = ({
  open,
  onClose,
  initialEvent,
  initialSubConfig,
  subscriptionID,
}) => {
  const { data: eventTypes, isLoading } = useLoadData<SubscriptionEvent[]>(
    "/api/webhooks/event-types",
    []
  );
  const [url, setUrl] = useState("");
  const [event, setEvent] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState<
    "idle" | "loading" | "success"
  >("idle");
  const [json, setJson] = useState("");

  function handleClose() {
    setUrl("");
    setEvent("");
    setJson("");
    onClose();
  }

  function handleSubmit() {
    setSubmissionStatus("loading");
    fetchClient.post({
      path: `/webhooks/test`,
      onSuccess,
      onError,
      data: {
        url,
        event,
        subscriptionID,
      },
    });
  }

  function onSuccess(data: unknown) {
    if (!data || typeof data !== "object") {
      return onError();
    }
    setSubmissionStatus("success");
    setTimeout(() => setSubmissionStatus("idle"), 2000);

    const fixedInitialEvent =
      initialEvent === "OrderPendingPayment"
        ? "OrderRequiresPayment"
        : initialEvent;

    data = {
      Event: fixedInitialEvent ? fixedInitialEvent : event,
      Data: { ...correctKeysOnWebhook(data as Record<string, unknown>) },
    };

    if (
      (data as any)?.Event === "OrderIssues" &&
      (data as any)?.Data?.Status === "Mailed"
    )
      (data as any).Data.Status = "Mailing";

    const jsonString = JSON.stringify(data, null, 2);
    setJson(jsonString);
  }

  type FailedRecipientData = {
    [key: string]: number | string | null | boolean;
  };

  const fallbackFailedRecipientData: FailedRecipientData = {
    RecordID: 0,
    OrderID: 0,
    Address: "",
    Address2: null,
    City: "",
    State: "",
    ZipCode: "",
    FirstName: "",
    LastName: "",
    ExternalRefNbr: null,
    Undeliverable: true,
    UndeliverableReason: "",
    MailingStatus: null,
    DeliveryDate: null,
    LastScanDate: null,
    LastFacilityID: null,
    RecipientListID: 0,
    RecipientListRecordsExt: null,
  };

  function correctKeysOnWebhook(
    data: Record<string, any>
  ): Record<string, any> {
    if (data.failedRecipients && Array.isArray(data.failedRecipients)) {
      data.failedRecipients.forEach((recipient: any) => {
        delete recipient.batchID;
        delete recipient.variables;
        delete recipient.company;
        Object.keys(fallbackFailedRecipientData).forEach((key) => {
          if (recipient[key] === undefined) {
            recipient[key] = fallbackFailedRecipientData[key];
          }
        });
      });
    }

    function capitalizeKeys(
      value: unknown,
      preserveKeys: string[] = []
    ): unknown {
      if (typeof value === "object" && value !== null) {
        if (Array.isArray(value)) {
          return value.map((item) => capitalizeKeys(item, preserveKeys));
        } else {
          const valueAsObject = value as Record<string, any>;
          return Object.keys(valueAsObject).reduce(
            (acc: Record<string, any>, key) => {
              const newKey = preserveKeys.includes(key)
                ? key
                : `${key.charAt(0).toUpperCase()}${key.slice(1)}`;
              acc[newKey] = capitalizeKeys(valueAsObject[key], preserveKeys);
              return acc;
            },
            {}
          );
        }
      }
      return value;
    }
    return capitalizeKeys(data, ["failedRecipients"]) as Record<string, any>;
  }

  function onError() {
    setSubmissionStatus("idle");
  }

  function updateTestData() {
    if (subscriptionID && initialSubConfig && initialEvent) {
      const obj: SubConfig = JSON.parse(initialSubConfig);
      const initialUrl = obj.url;
      setUrl(initialUrl);
      setEvent(initialEvent);
    }
  }

  useEffect(updateTestData, [subscriptionID, initialSubConfig, initialEvent]);

  return (
    <Dialog open={open}>
      <DialogTitle>Webhook Testing</DialogTitle>
      <DialogContent>
        {Boolean(subscriptionID) && (
          <Typography variant="body1" sx={{ mb: 2 }}>
            Use the button below to test this webhook. The JSON that we post
            will also show below once the webhook has been fired.
          </Typography>
        )}
        {!Boolean(subscriptionID) && (
          <Typography variant="body1" sx={{ mb: 2 }}>
            Use the form below to test specific webhook functionality. If you do
            not have a url prepared to test your webhooks, you can use a free
            service such as{" "}
            <Link href="https://webhook.site" target="_blank">
              Webhook.site
            </Link>{" "}
            to test. The JSON that we post will also show below once the webhook
            has been fired.
          </Typography>
        )}
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          label="Webhook Testing URL"
          disabled={submissionStatus === "loading" || Boolean(initialSubConfig)}
        />
        <FormControl sx={{ mb: 2 }} fullWidth>
          <InputLabel>Webhook</InputLabel>
          <Select
            value={event}
            onChange={(e) => setEvent(e.target.value)}
            label="Webhook"
            disabled={submissionStatus === "loading" || Boolean(initialEvent)}
          >
            {eventTypes.map((eType) => (
              <MenuItem value={eType.event} key={eType.event}>
                {eType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {Boolean(json) && (
          <Paper>
            <SyntaxHighlighter style={nord} language="json">
              {json}
            </SyntaxHighlighter>
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={submissionStatus === "loading"}
          success={submissionStatus === "success"}
          onClick={handleSubmit}
        >
          Test Webhook
        </LoadingButton>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WebhookSandbox;
