import { useRef, useState } from "react";
import useClickOutside from "../../../../hooks/useClickOutside";
import ToolButton from "../shared/ToolButton";
import React from "react";
import tw from "twin.macro";
import { SelectOption } from "../shared/DesignerInput/SelectOption";

const styles = {
  button: [
    tw`bg-glacier border-border border-solid border border-r-0 text-labels last:border-r hover:bg-border`,
  ],
  textButton: [tw`font-semibold`],
  container: [tw`flex items-center absolute bottom-4 right-4`],
};

interface ZoomOptionsProps {
  currentZoom: number;
  onFit: () => void;
  onFill: () => void;
  onZoom: (val: number) => void;
}

const zoomOptionStyles = tw`px-2 py-1 hover:bg-hover cursor-pointer`;

const ZoomOptions = ({
  currentZoom,
  onFit,
  onFill,
  onZoom,
}: ZoomOptionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, () => {
    setIsOpen(false);
  });

  function handleClick(value: string) {
    if (value === "fit") {
      onFit();
    } else if (value === "fill") {
      onFill();
    } else {
      onZoom(Number(value));
    }
    setIsOpen(false);
  }

  return (
    <div ref={popupRef} tw="relative">
      <ToolButton
        small
        position="center"
        styles={[...styles.button, ...styles.textButton]}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span tw="block text-center w-[45px]">{currentZoom}%</span>
      </ToolButton>
      {isOpen && (
        <div tw="absolute w-[150px] bg-glacier border border-solid border-border rounded">
          <div css={[zoomOptionStyles]} onClick={() => handleClick("fit")}>
            Fit To Screen
          </div>
          <div css={[zoomOptionStyles]} onClick={() => handleClick("fill")}>
            Fill Screen
          </div>
          <hr tw="border-b border-solid border-border" />
          <div css={[zoomOptionStyles]} onClick={() => handleClick("10")}>
            10%
          </div>
          <div css={[zoomOptionStyles]} onClick={() => handleClick("25")}>
            25%
          </div>
          <div css={[zoomOptionStyles]} onClick={() => handleClick("50")}>
            50%
          </div>
          <div css={[zoomOptionStyles]} onClick={() => handleClick("75")}>
            75%
          </div>
          <div css={[zoomOptionStyles]} onClick={() => handleClick("100")}>
            100%
          </div>
          <div css={[zoomOptionStyles]} onClick={() => handleClick("125")}>
            125%
          </div>
          <div css={[zoomOptionStyles]} onClick={() => handleClick("200")}>
            200%
          </div>
          <div css={[zoomOptionStyles]} onClick={() => handleClick("300")}>
            300%
          </div>
        </div>
      )}
    </div>
  );
};

export default ZoomOptions;
