import { Point } from "fabric/fabric-impl";
import { fabric } from "fabric";
import generateGuid from "../../../../../helpers/generateGuid";
import RESOLUTION from "../../../../constants/RESOLUTION";

export type Shape = "ribbon" | "square" | "circle" | "rectangle" | "ellipse";

export function getPoints(shape: Shape) {}

interface IShapeProperties {
  points: Point[];
  height: number;
  width: number;
}

function ribbon(): IShapeProperties {
  return {
    points: [
      { x: 0, y: 0 },
      { x: RESOLUTION * 0.1, y: RESOLUTION / 8 },
      { x: 0, y: RESOLUTION / 4 },
      { x: RESOLUTION, y: RESOLUTION / 4 },
      { x: RESOLUTION * 0.9, y: RESOLUTION / 8 },
      { x: RESOLUTION, y: 0 },
    ] as Point[],
    width: RESOLUTION,
    height: RESOLUTION / 4,
  };
}

function square(): IShapeProperties {
  return {
    points: [
      { x: 0, y: 0 },
      { x: 0, y: RESOLUTION },
      { x: RESOLUTION, y: RESOLUTION },
      { x: RESOLUTION, y: 0 },
    ] as Point[],
    width: RESOLUTION,
    height: RESOLUTION,
  };
}

function rectangle(): IShapeProperties {
  return {
    points: [
      { x: 0, y: 0 },
      { x: 0, y: RESOLUTION * 0.75 },
      { x: RESOLUTION, y: RESOLUTION * 0.75 },
      { x: RESOLUTION, y: 0 },
    ] as Point[],
    width: RESOLUTION,
    height: RESOLUTION * 0.75,
  };
}

function circle() {
  return {
    points: [],
    width: RESOLUTION,
    height: RESOLUTION,
  };
}

function ellipse() {
  return {
    points: [],
    width: RESOLUTION,
    height: RESOLUTION * 0.75,
  };
}

export function getShape(
  shape: Shape,
  mousePos: { x: number; y: number },
  canvas: fabric.Canvas
) {
  let shapeProperties: IShapeProperties = { points: [], height: 0, width: 0 };

  switch (shape) {
    case "ribbon": {
      shapeProperties = ribbon();
      break;
    }
    case "square": {
      shapeProperties = square();
      break;
    }
    default:
      shapeProperties = rectangle();
  }
  let top: number;
  let left: number;
  const background = canvas.getObjects().find((x) => x.name === "bleed");
  if (
    background &&
    background.left &&
    background.top &&
    background.width &&
    background.height
  ) {
    if (mousePos.x > background.left + background.width) {
      left = background.left + background.width - shapeProperties.width;
    } else if (mousePos.x < background.left) {
      left = background.left;
    } else {
      left = mousePos.x - shapeProperties.width / 2;
    }
    if (mousePos.y > background.top + background.height) {
      top = background.top + background.height - shapeProperties.height;
    } else if (mousePos.y < background.top) {
      top = background.top;
    } else {
      top = mousePos.y - shapeProperties.height / 2;
    }
  } else {
    left = mousePos.x - shapeProperties.width / 2;
    top = mousePos.y - shapeProperties.height / 2;
  }

  const properties = {
    top,
    left,
    strokeUniform: true,
    name: `${shape}-${generateGuid()}`,
    fill: "#000000",
    stroke: "#000000",
    strokeWidth: 0,
  };

  if (shape === "ellipse") {
    return new fabric.Ellipse({
      ...properties,
      width: RESOLUTION,
      height: RESOLUTION / 1.25,
      rx: RESOLUTION / 2,
      ry: RESOLUTION / 2.5,
    });
  }
  if (shape === "circle") {
    return new fabric.Circle({
      ...properties,
      radius: RESOLUTION / 2,
    });
  }
  if (shape === "rectangle" || shape === "square") {
    return new fabric.Rect({
      ...properties,
      width: shapeProperties.width,
      height: shapeProperties.height,
    });
  }
  const polygon = new fabric.Polygon(shapeProperties.points, properties);
  return polygon;
}
