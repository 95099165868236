import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import QrCode, {
  QrCodeRaw,
  QrCodeTracking,
} from "../../../shared/QrCodeEditor/models/QrCode";
import QrCodeElement from "../../../shared/QrCodeEditor/components/QrCodeElement";
import { useEffect } from "react";
import { QrCodeStylingOptions } from "../../../shared/QrCodeEditor/types/QrCodeStylingTypes";

import TailwindLoadingSpinner from "../../../shared/TailwindComponents/TailwindLoadingSpinner";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import { TableColumn } from "react-data-table-component";
import { RecipientListRecord } from "../../Batches/models/Batch";
import moment from "moment";
import exportCsv from "../../../helpers/exportCsv";

interface Result
  extends Omit<QrCodeTracking & RecipientListRecord, "id" | "recipient"> {
  id: number;
}

interface QrCodeResultsProps {
  qrCodeID: number;
}

const QrCodeResults = ({ qrCodeID }: QrCodeResultsProps) => {
  const { data, isLoading, error } = useGetData<QrCodeRaw>(
    `/api/qr-codes/${qrCodeID}/results-expanded`
  );

  const qrCode: QrCode | undefined = data
    ? {
        ...data,
        config: JSON.parse(data.config) as QrCodeStylingOptions,
      }
    : undefined;

  const results: Result[] =
    data?.results?.map((x) => {
      const { recipient, ...rest } = x;
      if (x.recipient) {
        return {
          ...rest,
          ...x.recipient,
        };
      } else {
        return {
          ...rest,
          ...({} as RecipientListRecord),
        };
      }
    }) ?? [];

  const columns: TableColumn<Result>[] = [
    {
      name: "Scanned",
      selector: (x) => x.created,
      format: (x) => moment(x.created).format("MM/DD/YYYY h:mm a"),
      sortable: true,
    },
    {
      name: "Ext. Ref. Number",
      selector: (x) => x.externalRefNbr,
      sortable: true,
    },
    {
      name: "Order ID",
      selector: (x) => x.orderID,
      sortable: true,
    },
    {
      name: "First Name",
      selector: (x) => x.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (x) => x.lastName,
      sortable: true,
    },
    {
      name: "Address",
      selector: (x) => x.address,
      sortable: true,
    },
    {
      name: "Address 2",
      selector: (x) => x.address2,
      sortable: true,
    },
    {
      name: "City",
      selector: (x) => x.city,
      sortable: true,
    },
    {
      name: "State",
      selector: (x) => x.state,
      sortable: true,
    },
    {
      name: "Zip",
      selector: (x) => x.zipCode,
      sortable: true,
    },
    {
      name: "IP Address",
      selector: (x) => x.ipAddress,
      sortable: true,
    },
  ];

  function handleExport() {
    if (results.length) {
      exportCsv(
        results.map((x) => {
          return {
            qrCodeID: x.qrCodeID,
            scanned: moment(x.created).format("MM/DD/YYYY h:mm a"),
            extRefNbr: x.externalRefNbr,
            orderID: x.orderID,
            firstName: x.firstName,
            lastName: x.lastName,
            address: x.address,
            address2: x.address2,
            city: x.city,
            state: x.state,
            zipCode: x.zipCode,
            ipAddress: x.ipAddress,
          };
        }),
        "QrCode Results"
      );
    }
  }

  return (
    <Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "400px",
            width: "100%",
          }}
        >
          <TailwindLoadingSpinner />
        </Box>
      )}
      {!isLoading && (
        <Box>
          <Paper sx={{ background: "#f1f1f1", mb: 2 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                {qrCode && (
                  <QrCodeElement {...qrCode.config} width={90} height={90} />
                )}
              </Grid>
              <Grid item>
                <Box>
                  <Typography>
                    <strong>URL: </strong> {qrCode?.url}
                  </Typography>
                  <Typography>
                    <strong>QR Code ID: </strong> {qrCodeID}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
          <SearchableDataTable
            data={results}
            columns={columns}
            customHeaderComponent={
              <Box>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={handleExport}
                >
                  Export CSV
                </Button>
              </Box>
            }
            searchableColumns={[
              "firstName",
              "lastName",
              "address",
              "city",
              "state",
              "zipCode",
              "ipAddress",
              "externalRefNbr",
              "orderID",
            ]}
          />
        </Box>
      )}
    </Box>
  );
};

export default QrCodeResults;
