import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useContext } from "react";
import Design from "../../../../../Designs/models/Design";
import { FieldMapping } from "../../../models/OrderRequest";
import { OrderRequestContext } from "../../../PlaceAnOrder";

interface MapListToCustomFieldProps {
  index: number;
  field: FieldMapping;
  onChange: (
    index: number,
    fieldName: string | undefined,
    userListFieldName: string | undefined
  ) => void;
  design: Design;
}

const MapListToCustomField: React.FC<MapListToCustomFieldProps> = ({
  index,
  field,
  onChange,
  design,
}) => {
  /**
   * State Objects and Refs
   */
  const orderRequestStore = useContext(OrderRequestContext);
  const request = orderRequestStore.orderRequest;

  /**
   * Component Methods
   */
  function handleFieldChange(e: SelectChangeEvent<string>) {
    onChange(index, e.target.value, undefined);
  }

  function handleListFieldChange(e: SelectChangeEvent<string>) {
    onChange(index, undefined, e.target.value);
  }
  function removeField() {
    orderRequestStore.removeCustomVariableMapping(index);
  }
  /**
   * Component Effects
   */

  /**
   * Render
   */
  if (request.listAttributes !== undefined) {
    const availableListFields = request.listAttributes?.listHeaders.filter(
      (header) => {
        const mappingMatch = request.customVariableMapping.filter((x) => {
          return x.userListFieldName === header;
        });

        if (mappingMatch.length) return false;
        return true;
      }
    );
    const availableDesignFields = design.designFields.filter((field) => {
      const mappingMatch = request.customVariableMapping.filter(
        (x) => x.fieldName === field.fieldKey
      );
      if (mappingMatch.length) return false;
      return true;
    });

    return (
      <React.Fragment>
        <Grid item xs={6} md={5}>
          <FormControl fullWidth>
            <InputLabel id={"design-field-" + index}>
              Select Design Variable
            </InputLabel>

            <Select
              onChange={handleFieldChange}
              value={field.fieldName}
              label={"Select Design Variable"}
              labelId={"design-field-" + index}
            >
              {Boolean(field.fieldName) && (
                <MenuItem value={field.fieldName}>{field.fieldName}</MenuItem>
              )}
              {availableDesignFields.map((f) => (
                <MenuItem value={f.fieldKey} key={f.fieldKey}>
                  {f.fieldKey}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={5}>
          <FormControl fullWidth>
            <InputLabel id={"list-input-" + index}>
              Select From Your List
            </InputLabel>

            <Select
              onChange={handleListFieldChange}
              value={field.userListFieldName}
              label={"Select From Your List"}
              labelId={"list-input-" + index}
            >
              {Boolean(field.userListFieldName) && (
                <MenuItem value={field.userListFieldName}>
                  {field.userListFieldName}
                </MenuItem>
              )}
              {availableListFields.map((header) => (
                <MenuItem value={header} key={header}>
                  {header}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button color="warning" variant="outlined" onClick={removeField}>
            Remove
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
  return <div />;
};

export default MapListToCustomField;
