import { TableColumn } from "react-data-table-component";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import { RecipientListRecord } from "../../Batches/models/Batch";

interface RecipientListTableProps {
  recipients: RecipientListRecord[];
  tableTitle?: string;
}

const RecipientListTable: React.FC<RecipientListTableProps> = ({
  recipients,
  tableTitle = "Recipients",
}) => {
  const columns: TableColumn<RecipientListRecord>[] = [
    {
      name: "Name",
      selector: (row) => row.firstName + row.lastName,
      sortable: true,
      format: (row) => row.firstName + " " + row.lastName,
    },
    {
      name: "Company",
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Address 2",
      selector: (row) => row.address2,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: "Zip",
      selector: (row) => row.zipCode,
      sortable: true,
    },
    {
      name: "Undeliverable Reason",
      sortable: true,
      selector: (row) =>
        row.undeliverableReason == null ? " " : row.undeliverableReason,
    },
  ];

  return (
    <SearchableDataTable
      data={recipients}
      columns={columns}
      tableTitle={tableTitle}
      conditionalRowStyles={[
        {
          when: (row) => {
            return row.undeliverable;
          },
          style: {
            background: "rgba(255, 0,0,.4)",
            borderRadius: 0,
          },
        },
      ]}
      searchableColumns={[
        "firstName",
        "lastName",
        "address",
        "address2",
        "city",
        "state",
        "zipCode",
      ]}
      straightEdge
      responsive
      elevation={0}
      dense
    />
  );
};

export default RecipientListTable;
