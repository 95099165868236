import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ISvgShape from "../models/ISvgShape";
import { DesignerState } from "../store";

const initialState: ISvgShape[] = [];

const availableShapesSlice = createSlice({
  name: "availableShapes",
  initialState,
  reducers: {
    setAvailableShapes: (state, action: PayloadAction<ISvgShape[]>) => {
      state = [...action.payload];
    },
    addNewShape: (state, action: PayloadAction<ISvgShape>) => {
      state = [...state, action.payload];
    },
  },
});

export const { setAvailableShapes, addNewShape } = availableShapesSlice.actions;

export const selectAvailableShapes = (state: DesignerState) =>
  state.availableShapes;

export default availableShapesSlice.reducer;
