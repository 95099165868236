import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import TwinMacroStyles from "../../../../models/types/TwinMacroStyles";

export interface ISelectOption {
  ({ children, value }: ISelectOptionProps): React.ReactElement;
}

export interface ISelectOptionProps {
  children?: React.ReactNode;
  value: string;
  css?: TwinMacroStyles;
}

export const SelectOption: ISelectOption = ({
  children,
  css,
}: ISelectOptionProps) => {
  return <div css={css}>{children}</div>;
};
