import { Box, Drawer } from "@mui/material";
import routes from "./Navbar/routes";
import SidebarLink from "./Navbar/SidebarLink/SidebarLink";

const LayoutSidebar = () => {
  return (
    <Drawer
      elevation={0}
      PaperProps={{ elevation: 0 }}
      variant="permanent"
      sx={{
        display: { xs: "none", lg: "flex" },
        height: "100%",
        width: "300px",
        fontSize: "14px",
        padding: "1.75rem",

        [`& .MuiDrawer-paper`]: {
          padding: "28px",
          width: 300,
          backgroundImage: "url(assets/img/gradient-background.webp)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          boxShadow: "none",
          border: 0,
        },
      }}
    >
      <img alt="PostcardMania Logo" src="assets/img/logo-redesign.svg" />

      <Box sx={{ mt: 4 }}>
        {routes.map((route) => (
          <SidebarLink key={route.label} route={route} />
        ))}
      </Box>
    </Drawer>
  );
};

export default LayoutSidebar;
