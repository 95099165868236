import { useEffect, useState } from "react";
import { getAsync, postAsync } from "../../../helpers/asyncFetch";
import QrCode, { QrCodeRaw, newQrCode as blankQrCode } from "../models/QrCode";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import QrCodeElement from "./QrCodeElement";
import React from "react";
import QrCodeEditor from "../QrCodeEditor";
import Modal, {
  ModalBody,
  ModalTitle,
} from "../../../postcard-designer/features/DesignerInterface/shared/Modal";

interface QrCodeSelectProps {
  onChange: (qrCodedID: string) => void;
  value: string;
  bAccountID?: number;
}

const QrCodeSelect = ({ value, onChange, bAccountID }: QrCodeSelectProps) => {
  const [qrCodes, setQrCodes] = useState<QrCode[]>([]);
  const [newOpen, setNewOpen] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [newQrCode, setNewQrCode] = useState(blankQrCode);
  const [internalValue, setInternalValue] = useState<QrCode>();
  async function getQrCodes() {
    const qrCodesRaw = await getAsync<QrCodeRaw[]>(
      bAccountID ? `/qr-codes?bAccountID=${bAccountID}` : `/qr-codes`
    );
    if (qrCodesRaw && qrCodesRaw.length) {
      const codes = qrCodesRaw.map((x) => {
        return {
          ...x,
          config: JSON.parse(x.config),
        };
      });
      setQrCodes(codes);
      setInternalValue(codes[0]);
      onChange(codes[0].qrCodeID.toString());
    }
  }

  function handleChange(e: SelectChangeEvent) {
    console.log(e.target.value);
    onChange(e.target.value);
  }

  async function onNewSave({
    config,
    url,
    preview,
  }: {
    config: string;
    url: string;
    preview: string;
  }) {
    setNewLoading(true);
    const qr = await postAsync<QrCodeRaw>(`/qr-codes`, {
      config,
      url,
      preview,
    });
    if (qr) {
      const code = {
        ...qr,
        config: JSON.parse(qr.config),
      };
      setQrCodes([code, ...qrCodes]);
      setNewQrCode(code);
      onChange(qr.qrCodeID.toString());
    }
    setNewLoading(false);
    setNewOpen(false);
  }

  useEffect(() => {
    getQrCodes();
  }, []);

  useEffect(() => {
    console.log(value);
    if (value) {
      setInternalValue(
        qrCodes.find((x) => x.qrCodeID.toString() === value) ?? newQrCode
      );
    }
  }, [value]);

  return (
    <React.Fragment>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs="auto">
          <Select
            value={internalValue?.qrCodeID.toString() ?? ""}
            onChange={handleChange}
            size="small"
            placeholder={
              qrCodes.length === 0
                ? "Please Create A QR Code"
                : "Select A QR Code"
            }
            displayEmpty
          >
            <MenuItem value="" disabled>
              {qrCodes.length === 0
                ? "Please Create A QR Code"
                : "Select A QR Code"}{" "}
            </MenuItem>
            {qrCodes.map((x) => (
              <MenuItem key={x.qrCodeID} value={x.qrCodeID.toString()}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    <QrCodeElement {...x.config} width={40} height={40} />
                  </Box>
                  <Box>{x.qrCodeID === -1 ? "Placeholder QR Code" : x.url}</Box>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {!bAccountID && (
          <Grid item xs="auto">
            <Button
              variant="text"
              color="primary"
              onClick={() => setNewOpen(true)}
            >
              Create New QR Code
            </Button>
          </Grid>
        )}
      </Grid>
      <Modal isOpen={newOpen}>
        <ModalTitle>Add New QR Code</ModalTitle>
        <ModalBody>
          <QrCodeEditor saveCallback={onNewSave} loading={newLoading} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default QrCodeSelect;
