import {
  Button,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { FC, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { fetchClient } from "../../../../helpers/fetchClient";
import LoadingButton from "../../../../shared/LoadingButton";
import GridItem from "../../../../shared/MaterialWrappers/GridItem";
import GridRow from "../../../../shared/MaterialWrappers/GridRow";
import SmallTextField from "../../../../shared/MaterialWrappers/SmallTextField";
import { IBudgetSettings } from "../../models/AccountModel";

interface BudgetProps {
  budget: IBudgetSettings;
}

const Budget: FC<BudgetProps> = ({ budget }) => {
  /**
   * State Objects and Refs
   */
  const [budgetType, setBudgetType] = useState(budget.budgetType);
  const budgetRef = useRef({
    budgetType: budget.budgetType,
    budgetAmount: convertBudgetAmountToString(),
  });
  const [budgetAmount, setBudgetAmount] = useState(
    convertBudgetAmountToString()
  );
  const [hasChanged, setHasChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);

  /**
   * Component Methods
   */

  function handleBudgetTypeChange(e: SelectChangeEvent) {
    if (e.target.value === "unlimited" || e.target.value === "manual") {
      setBudgetAmount("");
    }
    setBudgetType(e.target.value);
    if (!hasChanged) setHasChanged(true);
  }

  function handleBudgetAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
    setBudgetAmount(e.target.value);
    if (!hasChanged) setHasChanged(true);
  }

  function convertBudgetAmountToString(): string {
    if (!budget.budgetAmount) return "";
    return budget.budgetAmount.toFixed(2).toString();
  }

  function submitBudgetChanges() {
    setIsSubmitting(true);
    setHasError(false);
    setIsSuccess(false);

    let amount = parseFloat(budgetAmount.replace("$", "").replaceAll(",", ""));
    if (amount < 0) amount *= -1;
    const budgetInfo = {
      budgetType: budgetType,
      budgetAmount: amount,
    };
    console.log(amount);
    fetchClient.put({
      path: "/integration/budget",
      onSuccess: onSubmitSuccess,
      onError: onSubmitError,
      data: budgetInfo,
    });
  }

  function onSubmitSuccess() {
    setIsSuccess(true);
    setIsSubmitting(false);
    setHasChanged(false);
    budgetRef.current = { budgetType, budgetAmount };
    setTimeout(() => {
      setIsSuccess(false);
    }, 3000);
  }

  function onSubmitError() {
    setIsSubmitting(false);
    setIsSuccess(false);
    setHasError(true);
  }

  function resetBudget() {
    setBudgetType(budgetRef.current.budgetType);
    setBudgetAmount(budgetRef.current.budgetAmount);
    setHasChanged(false);
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <GridRow>
      <GridItem breakPoints={[12, 6]}>
        <FormControl fullWidth size="small">
          <InputLabel>Budget Type</InputLabel>
          <Select
            label="Budget Type"
            fullWidth
            native
            value={budgetType}
            onChange={handleBudgetTypeChange}
            size="small"
          >
            <option value="" disabled>
              Select an option
            </option>
            <option value="manual">I want to approve orders manually</option>
            <option value="monthly">I want to set a monthly budget</option>
            <option value="daily">I want to set a daily budget</option>
            <option value="unlimited">Automatically bill me</option>
          </Select>
        </FormControl>
      </GridItem>
      <GridItem breakPoints={[12, 6]}>
        {["daily", "monthly"].indexOf(budgetType) !== -1 && (
          <NumberFormat
            customInput={SmallTextField}
            thousandSeparator={true}
            prefix="$"
            value={budgetAmount}
            onChange={handleBudgetAmountChange}
            decimalSeparator="."
            decimalScale={2}
            allowNegative={false}
            fullWidth
            label="Budget Amount"
          />
        )}
      </GridItem>
      <GridItem xs="auto">
        <LoadingButton
          onClick={submitBudgetChanges}
          loading={isSubmitting}
          success={isSuccess}
          disabled={
            hasChanged === false ||
            (Boolean(budgetAmount) == false &&
              (budgetType === "monthly" || budgetType === "daily"))
          }
        >
          Update Budget
        </LoadingButton>
      </GridItem>
      <GridItem xs="auto">
        <Button onClick={resetBudget} color="primary" disabled={!hasChanged}>
          Reset
        </Button>
      </GridItem>
    </GridRow>
  );
};

export default Budget;
