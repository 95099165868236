import { useHistory, useParams } from "react-router";
import IHtmlVersion from "../../data/models/IHtmlVersion";
import useGetData from "../../hooks/dataFetchers/useGetData";
import LoadingWrapper from "../../shared/LoadingWrapper";
import Design from "../Designs/models/Design";
import StandAloneProofGenerator from "../Designs/Proof/components/StandAloneProofGenerator";
import React from "react";
import { postAsync, putAsync } from "../../helpers/asyncFetch";

const GenerateDesignerProof = ({ isAdmin = false }: { isAdmin?: boolean }) => {
  const { designId, htmlVersionId } = useParams<{
    designId: string;
    htmlVersionId: string;
  }>();
  const {
    data: design,
    isLoading,
    error,
  } = useGetData<Design>(`/api/html/${designId}`);

  const history = useHistory();

  async function saveProofs(proofs: string[]) {
    await putAsync(`/designer/${designId}/details`, {
      proofFront: proofs[0],
      proofBack: proofs[1],
    });
    if (isAdmin) {
      history.push(`/admin/designer/`);
    }
  }

  return (
    <LoadingWrapper height={500} loading={isLoading} hasError={error.hasError}>
      {design && (
        <React.Fragment>
          <StandAloneProofGenerator
            design={design}
            fields={design.designFields}
            designId={designId}
            htmlVersionId={design.htmlVersionID}
            isDesigner
            designerCallback={saveProofs}
            isAdmin={isAdmin}
          />
        </React.Fragment>
      )}
    </LoadingWrapper>
  );
  // return (
  //   <LoadingWrapper height={500} loading={isLoading} hasError={error.hasError}>
  //     {design && (
  //       <StandAloneProofGenerator
  //         design={design}
  //         fields={design.designFields}
  //         designId={designId}
  //         htmlVersionId={htmlVersionId}
  //       />
  //     )}
  //   </LoadingWrapper>
  // );
};

export default GenerateDesignerProof;
