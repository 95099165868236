import DesignerInput from "../shared/DesignerInput/DesignerInput";
import IPropertiesProps from "./IPropertiesProps";
import styles from "./propertiesStyles";
import IncrementInput from "../shared/DesignerInput/IncrementInput";
import React, { useContext, useEffect, useState } from "react";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import { Group, Image, IText } from "fabric/fabric-impl";

const CustomImageProperties = ({ selectedObject }: IPropertiesProps) => {
  const canvas = useContext(CanvasContext);
  const [variableName, setVariableName] = useState("");
  const [image, setImage] = useState<IText>();

  function getImage() {
    if (!canvas || !selectedObject) return;
    const imageWrapper = (selectedObject as Group)
      .getObjects()
      .find((x) => x.name === "customImage");

    if (!imageWrapper) return;
    const image = (imageWrapper as Group)
      .getObjects()
      .find((x) => x.type === "text") as IText | undefined;

    if (!image) return;
    setImage(image);
    setVariableName(image.name ?? "");
  }

  function renameVariable() {
    if (!canvas || !image) return;
    if (!variableName) {
      setVariableName(image.name ?? "");
      return;
    }
    image.set("name", variableName);
    image.set("text", `{{${variableName}}}`);
    canvas.renderAll();
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.replaceAll(/^[^a-zA-Z_$]|[^0-9a-zA-Z_$]/g, "");
    setVariableName(value);
  }

  useEffect(getImage, [selectedObject]);

  return (
    <div css={styles.propertiesGroup}>
      <div css={styles.property}>
        <div css={styles.label}>Variable Name</div>
        <DesignerInput
          type="text"
          inputStyle={styles.input}
          onChange={handleChange}
          value={variableName}
          onBlur={renameVariable}
        />
      </div>
    </div>
  );
};

export default CustomImageProperties;
