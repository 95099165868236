//import {CopyBlock, nord} from 'react-code-blocks';
import SyntaxHighlighter from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/hljs";

/* eslint-disable */ import React from "react";
import logger from "../../helpers/logger";
import Design from "../../routes/Designs/models/Design";
import { Box } from "@mui/material";
import buildDesignVariables from "./buildDesignVariables";

interface DesignOrderExampleProps {
  design: Design;
  version: string;
}

const DesignProofExample: React.FC<DesignOrderExampleProps> = ({
  design,
  version,
}) => {
  return (
    <div>
      <div>
        <p>
          Use the JSON Example below to get started with generating a proof via
          the API. For more information{" "}
          <a
            href={
              version === "v3"
                ? design.printPDFSource === "LAS" ||
                  design.printPDFSource === "HTML-LETTER"
                  ? "https://docs.pcmintegrations.com/docs/directmail-api/0c4jgskl0kz2i-generate-letter-design-proof"
                  : "https://docs.pcmintegrations.com/docs/directmail-api/m3oigjn7gdsh2-generate-postcard-design-proof"
                : "https://docs.pcmintegrations.com/docs/directmail-api/branches/main/61249b3bcec73-generate-design-proof"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            check out our documentation
          </a>
        </p>
        <Box sx={{ borderRadius: 1 }}>
          {version === "v3" ? (
            <SyntaxHighlighter language="json" style={nord}>
              {design.printPDFSource === "LAS" ||
              design.printPDFSource === "HTML-LETTER"
                ? `{
  "designID" : ${design.designID},
  "envelope": {
    "type": "Regular"
  },
  "format" : "JPG",
  "recipient" : {
    "firstName" : "Test",
    "lastName" : "Person",
    "address" : "123 4th St",
    "address2" : "Unit 1",
    "city" : "Clearwater",
    "state" : "FL",
    "zipCode" : "33765",
    "variables" : ${
      design.designFields?.length > 0 ? buildDesignVariables(design) : "[]"
    }
  },
  "returnAddress": {
    "firstName": "Return",
    "lastName": "Address",
    "address": "123 4th St",
    "address2": "Unit 1",
    "city": "Clearwater",
    "state": "FL",
    "zipCode": "33765"
  }  
}`
                : `{
  "designID" : ${design.designID},
  "format" : "JPG",
  "recipient" : {
    "firstName" : "Test",
    "lastName" : "Person",
    "address" : "123 4th St",
    "address2" : "Unit 1",
    "city" : "Clearwater",
    "state" : "FL",
    "zipCode" : "33765",
    "variables" : ${
      design.designFields?.length > 0 ? buildDesignVariables(design) : "[]"
    }
  },
  "returnAddress": {
    "firstName": "Return",
    "lastName": "Address",
    "address": "123 4th St",
    "address2": "Unit 1",
    "city": "Clearwater",
    "state": "FL",
    "zipCode": "33765"
  }  
}`}
            </SyntaxHighlighter>
          ) : (
            <SyntaxHighlighter language="json" style={nord}>
              {`
{
  "designID" : ${design.designID},
  "format" : "JPG",
  "recipient" : {
    "firstName" : "Test",
    "lastName" : "Person",
    "address" : "123 4th St",
    "address2" : "Unit 1",
    "city" : "Clearwater",
    "state" : "FL",
    "zipCode" : "33765",
    "recipientDesignVariables" : ${
      design.designFields && design.designFields.length > 0
        ? buildDesignVariables(design)
        : "[]"
    }
  }        
}`}
            </SyntaxHighlighter>
          )}
        </Box>
      </div>
    </div>
  );
};

export default DesignProofExample;
