import React from "react";

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  onSubmit: (e: React.FormEvent) => any;
  children: any;
}

const Form: React.FC<FormProps> = (props) => {
  const { onSubmit, children } = props;

  if (!onSubmit || !children) {
    console.warn("You must provide an onSubmit AND children to your form");
    return null;
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    onSubmit(e);
  }

  return <form onSubmit={handleSubmit}>{children}</form>;
};

export default Form;
