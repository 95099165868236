import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useContext, ChangeEvent } from "react";
import Design, { DesignField } from "../../../../../Designs/models/Design";
import { OrderRequestContext } from "../../../PlaceAnOrder";

const ConfigureDesignVariables: React.FC<{
  design: Design;
  errors: Record<string, boolean>;
}> = ({ design, errors }) => {
  /**
   * State Objects and Refs
   */
  const orderRequestStore = useContext(OrderRequestContext);
  const orderRequest = orderRequestStore.orderRequest;

  const [imageModalOpen, setImageModalOpen] = useState(false);

  /**
   * Component Methods
   */

  function handleTextVariableChange(
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) {
    orderRequestStore.updateGlobalDesignVariable({
      key: e.target.name,
      value: e.target.value,
    });
  }

  function getValue(field: DesignField): string {
    const keyValField = orderRequest.orderconfig.globalDesignVariables.filter(
      (f) => f.key === field.fieldKey
    )[0];
    if (keyValField) {
      return keyValField.value;
    }
    return "";
  }

  function handleImageModalOpen(e: React.MouseEvent) {
    e.preventDefault();
    setImageModalOpen(true);
  }

  function handleImageModalClose() {
    setImageModalOpen(false);
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */
  if (!design.designFields || design.designFields.length === 0) return <div />;
  return (
    <Box>
      <Typography sx={{ fontWeight: 500 }}>
        Static Design Variables (Optional)
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        These variables will be applied to each piece. You may also override
        these values using fields from your list in the mapping step.
      </Typography>
      {design.designFields.map((field) => {
        const val = getValue(field);
        if (field.designFieldOptions && field.designFieldOptions.length) {
          return (
            <div className="placeAnOrder__fieldGap" key={field.fieldKey}>
              <FormControl
                fullWidth
                error={errors[field.fieldKey]}
                size="small"
              >
                <InputLabel id={field.fieldKey + "-label"}>
                  {field.fieldLabel}
                </InputLabel>
                <Select
                  labelId={field.fieldKey + "-label"}
                  label={field.fieldLabel}
                  name={field.fieldKey}
                  value={val}
                  onChange={handleTextVariableChange}
                >
                  {field.designFieldOptions.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
                {errors[field.fieldKey] && (
                  <FormHelperText error>
                    You must select an option
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          );
        }
        return (
          <div className="placeAnOrder__fieldGap" key={field.fieldKey}>
            <TextField
              value={val}
              onChange={handleTextVariableChange}
              name={field.fieldKey}
              label={field.fieldLabel}
              error={errors[field.fieldKey]}
              fullWidth
              size="small"
            />
            {errors[field.fieldKey] && field.fieldType !== "image" && (
              <FormHelperText error>
                You must enter a value for {field.fieldLabel}
              </FormHelperText>
            )}
            {errors[field.fieldKey] && field.fieldType === "image" && (
              <FormHelperText error>
                You must enter a valid hosted url for this image field. The
                image must be png, jpg, jpeg, bmp, or gif
              </FormHelperText>
            )}
            {field.fieldType === "image" && (
              <FormHelperText>
                This field requires an image url.{" "}
                <Button color="primary" onClick={handleImageModalOpen}>
                  Click here for more information
                </Button>
              </FormHelperText>
            )}
          </div>
        );
      })}
      <Dialog open={imageModalOpen} onClose={handleImageModalClose}>
        <DialogTitle>Using images on your order</DialogTitle>
        <DialogContent>
          <p>
            You must supply a hosted image url for these fields. For example:{" "}
            <br />
            <code>
              https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png
            </code>{" "}
            <br />
            Valid image formats are png, jpg, jpeg, bmp, &amp; gif
          </p>
          <p>
            <strong>
              If you are using a service such as AWS, you must ensure that the
              links are publicly accessible or our servers will not be able to
              process them.
            </strong>
          </p>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleImageModalClose}>
            Close Window
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ConfigureDesignVariables;
