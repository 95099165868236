import generateGuid from "../../../../helpers/generateGuid";

export default function debugSvgGenerator(canvas: fabric.Canvas) {
  return (width: number, height: number) => {
    const bleed = canvas.getObjects().find((x) => x.name === "bleed");
    if (bleed) {
      canvas.clipPath = bleed;

      bleed.stroke = "";
      canvas.renderAll();
      const svg = canvas.toSVG({
        viewBox: {
          x: bleed.left ?? 0,
          y: bleed.top ?? 0,
          width: width * 300,
          height: height * 300,
        },
      });
      const decoded = htmlDecode(svg);
      if (decoded) {
        const blob = new Blob([svg], { type: "image/svg+xml" });
        const dataUrl = URL.createObjectURL(blob);
        const downloadAnchor = document.createElement("a");
        downloadAnchor.setAttribute("href", dataUrl);
        downloadAnchor.setAttribute("download", `${generateGuid()}.svg`);
        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();
        document.body.removeChild(downloadAnchor);
      }
    }
  };
}

function htmlDecode(input: string) {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}
