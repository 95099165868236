//import {CopyBlock, nord} from 'react-code-blocks';
import SyntaxHighlighter from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/hljs";

/* eslint-disable */ import React from "react";
import logger from "../../helpers/logger";
import Design from "../../routes/Designs/models/Design";
import { Box, Typography } from "@mui/material";
import buildDesignVariables from "./buildDesignVariables";
import IDesign from "../../data/models/IDesign";

interface DesignOrderExampleProps {
  design: Design | IDesign;
  designType?: string;
  isHTML?: boolean;
  version: string;
}

const DesignOrderExample: React.FC<DesignOrderExampleProps> = ({
  design,
  designType = "uProduce",
  isHTML = false,
  version,
}) => {
  logger.log(design);

  const buildMailClasses = () => {
    if (design.mailClasses && design.mailClasses.length) {
      let text = "";
      text += `"${design.mailClasses[0].mailClassID}"`;
      return text;
    }
    return '"No Valid Mail Class"';
  };

  return (
    <Box>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Use the JSON Example below to get started with placing an order via the
        API. For more information{" "}
        <a
          href={
            version === "v3"
              ? design.printPDFSource === "LAS" ||
                design.printPDFSource === "HTML-LETTER"
                ? "https://docs.pcmintegrations.com/docs/directmail-api/130adee30e721-placing-a-letter-order"
                : "https://docs.pcmintegrations.com/docs/directmail-api/24b58db675dad-placing-a-postcard-order"
              : "https://docs.pcmintegrations.com/docs/directmail-api/branches/main/64cc04686e04c-placing-an-order"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          check out our documentation
        </a>
      </Typography>
      <Box sx={{ borderRadius: 1 }}>
        {version === "v3" && (
          <SyntaxHighlighter language="json" style={nord}>
            {designType !== "LAS" && designType !== "HTML-LETTER"
              ? `
{
  "extRefNbr": "12345",
  "designID": ${design.designID},
  "globalDesignVariables": ${
    design.designFields && design.designFields.length > 0
      ? buildDesignVariables(design)
      : "[]"
  },
  "mailClass": ${buildMailClasses()},
  "recipients": [
    {
      "firstName": "Test",
      "lastName": "Person",
      "address": "123 4th St",
      "address2": "Unit 1",
      "city": "Clearwater",
      "state": "FL",
      "zipCode": "33765",
      "variables": []
    }
  ],
  "returnAddress": {
    "firstName": "Return",
    "lastName": "Address",
    "address": "123 4th St",
    "address2": "Unit 1",
    "city": "Clearwater",
    "state": "FL",
    "zipCode": "33765"
  } 
}
`
              : `
{
  "extRefNbr": "12345",
  "designID": ${design.designID},  
  "envelope": {
    "type": "Regular",
  },
  "globalDesignVariables": ${
    design.designFields && design.designFields.length > 0
      ? buildDesignVariables(design)
      : "[]"
  },
  "mailClass": ${buildMailClasses()},
  "printOnBothSides": true,
  "recipients": [
    {
      "firstName": "Test",
      "lastName": "Person",
      "address": "123 4th St",
      "address2": "Unit 1",
      "city": "Clearwater",
      "state": "FL",
      "zipCode": "33765",
      "variables": []
    }
  ],
  "returnAddress": {
    "firstName": "Return",
    "lastName": "Address",
    "address": "123 4th St",
    "address2": "Unit 1",
    "city": "Clearwater",
    "state": "FL",
    "zipCode": "33765"
  } 
}
`}
          </SyntaxHighlighter>
        )}
        {version !== "v3" && (
          <SyntaxHighlighter language="json" style={nord}>
            {`[
  {
    "extRefNbr": "12345",
    "orderConfig": {
      "designID": ${design.designID},
      "mailClass": ${buildMailClasses()},
      "globalDesignVariables": ${
        design.designFields && design.designFields.length > 0
          ? buildDesignVariables(design)
          : "[]"
      }${
              !isHTML && designType == "HTML-LETTER"
                ? `,
      "html_letter": "{{URL or Raw HTML}}"`
                : ""
            }${
              !isHTML && designType == "HTML-PC"
                ? `,
      "html_front": "{{URL or Raw HTML}}",
      "html_back": "{{URL or Raw HTML}}"`
                : ""
            }${
              designType == "LAS" || designType == "HTML-LETTER"
                ? `,
      "letterConfig": {
          "color": true,
          "printOnBothSides": false,
          "insertAddressingPage": false,
          "envelopeType": "FullWindow"
      }`
                : ""
            }
    },
    "recipientList": [
      {
        "firstName": "Test",
        "lastName" : "Person",
        "address" : "123 4th St",
        "address2" : "Unit 1",
        "city" : "Clearwater",
        "state" : "FL",
        "zipCode" : "33765",
        "extRefNbr" : "123",
        "recipientDesignVariables" : []
      }
    ]
  }
]`}
          </SyntaxHighlighter>
        )}
      </Box>
    </Box>
  );
};

export default DesignOrderExample;
