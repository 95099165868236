export default [
  "name",
  "type",
  "styles",
  "selectable",
  "overlay",
  "evented",
  "perPixelTargetFind",
  "visible",
  "cornerRadius",
  "controlsVisibility",
  "__height",
  // Image mask custom properties
  "__maskType",
  "__originalImageDimensions",
  "__originalFrameDimensions",
  "__fillType",
  "lockUniScaling",
  "__locked",
  "__points",
  "__isCompleted",
  "objectCaching",
  "pathOffset",
  "__fontSettings",
  "__bulletSettings",
  "__bullet",
  "__index",
  "__settings",
  "__addressBlock",
  "__foldLine",
  "vSpacing",
  "hSpacing",
  "alignment",
  "_controlsVisibility",
  "__qrCodeId",
  "__margin",
  "__foldLine",
];
