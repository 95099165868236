function getImageNameFromUrl(url: string, includeFileType = false):string {
  let fileName = "";
  const removeQueries = url.split("?")[0];
  const indexOfFileName = removeQueries.lastIndexOf("/") + 1;
  fileName = removeQueries.substr(indexOfFileName);
  if(!includeFileType) {
    fileName = fileName.split(".")[0];
  }
  return fileName;
}

export default getImageNameFromUrl;