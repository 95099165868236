import { Reducer, ReducerAction, ReducerState } from "react";
import IEnvelope, { ReplenishmentMethod } from "../models/IEnvelope";

export interface IEnvelopeSettings {
  orderQty: string;
  replenishmentMethod: ReplenishmentMethod;
  replenishmentValue: string;
  paymentMethod: string;
}

export interface IEnvelopeSettingsState {
  values: IEnvelopeSettings;
  errors: string[];
  status: EnvelopeStatus;
  submissionErrorMessage?: string;
}

export enum EnvelopeStatus {
  Idle = "idle",
  Submitting = "submitting",
  Success = "success",
  Error = "error",
}

export const initialEnvelopeSettingsState: IEnvelopeSettingsState = {
  values: {
    orderQty: "",
    replenishmentMethod: ReplenishmentMethod.None,
    replenishmentValue: "",
    paymentMethod: "",
  },
  errors: [],
  status: EnvelopeStatus.Idle,
};

export enum EnvelopeSettingsActions {
  UPDATE_VALUES = "UPDATE_VALUES",
  UPDATE_STATUS = "UPDATE_STATUS",
  UPDATE_ERRORS = "UPDATE_ERRORS",
}

export interface IEnvelopeAction {
  type: EnvelopeSettingsActions;
  payload: string[] | IEnvelopeSettings | EnvelopeStatus | string;
}

export function envelopeSettingsReducer(
  state: IEnvelopeSettingsState,
  action: IEnvelopeAction
): IEnvelopeSettingsState {
  const { type, payload } = action;
  switch (type) {
    case EnvelopeSettingsActions.UPDATE_ERRORS: {
      if (Array.isArray(payload)) return { ...state, errors: payload };
      return state;
    }
    case EnvelopeSettingsActions.UPDATE_STATUS: {
      if (Object.values(EnvelopeStatus).includes(payload as EnvelopeStatus)) {
        return { ...state, status: payload as EnvelopeStatus };
      }
      return state;
    }
    case EnvelopeSettingsActions.UPDATE_VALUES: {
      if (typeof payload === "object" && !Array.isArray(payload)) {
        return { ...state, values: { ...state.values, ...payload } };
      }
      return state;
    }
    default:
      return state;
  }
}
