import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useState } from "react";

export type MuiChangeEvent =
  | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | SelectChangeEvent;

const useFormData = <T,>(
  initialValues: T
): [
  values: T,
  handleChange: (e: MuiChangeEvent) => void,
  handleReset: () => void
] => {
  const [values, setValues] = useState<T>(initialValues);
  function handleChange(e: MuiChangeEvent) {
    const name = e.target.name as keyof T;
    const currentValue = values[name];
    if (currentValue && Array.isArray(currentValue)) {
      if (currentValue.indexOf(e.target.value) === -1) {
        setValues({ ...values, [name]: [...currentValue, e.target.value] });
      } else {
        setValues({
          ...values,
          [name]: currentValue.filter((x) => x !== e.target.value),
        });
      }
    }
    if (typeof currentValue === "string")
      setValues({ ...values, [e.target.name]: e.target.value });
  }

  function handleReset() {
    setValues(initialValues);
  }

  return [values, handleChange, handleReset];
};

const useInput = (
  initialValue: string | string[]
): [string | string[], (e: MuiChangeEvent) => void, () => void] => {
  const [input, setInput] = useState(initialValue);
  function onChange(e: MuiChangeEvent) {
    setInput(e.target.value);
  }

  function onReset() {
    setInput(initialValue);
  }

  return [input, onChange, onReset];
};

export default useFormData;
