import {
  BulletAlignment,
  IBulletOptions,
  IBulletedList,
  IListItem,
  Textbox,
  fabric,
} from "fabric";
import generateGuid from "../../../../helpers/generateGuid";
import { Canvas, Group, IText, ITextOptions } from "fabric/fabric-impl";
import { Text } from "fabric/fabric-impl";
import { createBleedClipPath } from "./createBackgroundClipPath";
import textResizer from "./textResizer";
import bulletResizer from "./bulletResizer";
import asyncClone from "./asyncClone";
export const bulletSizeDivisor = 1.25;

const defaultText: ITextOptions = {
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: 36,
  strokeUniform: true,
  name: "text",
  fill: "#000000",
  objectCaching: false,
};

const defaultBullet: ITextOptions = {
  fontFamily: "FontAwesome",
  fontWeight: 900,
  fontSize: 36 / bulletSizeDivisor,
  evented: false,
  selectable: false,
  name: "bullet",
  fill: "#000000",
  objectCaching: false,
};

export default async function createBulletedList(canvas: Canvas) {
  const background = canvas._objects.find((x) => x.name === "background");
  if (
    !background ||
    background.left === undefined ||
    background.left === null ||
    background.top === undefined ||
    background.top === null
  )
    return undefined;
  const x = background.left;
  const y = background.top;
  const bullet = new fabric.Text("\ue122", defaultBullet);

  const tempText = new fabric.IText("New Bullet", defaultText);
  canvas.add(tempText);
  canvas.renderAll();
  const textWidth = tempText.width;
  canvas.remove(tempText);
  const text = new fabric.Textbox("New Bullet", {
    ...defaultText,
    width: textWidth,
  });

  text.set({ left: bullet.getLineWidth(0) + 36, top: 0 });
  bullet.set({
    left: 0,
    top: getBulletTop(text, bullet, "top"),
  });

  const listItem = new fabric.Group([bullet, text], {
    name: `listItem-0`,
    objectCaching: false,
  }) as IListItem;

  listItem.__index = 0;

  listItem.set({ left: 0, top: 0 });

  const list = new fabric.Group([listItem], {
    name: `bulletedList-${generateGuid()}`,
    left: x,
    top: y,
    objectCaching: false,
  }) as IBulletedList;

  canvas.add(list);

  list.__settings = {
    alignment: "top",
    hSpacing: 36,
    vSpacing: 36,
  };
  list.__fontSettings = defaultText;
  list.__bulletSettings = defaultBullet;
  list.__bullet = {
    type: "unicode",
    value: "e122",
    hexUni: "\ue122",
  };
  //list.clipPath = createBleedClipPath(canvas);

  canvas.renderAll();

  bulletResizer(list);
  return list;
}

/**
 * @todo Implement editable bullet lists with IText/Textbox and automatically calc the bullets.
 */
export function getBulletTop(
  text: Text,
  bullet: Text,
  alignment: BulletAlignment
) {
  if (alignment === "top")
    return text.getHeightOfChar(0, 0) / 2 - bullet.getHeightOfChar(0, 0) / 2;
  if (alignment === "center")
    return (text.height ?? 0) / 2 - bullet.getHeightOfChar(0, 0) / 2;
  if (alignment === "bottom")
    return (
      (text.height ?? 0) -
      bullet.getHeightOfChar(0, 0) -
      bullet.getHeightOfChar(0, 0) / 2
    );
}

export function createNewListItem(list: IBulletedList) {
  const canvas = list?.canvas;
  if (!canvas) return undefined;
  const x = list.left ?? 0;
  const y = list.top ?? 0;
  const previousItem = list._objects[list._objects.length - 1] as IListItem;
  const prevBullet = previousItem._objects.find((x) => x.name === "bullet");
  if (!prevBullet) return undefined;
  const text = new fabric.Textbox("New Bullet", {
    ...list.__fontSettings,
    width:
      (list.width ?? 0) - (prevBullet.width ?? 0) - list.__settings.hSpacing,
  });

  const bullet = new fabric.Text(
    list.__bullet.hexUni ?? "",
    list.__bulletSettings
  );

  text.set({ left: bullet.getLineWidth(0) + 36, top: 0 });
  bullet.set({
    left: 0,

    scaleX: prevBullet.scaleX,
    scaleY: prevBullet.scaleY,
    fill: prevBullet.fill,
  });

  bullet.set({
    top: getBulletTop(text, bullet, "top"),
  });

  const listItem = new fabric.Group([bullet, text], {
    name: `listItem-${list._objects.length}`,
    objectCaching: false,
  }) as IListItem;
  listItem.__index = list._objects.length;

  listItem.set({
    top: previousItem.getBoundingRect().height + list.__settings.vSpacing,
    left: previousItem.left,
    width: list.width,
  });

  list.add(listItem);

  list.set({
    height:
      (list.height ?? 0) + list.__settings.vSpacing + (listItem.height ?? 0),
  });

  reAlignListAndItems(list);

  canvas.renderAll();
}

export function reAlignListAndItems(list: IBulletedList) {
  let newHeight = 0;
  list._objects.forEach((item, i) => {
    item.set({ width: list.width, left: 0 - (list.width ?? 0) / 2 });
    const text = item._objects.find((x) => x.name === "text") as Text;
    const bullet = item._objects.find((x) => x.name === "bullet") as Text;
    if (text && bullet) {
      bullet.set({
        left: 0 - (list.width ?? 0) / 2,
      });
      text.set({
        width:
          (list.width ?? 0) - (bullet.width ?? 0) - list.__settings.hSpacing,
        left:
          bullet.getLineWidth(0) +
          list.__settings.hSpacing -
          (list.width ?? 0) / 2,
      });
    }
    item.set({
      height: text.__lineHeights.reduce((prev, height) => prev + height, 0),
    });
    text.top = ((item.height ?? 0) / 2) * -1;
    bullet.top = text.top + (getBulletTop(text, bullet, "top") ?? 0);
    newHeight += item.height ?? 0;
    if (i < list._objects.length - 1) {
      newHeight += list.__settings.vSpacing;
    }
  });
  list.set({ height: newHeight });

  const topLeft = getGroupTopLeft(list);
  (list._objects as IListItem[]).forEach((obj, i) => {
    if (i === 0) {
      obj.set({
        top: topLeft.top,
        left: topLeft.left,
        width: list.width,
      });
    } else {
      const prevItem = list._objects[i - 1];
      obj.set({
        top:
          (prevItem.top ?? 0) +
          (prevItem.height ?? 0) +
          list.__settings.vSpacing,
        left: topLeft.left,
        width: list.width,
      });
    }
  });
}

export function updateListAndListItems(
  list: IBulletedList,
  type: "text" | "bullet" | "settings",
  changes: Partial<ITextOptions> | IBulletOptions
) {
  if (type === "text") {
    const updates = changes as Partial<ITextOptions>;
    list.__fontSettings = { ...list.__fontSettings, ...updates };
    list.__bulletSettings.fontSize =
      (list.__fontSettings.fontSize ?? 0) / bulletSizeDivisor;
    list.__bulletSettings.fill = list.__bulletSettings.fill;
    list._objects.forEach((item) => {
      const textObject = item._objects.find(
        (x) => x.name === "text"
      ) as Textbox;
      const bulletObject = item._objects.find(
        (x) => x.name === "bullet"
      ) as Text;
      textObject.set(list.__fontSettings);
      bulletObject.set(list.__bulletSettings);
    });
    list.canvas?.renderAll();
    reAlignListAndItems(list);
  }
}

export function updateBullets(
  list: IBulletedList,
  newBullet: string,
  newHexUni: string
) {
  list._objects.forEach((item) => {
    const bullet = item._objects.find((x) => x.name === "bullet") as Text;
    if (bullet) {
      // @ts-ignore
      bullet.set({ text: newHexUni });
    }
  });
  list.__bullet = {
    ...list.__bullet,
    value: `${newBullet}`,
    hexUni: newHexUni,
  };
  list.canvas?.renderAll();
}

export function getGroupTopLeft(group: Group) {
  const groupTop = group.height ? (group.height / 2) * -1 : 0;
  const groupLeft = group.width ? (group.width / 2) * -1 : 0;
  return {
    top: groupTop,
    left: groupLeft,
  };
}

export function bulletedListEvents(list: IBulletedList) {
  // const text = list._objects.find((x) => x.name === "text") as IText;
  // const bullet = list._objects.find((x) => x.name === "bullet") as Text;
  // list.on("mousedblclick", () => {
  //   if (text) {
  //     text.enterEditing();
  //     text.canvas?.setActiveObject(text);
  //     list.selectable = false;
  //     list.evented = false;
  //     text.on("editing:exited", () => {
  //       list.selectable = true;
  //       list.evented = true;
  //       list.canvas?.setActiveObject(list);
  //       list.canvas?.renderAll();
  //     });
  //     text.on("changed", () => {
  //       if (bullet) {
  //         list.width = (bullet.width ?? 0) + (text.width ?? 0);
  //       }
  //       list.dirty = true;
  //       list.canvas?.renderAll();
  //     });
  //   }
  // });
}

export const bulletOptions = [
  {
    unicode: "e122",
    hexUni: "\ue122",
    label: "Circle",
    weight: 900,
  },
  {
    unicode: "f45c",
    hexUni: "\uf45c",
    label: "Square",
    weight: 900,
  },
  {
    unicode: "f005",
    hexUni: "\uf005",
    label: "Star",
    weight: 900,
  },
  {
    unicode: "f00c",
    hexUni: "\uf00c",
    label: "Check",
    weight: 900,
  },
  {
    unicode: "f061",
    hexUni: "\uf061",
    label: "Arrow",
    weight: 900,
  },
  {
    unicode: "f0da",
    hexUni: "\uf0da",
    label: "Caret",
    weight: 900,
  },
  {
    unicode: "f14a",
    hexUni: "\uf14a",
    label: "Square Check",
    weight: 900,
  },
  {
    unicode: "f058",
    hexUni: "\uf058",
    label: "Circle Check",
    weight: 900,
  },
  {
    unicode: "f219",
    hexUni: "\uf219",
    label: "Diamond",
    weight: 900,
  },
];
