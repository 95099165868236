import { useEffect } from "react";
import LazyLoad from "react-lazyload";
import { IFont } from "../googleFontsService/types";
import tw, { css } from "twin.macro";
import WebFont from "webfontloader";
import { useDesignerDispatch } from "../../../state/store";
import { loadFont } from "../../../state/slices/fontManager";
import nonGoogleFontSafeList, {
  FontFamilyLabels,
} from "../nonGoogleFontSafeList";
interface IFontProps {
  font: IFont;
}

const styles = {
  fontPreview: (fontFamily: string, isSidebar = false) => [
    css({ fontFamily: fontFamily }),
    tw`block`,
  ],
};

const Font = ({ font }: IFontProps) => {
  const dispatch = useDesignerDispatch();
  function loadFontPreview() {
    if (!font.previewLoaded) {
      WebFont.load({ google: { families: [font.family] } });
      //dispatch(loadFont({ ...font, previewLoaded: true }));
    }
  }

  const fontLabel = nonGoogleFontSafeList.includes(font.family)
    ? FontFamilyLabels[font.family as keyof typeof FontFamilyLabels]
    : font.family;

  useEffect(loadFontPreview, []);

  return (
    <div onClick={() => {}} css={styles.fontPreview(font.family)}>
      {fontLabel}
    </div>
  );
};

export default Font;
