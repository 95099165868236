import { useEffect } from "react";
import { useHistory } from "react-router";
import LocalStorage, { checkIfTokenExpired } from "../../helpers/LocalStorage";

import {
  ActionAlertsContext,
  useActionAlertsContext,
} from "../../data/State/ActionAlerts";
import { Redirect, useLocation } from "react-router-dom";
import isAdminUser from "../../helpers/isAdminUser";
import { Box, Grid, ThemeProvider, useMediaQuery } from "@mui/material";
import LayoutHeader from "./components/LayoutHeader";

import theme from "../../helpers/theme";
import LayoutSidebar from "./components/LayoutSidebar";

import ErrorBoundary from "../ErrorBoundary";
import isEditorUser from "../../helpers/isEditorUser";
import ReassignPanel from "../../routes/Admin/components/ReassignPanel";
import LiveChatAndTagManager from "../LiveChatAndTagManager";

const AuthLayout: React.FC = ({ children }) => {
  const history = useHistory();
  const alertContext = useActionAlertsContext();
  const token = LocalStorage.getToken();
  const tokenExpired = checkIfTokenExpired();
  const isXLScreenSize = useMediaQuery(theme.breakpoints.up("lg"));
  const isAdmin = isAdminUser();
  const isEditor = isEditorUser();
  const primaryToken = LocalStorage.getPrimaryToken();
  const location = useLocation();

  function checkIfCompletedRegistration() {
    if (token) {
      const companies = LocalStorage.getCompanies();
      const currentCompanyId = LocalStorage.getCurrentCompany();
      if (companies && currentCompanyId) {
        const currentCompany = companies.find(
          (x) => x.baccountId === currentCompanyId,
        );

        if (currentCompany && !currentCompany.hasCompletedSetup) {
          history.push("/complete-registration");
        }
      }
    }
  }

  useEffect(checkIfCompletedRegistration, [token]);

  if ((!token || tokenExpired) && window.location) {
    return <Redirect to="/login" />;
  }
  if (isEditor && !window.location.pathname.includes("editor")) {
    return <Redirect to="/editor" />;
  }
  if (
    isAdmin &&
    primaryToken === null &&
    !window.location.pathname.includes("admin")
  )
    return <Redirect to="/admin" />;
  return (
    <ThemeProvider theme={theme}>
      <ActionAlertsContext.Provider value={alertContext}>
        <Grid container>
          {(!isAdmin || primaryToken !== null) && <LayoutSidebar />}
          <Grid sx={{ backgroundColor: "#F4F4F4" }} item xs={12} md={12} lg xl>
            <LayoutHeader />
            <Box
              sx={{
                pl: isXLScreenSize ? 8 : 3,
                pr: isXLScreenSize ? 8 : 3,
                pt: 6,
                pb: 6,
              }}
            >
              <ErrorBoundary key={location.pathname}>{children}</ErrorBoundary>
            </Box>
          </Grid>
        </Grid>
        {isAdminUser() && <ReassignPanel />}
        <LiveChatAndTagManager />
      </ActionAlertsContext.Provider>
    </ThemeProvider>
  );
};

export default AuthLayout;
