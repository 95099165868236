import { RecipientListRecord } from "../../../routes/Batches/models/Batch";
import { QrCodeStylingOptions } from "../types/QrCodeStylingTypes";

export default interface QrCode {
  url: string;
  qrCodeID: number;
  config: QrCodeStylingOptions;
  created: string;
  deleted?: string;
  published?: string;
  results?: QrCodeTracking[];
  preview?: string;
}

export interface QrCodeTracking {
  id: number;
  qrCodeID: number;
  recordID: number;
  orderID: number;
  ipAddress: string;
  created: string;
  recipient?: RecipientListRecord;
  extended?: string;
}

export interface QrCodeRaw extends Omit<QrCode, "config"> {
  config: string;
}
export const newQrCode: QrCode = {
  config: {
    data: "https://yourwebsite.com",
    imageOptions: {
      hideBackgroundDots: true,
    },
    cornersSquareOptions: {
      type: "square",
      color: "#000000",
    },
    cornersDotOptions: {
      type: "square",
      color: "#000000",
    },
    dotsOptions: {
      color: "#000000",
      type: "square",
    },
    backgroundOptions: {
      color: "#FFFFFF",
    },
  },
  url: "",
  qrCodeID: -1,
  created: "",
};
