/* eslint-disable */ import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import AliceCarousel from "react-alice-carousel";
import StarIcon from "@mui/icons-material/Star";
import "./alice-carousel.css";
import { Link } from "react-router-dom";
import { fetchClient } from "../../../helpers/fetchClient";
import LoadingButton from "../../../shared/LoadingButton";
import Img from "../../../shared/BasicHTML/Img";

const RequestADesign = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  function submitRequest() {
    setIsSubmitting(true);
    fetchClient.post({
      path: "/email/send",
      data: {
        emailTo: "darianr@postcardmania.com",
        emailBody: "Please reach out to the customer as soon as possible!",
        subject: "API Customer would like to order a custom design",
      },
      onSuccess: onSubmissionResponse,
    });
  }

  function onSubmissionResponse() {
    setIsSubmitting(false);
    setIsSuccess(true);
  }
  if (isSuccess) {
    return (
      <Paper>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Your request was successfully submitted
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            A representative will reach out to you as soon as possible to
            finalize your order and gather more details.
          </Typography>
          <Button
            component={Link}
            to="/designs"
            color="primary"
            variant="contained"
          >
            Back To My Designs
          </Button>
        </Box>
      </Paper>
    );
  }
  return (
    <Paper>
      <Box sx={{ p: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Purchase a Custom Design
          </Typography>

          <Typography variant="body1" gutterBottom>
            Our graphic design team can create a custom design for you based on
            your specifications. All design orders include unlimited revisions
            until you are 100% happy with the final design. You can provide
            images or we will select stock photos from our vast library at no
            additional charge. If you are interested, confirm below, and a
            representative will reach out as soon as possible.
          </Typography>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Actual Designs Done By Our Team
          </Typography>
          {/* design carousel  2*/}
          <AliceCarousel
            disableDotsControls
            disableButtonsControls
            autoPlay
            infinite
            autoPlayInterval={0}
            animationDuration={1000}
            paddingLeft={32}
            paddingRight={32}
            responsive={{
              0: {
                items: 1,
              },
              768: {
                items: 3,
              },
              1024: {
                items: 3,
              },
            }}
          >
            <Img
              src="/assets/img/custom-design-gallery/1.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/2.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/3.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/4.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/5.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/6.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/7.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/8.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/9.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/10.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/11.jpg"
              className="carousel-image"
            />
            <Img
              src="/assets/img/custom-design-gallery/12.jpg"
              className="carousel-image"
            />
          </AliceCarousel>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card elevation={4}>
                <CardContent>
                  <Box className="design-review">
                    <Box className="design-review--stars">
                      <StarIcon color="primary" /> <StarIcon color="primary" />{" "}
                      <StarIcon color="primary" /> <StarIcon color="primary" />{" "}
                      <StarIcon color="primary" />
                    </Box>
                    <Typography variant="body1">
                      I really like that you nailed what we were looking for
                      just from the survey.
                    </Typography>
                    <Typography variant="body1" component="span">
                      &mdash;Innovative Physical Therapy
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card elevation={4}>
                <CardContent>
                  <Box className="design-review">
                    <Box className="design-review--stars">
                      <StarIcon color="primary" /> <StarIcon color="primary" />{" "}
                      <StarIcon color="primary" /> <StarIcon color="primary" />{" "}
                      <StarIcon color="primary" />
                    </Box>
                    <Typography variant="body1">
                      The postcards that you made are beautiful! You do
                      excellent work!
                    </Typography>
                    <Typography variant="body1" component="span">
                      &mdash;Donna Sveinsvoll
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ pt: 3 }}>
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item xs="auto">
              <Button color="primary" component={Link} to="/designs">
                Back
              </Button>
            </Grid>
            <Grid item xs="auto">
              <LoadingButton
                color="primary"
                variant="contained"
                loading={isSubmitting}
                success={isSuccess}
                onClick={submitRequest}
              >
                Request A Design
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default RequestADesign;
