import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ICurrentSelection from "../models/ICurrentSelection";
import { DesignerState } from "../store";

const initialState: ICurrentSelection = {};

export const currentSelectionSlice = createSlice({
  name: "currentSelection",
  initialState: initialState,
  reducers: {
    updateCurrentSelection: (
      state: ICurrentSelection,
      action: PayloadAction<ICurrentSelection>
    ) => {
      state = { ...action.payload };
    },
  },
});

export const { updateCurrentSelection } = currentSelectionSlice.actions;
export const selectCurrentlySelected = (state: DesignerState) =>
  state.currentSelection;
export default currentSelectionSlice.reducer;
