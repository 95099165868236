import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const SuccessPage = () => {
  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
        Your PCM Integrations account is now setup!
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontWeight: "bold", fontSize: "1.15rem" }}
        gutterBottom
      >
        <Typography
          component="a"
          target="_blank"
          sx={{ fontWeight: "bold", fontSize: "1.15rem" }}
          href="https://api.pcmintegrations.com"
          rel="noreferrer"
        >
          Check out the documentation
        </Typography>{" "}
        for more information on using the Direct Mail API
      </Typography>
      <Typography variant="body1">
        You can head to your dashboard by <Link to="/">clicking here</Link>
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Need to connect to Zapier?
      </Typography>
      <Typography variant="body1" gutterBottom>
        We&apos;ve made it easy to connect CRM directly to our Direct Mail API
        with{" "}
        <Typography
          variant="body1"
          component="a"
          href="https://zapier.com"
          target="_blank"
          rel="noreferrer"
        >
          Zapier
        </Typography>{" "}
        to automate workflows.
      </Typography>
      <Box component="ol">
        <li>
          Go to your Zapier account (or create a free account{" "}
          <Typography
            component="a"
            rel="noreferrer"
            href="https://zapier.com/sign-up/"
            target="_blank"
          >
            here
          </Typography>
          )
        </li>
        <li>Click &quot;Make a Zap!&quot;</li>
        <li>Select your CRM app</li>
        <li>Choose your trigger event</li>
        <li>Sign in to your CRM using the prompt</li>
        <li>Follow the prompts to test your zap</li>
        <li>
          Under &quot;Do this...&quot; search &quot;PostcardMania&quot; and
          select it
        </li>
        <li>Log in to the PostcardMania account you just created</li>
        <li>
          Select &quot;Send a Postcard&quot; and click &quot;Continue&quot;
        </li>
        <li>
          Select your company name and your chosen design from the dropdown
        </li>
        <li>
          Map your client address, city, state, zip, first name and last name
          fields
        </li>
        <li>
          Map your business information. This is what will be automatically
          populated on the return address of your postcards. Click
          &quot;Continue&quot;
        </li>
        <li>&quot;Done Editing&quot; and click &quot;Turn Zap On&quot;</li>
      </Box>
    </Box>
  );
};

export default SuccessPage;
