import React, {
  InputHTMLAttributes,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import DesignerInputWrapper from "./DesignerInputWrapper";
import tw, { css, TwStyle } from "twin.macro";
import { ISelectOption, ISelectOptionProps } from "./SelectOption";
import DesignerSelectOptions from "./DesignerSelectOptions";
import InputReset from "../InputReset";
import { CSSInterpolation } from "@mui/material";
import { SerializedStyles } from "@emotion/react";
import ColorPicker from "./ColorPicker";
// import { ReactComponent as SelectInputIcon } from "../../../../../assets/images/designer-svg/SelectInputIcon.svg";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { SelectInputIcon, SvgElement } from "../SvgComponents";
import TwinMacroStyles from "../../../../models/types/TwinMacroStyles";
import EditorTooltip from "../EditorTooltip";

interface IDesignerInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "style"> {
  type: "text" | "number" | "select" | "color";
  inputStyle?: TwinMacroStyles;
  wrapperStyle?: (TwStyle | SerializedStyles)[] | (TwStyle | SerializedStyles);
  min?: number;
  max?: number;
  small?: boolean;
  children?:
    | React.ReactElement<ISelectOptionProps>
    | React.ReactElement<ISelectOptionProps>[];
  suffix?: string;
  preIcon?: SvgElement;
  position?: "left" | "right" | "center";
  onClose?: (color: string | undefined) => void;
  tooltip?: string | React.ReactFragment;
  disableAutoSelect?: boolean;
}

const styles = {
  preIcon: (disabled?: boolean) => [
    tw`fill-text mr-2 ml-0.5`,
    tw`w-4 h-4`,
    disabled &&
      css`
        color: rgba(0, 0, 0, 0.4);
      `,
  ],
  preFix: (disabled?: boolean) => [
    tw`text-text mr-2 ml-0.5 italic `,
    tw`text-xs`,
    css`
      line-height: 1;
    `,
    disabled &&
      css`
        color: rgba(0, 0, 0, 0.4);
      `,
  ],
  selectChevron: (isOpen: boolean) => [
    tw`ml-2 mr-0.5 relative`,
    css`
      top: 1px;
    `,
    isOpen
      ? css`
          transform: rotate(180deg);
        `
      : css``,
  ],
  inputContainer: (isSmall: boolean) => [
    tw`flex-1 flex flex-row items-center cursor-default`,
  ],
  input: [
    tw`w-full`,
    css`
      line-height: 1;
      &:disabled {
        color: rgba(0, 0, 0, 0.4);
      }
    `,
  ],
};

/**
 * 
 suffix !== undefined &&
      css({
        position: "relative",
        display: "inline-block",
        ":after": {
          content: `'${suffix}'`,
          display: "inline-block",
          fontFamily: "inherit",
          width: "1em",
          height: "1em",
          position: "absolute",
          top: "50%",
          right: "5px",
          transform: "translateY(-50%)",
        },
      }),
 * @returns 
 */

const DesignerInput = ({
  type,
  small = false,
  children,
  suffix,
  preIcon,
  value,
  onChange,
  name,
  onInput,
  onInputCapture,
  onBlurCapture,
  min,
  max,
  inputStyle: inputStyle,
  wrapperStyle,
  onBlur,
  onKeyPress,
  onKeyDown,
  position,
  prefix,
  onClose,
  disabled,
  tooltip,
  placeholder,
  disableAutoSelect = false,
}: IDesignerInputProps) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const ref = useRef<HTMLDivElement>(null);
  const close = useCallback(() => {
    setSelectOpen(false);
  }, []);
  useClickOutside(ref, close);

  function handleSelectClose() {
    setSelectOpen(false);
  }

  function handleInputClick() {
    if (type === "select") {
      setSelectOpen(!selectOpen);
    }

    if (inputRef.current && !disableAutoSelect) {
      inputRef.current.select();
    }
  }

  function handleClickChange(val?: string) {
    if (!onChange) return;

    onChange({
      target: { name: name, value: val },
    } as React.ChangeEvent<HTMLInputElement>);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      if (suffix && type !== "number") {
        e.target.value = e.target.value.replace(suffix, "");
      }

      if (type === "number") {
        e.target.value = e.target.value.replaceAll(/[^0-9.\-]/g, "");
        const num = parseFloat(e.target.value);
        if (!Number.isNaN(num)) {
          if (min !== undefined) {
            if (num < min) {
              e.target.value = min.toString();
            }
          }
          if (max !== undefined) {
            if (num > max) {
              e.target.value = max.toString();
            }
          }
        }

        if (e.target.value.length === 0) {
          // val = min?.toString() ?? "0";
        } else {
          // if (min) {
          //   const value = parseInt(val)
          //   if (val < min) {
          //     e.target.value = min.toString();
          //   }
          // }
          // if (max) {
          //   if (val > max) {
          //     e.target.value = max.toString();
          //   }
          // }
        }
      }

      onChange(e);
    }
  }

  function getInput() {
    const input = (
      <InputReset
        type="text"
        ref={inputRef}
        value={
          type === "color" && value === undefined
            ? "(no-color)"
            : value?.toString() ?? ""
        }
        onChange={handleChange}
        name={name}
        css={styles.input}
        size={1}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        disabled={disabled}
        placeholder={placeholder}
      />
    );
    if (suffix) {
      return (
        <div
          css={[
            styles.input,
            css({
              position: "relative",

              ":after": {
                content: `'${suffix}'`,
                display: "inline-block",
                fontFamily: "inherit",
                width: "1em",
                height: "1em",
                position: "absolute",
                top: "50%",
                right: "0",
                transform: "translateY(-50%)",
              },
            }),
          ]}
        >
          {input}
        </div>
      );
    } else return input;
  }

  function getSelectValue() {
    if (!children) return "";
    let arr: React.ReactElement<
      ISelectOptionProps,
      string | React.JSXElementConstructor<any>
    >[] = [];
    if (!Array.isArray(children))
      arr = [
        children as React.ReactElement<
          ISelectOptionProps,
          string | React.JSXElementConstructor<any>
        >,
      ];
    else arr = children;
    const val = arr.find((x) => x.props.value && x.props.value === value);
    if (val) {
      return val.props.children;
    } else return val;
  }

  const PreIcon = preIcon;
  return (
    <DesignerInputWrapper
      small={small}
      isOpen={selectOpen}
      ref={ref}
      styleOverride={wrapperStyle}
      position={position}
      tooltip={tooltip}
      disabled={disabled}
    >
      <div
        css={[
          ...styles.inputContainer(preIcon !== undefined),
          inputStyle !== undefined && inputStyle,
        ]}
        onClick={handleInputClick}
      >
        {PreIcon !== undefined && (
          <div css={styles.preIcon(disabled)}>
            <PreIcon styles={styles.preIcon(disabled)} />
          </div>
        )}

        {prefix !== undefined && (
          <div css={styles.preFix(disabled)}>{prefix}</div>
        )}
        {type === "color" && (
          <div css={styles.preIcon(disabled)}>
            <ColorPicker
              small={small}
              color={value?.toString()}
              onChange={handleClickChange}
              onClose={onClose}
            />
          </div>
        )}
        {type !== "select" && <React.Fragment>{getInput()}</React.Fragment>}
        {type === "select" && <div css={styles.input}>{getSelectValue()}</div>}
        {type === "select" && (
          <div>
            <SelectInputIcon styles={styles.selectChevron(selectOpen)} />
          </div>
        )}
      </div>
      {children !== undefined && type === "select" && (
        <DesignerSelectOptions
          onClick={handleClickChange}
          isOpen={selectOpen}
          onClose={handleSelectClose}
          small={small}
        >
          {children}
        </DesignerSelectOptions>
      )}
    </DesignerInputWrapper>
  );
};

export default DesignerInput;
