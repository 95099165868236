import { Box, Link, Paper, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import App from "../../../data/models/App";
import { fetchClient } from "../../../helpers/fetchClient";
import getDocsUrl from "../../../helpers/getDocsUrl";
import useGetData from "../../../hooks/dataFetchers/useGetData";

import AuthExample from "../../../shared/JSONExamples/AuthExample";
import GridItem from "../../../shared/MaterialWrappers/GridItem";
import GridRow from "../../../shared/MaterialWrappers/GridRow";
import AccountWrapper from "../components/AccountWrapper";
import ApiKey from "./components/ApiKey";
import ApiSecret from "./components/ApiSecret";

const ApiKeys: FC = (props) => {
  /**
   * State Objects and Refs
   */

  const { data, isLoading, error } = useGetData<App[]>(
    `/api/app`,
    [],
    (data: App[]) => onGetApiKeysResponse
  );

  const [apps, setApps] = useState<App[]>([]);
  const [exampleKey, setExampleKey] = useState<App>({
    apiKey: "",
    appType: "",
    appID: 0,
  });

  /**
   * Component Methods
   */
  function getApiKeys() {
    fetchClient.get({ path: "/app", onSuccess: onGetApiKeysResponse });
  }

  function onGetApiKeysResponse(res: App[]) {
    const sandboxIndex = res.findIndex((x) => x.appType === "sandbox");
    const productionIndex = res.findIndex((x) => x.appType === "production");

    if (productionIndex !== -1) {
      setExampleKey(res[productionIndex]);
    } else if (sandboxIndex !== -1) {
      setExampleKey(res[sandboxIndex]);
    } else {
      setExampleKey(res[0]);
    }

    const appArray = [];

    if (productionIndex !== -1) appArray.push(res[productionIndex]);
    if (sandboxIndex !== -1) appArray.push(res[sandboxIndex]);
    if (appArray.length > 0) setApps(appArray);
    else setApps(res);
  }
  /**
   * Component Effects
   */

  useEffect(getApiKeys, []);

  /**
   * Render
   */

  return (
    <AccountWrapper
      loading={apps.length === 0}
      pageTitle="API Keys"
      loadError={error.hasError}
    >
      <Box>
        <Box sx={{ mb: 4 }}>
          <GridRow>
            {apps.map((app) => (
              <GridItem key={app.apiKey} xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <ApiKey app={app} />
                  <ApiSecret app={app} />
                </Paper>
              </GridItem>
            ))}
          </GridRow>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Example Login JSON
          </Typography>
          <Typography variant="body1">
            Your API Key is used to authenticate with the Direct Mail API. Below
            you will find an example of the JSON request body the API expects
            for authentication. For more details about authentication,{" "}
            <Link
              href={`${getDocsUrl()}/help/docs/auth/login`}
              target="_blank"
              rel="noreferer"
            >
              please view our documentation
            </Link>
            .
          </Typography>
        </Box>
        <AuthExample apiKey={exampleKey.apiKey} />
      </Box>
    </AccountWrapper>
  );
};

export default ApiKeys;
