import { FunctionComponent, SVGProps } from "react";
import { Tool } from "../../../state/models/ICanvasTool";
import tw, { css } from "twin.macro";
import ButtonReset from "../shared/ButtonReset";
import { SvgElement } from "../shared/SvgComponents";

interface IToolSelectButtonProps {
  svg: FunctionComponent<SVGProps<SVGSVGElement>> | SvgElement;
  isActive: boolean;
  onClick: () => void;
  disabled?: boolean;
  flip?: boolean;
  tooltip?: string;
  label: string;
}

const styles = {
  button: (isActive: boolean, isDisabled: boolean) => [
    tw`w-full hover:bg-hover flex justify-center items-center`,
    isActive && tw`bg-hover`,
    isDisabled && tw`hover:bg-glacier`,
  ],
  svg: (isActive: boolean, isDisabled: boolean, flip: boolean) => [
    tw`w-5 h-5 fill-labels`,
    isActive && tw`fill-blue`,
    isDisabled && tw`fill-buttonDisabled`,
    flip && tw`rotate-180`,
  ],
  svgWrap: [tw`flex justify-center`],
  wrap: [tw`text-center  py-4`],
  label: [tw`[font-size: 11px] block mt-1`],
};

const ToolSelectButton = ({
  svg: Svg,
  isActive,
  onClick,
  disabled = false,
  flip = false,
  tooltip,
  label,
}: IToolSelectButtonProps) => {
  return (
    <ButtonReset
      css={styles.button(isActive, disabled)}
      onClick={() => onClick()}
      title={tooltip}
      disabled={disabled}
    >
      <div css={styles.wrap}>
        <div css={styles.svgWrap}>
          <Svg css={styles.svg(isActive, disabled, flip)} />
        </div>
        <span css={styles.label}>{label}</span>
      </div>
    </ButtonReset>
  );
};

export default ToolSelectButton;
