import { fabric } from "fabric";
export default function centerBleedOnCanvas(canvas: fabric.Canvas) {
  const bleed = canvas.getObjects().find((x) => x.name === "bleed");
  if (bleed) {
    const left = bleed.left ?? 0;
    const top = bleed.top ?? 0;
    canvas.centerObject(bleed);
    console.log(left, top, bleed.left, bleed.top);
    canvas.renderAll();
    const leftDiff = bleed.left! - left;
    const topDiff = bleed.top! - top;
    canvas.getObjects().forEach((obj) => {
      if (obj.name !== "bleed") {
        obj.left = obj.left! + leftDiff;
        obj.top = obj.top! + topDiff;
        obj.clipPath = new fabric.Rect({
          height: bleed.height,
          width: bleed.width,
          left: bleed.left,
          top: bleed.top,
          absolutePositioned: true,
        });
      }
    });
    canvas.renderAll();
  }
}
