import { Path } from "fabric";
import { Canvas } from "fabric/fabric-impl";

export default function insertPathIntoCanvas(path: Path, canvas: Canvas) {
  const layerName = path.name ?? "";
  const layer = canvas._objects.findIndex((x) => x.name === layerName);
  if (layer !== -1) {
    canvas.insertAt(path, layer, false);
  } else {
    canvas.add(path);
  }
}
