import { Grid, GridProps, GridSize } from "@mui/material";

import { FC } from "react";

interface GridItemProps extends GridProps {
  breakPoints?: GridSize[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
const GridItem: FC<GridItemProps> = (props) => {
  /**
   * State Objects and Refs
   */

  const { breakPoints, children, ...rest } = props;

  const [xs, md, lg, xl] = breakPoints ?? [
    undefined,
    undefined,
    undefined,
    undefined,
  ];
  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */

  return (
    <Grid item xs={xs} md={md} lg={lg} xl={xl} {...rest}>
      {children}
    </Grid>
  );
};

export default GridItem;
