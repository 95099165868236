import React from "react";
import { Provider } from "react-redux";
import CanvasProvider from "./contexts/CanvasContext";
import HistoryProvider from "./contexts/HistoryContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PagesProvider from "./contexts/PagesContext";
import SaveDataProvider from "./contexts/SaveDataContext";

import SelectedObjectProvider from "./contexts/SelectedObjectContext";
import SelectedPageProvider from "./contexts/SelectedPageContext";
import { store } from "./store";
import GuidesProvider from "./contexts/GuideContext";
import DesignVariablesProvider from "./contexts/DesignVariablesContext";

const StateProvider = ({ children }: { children?: React.ReactFragment }) => {
  return (
    <Provider store={store}>
      <SaveDataProvider>
        <CanvasProvider>
          <GuidesProvider>
            <HistoryProvider>
              <PagesProvider>
                <SelectedPageProvider>
                  <SelectedObjectProvider>
                    <DesignVariablesProvider>
                      <DndProvider backend={HTML5Backend}>
                        {children}
                      </DndProvider>
                    </DesignVariablesProvider>
                  </SelectedObjectProvider>
                </SelectedPageProvider>
              </PagesProvider>
            </HistoryProvider>
          </GuidesProvider>
        </CanvasProvider>
      </SaveDataProvider>
    </Provider>
  );
};

export default StateProvider;
