import { Textbox } from "fabric";
import { Object as IObject, IText } from "fabric/fabric-impl";
import OFF_LIMITS_VARS from "../../../constants/OFF_LIMITS_VARS";

export function getVarsFromString(str: string) {
  const vars: string[] = [];
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) === "{" && str.charAt(i + 1) === "{") {
      const stopIndex = str.indexOf("}", i);
      if (
        stopIndex !== -1 &&
        vars.indexOf(str.substring(i + 2, stopIndex)) === -1
      )
        vars.push(str.substring(i + 2, stopIndex));
    }
  }
  return vars;
}

export function sanitizeTextObjectVariables(obj: Textbox): string[] {
  const text = obj.text || "";
  let sanitizedText = text;
  const vars = getVarsFromString(text);
  if (vars.length) {
    const sanitized = vars.map((v) => {
      const sanitizedVar = v.replaceAll(/^[^a-zA-Z_${}]|[^0-9a-zA-Z_${}]/g, "");

      sanitizedText = sanitizedText.replaceAll(
        `{{${v}}}`,
        `{{${sanitizedVar}}}`
      );
      return sanitizedVar;
    });
    obj.text = sanitizedText;
    obj.canvas?.renderAll();

    // Filter out any off limits variables
    return sanitized.filter((x) => !OFF_LIMITS_VARS.includes(x.toLowerCase()));
  }
  return [];
}

export function sanitizeImageVariable(obj: IObject) {
  if (obj.name) {
    obj.name = obj.name.replaceAll(/^[^a-zA-Z_$]|[^0-9a-zA-Z_$]/g, "");
    obj.canvas?.renderAll();
  }
}

export function varCleanup(textObj: Textbox | IText) {
  if (!textObj.text) return;

  const styles = { ...(textObj.styles ?? {}) };

  const text = textObj.text;
  if (!text) return;
  const lines = text.split("\n");
  const corrected = lines.map((line, index) => {
    const matches = line.match(/{{[^\s]*?}}|{[^\s]*?}|{([^\s]*)|([^\s]*)}/g);

    if (matches) {
      const corrections = matches.map((x) => {
        if (x.startsWith("{{") && x.endsWith("}}")) return x;
        const y = `{{${x.replaceAll("{", "").replaceAll("}", "")}}}`;
        if (y === "{{}}") return "";
        return y;
      });

      let correctedText = line;
      corrections.forEach((c, i) => {
        const match = matches[i];

        if (styles[index]) {
          const matchIndex = line.indexOf(match);
          const matchStyles = styles[index][matchIndex] ?? {};

          const length = match.length;
          for (let i = matchIndex; i < length + matchIndex; i++) {
            styles[index][i] = matchStyles;
          }
        }

        correctedText = correctedText.replaceAll(matches[i], c);
      });
      return correctedText;
    }
    return line;
  });

  textObj.text = corrected.join("\n");

  textObj.canvas?.renderAll();
}
