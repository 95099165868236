import { Group, Object as IObject } from "fabric/fabric-impl";
import PropertyDropdown from "./PropertyDropdown";
import useImageMasking, {
  IImageGroup,
  MaskType,
} from "../../../hooks/useImageMasking/useImageMasking";
import tw, { css } from "twin.macro";
import {
  AngleIcon,
  CircleIcon,
  EditIcon,
  EllipseIcon,
  RectangleIcon,
  RemoveIcon,
  SkewXIcon,
  SkewYIcon,
  SquareIcon,
} from "../shared/SvgComponents";
import EditorTooltip from "../shared/EditorTooltip";
import propertiesStyles from "./propertiesStyles";
import DesignerButton from "../shared/DesignerButton";
import ToolButton from "../shared/ToolButton";
import { useEffect, useRef, useState } from "react";
import DesignerInput from "../shared/DesignerInput/DesignerInput";
import {
  convertPositionToPixels,
  convertSizeToPixels,
  getPositionInInches,
  getSizeInInches,
} from "../../Canvas/functions/dimensionHelpers";
import RESOLUTION from "../../../constants/RESOLUTION";
import { IObjectProperties } from "../../../state/contexts/SelectedObjectContext";
import generateGuid from "../../../../helpers/generateGuid";

interface IImageFramesProps {
  image: IImageGroup;
  updateRadiusBool: (show: boolean) => void;
  forceUpdate: (change: string) => void;
}

const styles = {
  frameButtonContainer: [tw`flex items-center`],
  frameButton: (isActive: boolean) => [
    tw`p-2.5 mr-2 rounded bg-inputBackground text-text border-solid border-border border hover:bg-hover cursor-pointer text-xs`,
    isActive && tw`bg-hover`,
  ],
  tooltip: [
    tw`bg-text rounded p-1 whitespace-nowrap bg-opacity-10 text-white`,
    css`
      font-size: 12px;
    `,
  ],
};

const ImageFrames = ({
  image,
  updateRadiusBool,
  forceUpdate,
}: IImageFramesProps) => {
  const {
    editMode,
    toggleEditMode,
    changeMaskType,
    maskType,
    frame,
    image: img,
  } = useImageMasking(image);

  const imageRef = useRef(image);
  const [frameDimensions, setFrameDimensions] = useState(getFrameDimensions());
  const [imageDimensions, setImageDimensions] = useState(getImageDimensions());
  function handleFrameTypeClick(type: MaskType) {
    if (type !== "rect" && type !== "square") {
      updateRadiusBool(false);
    } else {
      updateRadiusBool(true);
    }
    changeMaskType(type);
    forceUpdate(generateGuid());
    syncFrameDimensions();
  }

  function getFrameDimensions() {
    const frame = image._objects.find((x) => x.type !== "image");
    if (!frame)
      return {
        width: "0",
        height: "0",
        x: "0",
        y: "0",
        angle: "0",
        skewX: "0",
        skewY: "0",
      };
    const size = getSizeInInches(frame, image);

    return {
      width: size.width,
      height: size.height,
      angle: frame.angle?.toString() ?? "0",
      skewX: frame.skewX?.toString() ?? "0",
      skewY: frame.skewY?.toString() ?? "0",
    };
  }

  function getImageDimensions() {
    const img = image._objects.find((x) => x.type === "image");
    if (!img)
      return {
        width: "0",
        height: "0",
        x: "0",
        y: "0",
        angle: "0",
      };
    const width = img.width ?? 0;
    const height = img.height ?? 0;
    const scaleX = img.scaleX ?? 1;
    const scaleY = img.scaleY ?? 1;
    const groupScaleX = image.scaleX ?? 1;
    const groupScaleY = image.scaleY ?? 1;
    const widthInches = ((width * scaleX * groupScaleX) / RESOLUTION).toFixed(
      3
    );
    const heightInches = ((height * scaleY * groupScaleY) / RESOLUTION).toFixed(
      3
    );
    const position = getPositionInInches(img);
    return {
      width: widthInches,
      height: heightInches,
      x: position.x,
      y: position.y,
      angle: img.angle?.toString() ?? "0",
    };
  }

  function syncFrameDimensions() {
    setFrameDimensions(getFrameDimensions());
  }

  function syncImageDimensions() {
    setImageDimensions(getImageDimensions());
  }

  function handleFrameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    const newFrameDimensions = { ...frameDimensions };
    if (target.name === "width" || target.name === "height") {
      const size = {
        width: frameDimensions.width,
        height: frameDimensions.height,
      };
      if (target.name === "width") size.width = target.value;
      if (target.name === "height") size.height = target.value;

      if (maskType === "square") {
        if (target.name === "width") {
          size.height = target.value;
          newFrameDimensions.height = target.value;
        }
        if (target.name === "height") {
          size.width = target.value;
          newFrameDimensions.width = target.value;
        }
      }
      const newSize = convertSizeToPixels(size, {
        scaleX: image.scaleX ?? 1,
        scaleY: image.scaleY ?? 1,
      });
      if (newSize) {
        image.__updateFrameDimensions({
          width: newSize.width,
          height: newSize.height,
        });
      }
    } else {
      const val = parseFloat(target.value);

      image.__updateFrameDimensions({
        [target.name as keyof IObject]: val,
      });
    }
    image.canvas?.renderAll();

    setFrameDimensions({ ...newFrameDimensions, [target.name]: target.value });
  }

  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    const newDimensions = { ...imageDimensions };
    if (target.name === "height" || target.name === "width") {
      const width = img.width ?? 0;
      const height = img.height ?? 0;

      let scale = img.scaleX ?? 1;
      const groupScaleX = image.scaleX ?? 1;
      const groupScaleY = image.scaleY ?? 1;
      const val = parseFloat(target.value);

      if (!Number.isNaN(val)) {
        if (target.name === "width") {
          const pixelWidth = (val * RESOLUTION) / groupScaleX;

          scale = pixelWidth / width;

          newDimensions.height = (
            (height * scale * groupScaleY) /
            RESOLUTION
          ).toFixed(3);
        } else {
          const pixelHeight = (val * RESOLUTION) / groupScaleY;
          scale = pixelHeight / height;

          newDimensions.width = (
            (width * scale * groupScaleX) /
            RESOLUTION
          ).toFixed(3);
        }
      }
      image.__updateImageDimensions({ scaleX: scale, scaleY: scale });
    } else if (target.name === "x" || target.name === "y") {
      const pos = { x: imageDimensions.x, y: imageDimensions.y };
      if (target.name === "x") pos.x = target.value;
      if (target.name === "y") pos.y = target.value;
      const newPosition = convertPositionToPixels(pos);

      if (newPosition) {
        image.__updateImageDimensions({
          left: newPosition.x,
          top: newPosition.y,
        });
        image.canvas?.renderAll();
      }
    } else {
      const val = parseFloat(target.value);
      image.__updateImageDimensions({ [target.name]: val });
    }

    setImageDimensions({ ...newDimensions, [target.name]: target.value });
  }

  function syncSettings() {
    syncImageDimensions();
    syncFrameDimensions();
  }

  function removeListeners() {
    if (imageRef.current) {
      imageRef.current.off("scaling", syncSettings);
    }
  }

  function setupListeners() {
    if (imageRef.current) {
      removeListeners();
      imageRef.current = image;
    }

    image.on("scaling", syncSettings);

    return () => {
      removeListeners();
    };
  }

  useEffect(() => {
    syncFrameDimensions();
    syncImageDimensions();
  }, [image, frame, img]);

  useEffect(setupListeners, [image]);

  return (
    <PropertyDropdown label="Image Frame" isPopout>
      <div css={propertiesStyles.section}>
        <div css={propertiesStyles.sectionSubheading}>Frame Shape</div>
        <div css={styles.frameButtonContainer}>
          <button
            onClick={() => handleFrameTypeClick("rect")}
            css={styles.frameButton(maskType === "rect")}
          >
            <EditorTooltip
              label={
                <RectangleIcon
                  size={6}
                  styles={maskType === "rect" ? tw`fill-blue` : undefined}
                />
              }
              position="top"
            >
              <div css={styles.tooltip}>Rectangular Frame</div>
            </EditorTooltip>
          </button>
          <button
            onClick={() => handleFrameTypeClick("circle")}
            css={styles.frameButton(maskType === "circle")}
          >
            <EditorTooltip
              label={
                <CircleIcon
                  size={6}
                  styles={maskType === "circle" ? tw`fill-blue` : undefined}
                />
              }
              position="top"
            >
              <div css={styles.tooltip}>Circular Frame</div>
            </EditorTooltip>
          </button>
          <button
            onClick={() => handleFrameTypeClick("square")}
            css={styles.frameButton(maskType === "square")}
          >
            <EditorTooltip
              label={
                <SquareIcon
                  size={6}
                  styles={maskType === "square" ? tw`fill-blue` : undefined}
                />
              }
              position="top"
            >
              <div css={styles.tooltip}>Square Frame</div>
            </EditorTooltip>
          </button>
          <button
            onClick={() => handleFrameTypeClick("rect")}
            css={styles.frameButton(false)}
          >
            <EditorTooltip label={<RemoveIcon size={6} />} position="top">
              <div css={styles.tooltip}>Reset Frame</div>
            </EditorTooltip>
          </button>
        </div>
      </div>
      <div css={propertiesStyles.section}>
        <div css={tw`flex justify-between items-center mb-2`}>
          <div css={propertiesStyles.sectionSubheading}>Frame Dimensions</div>
          {/* <EditorTooltip
            position="top"
            disableLock
            label={
              <ToolButton css={tw`p-2`}>
                <EditIcon />
              </ToolButton>
            }
          >
            <div css={styles.tooltip}>Edit image frame</div>
          </EditorTooltip> */}
        </div>

        <div css={propertiesStyles.propertiesGroup}>
          <div css={propertiesStyles.property}>
            <DesignerInput
              name="width"
              prefix="Width"
              value={frameDimensions.width}
              onChange={handleFrameChange}
              type="number"
              suffix={`"`}
              inputStyle={propertiesStyles.input}
            />
          </div>
          <div css={propertiesStyles.property}>
            <DesignerInput
              name="height"
              prefix="Height"
              value={frameDimensions.height}
              onChange={handleFrameChange}
              type="number"
              suffix={`"`}
              inputStyle={propertiesStyles.input}
            />
          </div>
        </div>
        <div css={propertiesStyles.propertiesGroup}>
          <div css={propertiesStyles.property}>
            <DesignerInput
              name="angle"
              preIcon={AngleIcon}
              value={frameDimensions.angle}
              onChange={handleFrameChange}
              type="number"
              suffix={`°`}
              inputStyle={propertiesStyles.input}
              tooltip="Rotation"
            />
          </div>
          <div css={propertiesStyles.property}>
            <DesignerInput
              name="skewX"
              preIcon={SkewXIcon}
              value={frameDimensions.skewX}
              onChange={handleFrameChange}
              type="number"
              inputStyle={propertiesStyles.input}
              tooltip="Skew Left/Right"
            />
          </div>
          <div css={propertiesStyles.property}>
            <DesignerInput
              name="skewY"
              preIcon={SkewYIcon}
              value={frameDimensions.skewY}
              onChange={handleFrameChange}
              type="number"
              inputStyle={propertiesStyles.input}
              tooltip="Skew Top/Bottom"
            />
          </div>
        </div>
      </div>
      <div css={propertiesStyles.section}>
        <div css={propertiesStyles.sectionSubheading}>Image Dimensions</div>
        <div css={propertiesStyles.propertiesGroup}>
          <div css={propertiesStyles.property}>
            <DesignerInput
              name="x"
              prefix="X"
              value={imageDimensions.x}
              onChange={handleImageChange}
              type="number"
              suffix='"'
              inputStyle={propertiesStyles.input}
            />
          </div>
          <div css={propertiesStyles.property}>
            <DesignerInput
              name="y"
              prefix="Y"
              suffix='"'
              value={imageDimensions.y}
              onChange={handleImageChange}
              type="number"
              inputStyle={propertiesStyles.input}
            />
          </div>
        </div>
        <div css={propertiesStyles.propertiesGroup}>
          <div css={propertiesStyles.property}>
            <DesignerInput
              name="width"
              prefix="Width"
              value={imageDimensions.width}
              onChange={handleImageChange}
              type="number"
              suffix='"'
              inputStyle={propertiesStyles.input}
            />
          </div>
          <div css={propertiesStyles.property}>
            <DesignerInput
              name="height"
              prefix="Height"
              suffix='"'
              value={imageDimensions.height}
              onChange={handleImageChange}
              type="number"
              inputStyle={propertiesStyles.input}
            />
          </div>
        </div>
        {/* <div css={propertiesStyles.propertiesGroup}>
          <div css={propertiesStyles.property}>
            <DesignerInput
              name="angle"
              preIcon={AngleIcon}
              value={imageDimensions.angle}
              onChange={handleImageChange}
              type="number"
              suffix='"'
              inputStyle={propertiesStyles.input}
            />
          </div>
        </div> */}
      </div>
    </PropertyDropdown>
  );
};

export default ImageFrames;
