import { useEffect, useState } from "react";
import { getAsync } from "../../helpers/asyncFetch";
import LocalStorage from "../../helpers/LocalStorage";
import { getCookie } from "../../routes/Integrated/helpers/cookies";

interface IGetData<T> {
  data?: T;
  isLoading: boolean;
  getData: (toggleLoad: boolean) => void;
  updateData: (data: T) => void;
  getDataAsync: (toggleLoad: boolean) => void;
  error: IResponseError;
}

interface IResponseError {
  hasError?: boolean;
  errorMessage?: string;
}

export default function useGetData<T>(
  endpoint?: string,
  condition: unknown[] = [],
  optionalCallback?: (data: T) => unknown
): IGetData<T> {
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<IResponseError>({});
  function getData(toggleLoad = false) {
    if (!endpoint) return;
    if (toggleLoad) setIsLoading(true);

    fetch(endpoint, {
      method: "GET",
      headers: getHeaders(),
    })
      .catch((error) => {
        throw error;
      })
      .then((res) => {
        if (res.status === 200) {
          try {
            return res.json();
          } catch (exception) {
            throw exception;
          }
        } else {
          setError({ hasError: true, errorMessage: res.statusText });
        }
      })
      .catch((error) => {
        throw error;
      })
      .then((json: T) => {
        setData(json);
        if (optionalCallback) {
          optionalCallback(json);
        }
        setIsLoading(false);
      });
  }

  async function getDataAsync(toggleLoad = false) {
    if (!endpoint) return;
    if (toggleLoad) setIsLoading(true);
    try {
      const res = await fetch(endpoint, {
        method: "GET",
        headers: getHeaders(),
      });
      if (res.status === 200) {
        const json = await res.json();
        setData(json);
        if (optionalCallback) {
          optionalCallback(json);
        }
        setIsLoading(false);
      }
    } catch (error) {
      throw error;
    }
  }

  function updateData(data: T) {
    setData(data);
  }

  const dataObj: IGetData<T> = {
    data,
    isLoading,
    getData,
    updateData,
    error,
    getDataAsync,
  };

  useEffect(() => {
    if (endpoint) getData();
  }, condition);

  return dataObj;
}

function getHeaders() {
  let _token: string | null = null;
  const cookie = getCookie("token");
  if (cookie) {
    _token = cookie;
  } else _token = getApiToken();
  const headers: HeadersInit = {
    Authorization: `bearer ${_token}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return headers;
}

function getApiToken() {
  const token = LocalStorage.getToken();
  return token;
}
