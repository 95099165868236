import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { TableColumn } from "react-data-table-component";
import useLoadData from "../../../hooks/useLoadData";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import { ActiveItemDialogProps } from "../models/DialogProps";
import { WebhookNotificationLog } from "../models/Webhook";

const LogsDialog: React.FC<ActiveItemDialogProps> = ({
  open,
  onClose,
  webhook,
}) => {
  const { data, isLoading } = useLoadData<WebhookNotificationLog[]>(
    `/api/webhooks/${webhook?.id}/logs`,
    []
  );

  const columns: TableColumn<WebhookNotificationLog>[] = [
    {
      name: "Date (UTC)",
      selector: (log) => log.responseDate,
      format: (log) => moment(log.responseDate).format("MM-DD-YYYY HH:mm:ss"),
      sortable: true,
    },
    {
      name: "Request",
      selector: (log) => log.requestReceived,
      wrap: true,
    },
    {
      name: "Response",
      selector: (log) => log.responseResult,
      wrap: true,
      format: (log) => <Box sx={{ py: 1 }}>{log.responseResult}</Box>,
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{webhook?.friendlyName} Recent Logs</DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={isLoading} height={600}>
          <SearchableDataTable
            paginationRowsPerPageOptions={[5, 10, 25]}
            paginationPerPage={5}
            columns={columns}
            data={data}
            hideSearchBar
            dense={false}
            title=""
          />
        </LoadingWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default LogsDialog;
