import SyntaxHighlighter from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface IHtmlViewerProps {
  html: string;
}
const HtmlViewer = ({ html }: IHtmlViewerProps) => {
  return (
    <SyntaxHighlighter
      language="handlebars"
      codeTagProps={{
        style: {
          overflowX: "hidden",
        },
      }}
      lineProps={{
        style: {
          wordBreak: "break-all",
          whiteSpace: "pre-wrap",
        },
      }}
      wrapLongLines
      wrapLines
      customStyle={{
        overflowX: "hidden",
        padding: "16px",
        maxHeight: "350px",
        height: "350px",
      }}
      style={nord}
    >
      {html}
    </SyntaxHighlighter>
  );
};

export default HtmlViewer;
