import {
  Canvas,
  Circle,
  ICircleOptions,
  IEvent,
  Object as IObject,
  IPathOptions,
  Point,
} from "fabric/fabric-impl";
import { Tool } from "../../state/models/ICanvasTool";
import { fabric } from "fabric";
import { CanvasContext } from "../../state/contexts/CanvasContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useDesignerDispatch, useDesignerSelector } from "../../state/store";
import { IPathCurve, IPathPoint, Path } from "fabric";
import generateGuid from "../../../helpers/generateGuid";
import { setCurrentTool } from "../../state/slices/toolSettings";
import useLayers from "../useLayers";
import toggleCanvasSelection from "../../features/Canvas/functions/toggleCanvasSelection";

import { SelectedPageContext } from "../../state/contexts/SelectedPageContext";
import asyncClone from "../../features/Canvas/functions/asyncClone";

import PenCursor from "../../../assets/images/designer-svg/cursors/PenCursor.png";
import PenAddCursor from "../../../assets/images/designer-svg/cursors/PenAddCursor.png";
import PenRemoveCursor from "../../../assets/images/designer-svg/cursors/PenRemoveCursor.png";
import PenCompleteCursor from "../../../assets/images/designer-svg/cursors/PenCompleteCursor.png";
import PenGrabCursor from "../../../assets/images/designer-svg/cursors/PenGrabCursor.png";
import PenCurveCursor from "../../../assets/images/designer-svg/cursors/CurveCursor.png";
import PenAddCurveCursor from "../../../assets/images/designer-svg/cursors/CurveAddCursor.png";

import useHistory from "../useHistory";
import { createBleedClipPath } from "../../features/Canvas/functions/createBackgroundClipPath";
import findNewPointIndex from "./helperFunctions/findNewPointIndex";
import {
  IPenTool,
  PenToolMode,
  controlCircleOptions,
  controlLineOptions,
  controlRadius,
  lineStroke,
  lineStrokeWidth,
  pathOutlineOptions,
} from "./constants";
import drawIntersectionLines, {
  getIntersectionLineDrawCommands,
} from "./helperFunctions/drawIntersectionLines";
import getPath, { getPathString } from "./helperFunctions/getPath";
import updatePoints from "./helperFunctions/updatePoints";
import getControls from "./helperFunctions/getControls";
import coordsIntersectPoint from "./helperFunctions/coordsIntersectPoint";
import canClosePath from "./helperFunctions/canClosePath";
import getOppositePoint from "./helperFunctions/getOppositePoint";
import insertPathIntoCanvas from "./helperFunctions/insertPathIntoCanvas";
import getInverseQuadraticCoords from "./helperFunctions/getInverseQuadraticCoords";

const usePenTool = () => {
  const canvas = useContext(CanvasContext);
  const selectedPage = useContext(SelectedPageContext);
  // Ref duplicate of selectedPage for events. Assigns value each update.
  const selectedPageRef = useRef(selectedPage);
  selectedPageRef.current = selectedPage;

  const currentTool = useDesignerSelector(
    (state) => state.toolSettings.currentTool
  );
  // Ref duplicate of currentTool for events. Assigns value each update.
  const currentToolRef = useRef<Tool>(currentTool);
  currentToolRef.current = currentTool;

  const dispatch = useDesignerDispatch();

  const { currentHistory, updateHistory, cursorPosition } = useHistory();
  const { createLayer } = useLayers();

  const history = currentHistory?.history ?? [];
  const historyCursorPositionRef = useRef(cursorPosition);
  const historyLengthRef = useRef(history.length);
  // Our currently selected mode. State for ui updates.
  const [penToolMode, setPenToolMode] = useState<PenToolMode>("draw");
  const [penToolActive, setPenToolActive] = useState(false);
  // Ref duplicate of penToolMode for events. Assigns value each update.
  const penToolModeRef = useRef<PenToolMode>("draw");
  penToolModeRef.current = penToolMode;

  // Track the mouse state
  const mouseIsDownRef = useRef(false);
  // Whether or not the shape can be completed.
  const canBeCompleted = useRef(false);

  // The Path object for our ghost
  const ghostLine = useRef<Path>();
  // The Circle object for the mouse cursor position when we have a ghost line.
  const ghostPoint = useRef<Circle>();
  // The controls if necessary for a ghost.
  const ghostControls = useRef<IObject[]>([]);

  // the next point in the sequence. Tracked so we can build onto it with curves if necessary.
  const currentPointRef = useRef<IPathPoint>();
  // The points of the shape.
  const points = useRef<IPathPoint[]>([]);

  // the point we have grabbed if any
  const grabbedPointRef = useRef<IObject>();
  const grabbedPointOffsetRef = useRef<{ x: number; y: number }>();

  const disableControlEventsRef = useRef(false);

  const outlineRef = useRef<Path>();

  // The shape if it already exists on canvas
  const pathRef = useRef<Path>();
  // the shape's name so we can remove objects without worrying about losing the layer.
  const pathNameRef = useRef<string>("");

  // The shape while drawing.
  const drawingPathRef = useRef<Path>();

  const altKeyDown = useRef(false);
  const shiftKeyDown = useRef(false);
  const ctrlKeyDown = useRef(false);
  const currentTargetRef = useRef<IObject>();
  const currentTargetIndexRef = useRef<number>(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [targetPath, setTargetPath] = useState<IObject>();
  const offsetRef = useRef<Point>();
  const penToolOpenRef = useRef(false);
  const [isOverCanvas, setisOverCanvas] = useState(false);
  const isOverCanvasRef = useRef(false);
  isOverCanvasRef.current = isOverCanvas;
  penToolOpenRef.current = isEditing;

  const currentCursorRef = useRef("default");

  function setCanvasCursor(cursor: string) {
    if (!canvas) return;
    //canvas.setCursor(cursor);
    canvas.hoverCursor = cursor;
    canvas.moveCursor = cursor;
    canvas.defaultCursor = cursor;
    canvas.rotationCursor = cursor;
    canvas.freeDrawingCursor = cursor;
    canvas.setCursor(cursor);
    canvas.renderAll();
  }

  const initialized = useRef(false);
  useEffect(() => {
    handleHistoryLogic();
  }, [cursorPosition]);

  function discardDrawing() {
    if (!canvas || points.current.length === 0) return;
    if (drawingPathRef.current) {
      canvas.remove(drawingPathRef.current);
      drawingPathRef.current = undefined;
    }
    toggleCanvasSelection(canvas, "on");

    cleanupCanvas(canvas);

    if (ghostLine.current) {
      canvas.remove(ghostLine.current);
    }

    points.current = [];
    canBeCompleted.current = false;
    ghostLine.current = undefined;
    setPenToolMode("draw");
    penToolModeRef.current = "draw";
    drawingPathRef.current = undefined;
    pathRef.current = undefined;
    pathNameRef.current = "";
    dispatch(setCurrentTool(Tool.select));
    currentToolRef.current = Tool.select;
    outlineRef.current = undefined;
    setPenToolActive(false);

    canvas.renderAll();
  }

  async function handleHistoryLogic() {
    if (!canvas) return;

    let active = penToolActive;

    if (
      historyLengthRef.current === history.length &&
      historyCursorPositionRef.current !== cursorPosition
    ) {
      if (!drawingPathRef.current && pathNameRef.current) {
        drawingPathRef.current = canvas._objects.find(
          (x) => x.name === pathNameRef.current
        ) as Path | undefined;

        if (drawingPathRef.current) {
          setPenToolActive(true);
          active = true;
          dispatch(setCurrentTool(Tool.pen));
          currentToolRef.current = Tool.pen;
        }
      }
      if (!drawingPathRef.current && !pathNameRef.current) {
        // look for any incomplete paths
        const paths = canvas._objects.filter(
          (x) => x.type === "path" && x.name?.includes("path")
        ) as Path[];

        if (paths.length) {
          drawingPathRef.current = paths.find((x) => !x.__isCompleted);

          if (drawingPathRef.current) {
            setPenToolActive(true);
            active = true;
            pathNameRef.current = drawingPathRef.current.name ?? "";
            setPenToolMode("draw");
            penToolModeRef.current = "draw";
            penToolOpenRef.current = true;
            canBeCompleted.current = false;
            dispatch(setCurrentTool(Tool.pen));
            currentToolRef.current = Tool.pen;
            currentPointRef.current = undefined;
          } else {
            cleanupCanvas(canvas);
            discardGhost();
            setPenToolActive(false);
          }
        }
      }

      if (isEditing || active) {
        if (historyLengthRef.current === history.length) {
          drawingPathRef.current = canvas
            ?.getObjects()
            .find(
              (x) => pathNameRef.current && x.name === pathNameRef.current
            ) as Path | undefined;

          if (drawingPathRef.current) {
            drawingPathRef.current?.set({
              objectCaching: false,
              dirty: true,
            });
          }

          if (drawingPathRef.current?.__isCompleted && !isEditing) {
            points.current = [];
            drawingPathRef.current = undefined;
            setPenToolActive(false);
            dispatch(setCurrentTool(Tool.select));
            currentToolRef.current = Tool.select;
            pathNameRef.current = "";
            cleanupCanvas(canvas);
          } else {
            points.current = [...(drawingPathRef.current?.__points ?? [])];
            let applyTransform = false;
            if (drawingPathRef.current) {
              if (isEditing) {
                applyTransform = true;
                outlineRef.current = (await asyncClone(
                  drawingPathRef.current
                )) as Path;
                outlineRef.current.set({
                  fill: "",
                  stroke: lineStroke,
                  strokeWidth: lineStrokeWidth,
                  name: "PathControl-outline",
                  selectable: false,
                  evented: false,
                });
              } else {
                // @ts-ignore
                fabric.Polyline.prototype._setPositionDimensions.call(
                  drawingPathRef.current,
                  {}
                );
              }
              if (!penToolActive && !isEditing) {
                setPenToolActive(true);
              }
            } else {
              setPenToolActive(false);
            }

            const ghost = canvas._objects.find((x) => x.name === "GhostLine");
            if (ghost) canvas.remove(ghost);
            renderPoints();
          }

          canvas.renderAll();
        }
      }
    }
    historyCursorPositionRef.current = cursorPosition;
    historyLengthRef.current = history.length;
  }

  function initPenTool() {
    if (canvas && !initialized.current) {
      enableEventListeners();
      document.addEventListener("keydown", handleKeyCheckDown);
      document.addEventListener("keyup", handleKeyCheckUp);
      initialized.current = true;
    }
  }

  function enableEventListeners() {
    if (canvas) {
      canvas.on("mouse:down", handleMouseDown);
      canvas.on("mouse:up", handleMouseUp);
      canvas.on("mouse:move", handleMouseMove);
      canvas.on("mouse:out", handleMouseOut);
    }
  }

  function disableEventListeners() {
    if (canvas) {
      canvas.off("mouse:down", handleMouseDown);
      canvas.off("mouse:up", handleMouseUp);
      canvas.off("mouse:move", handleMouseMove);
      canvas.off("mouse:out", handleMouseOut);
    }
  }

  function handleMouseOut(e: IEvent) {
    if (isOverCanvasRef.current) setisOverCanvas(false);
  }

  function handleKeyCheckUp(e: KeyboardEvent) {
    if (!e.altKey) altKeyDown.current = false;
    if (!e.shiftKey) shiftKeyDown.current = false;
    if (!e.ctrlKey) ctrlKeyDown.current = false;
  }
  function handleKeyCheckDown(e: KeyboardEvent) {
    if (currentToolRef.current === Tool.pen && points.current.length > 0) {
      if (e.key === "Escape") {
        discardDrawing();
      }
    }
    if (e.altKey) {
      e.preventDefault();
      altKeyDown.current = true;
    }
    if (e.shiftKey) {
      shiftKeyDown.current = true;
    }
    if (e.ctrlKey || e.metaKey) {
      ctrlKeyDown.current = true;
    }
  }

  function renderGhost(point: IPathPoint) {
    // if we aren't drawing, we don't need to render a ghost.

    if (penToolModeRef.current !== "draw") {
      ghostControls.current = [];

      ghostLine.current = undefined;
      ghostPoint.current = undefined;
      return;
    }
    if (points.current.length && canvas) {
      const start = points.current[points.current.length - 1];

      if (point.quadraticBackward || point.quadraticForward) {
        canvas.remove(...ghostControls.current);
        ghostControls.current = getControls(point);
        canvas.add(...ghostControls.current);
      }
      if (
        ghostLine.current &&
        currentTargetRef.current &&
        currentTargetIndexRef.current !== -1 &&
        currentPointRef.current
      ) {
        if (drawingPathRef.current) {
          drawingPathRef.current.visible = false;
          drawingPathRef.current.dirty = true;
        }
        const newGhost = [...points.current];
        newGhost.splice(
          currentTargetIndexRef.current,
          0,
          currentPointRef.current
        );
        ghostLine.current.path = getPath(newGhost, false);
        ghostLine.current.dirty = true;
        canvas.renderAll();
      } else if (!ghostLine.current) {
        ghostLine.current = new fabric.Path(
          `M ${start.x} ${start.y} L ${point.x} ${point.y}`,
          {
            ...pathOutlineOptions,
          }
        ) as Path;
        canvas.add(ghostLine.current);
        canvas.renderAll();
      } else if (currentPointRef.current) {
        (ghostLine.current as Path).path = getPath([
          points.current[points.current.length - 1],
          currentPointRef.current,
        ]);
        ghostLine.current.dirty = true;
        canvas.renderAll();
      } else {
        (ghostLine.current as Path).path = getPath([
          points.current[points.current.length - 1],
          { x: point.x, y: point.y },
        ]);
        ghostLine.current.dirty = true;
        canvas.renderAll();
      }
    }
  }

  function translatePointsAndOutline() {
    if (
      !drawingPathRef.current ||
      !drawingPathRef.current.__points ||
      !outlineRef.current
    )
      return;
    disableControlEventsRef.current = true;
    points.current = updatePoints(
      drawingPathRef.current,
      drawingPathRef.current.__points.map((x) => ({
        ...x,
        quadraticBackward: x.quadraticBackward
          ? { ...x.quadraticBackward }
          : undefined,
        quadraticForward: x.quadraticForward
          ? { ...x.quadraticForward }
          : undefined,
      }))
    );

    renderPoints();
  }

  /**
   * @description External function to toggle the edit mode on a selected path.
   * @param pathObj The path to toggle edit mode on
   */
  async function editModeOn(pathObj: Path) {
    if (canvas) {
      setIsEditing(true);
      setTargetPath(pathObj);

      const newPoints = pathObj.__points?.map((x) => ({ ...x })) ?? [];
      //updatePoints(pathObj, pathObj.__points ?? []);

      pathObj.__points = [...(newPoints ?? [])];
      pathRef.current = pathObj;
      pathNameRef.current = pathObj.name ?? "";
      // Name the original path so it is removed from the canvas next cleanup.
      drawingPathRef.current = (await asyncClone(pathObj)) as Path;
      drawingPathRef.current.set({
        name: pathNameRef.current,
      });
      outlineRef.current = (await asyncClone(pathObj)) as Path;
      outlineRef.current.set({
        fill: "",
        stroke: lineStroke,
        strokeWidth: lineStrokeWidth,
        selectable: false,
        evented: false,
        name: "PathControl-outline",
        clipPath: undefined,
      });

      drawingPathRef.current.hasControls = false;
      drawingPathRef.current.hasBorders = false;
      drawingPathRef.current.objectCaching = false;
      drawingPathRef.current.dirty = true;
      drawingPathRef.current.clipPath = undefined;
      drawingPathRef.current.__points = [
        ...(newPoints.map((x) => ({ ...x })) ?? []),
      ];
      drawingPathRef.current.__isCompleted = pathObj.__isCompleted;
      drawingPathRef.current.perPixelTargetFind = false;
      // points.current = drawingPathRef.current.__points;
      points.current = [
        ...updatePoints(
          pathObj,
          newPoints.map((x) => ({ ...x }))
        ),
      ];
      insertPathIntoCanvas(drawingPathRef.current, canvas);
      canvas.add(outlineRef.current);
      pathRef.current.name = "PathControl";
      drawingPathRef.current.on("moving", (e: IEvent) => {
        translatePointsAndOutline();
      });
      // If we're editing a completed path, we want to set up grab mode.
      if (drawingPathRef.current.__isCompleted) {
        setPenToolMode("grab");
        disableSelection(drawingPathRef.current.name);

        renderPoints();

        canvas.setActiveObject(drawingPathRef.current);
        drawingPathRef.current.onDeselect = () => {
          if (penToolModeRef.current !== "draw") return true;
          return false;
        };

        canvas.renderAll();
      } else {
        // If we're editing an incomplete path, we want to set up draw mode and switch to the Pen Tool.
        setPenToolMode("draw");
        // Might not need this if I do this right.
        dispatch(setCurrentTool(Tool.pen));
        currentToolRef.current = Tool.pen;
      }
      setPenToolActive(true);
    }
  }

  // All Modes: ???
  // Draw Mode: Should render the ghost. If the mouse is down, it should start creating our quadratic points.
  // Grab Mode: If the mouse is down, it should allow manipulation of the control points. ???
  function handleMouseMove(event: IEvent<MouseEvent>) {
    if (!isOverCanvasRef.current) setisOverCanvas(true);
    if (canvas) {
      const pointer = canvas.getPointer(event.e);

      if (
        penToolModeRef.current === "draw" &&
        currentToolRef.current === Tool.pen
      ) {
        if (mouseIsDownRef.current && currentPointRef.current) {
          // if the mouse is down, we need to create the quadratic by creating the forward and opposite points

          const oppositePoint = getOppositePoint(currentPointRef.current, {
            x: pointer.x,
            y: pointer.y,
          });
          if (!canBeCompleted.current) {
            currentPointRef.current.quadraticForward = {
              x: pointer.x,
              y: pointer.y,
              name: generateGuid(),
            };
          }
          currentPointRef.current.quadraticBackward = {
            x: oppositePoint.x,
            y: oppositePoint.y,
            name: generateGuid(),
          };

          renderGhost(currentPointRef.current);
        } else if (
          !mouseIsDownRef.current &&
          (altKeyDown.current || shiftKeyDown.current || ctrlKeyDown.current)
        ) {
          discardGhost();
        } else {
          renderGhost({ x: pointer.x, y: pointer.y });
        }
      }
      if (penToolModeRef.current === "grab") {
        handleGrabTool(event);
      }
      handleCursor(event);
      if (
        (shiftKeyDown.current &&
          !mouseIsDownRef.current &&
          event.target?.name === "PathControl-intersection") ||
        (currentToolRef.current === Tool.pen &&
          penToolModeRef.current === "draw" &&
          !shiftKeyDown.current)
      ) {
        renderPreviewPoint(pointer.x, pointer.y);
      } else {
        renderPreviewPoint(pointer.x, pointer.y, true);
      }
    }
  }

  function renderPreviewPoint(x: number, y: number, remove = false) {
    if (!canvas) return;
    const controlPointPreview = canvas._objects.find(
      (x) => x.name === "GhostPoint-Preview"
    );
    if (controlPointPreview && remove) {
      canvas.remove(controlPointPreview);
    }
    if (remove) return;
    if (!controlPointPreview) {
      const previewPoint = new fabric.Circle({
        ...controlCircleOptions,
        top: y,
        left: x,
        name: "GhostPoint-Preview",
        evented: false,
        selectable: false,
        objectCaching: false,
      });
      canvas.add(previewPoint);
    } else {
      controlPointPreview.set({
        top: y,
        left: x,
      });
      canvas.renderAll();
    }
  }

  function discardGhost() {
    if (!canvas || !ghostLine.current) return;
    canvas.remove(ghostLine.current);
    canvas.renderAll();
    ghostLine.current = undefined;
  }

  // All Modes: Should set our mouse state to down.
  // Draw Mode: Should create our new current point and check if the path can be completed.
  // Grab Mode: Should check if we are grabbing a point, etc.
  function handleMouseDown(event: IEvent<MouseEvent>) {
    if (!canvas) return;

    const pointer = canvas.getPointer(event.e);
    if (
      shiftKeyDown.current &&
      event.target &&
      event.target.name === "PathControl-intersection"
    ) {
      currentTargetRef.current = event.target;
      currentTargetIndexRef.current = findNewPointIndex(
        points.current,
        currentTargetRef.current as Path
      );
      currentPointRef.current = {
        x: pointer.x,
        y: pointer.y,
        name: generateGuid(),
      };
    } else {
      currentTargetRef.current = undefined;
      currentTargetIndexRef.current = -1;
    }
    if (currentToolRef.current === Tool.pen) {
      mouseIsDownRef.current = true;
      if (canvas) {
        if (penToolModeRef.current === "draw") {
          currentPointRef.current = {
            x: pointer.x,
            y: pointer.y,
            name: generateGuid(),
          };
          // check if the point we are clicking can close this path.
          canBeCompleted.current = canClosePath(
            pointer.x,
            pointer.y,
            points.current,
            controlRadius
          );
        }
      }
      if (event.e.altKey) {
      }
    }
    if (penToolModeRef.current === "grab") {
      if (event.target && event.target.name?.includes("PathControl-point")) {
        mouseIsDownRef.current = true;
        grabbedPointRef.current = event.target;

        grabbedPointOffsetRef.current = {
          x: pointer.x - (grabbedPointRef.current.left ?? 0),
          y: pointer.y - (grabbedPointRef.current.top ?? 0),
        };
      } else {
        mouseIsDownRef.current = false;
        grabbedPointRef.current = undefined;
      }
    }
  }

  function editModeOff() {
    handleDoneEditing(pathRef.current?.__isCompleted);

    setIsEditing(false);
  }

  const grabDebounceRef = useRef<NodeJS.Timeout>();

  function handleGrabTool(event: IEvent<MouseEvent>) {
    if (!canvas || !drawingPathRef.current || penToolModeRef.current !== "grab")
      return;
    if (
      mouseIsDownRef.current &&
      grabbedPointRef.current &&
      event.pointer &&
      grabbedPointOffsetRef.current
    ) {
      const pointer = canvas.getPointer(event.e);

      grabbedPointRef.current.left =
        pointer.x + grabbedPointOffsetRef.current.x;
      grabbedPointRef.current.top = pointer.y + grabbedPointOffsetRef.current.y;
      const twinPoint = canvas._objects.find(
        (x) => x.name === grabbedPointRef.current?.name
      );
      if (twinPoint) {
        twinPoint.left = grabbedPointRef.current.left;
        twinPoint.top = grabbedPointRef.current.top;
      }
      for (let i = 0; i < points.current.length; i++) {
        const point = points.current[i];

        if (point.name && grabbedPointRef.current.name?.includes(point.name)) {
          const diffX = grabbedPointRef.current.left - point.x;
          const diffY = grabbedPointRef.current.top - point.y;
          points.current[i].x = grabbedPointRef.current.left;
          points.current[i].y = grabbedPointRef.current.top;

          if (i === 0 || i === points.current.length - 1) {
            points.current[points.current.length - 1].x =
              grabbedPointRef.current.left;
            points.current[points.current.length - 1].y =
              grabbedPointRef.current.top;
            points.current[0].x = grabbedPointRef.current.left;
            points.current[0].y = grabbedPointRef.current.top;
          }
          if (point.quadraticBackward) {
            point.quadraticBackward.x += diffX;
            point.quadraticBackward.y += diffY;
          }
          if (point.quadraticForward) {
            point.quadraticForward.x += diffX;
            point.quadraticForward.y += diffY;
          }
          break;
        }
        if (
          point.quadraticBackward &&
          point.quadraticBackward.name &&
          grabbedPointRef.current.name?.includes(point.quadraticBackward.name)
        ) {
          point.quadraticBackward.x = grabbedPointRef.current.left;
          point.quadraticBackward.y = grabbedPointRef.current.top;
          if (shiftKeyDown.current) {
            point.quadraticBackward.broken = true;
            if (point.quadraticForward) {
              point.quadraticForward.broken = true;
            }
          }
          if (
            point.quadraticForward &&
            !point.quadraticForward.broken &&
            !point.quadraticBackward.broken
          ) {
            const inverseCoords = getInverseQuadraticCoords(
              point.quadraticBackward,
              point.quadraticForward,
              point
            );
            point.quadraticForward.x = inverseCoords.x;
            point.quadraticForward.y = inverseCoords.y;
          }
          break;
        }
        if (
          point.quadraticForward &&
          point.quadraticForward.name &&
          grabbedPointRef.current.name?.includes(point.quadraticForward.name)
        ) {
          point.quadraticForward.x = grabbedPointRef.current.left;
          point.quadraticForward.y = grabbedPointRef.current.top;
          if (shiftKeyDown.current) {
            point.quadraticForward.broken = true;
            if (point.quadraticBackward) {
              point.quadraticBackward.broken = true;
            }
          }
          if (
            point.quadraticBackward &&
            !point.quadraticForward.broken &&
            !point.quadraticBackward.broken
          ) {
            const inverseCoords = getInverseQuadraticCoords(
              point.quadraticForward,
              point.quadraticBackward,
              point
            );
            point.quadraticBackward.x = inverseCoords.x;
            point.quadraticBackward.y = inverseCoords.y;
          }
          break;
        }
      }

      //drawingPathRef.current.path = getPath(points.current, false);

      const translatedPoints = translatePointsToPath();
      drawingPathRef.current.path = [...getPath(translatedPoints, true)];
      drawingPathRef.current.__points = translatedPoints;
      drawingPathRef.current.dirty = true;

      renderPoints();

      if (grabDebounceRef.current) {
        clearTimeout(grabDebounceRef.current);
      }
      grabDebounceRef.current = setTimeout(() => {
        historyCursorPositionRef.current += 1;
        updateHistory([], "grab point moved");
      }, 300);
    }
  }

  function updatePathFromEdits() {
    if (!drawingPathRef.current || !canvas) return;
    const translatedPoints = translatePointsToPath();
    drawingPathRef.current.path = [...getPath(translatedPoints, true)];
    drawingPathRef.current.__points = translatedPoints;
    drawingPathRef.current.dirty = true;
    // renderPoints();
    if (grabDebounceRef.current) {
      clearTimeout(grabDebounceRef.current);
    }
    grabDebounceRef.current = setTimeout(() => {
      historyCursorPositionRef.current += 1;
      updateHistory([], "grab point moved");
    }, 300);
  }

  function createDeepCloneOfPoints() {
    return points.current.map((x) => ({
      ...x,
      quadraticForward: x.quadraticForward
        ? { ...x.quadraticForward }
        : undefined,
      quadraticBackward: x.quadraticBackward
        ? { ...x.quadraticBackward }
        : undefined,
    }));
  }

  /**
   * Removes the transform on our points so we can update our path with points relative to its rotation and scale.
   * @returns {IPathPoint[]}
   */
  function translatePointsToPath() {
    if (!drawingPathRef.current || !canvas) return points.current;
    const transformMatrix = drawingPathRef.current.calcTransformMatrix();
    const inverseMatrix = fabric.util.invertTransform([...transformMatrix]);
    // add the offset to our x and y values to prevent the path from moving.
    inverseMatrix[4] = inverseMatrix[4] + drawingPathRef.current.pathOffset.x;
    inverseMatrix[5] = inverseMatrix[5] + drawingPathRef.current.pathOffset.y;

    const transformed: IPathPoint[] = [];
    points.current.forEach((point) => {
      const p: IPathPoint = {
        x: point.x,
        y: point.y,
        name: point.name,
      };
      if (point.quadraticForward) {
        p.quadraticForward = {
          x: point.quadraticForward.x,
          y: point.quadraticForward.y,
          name: point.quadraticForward.name,
          broken: point.broken,
        };
      }
      if (point.quadraticBackward) {
        p.quadraticBackward = {
          x: point.quadraticBackward.x,
          y: point.quadraticBackward.y,
          name: point.quadraticBackward.name,
          broken: point.broken,
        };
      }
      transformed.push(p);
    });
    transformed.forEach((p) => {
      const point = { x: p.x, y: p.y } as Point;
      const t = fabric.util.transformPoint(point, inverseMatrix);
      p.x = t.x;
      p.y = t.y;
      if (p.quadraticForward) {
        const fT = fabric.util.transformPoint(
          { x: p.quadraticForward.x, y: p.quadraticForward.y } as Point,
          inverseMatrix
        );
        p.quadraticForward.x = fT.x;
        p.quadraticForward.y = fT.y;
      }
      if (p.quadraticBackward) {
        const bT = fabric.util.transformPoint(
          { x: p.quadraticBackward.x, y: p.quadraticBackward.y } as Point,
          inverseMatrix
        );
        p.quadraticBackward.x = bT.x;
        p.quadraticBackward.y = bT.y;
      }
    });

    return transformed;
  }

  // All modes: Should set our mouse state to up.
  // Draw Mode: Should add the point to the canvas.
  // Grab Mode: Should drop the point we currently have selected. etc.
  function handleMouseUp(event: IEvent<MouseEvent>) {
    if (
      currentToolRef.current !== Tool.pen &&
      penToolModeRef.current !== "grab" &&
      !pathRef.current
    )
      return;
    mouseIsDownRef.current = false;
    if (disableControlEventsRef.current) {
      disableControlEventsRef.current = false;
      renderPoints();
    }

    handleCursor();
    if (
      currentToolRef.current !== Tool.pen &&
      penToolModeRef.current !== "grab" &&
      !shiftKeyDown.current &&
      !altKeyDown.current &&
      !ctrlKeyDown.current
    )
      return;

    if (canvas) {
      mouseIsDownRef.current = false;
      let shouldDrawNewPoint = true;

      if (currentTargetRef.current && currentTargetIndexRef.current !== -1) {
        if (currentTargetRef.current && currentPointRef.current) {
          points.current.splice(
            currentTargetIndexRef.current,
            0,
            currentPointRef.current
          );

          currentTargetRef.current = undefined;
          currentTargetIndexRef.current = -1;
        }

        updatePathFromEdits();
        shouldDrawNewPoint = false;
      }

      if (event.target && event.target.name?.includes("PathControl-point")) {
        const target = event.target;
        const pointIndex = points.current.findIndex((p) => {
          if (!p.name) return false;
          if (target.name?.includes(p.name)) {
            return true;
          }
          return false;
        });

        if (pointIndex != -1) {
          const point = points.current[pointIndex];
          if (shiftKeyDown.current) {
            points.current.splice(pointIndex, 1);
            renderPoints();
          } else if (altKeyDown.current) {
            if (
              !point.quadraticForward &&
              !point.quadraticBackward &&
              drawingPathRef.current
            ) {
              const centerPoint = drawingPathRef.current.getCenterPoint();
              const prevPoint =
                pointIndex === 0
                  ? points.current[points.current.length - 2]
                  : points.current[pointIndex - 1];
              // check the distance from the center point for the previous point and the selected point
              // so we can find out the pattern to use for our new quadratics.
              const diffCenterAx = centerPoint.x - prevPoint.x;
              const diffCenterAy = centerPoint.y - prevPoint.y;
              const diffCenterBx = centerPoint.x - point.x;
              const diffCenterBy = centerPoint.y - point.y;
              const diffX = diffCenterAx - diffCenterBx;
              const diffY = diffCenterAy - diffCenterBy;

              if (diffX > 0 && diffY > 0) {
                point.quadraticForward = {
                  name: generateGuid(),
                  x: point.x,
                  y: point.y + 75,
                  broken: false,
                };
                point.quadraticBackward = {
                  ...getOppositePoint(point, point.quadraticForward),
                  name: generateGuid(),
                  broken: false,
                };
              } else if (diffX < 0 && diffY > 0) {
                point.quadraticBackward = {
                  name: generateGuid(),
                  x: point.x + 75,
                  y: point.y,
                  broken: false,
                };
                point.quadraticForward = {
                  ...getOppositePoint(point, point.quadraticBackward),
                  name: generateGuid(),
                  broken: false,
                };
              } else if (diffX < 0 && diffY < 0) {
                point.quadraticBackward = {
                  name: generateGuid(),
                  x: point.x,
                  y: point.y + 75,
                  broken: false,
                };
                point.quadraticForward = {
                  ...getOppositePoint(point, point.quadraticBackward),
                  name: generateGuid(),
                  broken: false,
                };
              } else {
                point.quadraticForward = {
                  x: point.x + 75,
                  y: point.y,
                  name: generateGuid(),
                  broken: false,
                };
                point.quadraticBackward = {
                  ...getOppositePoint(point, point.quadraticForward),
                  name: generateGuid(),
                  broken: false,
                };
              }
            } else {
              if (
                (!point.quadraticForward && point.quadraticBackward) ||
                ctrlKeyDown.current
              ) {
                point.quadraticBackward = undefined;
              }
              if (point.quadraticForward) {
                point.quadraticForward = undefined;
              }
            }
          }
          if (!canBeCompleted.current) shouldDrawNewPoint = false;
        }
        updatePathFromEdits();
      }

      if (drawingPathRef.current) {
        drawingPathRef.current.visible = true;
        drawingPathRef.current.dirty = true;
      }
      if (currentPointRef.current && penToolModeRef.current === "draw") {
        setPenToolActive(true);
        if (canBeCompleted.current && shouldDrawNewPoint) {
          // If we're completing the path, we need to change the x and y of the last point to the same x and y as the first point so we can actually close the shape.
          points.current.push({
            ...currentPointRef.current,
            x: points.current[0].x,
            y: points.current[0].y,
            name: points.current[0].name,
          });
          handleDoneEditing(true);
        } else {
          // add our current point to the points array.
          if (shouldDrawNewPoint || !drawingPathRef.current) {
            points.current.push({ ...currentPointRef.current });
            // reset the current point
          }
          currentPointRef.current = undefined;
          // if we don't have an active path, we need to create one and add it to the canvas.
          if (!drawingPathRef.current) {
            const name = `path-${generateGuid()}`;
            drawingPathRef.current = new fabric.Path(
              `M ${points.current[0].x} ${points.current[0].y}`,
              {
                ...pathOutlineOptions,
                name,
              }
            );
            drawingPathRef.current.__points = [...points.current];
            drawingPathRef.current.__isCompleted = false;
            pathNameRef.current = name;
            canvas.add(drawingPathRef.current);
            canvas.renderAll();
            offsetRef.current = drawingPathRef.current.pathOffset;
            //dispatch(addNewLayer({ page: selectedPage, name: name }));
          } else {
            drawingPathRef.current.__points = [...points.current];
            // Update the path array to reflect the new point
            drawingPathRef.current.path = getPath(points.current);
            drawingPathRef.current.dirty = true;
            canvas.renderAll();
            offsetRef.current = drawingPathRef.current.pathOffset;
          }
          if (shouldDrawNewPoint) {
            renderPoints();
            historyCursorPositionRef.current += 1;
            updateHistory(canvas._objects);
          }
        }
      }
    }
  }

  /**
   * @description Removes all PathControl objects from the canvas.
   * @param canvas - The canvas to cleanup
   */
  function cleanupCanvas(canvas: Canvas) {
    const objectsToDelete = canvas._objects.filter((x) =>
      x.name?.includes("PathControl")
    );
    canvas.discardActiveObject();
    canvas.remove(...objectsToDelete);
    renderPreviewPoint(0, 0, true);
  }

  // @todo - Clean this up

  function handleDoneEditing(isCompleting = false) {
    if (canvas && drawingPathRef.current) {
      const pathString = getPathString(points.current, isCompleting);

      const name =
        pathRef.current && pathNameRef.current
          ? pathNameRef.current
          : drawingPathRef.current.name ?? "";
      const newPath = new fabric.Path(pathString, {
        stroke: pathRef.current?.stroke ? pathRef.current.stroke : "#000000",
        strokeWidth: pathRef.current?.strokeWidth
          ? pathRef.current.strokeWidth
          : isCompleting || drawingPathRef.current?.__isCompleted
          ? 0
          : lineStrokeWidth,
        strokeUniform: true,
        perPixelTargetFind: true,
        fill:
          pathRef.current?.fill != undefined
            ? pathRef.current.fill
            : !isCompleting
            ? ""
            : pathRef.current?.fill
            ? pathRef.current.fill
            : "#000000",
        objectCaching: false,
        name: name,
        selectable: true,
        clipPath: createBleedClipPath(canvas),
      }) as Path;
      newPath.__points = [...points.current];
      newPath.__isCompleted = isCompleting;
      insertPathIntoCanvas(newPath, canvas);
      if (!pathRef.current) {
        historyCursorPositionRef.current += 1;
        createLayer(name);
      }
      drawingPathRef.current.name = "PathControl";

      toggleCanvasSelection(canvas, "on");

      cleanupCanvas(canvas);

      if (ghostLine.current) {
        canvas.remove(ghostLine.current);
      }

      points.current = [];
      canBeCompleted.current = false;
      ghostLine.current = undefined;
      setPenToolMode("draw");
      penToolModeRef.current = "draw";
      drawingPathRef.current = undefined;
      pathRef.current = undefined;
      pathNameRef.current = "";
      const selection = canvas._objects.find((x) => x.name === newPath.name);
      canvas.discardActiveObject();
      if (selection) canvas.setActiveObject(selection);
      dispatch(setCurrentTool(Tool.select));

      currentToolRef.current = Tool.select;
      outlineRef.current = undefined;
      canvas.renderAll();
      setPenToolActive(false);
    }
  }

  // Function to render the circles and handles for the path while editing/drawing.
  function renderPoints(disableEvents = false) {
    if (canvas) {
      cleanupCanvas(canvas);

      const transformMatrix = drawingPathRef.current?.calcTransformMatrix();

      if (drawingPathRef.current && drawingPathRef.current.__isCompleted) {
        const lastPoint = points.current[points.current.length - 1];
        lastPoint.quadraticForward = points.current[0].quadraticForward;
        lastPoint.quadraticBackward = points.current[0].quadraticBackward;
      }

      if (outlineRef.current && drawingPathRef.current) {
        outlineRef.current.set({
          top: drawingPathRef.current.top,
          left: drawingPathRef.current.left,
          angle: drawingPathRef.current.angle,
          skewX: drawingPathRef.current.skewX,
          skewY: drawingPathRef.current.skewY,
          scaleX: drawingPathRef.current.scaleX,
          scaleY: drawingPathRef.current.scaleY,
          path: drawingPathRef.current.path,
          objectCaching: false,
          pathOffset: drawingPathRef.current.pathOffset,
          evented: false,
        });

        canvas.add(outlineRef.current);
      }
      const drawCommands = getIntersectionLineDrawCommands(
        points.current,
        drawingPathRef.current?.__isCompleted
      );

      drawIntersectionLines(
        canvas,
        drawCommands,
        disableControlEventsRef.current
      );
      points.current.forEach((point, index) => {
        canvas.add(
          ...getControls(
            point,
            transformMatrix,
            disableControlEventsRef.current
          )
        );
      });

      canvas.renderAll();
    }
  }

  /**
   * @description Disable selection on the canvas
   */
  function disableSelection(ignore?: string) {
    if (canvas) {
      if (!ignore) toggleCanvasSelection(canvas, "off");
      else toggleCanvasSelection(canvas, "off", ignore);
    }
  }
  function enableSelection() {
    if (canvas) {
      toggleCanvasSelection(canvas, "on");
    }
  }

  // Effects

  useEffect(initPenTool, [canvas]);

  useEffect(() => {
    currentToolRef.current = currentTool;
    if (currentTool === Tool.pen) {
      canvas?.discardActiveObject();
      disableSelection();
    } else if (pathRef.current) {
      handleDoneEditing(pathRef.current.__isCompleted);
    } else {
      enableSelection();
    }
  }, [currentTool]);

  function handleCursor(e?: IEvent<MouseEvent>) {
    if (!canvas) return;
    if (
      e &&
      (currentToolRef.current === Tool.pen ||
        (penToolModeRef.current === "grab" && pathRef.current))
    ) {
      if (!mouseIsDownRef.current) {
        if (
          shiftKeyDown.current &&
          e.target?.name === "PathControl-intersection"
        ) {
          if (currentCursorRef.current !== "add") {
            setCanvasCursor(`url(${PenAddCursor}), auto`);
            currentCursorRef.current = "add";
          }
          return;
        }
        if (
          altKeyDown.current &&
          e.target?.name?.includes("PathControl-point")
        ) {
          if (
            currentCursorRef.current !== "curve" &&
            currentCursorRef.current !== "addCurve"
          ) {
            const point = points.current.find(
              (x) => x.name && e.target?.name?.includes(x.name)
            );
            if (point) {
              if (point.quadraticBackward || point.quadraticForward) {
                setCanvasCursor(`url(${PenCurveCursor}), auto`);
                currentCursorRef.current = "curve";
              } else {
                setCanvasCursor(`url(${PenAddCurveCursor}), auto`);
                currentCursorRef.current = "addCurve";
              }
            }
          }
          return;
        }
        if (
          shiftKeyDown.current &&
          e.target?.name?.includes("PathControl-point")
        ) {
          if (currentCursorRef.current !== "remove") {
            const point = points.current.find(
              (x) => x.name && e.target?.name?.includes(x.name)
            );
            if (point) {
              setCanvasCursor(`url(${PenRemoveCursor}), auto`);
              currentCursorRef.current = "remove";
            }
          }
          return;
        }

        if (
          e.target?.name?.includes("PathControl-point") &&
          !shiftKeyDown.current &&
          currentToolRef.current === Tool.pen
        ) {
          if (currentCursorRef.current !== "complete") {
            const pointer = canvas.getPointer(e.e);
            if (
              canClosePath(pointer.x, pointer.y, points.current, controlRadius)
            ) {
              setCanvasCursor(`url(${PenCompleteCursor}), auto`);
              currentCursorRef.current = "complete";
            }
          }
          return;
        }
      }
    }

    if (penToolModeRef.current === "grab") {
      if (currentCursorRef.current !== "grab") {
        setCanvasCursor(`url(${PenGrabCursor}), auto`);
        currentCursorRef.current = "grab";
      }
      return;
    }
    if (currentToolRef.current === Tool.pen) {
      if (currentCursorRef.current !== "pen") {
        setCanvasCursor(`url(${PenCursor}), auto`);
        currentCursorRef.current = "pen";
      }
      return;
    }
    if (currentCursorRef.current !== "default") {
      setCanvasCursor("default");

      currentCursorRef.current = "default";
    }
  }

  useEffect(handleCursor, [isOverCanvas]);

  const penTool: IPenTool = {
    editModeOn,
    editModeOff,
    isEditing,
    targetPath,
    penToolActive,
    discardDrawing,
  };
  return penTool;
};

export default usePenTool;
