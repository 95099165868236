import ILayersObject from "../../../state/models/layers/ILayersObject";

export default function reArrangeObjects(
  layers: ILayersObject,
  canvas: fabric.Canvas
) {
  const layersArr = Object.keys(layers).map((x) => layers[x]);
  const objects = canvas.getObjects();

  let i = 0;
  layersArr.forEach((layer) => {
    const obj = objects.find((x) => x.name === layer.id);
    if (obj) {
      obj.moveTo(i);
      i++;
    } else {
      const groupedLayers = layer.layers;
      if (groupedLayers) {
        const keys = Object.keys(groupedLayers);
        keys.forEach((k) => {
          const groupObj = objects.find((x) => x.name === groupedLayers[k].id);

          if (groupObj) {
            groupObj.moveTo(i);
            i++;
          }
        });
      }
    }
  });
  const background = objects.find((x) => x.name === "background");

  const overlay = objects.filter(
    (x) => x.name === "overlay" || x.name === "bleed"
  );
  if (overlay.length) {
    overlay.forEach((obj) => {
      obj.bringToFront();
      obj.perPixelTargetFind = true;
      obj.selectable = false;
    });
  }
  if (background) background.sendToBack();

  canvas.requestRenderAll();
}
