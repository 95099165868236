import { CatchingPokemonSharp } from "@mui/icons-material";
import ILayer from "../../../state/models/layers/ILayer";
import ILayersObject from "../../../state/models/layers/ILayersObject";

export function moveLayer(
  id: string,
  destination: string,
  layers: ILayersObject
): ILayersObject {
  const newLayers = moveLayer2(id, destination, layers);

  return newLayers;
}

function moveLayerInArray(
  layers: ILayer[],
  origin: number,
  destination: number
) {
  const originObj = layers[origin];
  layers = layers.filter((x, index) => {
    if (index !== origin) return true;
    return false;
  });

  layers.splice(destination, 0, originObj);
  return layers;
}

function moveLayer2(
  origin: string,
  destination: string,
  layers: ILayersObject
) {
  let layerArr = Object.keys(layers).map((x) => ({ ...layers[x] })) as ILayer[];

  const originObject = getLayerSeekObject(origin, layers);
  const destinationObject = getLayerSeekObject(destination, layers);

  if (originObject && destinationObject) {
    // if neither layer is grouped, we just move the layers in the array

    if (!originObject.child && !destinationObject.child) {
      layerArr = moveLayerInArray(
        layerArr,
        originObject.index,
        destinationObject.index
      );
    } // if our originObject and destination object are the same, but we have no children in the destination, we need to move the child to the primary array
    else if (
      originObject.child &&
      !destinationObject.child &&
      destinationObject.id === originObject.id
    ) {
      const groupIndex = layerArr.findIndex((x) => x.id === originObject.id);

      if (groupIndex !== -1) {
        const group = { ...layerArr[groupIndex] };

        if (group) {
          const lifted = liftLayerIntoArray(group, origin);

          if (lifted) {
            layerArr[groupIndex] = lifted.group;
            layerArr.push(lifted.layer);
          }
        }
      }

      // if our originObject and destinationObject share the same id and are only one layer deep, we need to just move the child within the group
    } else if (
      originObject.child &&
      destinationObject.child &&
      !originObject.child.child &&
      !destinationObject.child.child &&
      destinationObject.id === originObject.id
    ) {
      const group = layerArr.find((x) => x.id === originObject.id);

      if (group && group.layers) {
        const layerArr = Object.keys(group.layers).map(
          // @ts-ignore
          (x: keyof ILayersObject) => group.layers[x]
        );
        const originIndex = layerArr.findIndex((y) => y.id === origin);
        const destinationIndex = layerArr.findIndex(
          (y) => y.id === destination
        );
        const groupArr = moveLayerInArray(
          // @ts-ignore
          Object.keys(group.layers).map((x) => group.layers[x]),
          originIndex,
          destinationIndex
        );

        group.layers = groupArr.reduce((prev, curr, i) => {
          prev[i] = curr;
          return prev;
        }, {} as ILayersObject);
      }
    } else {
      let originIndex = -1;
      // if we have a child in the origin, we need to move the child to the primary array.
      if (originObject.child) {
        const group = layerArr.find((x) => x.id === originObject.id);

        if (group && group.layers) {
          if (group.layers[originObject.child.index]) {
            layerArr.push(group.layers[originObject.child.index]);
            originIndex = layerArr.length - 1;
            const groupLayersArr = Object.keys(group.layers).map(
              // @ts-ignore
              (x) => group.layers[x]
            );

            if (groupLayersArr.length - 1 === 0) {
              group.layers = {} as ILayersObject;
            } else {
              // @ts-ignore
              group.layers = groupLayersArr.reduce((prev, curr, i) => {
                if (curr.id !== origin) {
                  prev[i] = curr;
                }
                return prev;
              }, {} as ILayersObject);
            }
          }
        }
      } else {
        originIndex = originObject.index;
      }
      // if our destination is not a group, we can just move the layer to the new index
      if (!destinationObject.child) {
        layerArr = moveLayerInArray(
          layerArr,
          layerArr.length - 1,
          destinationObject.index
        );
      } // now we know that our destination is grouped, so we need to take the origin and move it down the chain of objects until we hit an object with no children.
      else if (destinationObject.child) {
        if (originIndex === -1) {
          originIndex = originObject.index;
        }

        layerArr = moveLayerIntoGroup(destinationObject, layerArr, originIndex);
      }
    }

    layerArr = cleanUpEmptyGroups(layerArr);
  }

  return layerArr.reduce((prev, curr, i) => {
    prev[i] = curr;
    return prev;
  }, {} as ILayersObject);
}

function cleanUpEmptyGroups(layers: ILayer[]) {
  layers = layers.filter(
    (x) => !x.layers || (x.layers && Object.keys(x.layers).length > 0)
  );
  layers.forEach((layer) => {
    if (layer.layers) {
      // @ts-ignore
      const layerArr = Object.keys(layer.layers).map((x) => layer.layers[x]);
      layer.layers = cleanUpEmptyGroups(layerArr).reduce((prev, curr, i) => {
        prev[i] = curr;
        return prev;
      }, {} as ILayersObject);
    }
  });
  return layers;
}

function moveLayerIntoGroup(
  destinationObject: ILayerSeekObject,
  layerArray: ILayer[],
  originId: number
): ILayer[] {
  const originLayer = layerArray[originId];

  if (destinationObject.child) {
    const groupIndex = layerArray.findIndex(
      (x) => x.id === destinationObject.id
    );

    if (groupIndex !== -1) {
      const group = layerArray[groupIndex];

      if (group && group.layers) {
        layerArray.splice(originId, 1);

        // @ts-ignore
        let groupArr = Object.keys(group.layers).map((x) => group.layers[x]);

        groupArr.push(originLayer);
        if (destinationObject.child.child) {
          groupArr = moveLayerIntoGroup(
            destinationObject.child,
            groupArr,
            groupArr.length - 1
          );
        } else {
          groupArr = moveLayerInArray(
            groupArr,
            groupArr.length - 1,
            destinationObject.child.index
          );
        }
        group.layers = groupArr.reduce((prev, curr, i) => {
          prev[i] = curr;
          return prev;
        }, {} as ILayersObject);
      }
    }
  }
  return layerArray;
}

function liftLayerIntoArray(
  group: ILayer,
  id: string
): { group: ILayer; layer: ILayer } | undefined {
  if (group.layers) {
    const layersArr = Object.keys(group.layers).map((x) => ({
      // @ts-ignore
      ...group.layers[x],
    }));
    const layerIndex = layersArr.findIndex((x) => x.id === id);
    if (layerIndex !== -1) {
      const layer = { ...layersArr[layerIndex] };

      group.layers = layersArr
        .filter((x, i) => i !== layerIndex)
        .reduce((prev, curr, i) => {
          prev[i] = curr;
          return prev;
        }, {} as ILayersObject);

      return { group, layer };
    } else {
      const groups = layersArr.filter((x) => x.id.includes("group"));
      let layer: ILayer | undefined = undefined;
      for (let i = 0; i < groups.length; i++) {
        let group = groups[i];
        if (group.layers) {
          const lifted = liftLayerIntoArray(group, id);
          if (lifted) {
            group = { ...lifted.group };
            layer = lifted.layer;
            break;
          }
        }
      }
      if (layer) {
        return {
          group: {
            ...group,
            layers: layersArr.reduce((prev, curr, i) => {
              prev[i] = curr;
              return prev;
            }, {} as ILayersObject),
          },
          layer: layer,
        };
      }
      return undefined;
    }
  }
}

export function renameLayer(
  layers: ILayersObject,
  id: string,
  name: string,
  index: string
) {
  const newLayers = deepSeekAndRename(layers, id, name, index);
  return newLayers;
}

function deepSeekAndRename(
  layers: ILayersObject,
  id: string,
  name: string,
  index: string
) {
  if (layers[index] && layers[index].id === id) {
    return {
      ...layers,
      [index]: {
        ...layers[index],
        name: name,
      },
    };
  } else {
    const layerKeys = Object.keys(layers);
    return layerKeys.reduce((layersObject, key) => {
      if (layers[key] && layers[key].layers) {
        layersObject[key] = {
          ...layers[key],
          layers: deepSeekAndRename(
            layers[key].layers as ILayersObject,
            id,
            name,
            index
          ),
        };
      } else {
        layersObject[key] = layers[key];
      }
      return layersObject;
    }, {} as ILayersObject);
  }
}

export function getLayerSeekObject(
  id: string,
  layers: ILayersObject,
  parentGroup?: ILayerSeekObject
) {
  const layersArr = Object.keys(layers).map((x) => layers[x]);
  const layerIndex = layersArr.findIndex((x) => x.id === id);

  if (layerIndex !== -1) {
    const layerObject = layersArr[layerIndex];
    if (parentGroup) {
      return {
        ...parentGroup,
        child: { id: layerObject.id, index: layerIndex },
      };
    }
    return { id: layerObject.id, index: layerIndex };
  } else {
    const groups = layersArr.filter((x) => x.id.includes("group"));

    let existingGroup: ILayerSeekObject | undefined;
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];

      if (!group.layers) continue;
      const exists = layerExistsInGroup(id, group.layers);

      if (exists) {
        existingGroup = getLayerSeekObject(
          id,
          group.layers,
          parentGroup
            ? {
                ...parentGroup,
                child: {
                  id: group.id,
                  index: layersArr.findIndex((x) => x.id === group.id),
                },
              }
            : {
                id: group.id,
                index: layersArr.findIndex((x) => x.id === group.id),
              }
        );
      }
    }
    if (existingGroup) return existingGroup;
    // return undefined;
  }
}

function layerExistsInGroup(id: string, layers: ILayersObject) {
  const layersArr = Object.keys(layers).map((x) => layers[x]);
  const layerIndex = layersArr.findIndex((x) => x.id === id);
  if (layerIndex !== -1) return true;
  const groups = layersArr.filter((x) => x.id.includes("group"));
  let exists = false;
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    if (!group.layers) continue;
    if (layerExistsInGroup(id, group.layers)) {
      exists = true;
      break;
    }
  }
  return exists;
}

interface ILayerSeekObject {
  id: string;
  index: number;
  child?: ILayerSeekObject;
}

interface ILayerWithKey extends ILayer {
  key: string;
}

export function findLayerIdByDistance(
  id: string,
  distance: number,
  layers: ILayersObject
): string {
  const layersWithKeys = Object.keys(layers).reduce((prev, curr) => {
    return [...prev, { ...layers[curr], key: curr }];
  }, [] as ILayerWithKey[]);
  const indexOfCurrentLocation = layersWithKeys.findIndex((x) => x.id === id);
  if (indexOfCurrentLocation === -1) return "";
  const destinationLayer = layersWithKeys[indexOfCurrentLocation + distance];
  return destinationLayer.id;
}
