import React from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import tw, { css } from "twin.macro";
import IAsset from "../../../../data/models/IAsset";
import placeholder from "../../../../assets/images/dynamic-image-placeholder.png";

interface IDraggableImageProps {
  asset?: IAsset;
  isVariable?: boolean;
}

const styles = {
  imageContainer: tw`w-28 mr-4 mb-4`,
  imageWrapper: [tw`w-28 h-28 overflow-clip flex flex-col`],
  imageName: tw`text-center`,
  image: [tw`w-full max-w-full block mt-auto mb-auto`],
};

const DraggableImage = ({ asset, isVariable }: IDraggableImageProps) => {
  const [collected, drag, dragPreview] = useDrag({
    type: isVariable ? "variableImage" : "asset",
    item: asset,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isVariable) {
    return (
      <React.Fragment>
        {collected.isDragging && <div ref={dragPreview} />}
        <div css={styles.imageContainer}>
          <div css={styles.imageWrapper}>
            <img src={placeholder} css={styles.image} ref={drag} />
          </div>
        </div>
      </React.Fragment>
    );
  } else
    return (
      <React.Fragment>
        {collected.isDragging && <div ref={dragPreview} />}
        <div css={styles.imageContainer}>
          <div css={styles.imageWrapper}>
            {asset?.extension !== ".svg" && (
              <img
                src={asset?.url}
                css={styles.image}
                ref={drag}
                alt={asset?.name}
              />
            )}
            {asset?.extension === ".svg" && null}
          </div>
        </div>
      </React.Fragment>
    );
};

export default DraggableImage;
