import { Gradient, Object as IObject } from "fabric/fabric-impl";

export default function getGradientAngleFromFabric(
  obj: IObject,
  gradient: Gradient
) {
  if (!gradient.coords) return 90;
  const coords = gradient.coords ?? { x1: 0, x2: 0, y1: 0, y2: 0 };
  if (
    coords.x1 === undefined ||
    coords.x2 === undefined ||
    coords.y1 === undefined ||
    coords.y2 === undefined
  ) {
    return 90;
  }
  let a =
    Math.atan2(coords.y2 - coords.y1, coords.x2 - coords.x1) * (180 / Math.PI) +
    90;
  a = a < 0 ? map(a, -90, 0, 270, 360) : a;

  return Math.round(a);
}

function map(
  value: number,
  min1: number,
  max1: number,
  min2: number,
  max2: number
) {
  return min2 + (max2 - min2) * ((value - min1) / (max1 - min1));
}
