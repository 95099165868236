import { IText, Path, Textbox } from "fabric/fabric-impl";
import React, { useEffect, useState } from "react";
import IPropertiesProps, {
  IShapePropertiesProps,
} from "../Properties/IPropertiesProps";
import "twin.macro";
import toolbarStyles from "./toolbarStyles";

import ColorPicker from "../shared/ColorPicker/ColorPicker";
import IncrementInput from "../shared/NuDesignerInputs/IncrementInput";
import { ReactComponent as FillIcon } from "../../../../assets/images/designer-svg/FillIcon.svg";
import { ReactComponent as StrokeWidthIcon } from "../../../../assets/images/designer-svg/StrokeWidthIcon.svg";
import RoundedInputGroup from "../shared/RoundedInputGroup";
import ToolButton from "../shared/ToolButton";
import {
  BoldIcon,
  CenterAlignIcon,
  ItalicIcon,
  LeftAlignIcon,
  RightAlignIcon,
  UnderlineIcon,
} from "../shared/SvgComponents";
import DuplicateButton from "./DuplicateButton";
import DeleteButton from "./DeleteButton";
import EditTextButton from "./EditTextButton";
import PathEditButton from "./PathEditButton";
// import PathEditButton from "../../PenTool/PathEditButton";

const ShapeProperties = ({
  selectedObject,
  penTool,
}: IShapePropertiesProps) => {
  const [strokeWidth, setStrokeWidth] = useState(
    selectedObject.strokeWidth ?? 0
  );

  function handleStrokeWidthChange(property: string, value: number) {
    setStrokeWidth(value);
    selectedObject.set("strokeWidth", value);
    selectedObject.canvas?.renderAll();
    selectedObject.canvas?.fire("object:modified");
  }

  useEffect(() => {
    setStrokeWidth(selectedObject.strokeWidth ?? 0);
  }, [selectedObject]);

  return (
    <div>
      <div css={toolbarStyles.section}>
        <div css={toolbarStyles.property}>
          <ColorPicker
            selectedObject={selectedObject}
            type="fill"
            swatchOnly
            swatchIcon={FillIcon}
          />
        </div>
        <div css={toolbarStyles.property}>
          <ColorPicker
            selectedObject={selectedObject}
            type="stroke"
            swatchOnly
            swatchIcon={StrokeWidthIcon}
          />
        </div>
        <div css={toolbarStyles.property}>
          <IncrementInput
            property="strokeWidth"
            value={strokeWidth}
            onChange={handleStrokeWidthChange}
            height={7}
            width={48}
          />
        </div>
        <div css={toolbarStyles.seperator}></div>
        {selectedObject.type === "path" && (
          <div css={toolbarStyles.property}>
            <PathEditButton path={selectedObject as Path} penTool={penTool} />
          </div>
        )}
        <div css={toolbarStyles.property}>
          <DuplicateButton selectedObject={selectedObject} />
        </div>
        <div css={toolbarStyles.property}>
          <DeleteButton selectedObject={selectedObject} />
        </div>
      </div>
    </div>
  );
};

export default ShapeProperties;
