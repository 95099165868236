import { IPathPoint, fabric } from "fabric";

/**
 * @description Get the opposite or quadraticBackward for our quadratic curve.
 * @param point - the Mouse position
 * @param origin - The origin point first clicked
 * @returns {Partial<IPathPoint>} The opposite point for our quadratic curve.
 */
export default function getOppositePoint(
  point: IPathPoint,
  origin: IPathPoint
) {
  // first conver the coordinates to fabric Points
  const midPoint = new fabric.Point(point.x, point.y);
  const controlPoint = new fabric.Point(origin.x, origin.y);
  // then perform the arithmetic to get the opposite point, using fabric's Point methods.
  const oppositePoint = midPoint.multiply(2).subtract(controlPoint);
  return {
    x: oppositePoint.x,
    y: oppositePoint.y,
  };
}
