import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { postAsync } from "../../../../../helpers/asyncFetch";
import LoadingButton from "../../../../../shared/LoadingButton";
import IDesignCategory from "../../../../Designs/models/IDesignCategory";

interface INewCategoryModalProps {
  isOpen: boolean;
  onCategoryAdd: (category: IDesignCategory) => void;
  onClose: () => void;
  categories: IDesignCategory[];
}

const NewCategoryModal = ({
  isOpen,
  onCategoryAdd,
  onClose,
  categories,
}: INewCategoryModalProps) => {
  const [newCategory, setNewCategory] = useState("");
  const [status, setStatus] = useState({
    hasError: false,
    isSubmitting: false,
    isSuccess: false,
    errorSubmitting: false,
  });

  function validateCategory() {
    if (
      categories.find(
        (x) =>
          x.categoryName.toLocaleLowerCase().replaceAll(" ", "").trim() ===
          newCategory.replaceAll(" ", "").trim().toLowerCase()
      )
    ) {
      setStatus({ ...status, hasError: true });
    } else {
      setStatus({ ...status, hasError: false });
    }
  }

  async function handleSubmit() {
    setStatus({ ...status, isSubmitting: true, errorSubmitting: false });
    const result = await postAsync<IDesignCategory>("/design-category", {
      categoryName: newCategory,
    });
    if (result) {
      onCategoryAdd(result);
      setStatus({
        hasError: false,
        isSubmitting: false,
        isSuccess: true,
        errorSubmitting: false,
      });
    } else {
      setStatus({ ...status, isSubmitting: false, errorSubmitting: true });
    }
  }

  function handleCancel() {
    setNewCategory("");
    setStatus({
      hasError: false,
      isSubmitting: false,
      isSuccess: false,
      errorSubmitting: false,
    });
    onClose();
  }

  useEffect(validateCategory, [newCategory]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle>Add Design Category</DialogTitle>
      <DialogContent>
        <InputLabel>Enter the category name: </InputLabel>
        <TextField
          value={newCategory}
          disabled={status.isSubmitting || status.isSuccess}
          onChange={(e) => setNewCategory(e.target.value)}
          fullWidth
        />
        {status.hasError && (
          <FormHelperText error>Design category already exists</FormHelperText>
        )}
        {status.errorSubmitting && (
          <FormHelperText error>
            Something went wrong with your submission
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={status.isSubmitting}
          disabled={status.hasError || !newCategory.trim()}
          success={status.isSuccess}
          onClick={handleSubmit}
        >
          Create Category
        </LoadingButton>
        <Button onClick={handleCancel} disabled={status.isSubmitting}>
          {status.isSuccess ? "Close Window" : "Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewCategoryModal;
