import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DesignerState } from "../store";
import QrCode, { newQrCode } from "../../../shared/QrCodeEditor/models/QrCode";

export interface QrCodeState {
  qrCodeId: number | null;
  selectedQrCode: QrCode | null;
  selectorOpen: boolean | "edit";
  qrCodes: QrCode[];
}

interface QrCodeInitializePayload {
  qrCodes: QrCode[];
  qrCodeId: number | null;
}

const initialState: QrCodeState = {
  qrCodeId: null,
  selectorOpen: false,
  qrCodes: [],
  selectedQrCode: null,
};

export const qrCodeSlice = createSlice({
  name: "qrCode",
  initialState,
  reducers: {
    setQrCodeId: (state, action: PayloadAction<number | null>) => {
      state.qrCodeId = action.payload;
    },
    initializeQrCodes: (
      state,
      action: PayloadAction<QrCodeInitializePayload>
    ) => {
      return {
        qrCodeId: null,
        selectorOpen: false,
        qrCodes: action.payload.qrCodes,
        selectedQrCode: null,
      };
    },
    createNewQrCode: (state) => {
      state.selectedQrCode = newQrCode;
    },
    updateSelectedQrCode: (state, action: PayloadAction<QrCode | null>) => {
      state.selectedQrCode = action.payload;
    },
    setQrCodes: (state, action: PayloadAction<QrCode[]>) => {
      state.qrCodes = [...action.payload];
    },
    toggleQrCodeSelector: (
      state,
      action: PayloadAction<undefined | "edit">
    ) => {
      if (action.payload) {
        state.selectorOpen = action.payload;
      } else state.selectorOpen = !Boolean(state.selectorOpen);
    },
  },
});

export const {
  setQrCodeId,
  setQrCodes,
  initializeQrCodes,
  toggleQrCodeSelector,
  createNewQrCode,
  updateSelectedQrCode,
} = qrCodeSlice.actions;

export const selectQrCode = (state: DesignerState) => state.qrCode;

export default qrCodeSlice.reducer;
