import { IEvent, Point, Polygon, Transform } from "fabric/fabric-impl";
import generateGuid from "../../../../../helpers/generateGuid";
import { fabric } from "fabric";
import RESOLUTION from "../../../../constants/RESOLUTION";

export function ribbonScaling(ribbon: Polygon) {
  if (!ribbon || !ribbon.canvas) return;
  const scaleX = ribbon.scaleX ?? 1;
  const scaleY = ribbon.scaleY ?? 1;
  const points = [...(ribbon.points ?? [])];
  if (!points) return;
  points[1].x = (RESOLUTION * 0.1 * scaleY) / scaleX;
  points[4].x = RESOLUTION - (RESOLUTION * 0.1 * scaleY) / scaleX;
  ribbon.set("points", points);
  ribbon.canvas?.renderAll();
}
