import { AlignGuidelines } from "fabric-guideline-plugin";
import React, { createContext, useReducer } from "react";
import IDesignerVariable from "../models/IDesignerVariable";

export const DesignVariablesContext = createContext<IDesignerVariable[]>([]);

export enum DesignVariablesAction {
  load = "load",
  insert = "insert",
  remove = "remove",
  update = "update",
}

export const DesignVariablesDispatchContext = createContext<
  React.Dispatch<{
    type: DesignVariablesAction;
    payload: IDesignerVariable[] | IDesignerVariable;
  }>
>(
  {} as React.Dispatch<{
    type: DesignVariablesAction;
    payload: IDesignerVariable[] | IDesignerVariable;
  }>
);
const initialState: IDesignerVariable[] = [];

function designVariablesReducer(
  state: IDesignerVariable[],
  action: {
    type: DesignVariablesAction;
    payload: IDesignerVariable | IDesignerVariable[];
  }
) {
  if (
    action.type === DesignVariablesAction.load &&
    Array.isArray(action.payload)
  ) {
    return [...action.payload];
  } else if (!Array.isArray(action.payload)) {
    const payload = action.payload as IDesignerVariable;

    if (action.type === DesignVariablesAction.insert) {
      return [...state, payload];
    }

    if (action.type === DesignVariablesAction.remove) {
      return state.filter((x) => x.object.name !== payload.object.name);
    }
    if (action.type === DesignVariablesAction.update) {
      return state.map((x) => {
        if (x.object.name === payload.object.name) {
          return payload;
        }
        return x;
      });
    }
  }
  return state;
}

interface IGuidesProviderProps {
  children?: React.ReactFragment;
}

const DesignVariablesProvider = ({ children }: IGuidesProviderProps) => {
  const [designVariables, dispatch] = useReducer(
    designVariablesReducer,
    initialState
  );

  return (
    <DesignVariablesContext.Provider value={designVariables}>
      <DesignVariablesDispatchContext.Provider value={dispatch}>
        {children}
      </DesignVariablesDispatchContext.Provider>
    </DesignVariablesContext.Provider>
  );
};

export default DesignVariablesProvider;
