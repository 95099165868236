import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import IDesign from "../../../data/models/IDesign";
import IHtmlVersion from "../../../data/models/IHtmlVersion";
import { getAsync, postAsync } from "../../../helpers/asyncFetch";
import { fetchClient } from "../../../helpers/fetchClient";
import useGetData from "../../../hooks/dataFetchers/useGetData";

import Img from "../../../shared/BasicHTML/Img";
import LoadingButton from "../../../shared/LoadingButton";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import NewCategoryModal from "../../Admin/Designs/Design/components/NewCategoryModal";
import NewSubcategoryModal from "../../Admin/Designs/Design/components/NewSubcategoryModal";
import Design from "../../Designs/models/Design";
import IDesignCategory from "../../Designs/models/IDesignCategory";
import IDesignSubcategory from "../../Designs/models/IDesignSubcategory";
import HtmlEditor from "../components/HtmlEditor";
import getVars from "../helpers/getVars";
import IHtmlDesignDTO from "../models/IHtmlDesignDTO";
import IHmtlDesignRequest from "../models/IHtmlDesignRequest";
import IHtmlDesignSize from "../models/IHtmlDesignSize";
import QrCode, { QrCodeRaw } from "../../../shared/QrCodeEditor/models/QrCode";
import QrCodeSelect from "../../../shared/QrCodeEditor/components/QrCodeSelect";
import { Input } from "postcss";

enum NetworkStatus {
  Loading = "loading",
  Proof = "proof",
  Idle = "idle",
  Publish = "publish",
  Saving = "saving",
}

interface INetworkState {
  status: NetworkStatus;
  error?: INetworkError;
}

interface INetworkError {
  type: "general" | "proof" | "publish";
  error: string;
}

enum Steps {
  ChooseSize = 0,
  EnterHtml = 1,
  GenerateProof = 2,
  ApproveDesign = 3,
}

const EditHtml = ({ isAdmin = false }: { isAdmin?: boolean }) => {
  const history = useHistory();

  const params = useParams<{ designId: string }>();

  const isNewRef = useRef(
    params.designId === "new" || !params.designId ? true : false,
  );
  const isNew = isNewRef.current;
  const designId = isNew ? undefined : Number(params.designId);
  const [qrCodeID, setQrCodeID] = useState("");
  const [hasQrCode, setHasQrCode] = useState(false);
  const [design, setDesign] = useState<IHtmlDesignDTO>({
    friendlyName: "",
    designID: designId && !Number.isNaN(designId) ? designId : undefined,
    html: "",
    htmlBack: "",
    size: "",
    proofFront: "",
    proofBack: "",
    isTemplate: isAdmin,
  });

  const [envelopeType, setEnvelopeType] = useState("Regular");

  const [categoryModalState, setCategoryModalState] = useState({
    newCategoryOpen: false,
    newSubcategoryOpen: false,
  });
  const [htmlValidationErrors, setHtmlValidationErrors] = useState<
    Record<string, boolean>
  >({});
  const {
    data: designCategories,
    error: categoryError,
    updateData: setDesignCategories,
  } = useGetData<IDesignCategory[]>(`/api/design-category`, []);

  const currentCategory = designCategories?.find(
    (x) => x.categoryID === design.categoryID,
  );

  const [varErrors, setVarErrors] = useState<boolean>(false);

  const [activeStep, setActiveStep] = useState<Steps>(
    isNew ? Steps.ChooseSize : Steps.EnterHtml,
  );
  const [sizes, setSizes] = useState<IHtmlDesignSize[]>([
    {
      size: "46",
      id: 1,
      sizeLabel: `4.25" x 6"`,
      type: "postcard",
    },
    {
      size: "46S",
      id: 2,
      sizeLabel: `4" x 6"`,
      type: "postcard",
    },
    {
      size: "68",
      id: 3,
      sizeLabel: `6" x 8.5"`,
      type: "postcard",
    },
    {
      size: "69",
      id: 4,
      sizeLabel: `6" x 9"`,
      type: "postcard",
    },
    {
      size: "611",
      id: 5,
      sizeLabel: `6" x 11"`,
      type: "postcard",
    },
    {
      size: "811",
      id: 6,
      sizeLabel: `Letter`,
      type: "letter",
    },
    {
      size: "BRO",
      id: 7,
      sizeLabel: `Brochure`,
      type: "brochure",
    },
  ]);
  const [networkState, setNetworkState] = useState<INetworkState>({
    status: NetworkStatus.Loading,
  });

  const [proofFields, setProofFields] = useState<Record<string, string>>({});

  async function initData() {
    if (!design.designID) {
      setNetworkState({ ...networkState, status: NetworkStatus.Idle });
      return;
    }
    const existingDesign = await getAsync<Design>(
      `/html/${designId}?edit=true`,
    );

    if (existingDesign) {
      if (existingDesign.qrCodeID) {
        setQrCodeID(existingDesign.qrCodeID.toString());
      }
      setDesign({
        friendlyName: existingDesign.friendlyName,
        designID: existingDesign.designID,
        html: existingDesign.currentHtmlVersion?.html,
        htmlBack: existingDesign.currentHtmlVersion?.htmlBack,
        type:
          existingDesign.printPDFSource === "HTML-LETTER"
            ? "letter"
            : existingDesign.printPDFSource === "HTML-BRO"
              ? "brochure"
              : "postcard",
        htmlVersionID: existingDesign.currentHtmlVersion?.htmlVersionID,
        size: existingDesign.size,
        isTemplate: existingDesign.isTemplate,
        categoryID: existingDesign.categoryID,
        subcategoryID: existingDesign.subcategoryID,
      });
      setNetworkState({ ...networkState, status: NetworkStatus.Idle });
    }
  }

  function validateHtml() {
    const errors: Record<string, boolean> = {};
    if (!design.html) {
      errors.html = true;
    }
    if (design.size != "811" && design.size !== "BRO" && !design.htmlBack) {
      errors.htmlBack = true;
    }
    console.log(design.html?.match(/<\s*body[^>]*>/g));
    if (design.html?.match(/<\s*body[^>]*>/g) === null) {
      errors.htmlBody = true;
    }
    if (design.html?.match(/<\s*\/\s*body\s*>/g) === null) {
      errors.htmlBodyClose = true;
    }
    if (design.size != "811" && design.size !== "BRO") {
      if (design.htmlBack?.match(/<\s*body[^>]*>/g) === null) {
        errors.htmlBackBody = true;
      }
      if (design.htmlBack?.match(/<\s*\/\s*body\s*>/g) === null) {
        errors.htmlBackBodyClose = true;
      }
    }
    return errors;
  }

  async function handleSaveHtml() {
    const errors = validateHtml();
    setHtmlValidationErrors(errors);
    console.log(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    setNetworkState({ ...networkState, status: NetworkStatus.Saving });

    const res = await postAsync<IHtmlDesignDTO>(`/html/save`, design);

    if (res) {
      setDesign({ ...res });
      const vars = [...getVars(design.html), ...getVars(design.htmlBack)];
      setActiveStep(Steps.GenerateProof);
      if (
        vars.length === 0 &&
        design.type !== "letter" &&
        design.type !== "brochure"
      ) {
        await handleGenerateProof(res.designID, res.htmlVersionID);
      } else {
        setNetworkState({ ...networkState, status: NetworkStatus.Idle });
      }
    } else {
      setNetworkState({
        status: NetworkStatus.Idle,
        error: { type: "general", error: "Unable to save design" },
      });
    }
  }

  function handleHtmlError(hasError: boolean) {
    if (hasError !== varErrors) {
      setVarErrors(hasError);
    }
  }

  async function handlePublishHtml() {
    setNetworkState({ status: NetworkStatus.Publish });
    try {
      const fields = Object.keys(proofFields)
        .filter((x) => x !== "QrCode")
        .map((field) => field);
      const request = {
        ...design,
        proofFront: design.previewProofFront,
        proofBack: design.previewProofBack,
        fields,
        qrCodeID: hasQrCode ? Number(qrCodeID) : null,
      };

      const res = await postAsync(`/html/publish`, request);
      history.push(
        isAdmin ? `/admin/html` : `/designs/html/${design.designID}`,
      );
    } catch (e) {
      setNetworkState({
        status: NetworkStatus.Idle,
        error: { type: "publish", error: "Unable to publish" },
      });
    }
  }

  function handleNewCategoryClose() {
    setCategoryModalState({ ...categoryModalState, newCategoryOpen: false });
  }

  function handleNewSubcategoryClose() {
    setCategoryModalState({ ...categoryModalState, newSubcategoryOpen: false });
  }

  function handleCategoryAdd(category: IDesignCategory) {
    if (designCategories) {
      const updated = [...designCategories, category].sort((a, b) =>
        a.categoryName.localeCompare(b.categoryName),
      );
      setDesignCategories(updated);
      setDesign({ ...design, categoryID: category.categoryID });
    }
  }

  function handleSubcategoryAdd(subcategory: IDesignSubcategory) {
    if (designCategories) {
      const index = designCategories.findIndex(
        (x) => x.categoryID === subcategory.categoryID,
      );
      if (index !== -1) {
        const updated = [
          ...designCategories[index].subcategories,
          subcategory,
        ].sort((a, b) => a.subcategoryName.localeCompare(b.subcategoryName));
        designCategories[index].subcategories = updated;
        setDesignCategories([...designCategories]);
        setDesign({ ...design, subcategoryID: subcategory.subcategoryID });
      }
    }
  }

  async function handleGenerateProof(
    designID?: number,
    htmlVersionID?: string,
  ) {
    setNetworkState({ status: NetworkStatus.Proof });
    const vars = Object.keys(proofFields).map((field) => ({
      key: field,
      value: proofFields[field],
    }));

    const returnAddress = [
      { key: "returnaddressname", value: "returnName" },
      { key: "returnaddresscompany", value: "returnCompany" },
      { key: "returnaddress", value: "returnAddress" },
      { key: "returnaddresscity", value: "returnCity" },
      { key: "returnaddressstate", value: "returnState" },
      { key: "returnaddresszip", value: "returnZip" },
    ];

    const recipient = {
      firstName: "firstName",
      lastName: "lastName",
      company: "company",
      address: "address",
      address2: "",
      city: "city",
      state: "state",
      zipCode: "zipCode",

      recipientDesignVariables: [...vars, ...returnAddress],
    };

    const request = {
      recipient: {
        ...recipient,
        variables: recipient.recipientDesignVariables,
      },
      envelopeConfig: {
        type: envelopeType,
      },
      qrCodeID: hasQrCode ? qrCodeID : null,
      htmlVersionId: htmlVersionID
        ? htmlVersionID
        : design.htmlVersionID
          ? design.htmlVersionID
          : "",
    };
    const requests = [
      postAsync<{
        front?: string;
        back?: string;
        pdf?: string;
      }>(
        `/designs/${
          designID ? designID : design.designID
        }/generate-proof?htmlVersionID=${
          htmlVersionID
            ? htmlVersionID
            : design.htmlVersionID
              ? design.htmlVersionID
              : ""
        }`,
        request,
      ),
      postAsync<{
        front?: string;
        back?: string;
        pdf?: string;
      }>(
        `/designs/${
          designID ? designID : design.designID
        }/generate-proof?isPreview=true&htmlVersionID=${
          htmlVersionID
            ? htmlVersionID
            : design.htmlVersionID
              ? design.htmlVersionID
              : ""
        }`,
        request,
      ),
    ];
    const responses = await Promise.all(requests);
    const proofs = responses[0];
    const previewProofs = responses[1];
    if (proofs && previewProofs) {
      const d = { ...design };
      if (designID) {
        d.designID = designID;
      }
      if (htmlVersionID) {
        d.htmlVersionID = htmlVersionID;
      }
      setDesign({
        ...d,
        proofFront: proofs.pdf ? proofs.pdf : proofs.front,
        proofBack: proofs.back,
        previewProofFront: previewProofs.pdf
          ? previewProofs.pdf
          : previewProofs.front,
        previewProofBack: previewProofs.back,
      });
      setNetworkState({ status: NetworkStatus.Idle });
    } else {
      setNetworkState({
        status: NetworkStatus.Idle,
        error: { type: "proof", error: "Unable to generate proof" },
      });
    }
  }

  function handleProofFieldsUpdate() {
    const vars = [...getVars(design.html), ...getVars(design.htmlBack)].reduce(
      (prev: string[], current) => {
        if (!prev.includes(current)) prev.push(current);
        return prev;
      },
      [],
    );
    if (vars.length === 0) setProofFields({});
    else {
      const fields: Record<string, string> = {};

      vars.forEach((v) => {
        if (proofFields[v]) {
          fields[v] = proofFields[v];
        } else {
          fields[v] = `[${v}]`;
        }
      });
      setProofFields(fields);
    }
  }

  function handleChange(
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent,
  ) {
    if (e.target.name === "categoryID") {
      setDesign({ ...design, categoryID: Number(e.target.value) });
    } else if (e.target.name === "subcategoryID") {
      setDesign({ ...design, subcategoryID: Number(e.target.value) });
    } else if (e.target.name === "size") {
      const activeSize = sizes.find((x) => x.size === e.target.value);
      if (activeSize) {
        if (e.target.value === "BRO") {
          setEnvelopeType("Tri");
        } else {
          setEnvelopeType("Regular");
        }
        setDesign({ ...design, size: e.target.value, type: activeSize.type });
      }
    } else setDesign({ ...design, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (activeStep === Steps.GenerateProof) {
      if (
        design.html?.includes("{{QrCode}}") ||
        design.htmlBack?.includes("{{QrCode}}")
      ) {
        setHasQrCode(true);
      } else {
        setHasQrCode(false);
      }
      handleProofFieldsUpdate();
    }
  }, [activeStep]);

  const activeSize = sizes.find((x) => x.size === design.size);

  const fieldsArray = Object.keys(proofFields)
    .filter((x) => x !== "QrCode")
    .map((field) => field);

  return (
    <React.Fragment>
      {isAdmin && (
        <Box sx={{ mb: 3 }}>
          <Link to="/admin/html">
            <i className="fas fa-chevron-left"></i> Back to Templates
          </Link>
        </Box>
      )}
      <LoadingWrapper
        height={500}
        loading={networkState.status === NetworkStatus.Loading}
        container={Paper}
      >
        <Stepper activeStep={activeStep} orientation={"vertical"}>
          <Step>
            <StepLabel>Configure The Design</StepLabel>
            <StepContent>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <FormLabel>Design Name</FormLabel>
                <TextField
                  hiddenLabel
                  fullWidth
                  size="small"
                  name="friendlyName"
                  value={design.friendlyName}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl fullWidth size="small" disabled={!isNew}>
                <FormLabel>Size</FormLabel>
                <Select
                  value={Boolean(design.size) ? design.size : "size"}
                  name="size"
                  onChange={handleChange}
                  size="small"
                  disabled={!isNew}
                >
                  <MenuItem value="size" disabled>
                    Choose A Size
                  </MenuItem>
                  {sizes.map((size) => (
                    <MenuItem key={size.size} value={size.size}>
                      {size.sizeLabel}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isAdmin && (
                <React.Fragment>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ mb: 2, mt: 2 }}
                    hiddenLabel
                  >
                    <FormLabel>Design Category (optional)</FormLabel>
                    <Select
                      value={
                        design.categoryID ? design.categoryID.toString() : ""
                      }
                      onChange={handleChange}
                      name="categoryID"
                    >
                      <MenuItem value="">No Category</MenuItem>
                      {designCategories?.map((category) => (
                        <MenuItem
                          key={category.categoryID}
                          value={category.categoryID.toString()}
                        >
                          {category.categoryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      setCategoryModalState({
                        ...categoryModalState,
                        newCategoryOpen: true,
                      })
                    }
                  >
                    Add New Category
                  </Button>
                  {Boolean(design.categoryID) && (
                    <React.Fragment>
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{ mt: 2 }}
                        hiddenLabel
                      >
                        <FormLabel>Design Subcategory (optional)</FormLabel>
                        <Select
                          value={
                            design.subcategoryID
                              ? design.subcategoryID.toString()
                              : ""
                          }
                          onChange={handleChange}
                          name="subcategoryID"
                        >
                          <MenuItem value="">No Subcategory</MenuItem>
                          {currentCategory &&
                            currentCategory.subcategories.map((subcategory) => (
                              <MenuItem
                                key={subcategory.subcategoryID}
                                value={subcategory.subcategoryID.toString()}
                              >
                                {subcategory.subcategoryName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={() =>
                          setCategoryModalState({
                            ...categoryModalState,
                            newSubcategoryOpen: true,
                          })
                        }
                      >
                        Add New Subcategory
                      </Button>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              <Box sx={{ mt: 3 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setActiveStep(Steps.EnterHtml)}
                  disabled={!design.size || !design.friendlyName}
                >
                  Next
                </Button>
              </Box>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Enter Your Html</StepLabel>
            <StepContent>
              <Box sx={{ mb: 3 }}>
                <HtmlEditor
                  name="html"
                  value={design.html ?? ""}
                  onChange={handleChange}
                  label={design.type === "postcard" ? "HTML Front" : "HTML"}
                  disabled={networkState.status === NetworkStatus.Saving}
                  errorCallBack={handleHtmlError}
                />
              </Box>
              {design.type === "postcard" && (
                <Box>
                  <HtmlEditor
                    name="htmlBack"
                    value={design.htmlBack ?? ""}
                    onChange={handleChange}
                    label="HTML Back"
                    disabled={networkState.status === NetworkStatus.Saving}
                    errorCallBack={handleHtmlError}
                  />
                </Box>
              )}
              {networkState.error && networkState.error.type === "general" && (
                <Box>
                  <FormHelperText error>
                    {networkState.error.error}
                  </FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 3 }}>
                <Button
                  color="primary"
                  onClick={() => setActiveStep(Steps.ChooseSize)}
                >
                  Back
                </Button>

                <LoadingButton
                  onClick={handleSaveHtml}
                  color="primary"
                  variant="contained"
                  loading={networkState.status === NetworkStatus.Saving}
                  disabled={
                    !Boolean(design.html) ||
                    (activeSize?.type === "postcard" &&
                      !Boolean(design.htmlBack)) ||
                    varErrors
                  }
                >
                  Next
                </LoadingButton>
                <Box sx={{ mt: 1 }}>
                  {htmlValidationErrors.html && (
                    <div>
                      <FormHelperText error>
                        You must provide HTML{" "}
                        {design.size !== "811" && design.size !== "BRO"
                          ? "for the front of your postcard"
                          : `for your ${design.type}`}
                      </FormHelperText>
                    </div>
                  )}
                  {htmlValidationErrors.htmlBack && (
                    <div>
                      <FormHelperText error>
                        You must provide HTML for the back of your {design.type}
                      </FormHelperText>
                    </div>
                  )}
                  {htmlValidationErrors.htmlBody && (
                    <div>
                      <FormHelperText error>
                        No valid {"<body>"} tag found{" "}
                        {design.size !== "811" && design.size !== "BRO"
                          ? `in the front html of your ${design.type}`
                          : "in your html"}{" "}
                      </FormHelperText>
                    </div>
                  )}
                  {htmlValidationErrors.htmlBodyClose && (
                    <div>
                      <FormHelperText error>
                        No valid closing body tag ({"</body>"}) found{" "}
                        {design.size !== "811" && design.size !== "BRO"
                          ? `in the front html of your ${design.type}`
                          : "in your html"}{" "}
                      </FormHelperText>
                    </div>
                  )}
                  {htmlValidationErrors.htmlBackBody &&
                    design.size !== "811" &&
                    design.size !== "BRO" && (
                      <div>
                        <FormHelperText error>
                          No valid {"<body>"} tag found in the back html of your
                          {design.type}
                        </FormHelperText>
                      </div>
                    )}

                  {htmlValidationErrors.htmlBackBodyClose &&
                    design.size !== "811" && (
                      <div>
                        <FormHelperText error>
                          No valid closing body tag ({"</body>"}) found in the
                          back html of your
                        </FormHelperText>
                      </div>
                    )}
                </Box>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Approve Your Design</StepLabel>
            <StepContent>
              {fieldsArray.length > 0 && (
                <Grid container spacing={3}>
                  {fieldsArray.map((field) => (
                    <Grid item xs={12} md={4} key={field}>
                      <FormLabel>{field}</FormLabel>
                      <TextField
                        name={field}
                        value={proofFields[field]}
                        onChange={(e) =>
                          setProofFields({
                            ...proofFields,
                            [e.target.name]: e.target.value,
                          })
                        }
                        fullWidth
                        size="small"
                        disabled={
                          networkState.status === NetworkStatus.Proof ||
                          networkState.status === NetworkStatus.Publish
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              {design.type === "brochure" && (
                <Box sx={{ mt: 2 }}>
                  <InputLabel>Fold Type</InputLabel>
                  <Select
                    value={envelopeType}
                    onChange={(e) => setEnvelopeType(e.target.value)}
                    size="small"
                    disabled={
                      networkState.status === NetworkStatus.Proof ||
                      networkState.status === NetworkStatus.Publish
                    }
                  >
                    <MenuItem value="Tri">Tri Fold</MenuItem>
                    <MenuItem value="Bi">Bi Fold</MenuItem>
                  </Select>
                </Box>
              )}
              {design.type === "letter" && (
                <Box sx={{ mt: 2 }}>
                  <InputLabel>Envelope Type</InputLabel>
                  <Select
                    value={envelopeType}
                    onChange={(e) => setEnvelopeType(e.target.value)}
                    size="small"
                    disabled={
                      networkState.status === NetworkStatus.Proof ||
                      networkState.status === NetworkStatus.Publish
                    }
                  >
                    <MenuItem value="Regular">Regular Envelope</MenuItem>
                    <MenuItem value="BiFold">Bi-Fold Regular Envelope</MenuItem>
                    <MenuItem value="Custom">Single Window</MenuItem>
                    <MenuItem value="doubleWindow">Double Window</MenuItem>
                    <MenuItem value="fullWindow">Full Window</MenuItem>
                  </Select>
                </Box>
              )}

              {hasQrCode && (
                <Box sx={{ mt: 2 }}>
                  <InputLabel>Default QR Code</InputLabel>
                  <QrCodeSelect value={qrCodeID} onChange={setQrCodeID} />
                  {!qrCodeID && (
                    <FormHelperText>
                      You must select or create a QR Code to continue
                    </FormHelperText>
                  )}
                </Box>
              )}

              <Box sx={{ pt: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      onClick={() => handleGenerateProof()}
                      loading={networkState.status === NetworkStatus.Proof}
                      disabled={hasQrCode && !qrCodeID}
                    >
                      {networkState.status === NetworkStatus.Proof ? (
                        <React.Fragment>Generating Your Proof</React.Fragment>
                      ) : (
                        <React.Fragment>Generate Proof</React.Fragment>
                      )}
                    </LoadingButton>
                  </Grid>
                  {Boolean(design.proofFront) &&
                    (design.type === "letter" ||
                      design.type === "brochure") && (
                      <Grid item xs={12}>
                        <Typography gutterBottom variant="h6">
                          Proof
                        </Typography>
                        {networkState.status !== NetworkStatus.Proof && (
                          <Img
                            src={design.proofFront}
                            style={{ width: "100%" }}
                            showLoadingScreen
                            canZoom
                          />
                        )}
                        {networkState.status === NetworkStatus.Proof && (
                          <Skeleton height={"311px"} variant="rectangular" />
                        )}
                      </Grid>
                    )}
                  {Boolean(design.proofFront) && design.type === "postcard" && (
                    <React.Fragment>
                      <Grid item xs={12} md={6} lg={4} xl={3}>
                        <Typography gutterBottom variant="h6">
                          {design.type === "postcard" ? "Proof Front" : "Proof"}
                        </Typography>

                        {networkState.status !== NetworkStatus.Proof && (
                          <Img
                            src={design.proofFront}
                            style={{ width: "100%" }}
                            showLoadingScreen
                            canZoom
                          />
                        )}
                        {networkState.status === NetworkStatus.Proof && (
                          <Skeleton height={"311px"} variant="rectangular" />
                        )}
                      </Grid>
                      {design.type === "postcard" && (
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                          <Typography gutterBottom variant="h6">
                            Proof Back
                          </Typography>

                          {networkState.status !== NetworkStatus.Proof && (
                            <Img
                              src={design.proofBack}
                              style={{ width: "100%" }}
                              showLoadingScreen
                              canZoom
                            />
                          )}
                          {networkState.status === NetworkStatus.Proof && (
                            <Skeleton height={"311px"} variant="rectangular" />
                          )}
                        </Grid>
                      )}
                    </React.Fragment>
                  )}

                  <Grid item xs={12}>
                    {networkState.error &&
                      (networkState.error.type === "proof" ||
                        networkState.error.type === "publish") && (
                        <Box>
                          <FormHelperText error>
                            {networkState.error.error}
                          </FormHelperText>
                        </Box>
                      )}
                    <Box sx={{ mt: 3 }}>
                      <Button
                        color="primary"
                        onClick={() => setActiveStep(Steps.EnterHtml)}
                      >
                        Back
                      </Button>
                      {(design.type === "letter" ||
                        design.type === "brochure") && (
                        <Button
                          href={design.proofFront ?? ""}
                          target="_blank"
                          rel="noreferrer"
                          color="primary"
                          variant="outlined"
                          sx={{ mr: 2 }}
                          disabled={!design.proofFront}
                        >
                          View PDF
                        </Button>
                      )}
                      <Button
                        onClick={handlePublishHtml}
                        color="primary"
                        variant="contained"
                        disabled={
                          !design.proofFront ||
                          (design.type === "postcard" && !design.proofBack)
                        }
                      >
                        Publish Html
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
        <NewCategoryModal
          isOpen={categoryModalState.newCategoryOpen}
          onClose={handleNewCategoryClose}
          onCategoryAdd={handleCategoryAdd}
          categories={designCategories ?? []}
        />
        {currentCategory && (
          <NewSubcategoryModal
            isOpen={categoryModalState.newSubcategoryOpen}
            onClose={handleNewSubcategoryClose}
            onSubcategoryAdd={handleSubcategoryAdd}
            category={currentCategory}
          />
        )}
      </LoadingWrapper>
    </React.Fragment>
  );
};

export default EditHtml;
