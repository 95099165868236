import { Object as IObject } from "fabric/fabric-impl";
import { fabric } from "fabric";
import DATALESS_PROPERTIES from "../../../constants/DATALESS_PROPERTIES";

export default async function asyncClone(object: IObject) {
  return new Promise<IObject>((resolve) => {
    object.clone(
      (clone: IObject) => {
        resolve(clone);
      },
      [...DATALESS_PROPERTIES, "_objects"]
    );
  });
}

// export async function asyncAddSvg()

export async function asyncAddSvgFromUrl(url: string) {
  return new Promise<IObject>((resolve) => {
    fabric.loadSVGFromURL(url, (results) => {
      const obj = fabric.util.groupSVGElements(results);
      resolve(obj);
    });
  });
}
