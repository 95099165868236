import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import { Fragment } from "react";

const IntegratedTooltip = styled(
  ({
    className,

    ...props
  }: Omit<TooltipProps, "children">) => {
    return (
      <Tooltip
        disableFocusListener
        disableTouchListener
        placement="top-start"
        {...props}
        classes={{ popper: className }}
      >
        <InfoIcon sx={{ color: "#6F6F6F" }} />
      </Tooltip>
    );
  }
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 250,
    padding: "12px",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    h5: {
      fontSize: "12px",
      marginBottom: "4px",
      marginTop: 0,
      fontWeight: 600,
      color: "rgb(68, 68, 68)",
    },
    p: {
      fontSize: "12px",
      marginTop: 0,
      color: "rgb(24, 24, 24)",
    },
    "p+h5": {
      marginTop: "10px",
    },
  },
}));

export default IntegratedTooltip;
