import {
  Group,
  IEvent,
  IImageOptions,
  Object as IObject,
  IObjectOptions,
  Image,
} from "fabric/fabric-impl";
import { useContext, useEffect, useRef, useState } from "react";

import { CanvasContext } from "../../state/contexts/CanvasContext";
import {
  changeToCircle,
  changeToEllipse,
  changeToExistingShape,
  changeToRect,
  groupCleanup,
} from "./functions/changeMaskFunctions";
import { IObjectProperties } from "../../state/contexts/SelectedObjectContext";

/**
 *  1. Event Listeners - selection, need to have a selected object state
 *  2.
 */

/**
 *
 */

const useImageMasking = (selection: IImageGroup): IImageMasking => {
  const canvas = useContext(CanvasContext);

  const [editMode, setEditMode] = useState(false);
  const [maskType, setMaskType] = useState(selection.__maskType ?? "rect");
  const frameRef = useRef<IObject>(
    selection._objects.find((x) => x.type !== "image") as IObject
  );
  const imageRef = useRef<Image>(
    selection._objects.find((x) => x.type === "image") as Image
  );

  // need to create a ref so the event listeners always have the most up to date object for manipulating its selection appearances.

  /**
   * @todo create the logic to update the frame with a border if it doesn't have one,
   * and draw the selection around the type. Use selectionRef for most up to date object.
   */
  function toggleEditMode() {
    setEditMode(!editMode);
  }

  function changeMaskType(type: MaskType, shape?: IObject) {
    if (shape) {
      changeToExistingShape(selection, shape);
    } else {
      switch (type) {
        case "rect":
          changeToRect(selection);
          break;
        case "ellipse":
          changeToEllipse(selection);
          break;
        case "circle":
          changeToCircle(selection);
          break;
        case "square":
          changeToRect(selection, true);
          break;
        default:
          break;
      }
    }
    selection.__maskType = type;
    selection.canvas?.renderAll();

    setMaskType(type);
  }

  function updateFrameDimensions(
    this: IImageGroup,
    dim: Partial<IObjectOptions>
  ) {
    const frame = this.__getFrame();
    const image = this.__getImage();

    if (
      this.__maskType === "circle" &&
      dim.width !== undefined &&
      dim.height !== undefined
    ) {
      // @ts-ignore
      dim.rx = dim.width / 2;
      // @ts-ignore
      dim.ry = dim.height / 2;
    }
    frame.set({ ...dim });
    this.canvas?.renderAll();
    groupCleanup(this, image, frame);
    this.canvas?.renderAll();
  }

  function updateImageDimensions(
    this: IImageGroup,
    dim: Partial<IImageOptions>
  ) {
    const image = this.__getImage();
    const frame = this.__getFrame();

    image.set({ ...dim });

    this.canvas?.renderAll();
    groupCleanup(this, image, frame);
    this.canvas?.renderAll();
  }

  function clearSelection() {
    toggleEditMode();
  }

  function handleImageGroupSelection() {
    if (!selection._objects.find((x) => x.type === "image"))
      return clearSelection();
    // create the imageGroup functions if they don't exist
    if (!selection.__getImage) {
      selection.__getImage = () =>
        selection.getObjects().find((x) => x.type === "image") as Image;
      selection.__getFrame = () =>
        selection.getObjects().find((x) => x.type !== "image") as IObject;
      selection.__updateImageDimensions = updateImageDimensions;
      selection.__updateFrameDimensions = updateFrameDimensions;
    }

    // setup the baseline dimensions if we don't currently have any stored.
    if (!selection.__maskType) {
      selection.__maskType = "rect";
      const image = selection.__getImage();
      const frame = selection.__getFrame();
      selection.__originalImageDimensions = {
        left: image.left ?? 0,
        top: image.top ?? 0,
        width: image.width ?? selection.width ?? 0,
        height: image.height ?? selection.height ?? 0,
        // @ts-ignore
        rx: image.rx ?? 0,
        // @ts-ignore
        ry: image.ry ?? 0,
        angle: image.angle ?? 0,
        skewX: image.skewX ?? 0,
        skewY: image.skewY ?? 0,
      };
      selection.__originalFrameDimensions = {
        left: frame.left ?? 0,
        top: frame.top ?? 0,
        width: frame.width ?? selection.width ?? 0,
        height: frame.height ?? selection.height ?? 0,
        // @ts-ignore
        rx: frame.rx ?? 0,
        // @ts-ignore
        ry: frame.ry ?? 0,
        angle: frame.angle ?? 0,
        skewX: frame.skewX ?? 0,
        skewY: frame.skewY ?? 0,
      };
    }

    setMaskType(selection.__maskType);
  }

  useEffect(() => {
    handleImageGroupSelection();

    frameRef.current = selection._objects.find(
      (x) => x.type !== "image"
    ) as IObject;
    imageRef.current = selection._objects.find(
      (x) => x.type === "image"
    ) as Image;
    () => {
      // destroy the event listeners on the object if we were in edit mode while unmounting.
    };
  }, [selection]);

  return {
    editMode,
    toggleEditMode,
    maskType,
    changeMaskType,
    frame: frameRef.current,
    image: imageRef.current,
  };
};

export interface IImageGroup extends Group {
  __maskType: MaskType;
  __originalImageDimensions: IImageGroupDimensions;
  __originalFrameDimensions: IImageGroupDimensions;
  __getImage: () => Image;
  __getFrame: () => IObject;
  __updateFrameDimensions: ManipulationFunction;
  __updateImageDimensions: (
    this: IImageGroup,
    dim: Partial<IImageOptions>
  ) => void;
  __changeMaskType: (
    this: IImageGroup,
    type: MaskType,
    shape?: IObject
  ) => void;
}

export interface IImageMasking {
  editMode: boolean;
  toggleEditMode: () => void;
  maskType: MaskType;
  changeMaskType: (type: MaskType) => void;
  frame: IObject;
  image: Image;
}

export type MaskType =
  | "rect"
  | "circle"
  | "ellipse"
  | "path"
  | "polygon"
  | "square";

type ManipulationFunction = (
  this: IImageGroup,
  dim: Partial<IObjectOptions>
) => void;

interface IImageGroupDimensions {
  left: number;
  top: number;
  width: number;
  height: number;
  angle: number;
  skewX: number;
  skewY: number;
  rx: number;
  ry: number;
}

export default useImageMasking;
