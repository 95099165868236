import DesignGallery from "../Integrated/DesignGallery/DesignGallery";

const PortalDesignGallery = () => {
  return (
    <div>
      <DesignGallery isPortalGallery />
    </div>
  );
};

export default PortalDesignGallery;
