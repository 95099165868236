import React, { useContext, useEffect, useReducer, useState } from "react";
import { getAsync, postAsync, putAsync } from "../../../helpers/asyncFetch";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import isAdminUser from "../../../helpers/isAdminUser";
import Integration from "../models/Integration";
import LoadingButton from "../../../shared/LoadingButton";

interface ReassignPanelProps {
  open: boolean;
  onClose: () => void;
  designID: number;
  bAccountID?: number;
}

export type ReassignNetworkStatus = "loading" | "success" | "error" | "idle";

interface ReassignState {
  open: boolean;
  networkState: {
    status: ReassignNetworkStatus;
    error?: string;
  };
  designID?: number;
  bAccountID?: number;
  currentBAccountID?: number;
  bAccounts: Integration[];
}

const initialState: ReassignState = {
  open: false,
  networkState: {
    status: "idle",
  },
  bAccounts: [],
};

export const ReassignContext = React.createContext<ReassignState>(initialState);

function reAssignReducer(
  state: ReassignState,
  action: { type: string; payload: Partial<ReassignState> }
) {
  switch (action.type) {
    case "openPanel": {
      return {
        ...state,
        open: true,
        bAccountID: action.payload.bAccountID,
        designID: action.payload.designID,
        currentBAccountID: action.payload.bAccountID,
      };
    }
    case "closePanel": {
      return {
        ...state,
        open: false,
        bAccountID: undefined,
        designID: undefined,
        currentBAccountID: undefined,
      };
    }
    case "updateBAccounts": {
      return {
        ...state,
        bAccounts: action.payload.bAccounts ?? [],
      };
    }
    case "updateBAccountID": {
      return {
        ...state,
        bAccountID: action.payload.bAccountID,
      };
    }
    case "updateNetworkState": {
      return {
        ...state,
        networkState: {
          ...state.networkState,
          ...action.payload.networkState,
        },
      };
    }
    default:
      return state;
  }
}

export const ReAssignDispatch = React.createContext<
  React.Dispatch<{ type: string; payload: Partial<ReassignState> }>
>({} as React.Dispatch<{ type: string; payload: Partial<ReassignState> }>);

export function useReassign() {
  const state = useContext(ReassignContext);
  const dispatch = useContext(ReAssignDispatch);
  function openPanel(designID: number, bAccountID?: number) {
    dispatch({ type: "openPanel", payload: { designID, bAccountID } });
  }
  function closePanel() {
    dispatch({ type: "closePanel", payload: {} });
  }
  function updateBAccountID(bAccountID: number) {
    dispatch({ type: "updateBAccountID", payload: { bAccountID } });
  }
  function updateNetworkState(status: ReassignNetworkStatus, error?: string) {
    dispatch({
      type: "updateNetworkState",
      payload: { networkState: { status, error } },
    });
  }
  return {
    openPanel,
    closePanel,
    updateBAccountID,
    updateNetworkState,
    ...state,
  };
}

export const ReAssignProvider = ({
  children,
}: {
  children?: React.ReactFragment;
}) => {
  const [state, dispatch] = useReducer(reAssignReducer, initialState);

  const isAdmin = isAdminUser();

  async function getIntegrations() {
    if (isAdmin && state.bAccounts.length === 0) {
      const bAccounts = await getAsync<Integration[]>("/admin/integrations");
      if (bAccounts) {
        dispatch({ type: "updateBAccounts", payload: { bAccounts } });
      }
    }
  }

  useEffect(() => {
    getIntegrations();
  }, []);

  if (!isAdmin) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <ReassignContext.Provider value={state}>
      <ReAssignDispatch.Provider value={dispatch}>
        {children}
      </ReAssignDispatch.Provider>
    </ReassignContext.Provider>
  );
};

const ReassignPanel = () => {
  const state = useReassign();
  async function reAssignDesign() {
    state.updateNetworkState("loading");
    const design = await putAsync(
      `/admin/integrations/designs/${state.designID}/reassign`,
      {
        bAccountID: state.bAccountID,
      }
    );
    if (design) {
      state.updateNetworkState("success");
      setTimeout(() => {
        state.updateNetworkState("idle");
      }, 500);
      state.closePanel();
    }
  }

  function handleClose() {
    state.closePanel();
  }
  const currentBAccount = state.bAccounts.find(
    (x) => x.bAccountID === state.currentBAccountID
  );
  const selectedBAccount = state.bAccounts.find(
    (x) => x.bAccountID === state.bAccountID
  );

  return (
    <Dialog open={state.open} maxWidth="sm" fullWidth>
      <DialogTitle>Reassign Design #{state.designID}</DialogTitle>
      <DialogContent>
        <Box>
          <strong>Currently Assigned To: </strong>{" "}
          {state.currentBAccountID ? (
            <span>
              {currentBAccount?.companyName} ({currentBAccount?.bAccountID})
            </span>
          ) : (
            <span>Unassigned (Template Design)</span>
          )}
        </Box>
        <Box sx={{ py: 2 }}>
          <strong>Reassign To:</strong>
        </Box>
        <Box>
          <Autocomplete
            size="small"
            autoComplete
            options={[
              { label: "Template Design", value: "" },
              ...state.bAccounts.map((x) => ({
                label: `${x.companyName} (${x.bAccountID})`,
                value: x.bAccountID.toString(),
              })),
            ]}
            value={
              selectedBAccount
                ? {
                    label: `${selectedBAccount?.companyName} (${selectedBAccount?.bAccountID})`,
                    value: selectedBAccount?.bAccountID.toString(),
                  }
                : { label: "Template Design", value: "" }
            }
            onChange={(event, newValue) => {
              state.updateBAccountID(parseInt(newValue?.value ?? "0"));
            }}
            renderInput={(params) => (
              <TextField {...params} label="Business Account" />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          onClick={reAssignDesign}
          loading={state.networkState.status === "loading"}
        >
          Reassign
        </LoadingButton>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReassignPanel;
