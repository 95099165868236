import React, { useState, useEffect, useRef } from "react";
import BatchResponse from "../models/BatchResponse";
import { fetchClient } from "../../../helpers/fetchClient";
import downloadFileJS from "../../../helpers/downloadFileJS";
import {
  Box,
  Typography,
  Paper,
  Dialog,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  Table,
  TableHead,
  TableCell,
  Button,
  TableRow,
  DialogActions,
  TableBody,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import formattingHelpers from "../../../helpers/formattingHelpers";

interface BatchStatusProps {
  batch: BatchResponse;
}

const getContent = (activeStep: number) => {
  switch (activeStep) {
    case 0:
      return "Your batch has been received and we are preparing it for printing";
    case 1:
      return "Your mail is currently printing and we are preparing it for delivery";
    case 2:
      return "Your mail is out for delivery";
    case 3:
      return "Your mail has started to hit mailboxes";
    case -1:
      return <strong>Your order was successfully canceled</strong>;
    case -2:
      return <strong>Your order failed to process</strong>;
  }
};

const BatchStatus: React.FC<BatchStatusProps> = ({ batch }) => {
  let activeStep = 0;

  const [receiptLoading, setReceiptLoading] = useState(false);
  const [receiptError, setReceiptError] = useState(false);
  if (batch && batch.status) {
    switch (batch.status.toLowerCase()) {
      case "processing":
        activeStep = 1;
        break;
      case "mailing":
        activeStep = 2;
        break;
      case "delivered":
        activeStep = 3;
        break;
      case "cancelled":
      case "canceled":
        activeStep = -1;
        break;
      case "failed":
        activeStep = -2;
        break;
      default:
        activeStep = 0;
        break;
    }
  }
  const activeStepContent = getContent(activeStep);
  function handleDownload(e: React.SyntheticEvent<Element, Event>) {
    e.preventDefault();
    setReceiptLoading(true);
    fetchClient.get({
      path: `/orders/${batch.batchId}/report`,
      onSuccess: onSuccessResponse,
      onError: onErrorResponse,
    });
  }

  function onSuccessResponse(data: string) {
    downloadFileJS(
      `/uploads${data}`,
      "application/pdf",
      `receipt-${batch.orderID}.pdf`,
    ).then(() => setReceiptLoading(false));
  }
  function onErrorResponse() {
    setReceiptLoading(false);
    setReceiptError(false);
  }

  return (
    <Box>
      <Box sx={{ p: 3 }}>
        <Stepper activeStep={activeStep}>
          <Step>
            {batch.status && <StepLabel>Preparing to print</StepLabel>}
          </Step>
          <Step>
            <StepLabel>Printing in progress</StepLabel>
          </Step>
          <Step>
            <StepLabel>Out for delivery</StepLabel>
          </Step>
          <Step>
            <StepLabel>Delivered</StepLabel>
          </Step>
        </Stepper>

        <Typography sx={{ mt: 3, ml: 1 }}>{activeStepContent}</Typography>
      </Box>
      <Box sx={{ p: 3, mt: 2, background: "rgba(0,0,0,.04)" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 0, border: "none" }}>Recipients</TableCell>
              <TableCell sx={{ p: 0, border: "none" }}>Batch Date</TableCell>
              {!batch.isSandbox &&
                batch.invoiceURL &&
                (!batch.bAccountID || batch.bAccountID !== 1088636) && (
                  <TableCell sx={{ p: 0, border: "none" }}>
                    Batch Receipt
                  </TableCell>
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ p: 0, border: "none" }}>
                <strong>
                  {formattingHelpers.formatThousands(batch.recipients.length)}
                </strong>
              </TableCell>
              <TableCell sx={{ p: 0, border: "none" }}>
                <strong>{moment(batch.orderDate).format("MM/DD/YYYY")}</strong>
              </TableCell>
              {!batch.isSandbox &&
                !Boolean(batch.invoiceURL) &&
                (!batch.bAccountID || batch.bAccountID !== 1088636) && (
                  <TableCell sx={{ p: 0, border: "none" }}>
                    <div />
                  </TableCell>
                )}
              {!batch.isSandbox &&
                Boolean(batch.invoiceURL) &&
                (!batch.bAccountID || batch.bAccountID !== 1088636) && (
                  <TableCell sx={{ p: 0, border: "none" }}>
                    {/* @ts-ignore */}
                    <Button
                      color="primary"
                      variant="outlined"
                      href={batch.invoiceURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download Receipt
                    </Button>
                  </TableCell>
                )}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Dialog open={receiptLoading || receiptError}>
        <DialogTitle>Loading Receipt</DialogTitle>
        <DialogContent>
          {receiptLoading && (
            <Typography>Your download will begin soon</Typography>
          )}
          {receiptError && (
            <Typography sx={{ color: (theme) => theme.palette.error.main }}>
              There was an error downloading your receipt
            </Typography>
          )}
        </DialogContent>
        {receiptError && (
          <DialogActions>
            <Button color="primary" onClick={() => setReceiptError(false)}>
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
};

export default BatchStatus;
