import {
  Typography,
  Paper,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
} from "@mui/material";
import GridItem from "../../shared/MaterialWrappers/GridItem";
import GridRow from "../../shared/MaterialWrappers/GridRow";
import { fetchClient } from "../../helpers/fetchClient";
import BatchResponse from "./models/BatchResponse";
import { useParams } from "react-router-dom";
import BatchStatus from "./components/BatchStatus";
import exportCsv from "../../helpers/exportCsv";
import OrderList from "./components/OrderList";

import "./Batch.scss";
import RecipientListTable from "./components/RecipientListTable";
import LoadingWrapper from "../../shared/LoadingWrapper";
import Design from "../Designs/models/Design";
import Img from "../../shared/BasicHTML/Img";
import MailTracking from "../../shared/MailTracking/MailTracking";
import { useEffect, useState } from "react";

import useLoadData from "../../hooks/useLoadData";

import Batch from "./models/Batch";

import BatchOrder from "./models/BatchOrder";
import BatchRecipient from "./models/BatchRecipient";
import moment from "moment";
import { BatchRecipientExpanded } from "./models/BatchRecipientExpanded";
import LoadingButton from "../../shared/LoadingButton";
import LocalStorage from "../../helpers/LocalStorage";
import useGetData from "../../hooks/dataFetchers/useGetData";

interface BatchRouteParams {
  batchId: string;
}

const BatchPage = () => {
  const { batchId } = useParams<BatchRouteParams>();
  const session = LocalStorage.getSession();
  const enableRecipientPrivacy = session?.enableRecipientPrivacy;

  const {
    data: rawBatch,
    isLoading: batchIsLoading,
    error,
  } = useGetData<Batch>(`/api/batch/${batchId}`);
  const {
    data: orders,
    isLoading: ordersAreLoading,
    error: ordersError,
  } = useGetData<BatchOrder[]>(`/api/batch/${batchId}/orders`);
  const {
    data: designs,
    isLoading: designsAreLoading,
    error: designError,
  } = useGetData<Design[]>(`/api/batch/${batchId}/designs`);
  const {
    data: recipients,
    isLoading: recipientsAreLoading,
    error: batchRecipientsError,
  } = useGetData<BatchRecipient[]>(`/api/batch/${batchId}/recipients`);

  const [recipientsLoading, setRecipientsLoading] = useState(false);
  const [recipientsError, setRecipientsError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [batch, setBatch] = useState<BatchResponse>({
    orderID: "",
    batchId: 0,
    status: "",
    orderDate: "",
    deliveredDate: null,
    recipients: [],
    designs: [],
    orders: [],
    isSandbox: false,
  } as BatchResponse);
  const [activeDesign, setActiveDesign] = useState<Design>();

  function createBatchResponse() {
    if (
      !batchIsLoading &&
      rawBatch &&
      !ordersAreLoading &&
      orders &&
      !designsAreLoading &&
      designs &&
      !recipientsAreLoading &&
      recipients
    ) {
      const responseOrders =
        orders?.map<BatchOrder>((order) => ({
          ...order,
          recipients:
            recipients?.filter((x) => x.orderID === order.orderID) ?? [],
        })) ?? [];

      setBatch({
        batchId: rawBatch.batchID,
        recipients: recipients,
        orderID: batchId,
        status: rawBatch.status,
        orderDate: rawBatch.batchDate,
        deliveredDate:
          rawBatch.status === "Delivered" ? rawBatch.lastModifiedDate : null,
        designs: designs,
        orders: responseOrders,
        isSandbox: rawBatch.isSandbox,
          invoiceURL: rawBatch.invoiceURL,
        bAccountID: rawBatch.bAccountID,
      });
      setLoading(false);
    }
  }

  const exportContacts = () => {
    setRecipientsError(false);
    const today = new Date();
    const todayFormatted = moment(today).format("MM-DD-YYYY");
    setRecipientsLoading(true);
    fetchClient.get({
      path: `/batch/${batchId}/recipients/expanded`,
      onSuccess: (data: BatchRecipientExpanded[]) => {
        if (data && data.length > 0) {
          const recipients = data.map((record) => {
            const order = orders?.find((x) => x.orderID === record.orderID);
            if (order && order.status === "Canceled") {
              (record as any).mailingStatus = order.status;
            }
            const { recipientDesignVariables, ...rest } = record;
            if (recipientDesignVariables.length > 0) {
              const variableObject = recipientDesignVariables.reduce(
                (prev, current) => {
                  const newObj = { ...prev, [current.key]: current.value };
                  return newObj;
                },
                {},
              );

              return { ...rest, ...variableObject };
            }
          });
          setRecipientsLoading(false);
          exportCsv(
            recipients,
            `recipients-batch-${batch.batchId}-${todayFormatted}`,
          );
        }
      },
      onError: (e) => {
        setRecipientsLoading(false);
        setRecipientsError(true);
        exportCsv(
          batch.recipients,
          `recipients-batch-${batchId}-${todayFormatted}`,
        );
      },
    });
  };

  useEffect(createBatchResponse, [
    batchIsLoading,
    ordersAreLoading,
    designsAreLoading,
    recipientsAreLoading,
  ]);

  return (
    <Box sx={{ pt: 8, pb: 8 }}>
      <LoadingWrapper
        loading={loading}
        height={600}
        container={Paper}
        hasError={
          error.hasError ||
          ordersError.hasError ||
          designError.hasError ||
          batchRecipientsError.hasError
        }
      >
        <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
          Batch #{batchId}
        </Typography>
        <GridRow>
          <GridItem md={12} sx={{ marginBottom: "30px" }}>
            <Paper elevation={3}>
              {batch && batch.status !== "Undeliverable" && (
                <BatchStatus batch={batch} />
              )}
            </Paper>
          </GridItem>
        </GridRow>

        {!enableRecipientPrivacy && batch?.recipients && (
          <Box sx={{ mt: 8 }}>
            <Box>
              <RecipientListTable
                data={batch.recipients}
                showOrderID
                orders={batch.orders}
              />
              <Box sx={{ mt: 2, textAlign: "right" }}>
                <LoadingButton
                  color="primary"
                  onClick={exportContacts}
                  variant="outlined"
                  loading={recipientsLoading}
                  success={false}
                >
                  Export Recipients
                </LoadingButton>
                {recipientsError && (
                  <Box>
                    <Typography variant="error">
                      There was an error retrieving variable data for your list
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
        {batch?.orders && (
          <Box sx={{ mt: 8 }}>
            <Box>
              <OrderList data={batch?.orders} />
            </Box>
          </Box>
        )}

        {batch?.status == "Delivered" && (
          <Box sx={{ mt: 8 }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "1.5rem", mb: 2 }}
              gutterBottom
            >
              Mail Tracking
            </Typography>
            <MailTracking batchId={batch.batchId} />
          </Box>
        )}

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontSize: "1.5rem", mb: 2, mt: 8 }}
        >
          Designs
        </Typography>
        <Box>
          <GridRow>
            {batch?.designs.map((design) => (
              <GridItem breakPoints={[6, 3]} key={design.designID}>
                <Card elevation={4} onClick={() => setActiveDesign(design)}>
                  <Img src={design.proofFront} />
                </Card>
              </GridItem>
            ))}
          </GridRow>
          <Dialog open={Boolean(activeDesign)} fullWidth maxWidth="lg">
            <DialogTitle>
              {activeDesign?.friendlyName
                ? activeDesign?.friendlyName
                : activeDesign?.designID}
            </DialogTitle>
            <DialogContent>
              <GridRow spacing={2}>
                <GridItem xs={6}>
                  <Box>
                    <Typography variant="h4" gutterBottom component="div">
                      Design Front
                    </Typography>
                    <Img src={activeDesign?.proofFront} />
                  </Box>
                </GridItem>
                <GridItem xs={6}>
                  <Box>
                    <Typography variant="h4" gutterBottom component="div">
                      Design Back
                    </Typography>
                    <Img src={activeDesign?.proofBack} />
                  </Box>
                </GridItem>
              </GridRow>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => setActiveDesign(undefined)}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </LoadingWrapper>
    </Box>
  );
};

export default BatchPage;
