import {
  Canvas,
  Group,
  Object as IObject,
  StaticCanvas,
} from "fabric/fabric-impl";
import { IPage } from "../../../state/contexts/PagesContext";
import DATALESS_PROPERTIES from "../../../constants/DATALESS_PROPERTIES";
import insertBackgroundAndOverlay, {
  generateMargins,
  generateSafeArea,
} from "./insertBackgroundAndOverlay";
import { IBulletedList, Textbox, fabric } from "fabric";
import { createBleedClipPath } from "./createBackgroundClipPath";
import textResizer from "./textResizer";
import RESOLUTION from "../../../constants/RESOLUTION";
import getOverlayObjects from "./getOverlayObjects";
import toggleCanvasSelection from "./toggleCanvasSelection";
import bulletResizer from "./bulletResizer";
import { reAlignListAndItems } from "./createBulletedList";
import { ILayersCollection } from "../../../state/models/ILayers";
import ILayer from "../../../state/models/layers/ILayer";
import {
  LetterFoldTypes,
  LetterSettingsMargins,
} from "../../../state/slices/letterSettings";
import nonGoogleFontSafeList from "../../Fonts/nonGoogleFontSafeList";
import DesignerSizes from "../../../../data/models/DesignerSizes";
import centerAllObjectsOnCanvas from "../../../helpers/centerAllObjectsOnCanvas";
import centerBleedOnCanvas from "../../../helpers/centerBleedOnCanvas";
export default async function loadSavedCanvasData(
  canvas: Canvas,
  pages: IPage[],
  overlay: string,
  width: number,
  height: number,
  layers: ILayersCollection[],
  envelopeType?: string,
  fold?: LetterFoldTypes,

  margins?: LetterSettingsMargins,
) {
  const newPages: IPage[] = [];
  for (let i = pages.length - 1; i >= 0; i--) {
    const page = layers.find((x) => x.name === pages[i].name);

    if (page) {
      const layersArr = Object.keys(page.layers).map((key) => {
        const layer = { ...page.layers[key] } as any;
        if (layer.layers) {
          layer.layers = Object.keys(layer.layers).map((k) => ({
            ...layer.layers[k],
          }));
        }
        return layer;
      });
      layersArr.forEach((layer) => {
        if (layer.layers && layer.layers.length) {
          layer.layers.forEach((nestedLayer: ILayer) => {
            const obj = pages[i].objects.find((x) => x.name === nestedLayer.id);
            if (obj && nestedLayer.isLocked) {
              obj.selectable = false;
              // @ts-ignore
              obj.__locked = true;
            } else if (obj) {
              obj.selectable = true;
              // @ts-ignore
              obj.__locked = false;
            }
          });
        }
        const obj = pages[i].objects.find((x) => x.name === layer.id);
        if (obj) {
          if (layer.isLocked) {
            obj.selectable = false;
            // @ts-ignore
            obj.__locked = true;
          } else {
            obj.selectable = true;
            // @ts-ignore
            obj.__locked = false;
          }
        }
      });
      const objects = pages[i].objects.map((obj: any) => {
        if (obj.type?.includes("text")) {
          if (obj.__fontFamily) {
            obj.fontFamily = obj.__fontFamily;
          }

          if (obj.styles && obj.styles.length) {
            obj.styles = obj.styles.map((style: any) => {
              if (style.style?.__fontFamily) {
                style.style.fontFamily = style.style.__fontFamily;
              }
              return style;
            });
          }
        }
        if (obj.type === "group" && obj.name?.includes("bulletedList")) {
          obj = obj as IBulletedList;
          if (
            obj.__fontSettings?.fontFamily &&
            nonGoogleFontSafeList.includes(obj.__fontSettings?.fontFamily)
          ) {
            obj.objects.forEach((li: any) => {
              li.objects.forEach((item: Textbox) => {
                if (item.type === "textbox") {
                  item.fontFamily = obj.__fontSettings?.fontFamily;
                }
              });
            });
          }
        }
        return obj;
      });

      pages[i] = {
        ...pages[i],
        objects,
      };

      await loadPage(
        canvas,
        pages[i],
        width,
        height,
        overlay,
        envelopeType,
        fold,
        Boolean(page.name === "Front" && fold),
        margins,
      );

      canvas.discardActiveObject();
      centerBleedOnCanvas(canvas);
      canvas.renderAll();
      newPages.push({ ...pages[i], objects: canvas.getObjects() });
    }
  }
  return newPages.reverse();
}

async function loadPage(
  canvas: Canvas,
  page: IPage,
  width: number,
  height: number,
  overlay: string,
  envelopeType?: string,
  fold?: LetterFoldTypes,
  isAddressed?: boolean,
  margins?: LetterSettingsMargins,
) {
  // create our dataless json version of the canvas so we can retain its properties when we load from json.
  const newCanvas = canvas.toDatalessJSON(DATALESS_PROPERTIES);
  // Add our save data, and remove the background and overlay so we can refresh them later
  newCanvas.objects = page.objects.filter(
    (x) => !x.name || !x.name.includes("PathControl"),
  );
  // clear our current canvas
  canvas.remove(...canvas.getObjects());
  await loadFromJSON(canvas, newCanvas);
  canvas.selection = true;
  toggleCanvasSelection(canvas, "on");
  hideTextObjectControls(canvas.getObjects());
  disableCursorsForUnselectableObjects(canvas.getObjects());
  orderImageGroups(canvas);
  await fixBackgroundAndOverlayDimensions(
    canvas,
    width,
    height,
    page.name.toLowerCase() === "page 1" ||
      page.name.toLowerCase() === "back" ||
      overlay === DesignerSizes.BROCHURE
      ? overlay
      : undefined,
    envelopeType,
    fold,
    isAddressed,
    margins,
  );
  canvas.renderAll();
}

export async function loadFromJSON(
  canvas: Canvas | StaticCanvas,
  newCanvas: { version: string; objects: IObject[] },
  skipRender = false,
) {
  return new Promise<void>((resolve) => {
    canvas.loadFromJSON(newCanvas, () => {
      if (!skipRender) canvas.renderAll();
      resolve();
    });
  });
}

function disableCursorsForUnselectableObjects(objs: IObject[]) {
  objs.forEach((obj) => {
    if (
      obj.name === "background" ||
      obj.name === "overlay" ||
      obj.name === "bleed" ||
      obj.selectable === false
    ) {
      obj.hoverCursor = "default";
    }
  });
}

export function hideTextObjectControls(objs: IObject[]) {
  const textObjs = objs.filter(
    (x) => x.type === "textbox" || x.name?.includes("bulletedList"),
  ) as (Textbox | IBulletedList)[];

  textObjs.forEach((textObj) => {
    if (textObj.type === "textbox") {
      textResizer(textObj as Textbox);
      textObj.objectCaching = false;
    } else {
      bulletResizer(textObj as IBulletedList);
    }
  });
  if (textObjs.length) {
    textObjs[0].canvas?.renderAll();
  }
  if (textObjs.find((x) => x.name?.includes("bulletedList"))) {
    setTimeout(() => {
      const bulletedLists = textObjs.filter((x) =>
        x.name?.includes("bulletedList"),
      ) as IBulletedList[];
      bulletedLists[0].canvas?.renderAll();
      bulletedLists.forEach((list) => {
        reAlignListAndItems(list);
      });
      bulletedLists[0].canvas?.renderAll();
    }, 100);
  }
}

function orderImageGroups(canvas: Canvas) {
  const images = canvas
    .getObjects()
    .filter((x) => x.type === "group" && x.name?.includes("image")) as Group[];
  images.forEach((image) => {
    const border = image._objects.find((x) => x.type !== "image");

    if (border) {
      border.fill = "rgba(0,0,0,0)";
      canvas.renderAll();
    }
  });
}

// If A design's size changes, we need to update the dimensions of the background and overlay.
async function fixBackgroundAndOverlayDimensions(
  canvas: Canvas,
  width: number,
  height: number,
  overlay?: string,
  envelopeType?: string,
  fold?: LetterFoldTypes,
  isAddressed?: boolean,
  margins?: LetterSettingsMargins,
) {
  const isLetter = width === 8.5 && height === 11;
  const isBrochure = width === 11 && height === 8.5;

  const background = canvas._objects.find((x) => x.name === "background");
  if (background) {
    const backWidth = background.width ?? 0;
    const backHeight = background.height ?? 0;
    const backLeft = background.left ?? 0;
    const backTop = background.top ?? 0;
    const widthInPixels =
      isLetter || isBrochure ? width * RESOLUTION : (width - 0.25) * RESOLUTION;
    const heightInPixels =
      isLetter || isBrochure
        ? height * RESOLUTION
        : (height - 0.25) * RESOLUTION;

    if (backWidth !== widthInPixels) {
      const difference = widthInPixels - backWidth;
      background.width = widthInPixels;
      background.left = backLeft - difference / 2;
    }
    if (backHeight !== heightInPixels) {
      const difference = heightInPixels - backHeight;
      background.height = heightInPixels;
      background.top = backTop - difference / 2;
    }

    const overlays = canvas._objects.filter((x) => x.name === "overlay");

    if (
      overlays.length &&
      background.left !== undefined &&
      background.top !== undefined
    ) {
      canvas.remove(...overlays);
      const safeArea = generateSafeArea(width, height);

      safeArea.left =
        isLetter || isBrochure
          ? background.left + 0.25 * RESOLUTION
          : background.left + 0.125 * RESOLUTION;
      safeArea.top =
        isLetter || isBrochure
          ? background.top + 0.25 * RESOLUTION
          : background.top + 0.125 * RESOLUTION;
      canvas.add(safeArea);
      const safeAreaLabel = new fabric.Text("Safe Area", {
        fill: "green",
        originX: "center",
        originY: "center",
        left: safeArea.getCenterPoint().x,
        top: safeArea.getCenterPoint().y - safeArea.getScaledHeight() / 2 - 20,
        name: "overlay",
        fontSize: 26,
        evented: false,
        selectable: false,
        fontFamily: "Roboto",
      });
      canvas.add(safeAreaLabel);
      if (margins) {
        const marginObjects = generateMargins(margins, background);
        canvas.add(...marginObjects);
      }
      if (overlay) {
        await getOverlayObjects(
          overlay,
          canvas,
          background,
          width === 8.5 && height === 11 ? envelopeType : undefined,
          fold,
          isAddressed,
        );
      }
    }
  }

  const bleed = canvas._objects.find((x) => x.name === "bleed");
  if (bleed) {
    const bleedWidth = bleed.width ?? 0;
    const bleedHeight = bleed.height ?? 0;
    const bleedLeft = bleed.left ?? 0;
    const bleedTop = bleed.top ?? 0;
    const widthInPixels = width * RESOLUTION - 2;
    const heightInPixels = height * RESOLUTION - 2;
    let isWrong = false;
    if (bleedWidth !== widthInPixels) {
      const difference = widthInPixels - bleedWidth;
      bleed.width = widthInPixels;
      bleed.left = bleedLeft - difference / 2;
      isWrong = true;
    }
    if (bleedHeight !== heightInPixels) {
      const difference = heightInPixels - bleedHeight;
      bleed.height = heightInPixels;
      bleed.top = bleedTop - difference / 2;
      isWrong = true;
    }

    if (isWrong) {
      const objectsWithBleedClip = canvas._objects.filter(
        (x) =>
          x.clipPath &&
          x.clipPath.width &&
          x.clipPath.width === bleedWidth &&
          x.clipPath.height &&
          x.clipPath.height === bleedHeight,
      );

      if (objectsWithBleedClip.length) {
        objectsWithBleedClip.forEach((obj) => {
          obj.clipPath = createBleedClipPath(canvas);
        });
      }
    }

    if (!isLetter && !isBrochure) {
      const bleedLabel = new fabric.Text("Bleed", {
        fill: "blue",
        originX: "center",
        originY: "center",
        left: bleed.getCenterPoint().x,
        top: bleed.getCenterPoint().y - bleed.getScaledHeight() / 2 - 20,
        name: "overlay",
        fontSize: 26,
        evented: false,
        selectable: false,
        fontFamily: "Roboto",
      });
      canvas.insertAt(bleedLabel, 1, false);
    }
  }

  canvas.renderAll();
}
