import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { fetchClient } from "../../../helpers/fetchClient";
import Form from "../../../shared/BasicHTML/Form";

interface ForgotPasswordDialogProps {
  open: boolean;
  onClose: () => void;
}

const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = ({
  open,
  onClose,
}) => {
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>("");
  const [forgotPasswordState, setForgotPasswordState] = useState<
    "none" | "submitting" | "success" | "error" | "no-email"
  >("none");
  const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] =
    useState("");

  function handleResetPassword(e: React.FormEvent) {
    if (!forgotPasswordEmail.trim()) {
      setForgotPasswordState("no-email");
      return;
    }
    setForgotPasswordState("submitting");
    fetchClient.post({
      path: "/auth/forgot-password",
      data: forgotPasswordEmail,
      onSuccess: onHandleResetPasswordResponse,
      onError: onHandleResetPasswordFailure,
    });
  }

  function onHandleResetPasswordResponse() {
    setForgotPasswordState("success");
    setForgotPasswordEmail("");
  }

  function onHandleResetPasswordFailure(err: { message: string }) {
    setForgotPasswordState("error");
    console.log(err);
    setForgotPasswordErrorMessage(err.message);
  }

  function handleClose() {
    setForgotPasswordEmail("");

    onClose();
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Reset Password</DialogTitle>
      <Form onSubmit={handleResetPassword}>
        <DialogContent>
          <Box>
            <Typography variant="body1" gutterBottom>
              Enter your email address and we&apos;ll send you a link to reset
              your password through your MyPostcardmania account. This new
              password will work with your PCM Ingegrations account as well.
            </Typography>
            <Box mt={2}>
              <TextField
                size="small"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                label="Email Address"
                fullWidth
              />
              <Box mt={2}>
                {forgotPasswordState === "submitting" &&
                  "Sending your request..."}
                {forgotPasswordState === "error" && (
                  <Typography variant="body1" color="red">
                    There was an error with your request, please try again.{" "}
                    {forgotPasswordErrorMessage}
                  </Typography>
                )}
                {forgotPasswordState === "success" && (
                  <Typography variant="body1">
                    Request successful! Please check your email for password
                    reset instructions.
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            disabled={forgotPasswordState === "submitting"}
            color="primary"
            variant="contained"
          >
            Send Request
          </Button>
          <Button
            type="button"
            onClick={handleClose}
            color="primary"
            disabled={forgotPasswordState === "submitting"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
