import { css } from "@emotion/react";
import { useMemo } from "react";
import tw from "twin.macro";
interface IToolButtonGroupProps {
  children?: React.ReactChild[] | React.ReactChild;
  height?: number;
}

const styles = {
  container: (height?: number) => [
    tw`flex items-center`,
    height !== undefined && css(`height: calc(${height}rem / 4)`),
  ],
  button: (height: boolean) => [
    tw`mr-0.5 last:mr-0`,
    height && tw`h-full flex flex-col justify-center`,
  ],
};

const RoundedInputGroup = ({ children, height = 9 }: IToolButtonGroupProps) => {
  const wrappedChildren = useMemo(wrapChildren, [children]);

  function wrapChildren() {
    if (!children) return undefined;
    if (Array.isArray(children)) {
      return children.map((child, index) => {
        return (
          <div key={index} css={styles.button(height !== undefined)}>
            {child}
          </div>
        );
      });
    } else {
      return <div css={styles.button(height !== undefined)}>{children}</div>;
    }
  }
  return <div css={styles.container(height)}>{wrappedChildren}</div>;
};

export default RoundedInputGroup;
