import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";

import DesignModel from "../models/DesignModel";

import { Skeleton } from "@mui/lab";
import SearchableDataTable from "../../../shared/SearchableDataTable";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import "./AdminDesign.scss";
import { fetchClient } from "../../../helpers/fetchClient";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import IDesignCategory from "../../Designs/models/IDesignCategory";
import { getAsync, postAsync } from "../../../helpers/asyncFetch";
import IDesignSubcategory from "../../Designs/models/IDesignSubcategory";
import { TableColumn } from "react-data-table-component";
import IActiveCategory from "../models/IActiveCategory";
import Design from "../../Designs/models/Design";
import Img from "../../../shared/BasicHTML/Img";
import { useReassign } from "../components/ReassignPanel";

/**
 * Single use interfaces
 */

interface HandleCopyResponse {
  designID: number;
}

const AdminDesigns: React.FC = () => {
  /**
   * State Objects and Refs
   */
  const { customerId } = useParams<any>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [designs, setDesigns] = useState<DesignModel[]>([]);
  const reassign = useReassign();
  const [activeCategory, setActiveCategory] = useState<IActiveCategory>({});
  const { data: designCategories, error } = useGetData<IDesignCategory[]>(
    `/api/design-category`,
    []
  );
  const [copyDesignerDesignOpen, setCopyDesignerDesignOpen] = useState(false);
  const [copyDesign, setCopyDesign] = useState({
    name: "",
    size: "",
    duplicateID: -1,
    bAccountID: customerId,
  });

  function handleCategoryChange(e: SelectChangeEvent) {
    if (e.target.value && designCategories) {
      const categoryID = parseInt(e.target.value);
      const category = designCategories.find(
        (x) => x.categoryID === categoryID
      );
      setActiveCategory({ category });
    } else {
      setActiveCategory({});
    }
  }

  function handleSubcategoryChange(e: SelectChangeEvent) {
    if (e.target.value && designCategories && activeCategory.category) {
      const subcategoryID = parseInt(e.target.value);
      const subcategory = activeCategory.category.subcategories.find(
        (x) => x.subcategoryID === subcategoryID
      );
      setActiveCategory({ ...activeCategory, subcategory });
    } else {
      setActiveCategory({ category: activeCategory.category });
    }
  }

  const columns: TableColumn<DesignModel>[] = [
    {
      name: "Design Proof",
      width: "300px",
      cell: (row: DesignModel) => (
        <div>
          <Img
            src={row.front}
            width={268}
            className="design__image img-fluid"
          />
        </div>
      ),
    },
    {
      name: "Design ID",
      selector: (design) => design.designID,
      sortable: true,
    },
    {
      name: "Design Name",
      selector: (design) => design.name,
      sortable: true,
    },
    {
      name: "Size",
      selector: (design) => design.size,
      sortable: true,
    },
    {
      name: "Approval Date",
      selector: (cell: DesignModel) => cell.approvalDate,
      sortable: true,
      format: (row: DesignModel) =>
        row.approvalDate
          ? moment(row.approvalDate).format("MM/DD/YYYY")
          : "Not Approved",
    },
  ];
  if (customerId === "0") {
    columns.push({
      name: "Category",
      selector: (design: DesignModel) =>
        design.designCategory ? design.designCategory.categoryName : "",
      sortable: true,
    });
    columns.push({
      name: "Subcategory",
      selector: (design) =>
        design.designSubcategory
          ? design.designSubcategory.subcategoryName
          : "",
      sortable: true,
    });
  }
  columns.push({
    name: "",
    cell: (row: DesignModel) => (
      <div>
        {!row.customerModified && (
          <div className="design__actions">
            <Link
              to={
                row.isDesigner
                  ? `/admin/designer/${row.designID}`
                  : `/admin/integrations/${customerId}/designs/${row.designID}`
              }
            >
              Edit Design
            </Link>

            <Link
              to={`/admin/integrations/${customerId}/designs/${row.designID}/proof`}
            >
              Generate Proof
            </Link>
            <a
              role="button"
              href="javascript:void(0)"
              onClick={(e: React.MouseEvent) => handleCopy(e, row.designID)}
            >
              Copy Design
            </a>
            <a
              role="button"
              href="javascript:void(0)"
              onClick={() =>
                reassign.openPanel(
                  row.designID,
                  customerId ? parseInt(customerId) : undefined
                )
              }
            >
              Reassign Design
            </a>
          </div>
        )}
        {row.customerModified && (
          <div className="design__actions">
            <span>
              This design has been modified by the customer. Please log in to
              their account to view/edit it.
            </span>
          </div>
        )}
      </div>
    ),
  });

  function filterDesigns() {
    if (!activeCategory.category) return designs;
    return designs.filter((x) => {
      if (activeCategory.subcategory) {
        return (
          x.designSubcategory?.subcategoryID ===
            activeCategory.subcategory.subcategoryID &&
          x.designCategory?.categoryID === activeCategory.subcategory.categoryID
        );
      }
      return (
        x.designCategory?.categoryID === activeCategory.category?.categoryID
      );
    });
  }

  /**
   * Component Methods
   */
  function getDesigns() {
    fetchClient.get({
      path: `/admin/integrations/${customerId}/designs`,
      onSuccess: getDesignsCallback,
    });
  }

  function getDesignsCallback(data: DesignModel[]) {
    setDesigns(data);
    setLoading(false);
  }

  function handleCopy(e: React.MouseEvent, designID: number) {
    e.preventDefault();
    const design = designs.find((x) => x.designID === designID);
    if (design && design.isDesigner) {
      setCopyDesignerDesignOpen(true);
      setCopyDesign({
        name: `${design.name} - Copy`,
        size: design.size,
        duplicateID: designID,
        bAccountID: customerId,
      });
    } else {
      fetchClient.post({
        path: `/admin/integrations/${customerId}/designs/${designID}/clone`,
        onSuccess: handleCopyCallback,
      });
    }
  }
  function handleCopyCallback(data: HandleCopyResponse) {
    history.push(`/admin/integrations/${customerId}/designs/${data.designID}`);
  }

  async function handleCopyDesignerDesign() {
    const res = await postAsync<{ design: Design }>(`/designer`, {
      ...copyDesign,
      isTemplate: false,
      category: null,
      subcategory: null,
    });
    if (res) {
      history.push(`/admin/designer/${res.design.designID}`);
    }
    setCopyDesignerDesignOpen(false);
  }

  /**
   * Component Effects
   */
  useEffect(getDesigns, [customerId]);
  useEffect(() => {
    if (reassign.networkState.status === "success") {
      getDesigns();
    }
  }, [reassign.networkState.status]);
  /**
   * Render
   */

  const customHeader = (
    <Box>
      <Grid container spacing={3} alignItems="center">
        {customerId === "0" && (
          <React.Fragment>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Filter Categories</InputLabel>
                <Select
                  fullWidth
                  label="Filter Categories"
                  value={activeCategory.category?.categoryID.toString() ?? ""}
                  onChange={handleCategoryChange}
                >
                  <MenuItem value="">No Filter</MenuItem>
                  {designCategories?.map((category) => (
                    <MenuItem
                      key={category.categoryID}
                      value={category.categoryID}
                    >
                      {category.categoryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel>Filter Subcategories</InputLabel>
                <Select
                  fullWidth
                  label="Filter Subcategories"
                  disabled={!activeCategory.category}
                  value={
                    activeCategory.subcategory?.subcategoryID.toString() ?? ""
                  }
                  onChange={handleSubcategoryChange}
                >
                  <MenuItem value="">No Filter</MenuItem>
                  {activeCategory.category?.subcategories?.map((category) => (
                    <MenuItem
                      key={category.subcategoryID}
                      value={category.subcategoryID}
                    >
                      {category.subcategoryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} md={"auto"}>
          <Button
            to={`/admin/integrations/${customerId}/designs/new`}
            component={Link}
            LinkComponent={Link}
            color="primary"
            variant="contained"
          >
            Add New Design
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div id="adminDesignsPage" className="page">
      <div className="pb-4 pt-4">
        <div className="breadcrumbs">
          <Link to="/admin">
            <i className="fas fa-chevron-left"></i> Back To Admin
          </Link>
        </div>
        <div className="page__header">
          <h3 className="page__header__heading">
            {customerId === "0" ? "Templates" : "Designs"}
          </h3>
        </div>
        <div className="page__content">
          <div className="section" id="integrations">
            {loading && (
              <Skeleton
                variant="rectangular"
                style={{ width: "100%", height: "300px" }}
              />
            )}

            <SearchableDataTable
              tableTitle=""
              customHeaderComponent={customHeader}
              searchableColumns={[
                "designID",
                "name",
                "designCategory.categoryName",
                "designSubcategory.subcategoryName",
              ]}
              columns={columns}
              data={filterDesigns()}
            />
          </div>
        </div>
      </div>
      <Dialog open={copyDesignerDesignOpen}>
        <DialogTitle>Duplicate Design</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="name"
              value={copyDesign.name}
              onChange={(e) =>
                setCopyDesign({ ...copyDesign, name: e.target.value })
              }
              label="Design Name"
              fullWidth
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Design Size</InputLabel>
              <Select
                name="size"
                value={copyDesign.size}
                onChange={(e) =>
                  setCopyDesign({ ...copyDesign, size: e.target.value })
                }
                label="Design Size"
              >
                <MenuItem value="46">4.25&quot; x 6&quot;</MenuItem>
                <MenuItem value="68">6&quot; x 8.5&quot;</MenuItem>
                <MenuItem value="611">6&quot; x 11&quot;</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={copyDesign.category}
                onChange={handleNewDesignChange}
                label="Category"
              >
                <MenuItem value="">None</MenuItem>
                {designCategories?.map((category) => (
                  <MenuItem
                    key={category.categoryID}
                    value={category.categoryID.toString()}
                  >
                    {category.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {Boolean(copyDesign.category) && (
            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Subcategory</InputLabel>
                <Select
                  name="subcategory"
                  value={copyDesign.subcategory}
                  onChange={handleNewDesignChange}
                  label="Subcategory"
                >
                  <MenuItem value="">None</MenuItem>
                  {designCategories
                    ?.find(
                      (x) => x.categoryID.toString() === copyDesign.category
                    )
                    ?.subcategories.map((category) => (
                      <MenuItem
                        key={category.subcategoryID}
                        value={category.subcategoryID.toString()}
                      >
                        {category.subcategoryName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCopyDesignerDesign}
            disabled={!copyDesign.name || !copyDesign.size}
          >
            Duplicate Design
          </Button>
          <Button
            color="primary"
            onClick={() => setCopyDesignerDesignOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminDesigns;
