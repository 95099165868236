import { Button, Card, Container } from "@mui/material";
import React from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { fetchClient } from "../../../helpers/fetchClient";
import LocalStorage from "../../../helpers/LocalStorage";
import { Order } from "../../Batches/models/Batch";
import "./OrderSuccess.scss";

const OrderSuccess: React.FC = () => {
  /**
   * State Objects and Refs
   */
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const confirmation = LocalStorage.getOrderDetails();
  const order =
    confirmation && confirmation.successfulOrders.length
      ? confirmation.successfulOrders[0]
      : null;
  LocalStorage.clearOrderDetails();
  /**
   * Component Methods
   */

  /**
   * Component Effects
   */

  /**
   * Render
   */
  if (order) {
    return (
      <Container className="orderSuccess">
        <h2>Success! We&apos;ve received your order.</h2>
        <h4>Check out the details of your order below.</h4>
        <Button
          color="primary"
          variant="contained"
          to={`/batches/${confirmation.batchID}`}
          size="large"
          sx={{ display: "inline-block", mb: 3 }}
          component={Link}
          LinkComponent={Link}
        >
          View Batch
        </Button>

        <h5 className="orderSuccess__cardHeader">Order ID: {order.orderID}</h5>
        <div className="orderSuccess__cardContent">
          <ul>
            <li className="orderSuccess__lineItem">
              <span>External Reference Number:</span> {order.extRefNbr}
            </li>
            <li className="orderSuccess__lineItem">
              <span>Batch ID:</span> {confirmation.batchID}
            </li>
            <li className="orderSuccess__lineItem">
              <span>Recipients:</span> {order.successfulRecipientCount}
            </li>
            <li className="orderSuccess__lineItem">
              <span>Failed Recipients:</span> {order.failedRecipientCount}
            </li>
          </ul>
        </div>

        {order.failedRecipientCount > 0 && (
          <div className="orderSuccess__failedRecipients">
            <h6>Failed Recipients</h6>
            <p>The following recipients from your list failed to process:</p>

            <ul className="orderSuccess__failedRecipients__list">
              {order.failedRecipientList.map((recipient) => (
                <Card
                  component="li"
                  key={recipient.recipientIndex}
                  className="orderSuccess__failedRecipients__list__item"
                >
                  <div className="failedRecipient">
                    <header className="failedRecipient__header">
                      Recipient at row {recipient.recipientIndex + 1} in your
                      csv
                    </header>
                    <div className="failedRecipient__body">
                      {recipient.extRefNbr ? (
                        <span>
                          <span>External Reference:</span> {recipient.extRefNbr}
                        </span>
                      ) : (
                        ""
                      )}

                      {recipient.missingFields && (
                        <div className="failedRecipient__missingFields">
                          <span>Missing Fields</span>
                          <ul>
                            {recipient.missingFields.map((field) => (
                              <li key={field}>{field}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              ))}
            </ul>
          </div>
        )}
      </Container>
    );
  }

  function getBatchPage() {
    fetchClient.get({
      path: `/orders/${orderId}/details`,
      onSuccess: onBatchPageResponse,
      onError: onBatchPageError,
    });
  }

  function onBatchPageResponse(res: Order) {
    history.push(`/batches/${res.batchID}`);
  }

  function onBatchPageError() {
    history.push("/");
  }

  getBatchPage();

  return <div />;
};

export default OrderSuccess;
