import { IText } from "fabric/fabric-impl";
import { useContext, useEffect, useRef } from "react";
import { CanvasContext } from "../state/contexts/CanvasContext";

import useLayers from "./useLayers";

/**
 *
 * @todo Rework this logic into our keyboard event listener hook.
 */
const useDelete = () => {
  const canvas = useContext(CanvasContext);
  const initialized = useRef(false);
  const { removeSelectedObjectLayers, layers } = useLayers();

  function handleKeyboardDelete(e: KeyboardEvent) {
    if (!canvas || !canvas._activeObject) return;

    if (
      e.target &&
      !["body", "canvas"].includes(
        (e.target as HTMLElement).tagName?.toLowerCase()
      )
    )
      return;
    if (
      canvas._activeObject.type?.includes("text") &&
      (canvas._activeObject as IText).isEditing
    )
      return;

    if (e.key === "Delete") {
      deleteObject();
    }
  }

  function deleteObject() {
    if (canvas) {
      removeSelectedObjectLayers();
    }
  }

  useEffect(() => {
    if (!canvas) return;
    if (initialized.current) {
      window.removeEventListener("keydown", handleKeyboardDelete);
    }
    window.addEventListener("keydown", handleKeyboardDelete);
    initialized.current = true;
    return () => window.removeEventListener("keydown", handleKeyboardDelete);
  }, [canvas, layers]);

  return { deleteObject };
};

export default useDelete;
