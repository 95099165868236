import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { getObjectTruthyValues } from "../../../../helpers/getObjectTruthyValues";
import {
  creditCardValidation,
  NewCardValidationErrors,
} from "../../../../shared/validationHelpers/creditCardValidation";
import { INewPaymentMethod, IPaymentMethod } from "../../models/AccountModel";
import valid from "card-validator";
import { fetchClient } from "../../../../helpers/fetchClient";
import GridRow from "../../../../shared/MaterialWrappers/GridRow";
import GridItem from "../../../../shared/MaterialWrappers/GridItem";
import NumberFormat from "react-number-format";
import SmallTextField from "../../../../shared/MaterialWrappers/SmallTextField";
import { cardNumber } from "card-validator/dist/card-number";
import Form from "../../../../shared/BasicHTML/Form";
import LoadingButton from "../../../../shared/LoadingButton";
import CreditCardDataTable from "./CreditCardDataTable";

interface PaymentMethodsProps {
  cards: IPaymentMethod[];
}

const newPaymentMethodObject: INewPaymentMethod = {
  firstName: "",
  lastName: "",
  cardNumber: "",
  expiration: "",
  cvc: "",
};

const PaymentMethods: FC<PaymentMethodsProps> = ({ cards }) => {
  /**
   * State Objects and Refs
   */
  const [paymentMethods, setPaymentMethods] = useState(cards);
  const [newPaymentMethod, setNewPaymentMethod] = useState(
    newPaymentMethodObject
  );
  const [cardValidationErrors, setCardValidationErrors] =
    useState<NewCardValidationErrors>({});
  const [showValidation, setShowValidation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [newCardError, setNewCardError] = useState(false);
  const [primaryError, setPrimaryError] = useState(false);
  const [primarySuccess, setPrimarySuccess] = useState(false);
  const [addNewIsOpen, setAddNewIsOpen] = useState(false);

  /**
   * Component Methods
   */

  function validateCard() {
    setCardValidationErrors(creditCardValidation(newPaymentMethod));
  }

  function submitNewCard(e: React.FormEvent) {
    e.preventDefault();
    if (getObjectTruthyValues(cardValidationErrors).length !== 0) {
      setShowValidation(true);
      return;
    }
    const cardNumber = newPaymentMethod.cardNumber.replace(/\D/g, "");
    const expirationMonth = valid.expirationDate(
      newPaymentMethod.expiration
    ).month;
    const expirationYear = valid.expirationDate(
      newPaymentMethod.expiration
    ).year;
    setIsSubmitting(true);
    fetchClient.post({
      path: "/payments",
      onSuccess: onSubmitSuccess,
      onError: onSubmitError,
      data: { cardNumber, expirationMonth, expirationYear },
    });
  }

  function onSubmitSuccess(res: IPaymentMethod) {
    setPaymentMethods(addNewPaymentMethod(res));
    setIsSubmitting(false);
    setNewCardError(false);
    setAddNewIsOpen(false);
    setShowValidation(false);
    setNewPaymentMethod(newPaymentMethodObject);
  }

  function addNewPaymentMethod(card: IPaymentMethod): IPaymentMethod[] {
    const primary = paymentMethods.findIndex((x) => x.isPrimary);
    if (primary === -1) return [card, ...paymentMethods];
    const newPaymentMethods = [...paymentMethods];
    newPaymentMethods.splice(1, 0, card);
    return newPaymentMethods;
  }

  function onSubmitError() {
    setNewCardError(true);
    setIsSubmitting(false);
  }

  function closeDialog() {
    setNewCardError(false);
    setNewPaymentMethod(newPaymentMethodObject);
    setShowValidation(false);
    setAddNewIsOpen(false);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setNewPaymentMethod({
      ...newPaymentMethod,
      [e.target.name]: e.target.value,
    });
  }

  function setCardAsPrimary(card: IPaymentMethod) {
    setPrimaryError(false);
    setIsSubmitting(true);
    fetchClient.post({
      path: "/payments/set-default",
      onSuccess: () => onSetCardAsPrimarySuccess(card),
      onError: onSetCardAsPrimaryError,
      data: `${card.id}`,
    });
  }

  function onSetCardAsPrimarySuccess(card: IPaymentMethod) {
    const index = paymentMethods.findIndex((x) => x.id === card.id);
    const currentPrimary = paymentMethods.findIndex((x) => x.isPrimary);

    if (currentPrimary === -1) return;
    if (index !== -1 && currentPrimary !== -1) {
      const newPaymentMethods = [...paymentMethods];
      newPaymentMethods[index] = { ...card, isPrimary: true };
      newPaymentMethods[currentPrimary] = {
        ...newPaymentMethods[currentPrimary],
        isPrimary: false,
      };

      setPaymentMethods(newPaymentMethods.sort((x) => (x.isPrimary ? -1 : 1)));
    }
    setIsSubmitting(false);
    setPrimarySuccess(true);
    setTimeout(() => setPrimarySuccess(false), 1);
  }

  function onSetCardAsPrimaryError() {
    setIsSubmitting(false);
    setPrimaryError(false);
  }

  /**
   * Component Effects
   */
  useEffect(() => {
    validateCard();
  }, [newPaymentMethod]);

  /**
   * Render
   */

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ mb: 2 }}>
          <CreditCardDataTable
            onDefaultChange={setCardAsPrimary}
            cards={paymentMethods}
            isSubmitting={isSubmitting}
            isSuccess={primarySuccess}
            hasError={primaryError}
          />
        </Box>
        <Button
          color="primary"
          onClick={() => setAddNewIsOpen(true)}
          variant="contained"
        >
          Add New Card
        </Button>
      </Box>
      <Dialog open={addNewIsOpen}>
        <DialogTitle>New Credit Card</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <Form onSubmit={submitNewCard}>
              <GridRow>
                <GridItem xs={12}>
                  <TextField
                    required
                    size="small"
                    value={newPaymentMethod.firstName}
                    name="firstName"
                    onChange={handleChange}
                    fullWidth
                    label="First Name"
                    disabled={isSubmitting}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    required
                    size="small"
                    value={newPaymentMethod.lastName}
                    name="lastName"
                    onChange={handleChange}
                    fullWidth
                    label="Last Name"
                    disabled={isSubmitting}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <NumberFormat
                    customInput={SmallTextField}
                    required
                    value={newPaymentMethod.cardNumber}
                    onChange={handleChange}
                    fullWidth
                    label="Card Card Number"
                    type="tel"
                    mask="_"
                    name="cardNumber"
                    format={
                      newPaymentMethod.cardNumber.startsWith("34") ||
                      newPaymentMethod.cardNumber.startsWith("37")
                        ? "#### - ###### - #####"
                        : "#### - #### - #### - ####"
                    }
                    disabled={isSubmitting}
                    error={
                      showValidation && Boolean(cardValidationErrors.cardNumber)
                    }
                  />
                  {showValidation &&
                    Boolean(cardValidationErrors.cardNumber) && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {cardValidationErrors.cardNumber}
                      </Typography>
                    )}
                </GridItem>
                <GridItem xs={6}>
                  <NumberFormat
                    customInput={SmallTextField}
                    required
                    value={newPaymentMethod.expiration}
                    onChange={handleChange}
                    name="expiration"
                    type="tel"
                    format="##/##"
                    label="Exp. (MM/YY)"
                    fullWidth
                    disabled={isSubmitting}
                    error={
                      showValidation && Boolean(cardValidationErrors.expiration)
                    }
                  />
                  {showValidation &&
                    Boolean(cardValidationErrors.expiration) && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {cardValidationErrors.expiration}
                      </Typography>
                    )}
                </GridItem>
                <GridItem xs={6}>
                  <NumberFormat
                    customInput={SmallTextField}
                    value={newPaymentMethod.cvc}
                    type="tel"
                    format={
                      newPaymentMethod.cardNumber.startsWith("34") ||
                      newPaymentMethod.cardNumber.startsWith("37")
                        ? "####"
                        : "###"
                    }
                    label="CVC"
                    name="cvc"
                    onChange={handleChange}
                    fullWidth
                    disabled={isSubmitting}
                    error={showValidation && Boolean(cardValidationErrors.cvc)}
                  />
                  {showValidation && Boolean(cardValidationErrors.cvc) && (
                    <Typography variant="caption" sx={{ color: "red" }}>
                      {cardValidationErrors.cvc}
                    </Typography>
                  )}
                </GridItem>
                <GridItem xs="auto">
                  <LoadingButton
                    loading={isSubmitting}
                    success={false}
                    type="submit"
                  >
                    Add New Card
                  </LoadingButton>
                </GridItem>
                <GridItem xs="auto">
                  <Button
                    color="primary"
                    type="button"
                    onClick={closeDialog}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </GridItem>
                <GridItem xs={12}>
                  {newCardError && (
                    <Typography variant="caption" sx={{ color: "red" }}>
                      There was an error adding your new payment method, please
                      ensure all of the information provided is correct
                    </Typography>
                  )}
                </GridItem>
              </GridRow>
            </Form>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PaymentMethods;
