import React, {useState, useEffect} from 'react';


interface UseRequestCleanupReturn {
  addRequest: (controller: AbortController) => void,
  abortAllRequests: () => void
}

interface UseRequestCleanup {
  /**
   * Creates a self contained array of abort controllers in order to cancel any pending requests when the component unmounts
   */
  (): UseRequestCleanupReturn
}


const useRequestCleanup = (): UseRequestCleanupReturn => {
  const [controllers, setControllers] = useState<AbortController[]>([]);

  function addController(controller: AbortController) {
    setControllers([...controllers, controller]);
  }

  function abortAllRequests() {
    controllers.forEach(controller => controller.abort());
  }

  return {
    addRequest: addController,
    abortAllRequests
  }

}

export default useRequestCleanup;