import { Image, IText, Object as IObject, Shadow } from "fabric/fabric-impl";
import { createContext, useContext, useEffect, useState } from "react";
import { Tool } from "../state/models/ICanvasTool";
import IToolSettings, {
  FontStyle,
  IShadowSettings,
  Shape,
  TextAlign,
} from "../state/models/IToolSettings";
import {
  setCurrentTool,
  setToolSettings,
  toggleGuides as toggleGuidesState,
} from "../state/slices/toolSettings";
import { useDesignerDispatch, useDesignerSelector } from "../state/store";
import isShape from "../features/Canvas/functions/isShape";
import dynamicImagePlaceholder from "../features/Canvas/svg/dynamicImagePlaceholder";

import { IBulletedList, Textbox, fabric } from "fabric";
import { CanvasContext } from "../state/contexts/CanvasContext";
import generateGuid from "../../helpers/generateGuid";
import ISvgShape from "../state/models/ISvgShape";
import useSaveData from "./useSaveData";
import { SelectedPageContext } from "../state/contexts/SelectedPageContext";
import { AlignGuidelines } from "fabric-guideline-plugin";
import { GuidesContext } from "../state/contexts/GuideContext";
import { createBleedClipPath } from "../features/Canvas/functions/createBackgroundClipPath";
import useLayers from "./useLayers";
import truncateString from "../features/Canvas/functions/truncateString";
import IListText from "../models/types/IListText";
import RESOLUTION from "../constants/RESOLUTION";
import textResizer from "../features/Canvas/functions/textResizer";

/**
 *  toolSettings,
    availableShapes,
    availableFonts,
    updateToolSetting,
    currentTool,
    changeCurrentTool,
    selectedObject,
    addVariableImageToCanvas,
 */

interface IToolsCollection {
  toolSettings: IToolSettings;
  availableShapes: unknown;

  updateToolSetting: UpdateToolSetting;

  currentTool: Tool;
  changeCurrentTool: (tool: Tool) => void;
}

export type UpdateToolSetting = (
  property: string,
  value: string | number | ISvgShape | undefined | IShadowSettings | boolean
) => void;

const useTools = () => {
  const canvas = useContext(CanvasContext);
  const guides = useContext(GuidesContext);
  const { createLayer } = useLayers();
  const { toolSettings, availableShapes } = useDesignerSelector((x) => ({
    toolSettings: x.toolSettings,
    availableShapes: x.availableShapes,
  }));

  const { lastSave } = useSaveData();
  const currentTool = toolSettings.currentTool;
  const dispatch = useDesignerDispatch();

  const selectedPage = useContext(SelectedPageContext);
  const [hasOverlay, setHasOverlay] = useState(false);

  function updateToolSetting(
    property: string,
    value: IToolSettings[keyof IToolSettings]
  ) {
    if (
      property === "strokeWidth" ||
      property === "fontSize" ||
      (property.includes("shadow") && !property.includes("color")) ||
      (property === "fontWeight" && value !== "bold")
    ) {
      value = parseInt(value as string);
      if (isNaN(value)) return;
    }
    if (property.includes("shadow")) {
      const shadowSetting = property.replace(
        "shadow.",
        ""
      ) as keyof IShadowSettings;

      dispatch(
        setToolSettings({
          ...toolSettings,
          shadow: { ...toolSettings.shadow, [shadowSetting]: value },
        })
      );
    } else {
      dispatch(
        setToolSettings({ ...toolSettings, [property as keyof IObject]: value })
      );
    }
  }

  function getOverlayForPage() {
    // if (!canvas) return;
    // if (selectedPage === "Front") {
    //   if (hasOverlay) {
    //     applyOverlay("/assets/img/designer-overlays/4.25x6-front.png");
    //   } else {
    //     canvas.overlayImage = undefined;
    //   }
    // } else {
    //   if (hasOverlay) {
    //     applyOverlay("/assets/img/designer-overlays/4.25x6-back.png");
    //   } else {
    //     applyOverlay("/assets/img/designer-overlays/4.25x6-back-off.png");
    //   }
    // }
    // canvas.renderAll();
  }

  function addBulletedListToLayers(listObj: IBulletedList) {
    if (!listObj.name) {
      listObj.name = `bulletedList-${generateGuid()}`;
    }
    createLayer(listObj.name, "New Bulleted List");
    canvas?.setActiveObject(listObj);
    canvas?.renderAll();
    dispatch(setCurrentTool(Tool.select));
  }

  function addTextToCanvas(textObj: Textbox) {
    if (!canvas) return;
    const background = canvas._objects.find((x) => x.name === "background");
    if (
      !background ||
      background.left === undefined ||
      background.left === null ||
      background.top === undefined ||
      background.top === null
    )
      return;

    canvas.add(textObj);

    canvas.renderAll();
    textResizer(textObj);
    createLayer(textObj.name ?? "");

    textObj.width =
      textObj.__lineWidths.reduce((total, width) => {
        total += width;
        return total;
      }, 0) +
      (textObj.fontSize ?? 25) * 5;

    canvas.renderAll();

    canvas.setActiveObject(textObj);

    setTimeout(() => {
      textObj.enterEditing();
      textObj.selectAll(); // @ts-ignore

      canvas.renderAll();
    }, 0);
  }

  function applyOverlay(url: string) {
    if (!canvas) return;
    // const background = canvas._objects.find((x) => x.name === "background");
    // fabric.Image.fromURL(url, (img) => {
    //   if (!background) return;
    //   img.left = background.left;
    //   img.top = background.top;
    //   img.scaleToWidth(background.width ?? 0);
    //   canvas.overlayImage = img;
    //   canvas.renderAll();
    // });
  }

  function toggleGuides() {
    if (!canvas || !guides) return;
    dispatch(toggleGuidesState());
  }

  function toggleOverlay() {}

  function changeCurrentTool(tool: Tool) {
    if (tool !== Tool.select && canvas?.getActiveObject()) {
      canvas?.discardActiveObject();
      canvas?.renderAll();
    }
    dispatch(setCurrentTool(tool));
  }

  useEffect(getOverlayForPage, [selectedPage]);

  useEffect(() => {
    if (canvas) {
      if (!hasOverlay) {
        toggleOverlay();
      }
      if (guides) {
        toggleGuides();
      }
    }
  }, [canvas, guides]);

  return {
    toolSettings,
    availableShapes,
    updateToolSetting,
    currentTool,
    changeCurrentTool,

    addTextToCanvas,
    addBulletedListToLayers: addBulletedListToLayers,
    lastSave,
    toggleOverlay,
    toggleGuides,
  };
};

export const ToolbarContext = createContext({} as IToolsCollection);

export default useTools;
