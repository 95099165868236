import { ConstructionOutlined } from "@mui/icons-material";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { putAsync } from "../../helpers/asyncFetch";
import DATALESS_PROPERTIES from "../constants/DATALESS_PROPERTIES";
import { CanvasContext } from "../state/contexts/CanvasContext";
import { DesignVariablesContext } from "../state/contexts/DesignVariablesContext";
import {
  CanvasObject,
  PagesContext,
  PagesDispatchContext,
} from "../state/contexts/PagesContext";
import {
  ISaveData,
  SaveDataContext,
  SaveDataDispatchContext,
} from "../state/contexts/SaveDataContext";
import {
  SelectedPageContext,
  SelectedPageDispatchContext,
} from "../state/contexts/SelectedPageContext";
import { loadLayers } from "../state/slices/layers";
import { useDesignerDispatch, useDesignerSelector } from "../state/store";
import { ActiveSelection, Group, IText, Image } from "fabric/fabric-impl";
import {
  getVarsFromString,
  sanitizeTextObjectVariables,
} from "../features/Canvas/functions/variableHelpers";
import { IBulletedList, Textbox, fabric } from "fabric";
import nonGoogleFontSafeList, {
  FontFamilyLabels,
} from "../features/Fonts/nonGoogleFontSafeList";

interface IDesignVariable {
  name: string;
  type: "text" | "image";
}

/**
 * @description A hook that returns functions which are helpful for keeping the saveData in sync.
 * @returns {saveCanvas: function, loadCanvas: function}
 */
const useSaveData = () => {
  const saveData = useContext(SaveDataContext);
  const updateSaveData = useContext(SaveDataDispatchContext);
  const pages = useContext(PagesContext);
  const selectedPage = useContext(SelectedPageContext);
  const canvas = useContext(CanvasContext);
  const [lastSave, setLastSave] = useState<Date>();
  const { layers, qrCodeID, letterSettings } = useDesignerSelector((state) => ({
    layers: state.layers,
    qrCodeID: state.qrCode.qrCodeId,
    letterSettings: state.letterSettings,
  }));
  const { designId } = useParams<{ designId: string }>();
  const variables = useContext(DesignVariablesContext);
  const qrCodeIDRef = useRef(qrCodeID);
  qrCodeIDRef.current = qrCodeID;

  const layersRef = useRef(layers);
  layersRef.current = layers;

  const pagesRef = useRef(pages);
  pagesRef.current = pages;

  const canvasRef = useRef(canvas);
  canvasRef.current = canvas;

  const variablesRef = useRef(variables);
  variablesRef.current = variables;

  /**
   * @description Returns the current canvas as described in the pages context and the layers state from redux
   * @returns {pages: [], layers: []}
   */
  function handleSaveDataUpdate() {
    if (!canvas) return "";

    const newSaveData: ISaveData = { pages: [], layers: [] };
    newSaveData.layers = layers.pageLayers;
    newSaveData.pages = pages;

    newSaveData.pages = pages.map((page) => {
      if (page.name === selectedPage) {
        const objects = canvas
          .getObjects()
          .map((x) => x.toDatalessObject(DATALESS_PROPERTIES));
        return { ...page, objects };
      } else {
        const objects = page.objects.map((obj) =>
          obj.toDatalessObject(DATALESS_PROPERTIES)
        );
        return { ...page, objects };
      }
    });
  }

  function saveCanvas(): string {
    if (saveData?.pages.find((x) => x.objects.length === 0)) {
      return "";
    }

    putAsync(`/designer/${designId}`, {
      json: JSON.stringify({
        ...saveData,
        envelopeType: letterSettings.envelopeType,
        foldType: letterSettings.foldType,
        margins: letterSettings.margins,
      }),
      variables: getVariables(),
      qrCodeID: qrCodeIDRef.current,
    });
    setLastSave(new Date());
    return JSON.stringify(saveData);
  }

  function getVariables() {
    const objs = pagesRef.current.reduce((objects, page) => {
      objects = [...objects, ...page.objects];
      return objects;
    }, [] as CanvasObject[]);
    const variables = objs.reduce((varCollection, obj) => {
      if (obj.type === "group") {
        const group = obj as Group;
        const image = group._objects.find((x) => x.name && x.type === "image");

        if (
          image &&
          image.name &&
          !varCollection.find((x) => x.name === image.name)
        ) {
          const varName = image.name.replaceAll(
            /^[^a-zA-Z_${}]|[^0-9a-zA-Z_${}]/g,
            ""
          );
          image.name = varName;

          varCollection.push({
            name: varName.replace("{{", "").replace("}}", ""),
            type: "image",
          });
        }
        if (obj.name?.includes("bulletedList")) {
          group._objects.forEach((li) => {
            if (li.type === "group") {
              const grp = li as Group;
              const text = grp._objects.find(
                (x) => x.type === "textbox"
              ) as Textbox;
              if (
                text &&
                text.text &&
                text.text.includes("{{") &&
                text.text.includes("}}")
              ) {
                const vars = sanitizeTextObjectVariables(text);
                vars.forEach((v) => {
                  const name = v.replace("{{", "").replace("}}", "");
                  if (!varCollection.find((x) => x.name === name)) {
                    varCollection.push({ name, type: "text" });
                  }
                });
              }
            }
          });
        }
      }
      if (obj.type?.includes("text")) {
        const text = obj as Textbox;

        if (text.text && text.text.includes("{{") && text.text.includes("}}")) {
          const vars = sanitizeTextObjectVariables(text);
          vars.forEach((v) => {
            const name = v.replace("{{", "").replace("}}", "");
            if (!varCollection.find((x) => x.name === name)) {
              varCollection.push({ name, type: "text" });
            }
          });
        }
      }
      return varCollection;
    }, [] as IDesignVariable[]);
    return variables;
  }

  function getVariablesBad() {
    if (!variables || variables.length === 0) return [];

    return variables.reduce((prev, curr) => {
      if (prev.find((x) => x.name === curr.key && x.type === curr.type))
        return prev;
      return [...prev, { name: curr.key, type: curr.type }];
    }, [] as { name: string; type: string }[]);
  }

  async function saveAsync() {
    const newSaveData = handleUpdate();
    // Swap webfont names to local install names on save - we will convert them back to webfont names on load.
    newSaveData?.pages.forEach((page) => {
      page.objects.forEach((obj) => {
        if (obj.type === "textbox") {
          const text = obj as Textbox;
          if (nonGoogleFontSafeList.includes(text.fontFamily ?? "")) {
            // @ts-ignore
            text.__fontFamily = text.fontFamily;
            text.fontFamily =
              FontFamilyLabels[
                text.fontFamily as keyof typeof FontFamilyLabels
              ];
          }
          if (text.styles && text.styles.length) {
            text.styles.forEach((style: any) => {
              if (
                style.style.fontFamily &&
                nonGoogleFontSafeList.includes(style.style.fontFamily)
              ) {
                style.style.__fontFamily = style.style.fontFamily;
                style.style.fontFamily =
                  FontFamilyLabels[
                    style.style.fontFamily as keyof typeof FontFamilyLabels
                  ];
              }
            });
          }
        }
        if (obj.type === "group" && obj.name?.includes("bulletedList")) {
          const bullets = obj as IBulletedList;

          if (
            bullets.__fontSettings.fontFamily &&
            nonGoogleFontSafeList.includes(bullets.__fontSettings.fontFamily)
          ) {
            // @ts-ignore
            bullets.objects.forEach((li: any) => {
              li.objects.forEach((x: any) => {
                if (x.type === "textbox") {
                  x.fontFamily =
                    FontFamilyLabels[
                      bullets.__fontSettings
                        .fontFamily as keyof typeof FontFamilyLabels
                    ];
                }
              });
            });
          }
        }
      });
    });

    await putAsync(`/designer/${designId}`, {
      json: JSON.stringify({
        ...newSaveData,
        envelopeType: letterSettings.envelopeType,
        margins: letterSettings.margins,
        foldType: letterSettings.foldType,
      }),
      variables: getVariables(),
      qrCodeID: qrCodeIDRef.current,
    });

    return JSON.stringify(newSaveData);
  }

  function handleUpdate() {
    if (!canvasRef.current) return;
    const selection = canvasRef.current.getActiveObject();
    if (selection) {
      canvasRef.current.discardActiveObject();
      canvasRef.current.renderAll();
    }

    const newSaveData: ISaveData = {
      pages: [],
      layers: [],
    };
    newSaveData.layers = layersRef.current.pageLayers;
    newSaveData.pages = pagesRef.current;

    newSaveData.pages = pagesRef.current.map((page) => {
      if (page.name === selectedPage) {
        // @ts-ignore
        const objects = canvasRef.current
          .getObjects()
          .filter((x) => x.name !== undefined)
          .map((x) => x.toDatalessObject(DATALESS_PROPERTIES));
        return { ...page, objects };
      } else {
        const objects = page.objects.map((obj) =>
          obj.toDatalessObject(DATALESS_PROPERTIES)
        );
        return { ...page, objects };
      }
    });
    updateSaveData({
      ...newSaveData,
    });

    return newSaveData;
  }

  /**
   * @description Takes ISaveData as data, then loads it into the canvas and layers state.
   * @param data The save data you wish to load
   */
  function loadSavedCanvas(data: ISaveData, cvs: fabric.Canvas) {}

  useEffect(() => {
    if (!saveData && layers?.pageLayers.length) {
      handleUpdate();
    }
  }, [layers]);

  return {
    saveCanvas,
    loadSavedCanvas,
    saveData,
    lastSave,
    saveAsync,
    handleUpdate,
    getVariables,
  };
};

export default useSaveData;
