import { Card, CardContent, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import LocalStorage from "../../helpers/LocalStorage";
import InfoBlock from "./components/InfoBlock";
import SignUpForm from "./components/SignUpForm";
import useQuery from "../../hooks/useQuery";
import LiveChatAndTagManager from "../../shared/LiveChatAndTagManager";

const SignUp = () => {
  /**
   * State Objects and Refs
   */

  const history = useHistory();

  /**
   * Component Methods
   */

  /**
   * Component Effects
   */
  useEffect(() => {
    const token = LocalStorage.getToken();
    if (token !== null && window.location) {
      history.push("/");
    }
  }, []);

  /**
   * Render
   */

  return (
    <Container id="sign-up-page" style={{ paddingTop: "64px" }}>
      <Card>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <div className="text-center">
                <img
                  src="/assets/img/logo.png"
                  alt="myPostcardmania Logo, click here to return home"
                  className="img-fluid"
                  style={{ width: "300px", marginBottom: "20px" }}
                />
              </div>
              <SignUpForm />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoBlock />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <LiveChatAndTagManager />
    </Container>
  );
};

export default SignUp;
