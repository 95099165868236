import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { fetchClient } from "../../../../helpers/fetchClient";
import DropzoneUploader from "../../../../shared/DropzoneUploader";
import { SizeInfo } from "../../../Designs/models/Design";
import UploadImageResponse from "../../../Designs/UploadADesign/models/UploadImageResponse";
import { DesignSize } from "../../models/DesignCreationModel";

interface NewDesignSizeModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  existingDesignSize?: SizeInfo;
}

const NewDesignSizeModal: React.FC<NewDesignSizeModalProps> = ({
  open,
  onClose,
  onSubmit,
  existingDesignSize,
}) => {
  /**
   * State Objects and Refs
   */
  const [designSize, setDesignSize] = useState<DesignSize>(
    existingDesignSize
      ? existingDesignSize
      : {
          sizeInfoSize: "",
          inventoryCD: "",
          sizeLabel: "",
          guidelinesPDF: "",
          productType: "Postcard",
        }
  );
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [pdfFile, setPdfFile] = useState<File>();
  const uploaderRef = useRef<HTMLInputElement>(null);
  /**
   * Component Methods
   */
  function handleUpload(files: File[]) {
    setIsUploading(true);
    setPdfFile(files[0]);
    const request = fetchClient.uploadFile({
      onSuccess: handleUploadSuccess,
      fileType: "CUSTOM_POSTCARD",
      files,
      onError: handleUploadError,
    });
  }
  function handleUploadSuccess(res: UploadImageResponse) {
    const img = res.data[0];

    setDesignSize({ ...designSize, guidelinesPDF: img });
    setIsUploading(false);
  }
  function handleUploadError() {}
  function handleRejection(files: File[]) {}

  function handleSubmit() {
    setSubmitting(true);
    if (!existingDesignSize) {
      fetchClient.post({
        path: "/admin/integrations/design-sizes",
        onSuccess: () => {
          setDesignSize({
            sizeInfoSize: "",
            inventoryCD: "",
            sizeLabel: "",
            guidelinesPDF: "",
            productType: "Postcard",
          });
          onSubmit();
          onClose();
          setSubmitting(false);
        },
        data: designSize,
        onError: (e) => {
          setError(true);
          setSubmitting(false);
        },
      });
    } else {
      fetchClient.put({
        path: `/admin/integrations/design-sizes`,
        onSuccess: () => {
          onSubmit();
          onClose();
          setSubmitting(false);
        },
        data: designSize,
      });
    }
  }

  function handleChange(
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) {
    if (e.target.name === "width") {
      const numVal = Number.parseFloat(e.target.value) ?? undefined;
      setDesignSize({
        ...designSize,
        width: numVal,
      });
    } else if (e.target.name === "height") {
      const numVal = Number.parseFloat(e.target.value) ?? undefined;
      setDesignSize({
        ...designSize,
        height: numVal,
      });
    } else {
      setDesignSize({
        ...designSize,
        [e.target.name]: e.target.value,
      });
    }
  }

  /**
   * Component Effects
   */

  /**
   * Render
   */

  useEffect(() => {
    if (existingDesignSize) {
      setDesignSize(existingDesignSize);
    } else {
      setDesignSize({
        sizeInfoSize: "",
        inventoryCD: "",
        sizeLabel: "",
        guidelinesPDF: "",
        productType: "Postcard",
      });
    }
  }, [existingDesignSize]);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        {existingDesignSize ? "Edit Design Size" : "New Design Size"}
      </DialogTitle>
      <DialogContent>
        <TextField
          name="sizeInfoSize"
          value={designSize.sizeInfoSize}
          disabled={submitting || Boolean(existingDesignSize)}
          label="Size Code"
          variant="outlined"
          helperText="Example: 46"
          style={{ marginBottom: "10px" }}
          fullWidth
          onChange={handleChange}
        />
        <FormControl style={{ marginBottom: "10px" }} fullWidth>
          <FormLabel>Print Product</FormLabel>
          <Select
            name="productType"
            value={designSize.productType}
            disabled={submitting}
            label="Print Product"
            variant="outlined"
            onChange={handleChange}
          >
            <MenuItem value="Postcard">Postcard</MenuItem>
            <MenuItem value="Letter">Letter</MenuItem>
          </Select>
        </FormControl>

        <TextField
          name="sizeLabel"
          value={designSize.sizeLabel}
          disabled={submitting}
          label="Size Label"
          variant="outlined"
          style={{ marginBottom: "10px" }}
          helperText="Example: 4.25 x 6"
          fullWidth
          onChange={handleChange}
        />
        <TextField
          name="inventoryCD"
          value={designSize.inventoryCD}
          disabled={submitting}
          label="Inventory CD"
          variant="outlined"
          style={{ marginBottom: "10px" }}
          helperText="Use multiple CDs, separate with commas: A68S,A68F"
          fullWidth
          onChange={handleChange}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <NumberFormat
              customInput={TextField}
              value={designSize.height ? designSize.height.toString() : ""}
              label="Height (Exact inches)"
              name="height"
              onChange={handleChange}
              fullWidth
              helperText="Example: 6.5"
              disabled={submitting}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberFormat
              customInput={TextField}
              value={designSize.width ? designSize.width.toString() : ""}
              label="Width (Exact inches)"
              name="width"
              onChange={handleChange}
              fullWidth
              helperText="Example: 4.25"
              disabled={submitting}
            />
          </Grid>
        </Grid>
        <Box>
          <DropzoneUploader
            style={
              !designSize.guidelinesPDF
                ? { height: "200px" }
                : { display: "none" }
            }
            onUpload={handleUpload}
            onRejections={handleRejection}
            fileTypes={[".pdf"]}
            disabled={isUploading || submitting}
            additionalHelpText={
              "Upload the Design Guidelines PDF for this size"
            }
            ref={uploaderRef}
          />
        </Box>
        {Boolean(designSize.guidelinesPDF) && pdfFile && (
          <Box sx={{ mt: 2 }}>
            <Box>
              Successfully uploaded: <em>{pdfFile.name}</em>{" "}
              <Button onClick={() => uploaderRef.current?.click()}>
                Change File
              </Button>
            </Box>
          </Box>
        )}
        {Boolean(designSize.guidelinesPDF) && !pdfFile && (
          <Box sx={{ mt: 2 }}>
            <Box>
              <a
                href={`/uploads/${designSize.guidelinesPDF}`}
                target="_blank"
                rel="noreferrer"
              >
                View Current Guidelines File
              </a>{" "}
              <Button onClick={() => uploaderRef.current?.click()}>
                Change File
              </Button>
            </Box>
          </Box>
        )}
        {error && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>
              There was an error with your design size, it is possible the size
              already exists. Double check and try again.
            </FormHelperText>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} disabled={submitting}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewDesignSizeModal;
