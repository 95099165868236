import { IPathPoint } from "fabric";

/**
 * @description Check if the mouse is inside of a control point.
 * @param x - mouse x position
 * @param y - mouse y position
 * @param point - the point of the control to check
 * @param radius - the radius of the control
 * @returns
 */
export default function coordsIntersectPoint(
  x: number,
  y: number,
  point: IPathPoint,
  radius: number
) {
  const cX = point.x;
  const cY = point.y;
  const ltX = cX - radius;
  const ltY = cY - radius;
  const rbX = cX + radius;
  const rbY = cY + radius;
  return x >= ltX && y >= ltY && x <= rbX && y <= rbY;
}
