import { DesignField } from "../../Designs/models/Design";

export default function buildFields(vars: string[]): DesignField[] {
  const fields: DesignField[] = [];
  vars.forEach((v) => {
    if (!fields.find((x) => x.fieldKey === v)) {
      fields.push({
        designID: 0,
        listFieldKey: v,
        fieldKey: v,
        fieldLabel: v,
        fieldType: "string",
        mandatory: false,
        designFieldOptions: [],
      });
    }
  });
  return fields;
}
