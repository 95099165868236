import { Canvas } from "fabric/fabric-impl";
import { IPathPoint, fabric } from "fabric";
import { controlStroke, controlStrokeWidth } from "../constants";
import { getPathString } from "./getPath";

export default function drawIntersectionLines(
  canvas: Canvas,
  drawCommands: string[],
  disableEvents = false
) {
  const paths = drawCommands.map((command) => {
    const path = new fabric.Path(command, {
      selectable: false,
      name: "PathControl-intersection",
      stroke: controlStroke,
      strokeWidth: controlStrokeWidth,
      perPixelTargetFind: true,

      fill: "",
      objectCaching: false,
      opacity: 0.01,
      evented: !disableEvents,
    });
    // @ts-ignore
    path.findTolerance = 0;
    canvas.add(path);
    path.sendBackwards(true);
  });
}

export function getIntersectionLineDrawCommands(
  points: IPathPoint[],
  isCompleted = false
) {
  const pointGroups: IPathPoint[][] = [];
  points.forEach((point, index) => {
    const pointGroup = [{ ...point }];
    if (index !== points.length - 1) {
      pointGroup.push(points[index + 1]);
    } else if (isCompleted) {
      pointGroup.push(points[0]);
    }
    if (pointGroup.length > 1) {
      pointGroups.push(pointGroup);
    }
  });

  const paths = pointGroups.map((group) => {
    return getPathString(group, true);
  });
  return paths;
}
