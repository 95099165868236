import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { fetchClient } from "../../../helpers/fetchClient";
import LoadingButton from "../../../shared/LoadingButton";
import { ActiveItemDialogProps } from "../models/DialogProps";

interface DeleteWebhookConfirmationDialogProps extends ActiveItemDialogProps {
  onSuccessCallback: (webhookId: number) => void;
}

const DeleteWebhookConfirmationDialg: React.FC<
  DeleteWebhookConfirmationDialogProps
> = ({ open, onClose, webhook, onSuccessCallback }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [hasError, setHasError] = useState(false);

  function handleSubmit() {
    setIsSubmitting(true);
    fetchClient.delete({
      path: `/webhooks/${webhook?.id}`,
      onSuccess,
      onError,
    });
  }

  function onSuccess() {
    if (webhook) {
      onSuccessCallback(webhook.id);
      setIsSubmitting(false);
      onClose();
    }
  }

  function onError() {
    setIsSubmitting(false);
    setHasError(true);
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Are you sure you want to delete &quot;{webhook?.friendlyName}&quot;?
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1">This action cannot be undone</Typography>
          {hasError && (
            <Typography
              variant="body1"
              sx={{ color: (theme) => theme.palette.error.main }}
            >
              Something went wrong, please try again later.
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isSubmitting}
          success={false}
          onClick={handleSubmit}
        >
          Delete
        </LoadingButton>
        <Button onClick={onClose} color="primary" disabled={isSubmitting}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWebhookConfirmationDialg;
