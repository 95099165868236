import DesignerInput from "../shared/DesignerInput/DesignerInput";
import IPropertiesProps from "./IPropertiesProps";
import styles from "./propertiesStyles";
import tw, { css } from "twin.macro";

import React, { useState } from "react";

import IncrementInput from "../shared/NuDesignerInputs/IncrementInput";
import { Rect } from "fabric/fabric-impl";

const ShapeProperties = ({ selectedObject }: IPropertiesProps) => {
  const [cornerRadius, setCornerRadius] = useState(getCornerRadius());

  function handleChange(property: string, value: number) {
    if (selectedObject.type === "rect") {
      const rect = selectedObject as Rect;
      setCornerRadius(value);
      // @ts-ignore
      rect.set("cornerRadius", value);
      rect.set("rx", value * (1 / (rect.scaleX ?? 1)));
      rect.set("ry", value * (1 / (rect.scaleY ?? 1)));
      rect.canvas?.renderAll();
      rect.canvas?.fire("object:modified");
    }
  }

  function getCornerRadius() {
    if (selectedObject.type === "rect") {
      return (selectedObject as Rect).rx ?? 0;
    }
    return 0;
  }
  if (selectedObject.type !== "rect") return null;
  return (
    <div css={styles.section}>
      <div
        css={[
          styles.propertiesGroup,
          selectedObject.type === "rect" && tw`items-end`,
        ]}
      >
        {selectedObject.type === "rect" && (
          <div css={styles.property}>
            <div>
              <span css={styles.label}>Corner Rounding</span>
            </div>

            <IncrementInput
              min={0}
              property={"cornerRadius"}
              value={cornerRadius}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShapeProperties;
