export default [
  "firstname",
  "lastname",
  "address",
  "address2",
  "city",
  "state",
  "zipcode",
  "company",
  "returnaddress",
  "returnaddress2",
  "returnaddresscity",
  "returnaddressstate",
  "returnaddresszipcode",
  "returnaddresscompany",
  "returnaddressfirstname",
  "returnaddresslastname",
  "returnaddressname",
];
