import LocalStorage from "../../../helpers/LocalStorage";
import { getCookie } from "./cookies";

export interface V3ApiClientOptions {
  onError?: (error: Error) => void;
}

export interface V3ApiClientQueryParam {
  key: string;
  value: string;
}

export default class V3ApiClient {
  private token: string;
  private baseUrl: string;
  private onError: (error: Error) => void;
  constructor(options?: V3ApiClientOptions) {
    this.token = getCookie("token") ?? "";
    if (options?.onError) {
      this.onError = options.onError;
    } else this.onError = this.handleError;
    this.baseUrl = getCookie("apiBaseUrl") ?? "";
  }

  private handleError = (error: Error) => {
    throw error;
  };

  private getHeaders(): HeadersInit {
    return {
      Authentication: `Bearer ${this.token}`,
      Accept: "*/*",
    };
  }

  async get<T>(
    endpoint: string,
    queryParams?: V3ApiClientQueryParam[]
  ): Promise<T> {
    const query = queryParams?.map((x) => `${x.key}=${x.value}`).join("&");
    const headers: HeadersInit = {
      Authorization: "Bearer " + this.token,
    };

    const res = await fetch(
      `${getCookie("apiBaseUrl")}${endpoint}${query ? `?${query}` : ""}`,
      {
        headers: {
          Authorization: "Bearer " + getCookie("token"),
        },
        method: "get",
        mode: "cors",
      }
    );
    if (res.ok) {
      try {
        const json: T = await res.json();
        return json;
      } catch (e) {
        throw new Error(res.statusText);
      }
    } else if (res.status === 204) {
      return {} as T;
    } else if (res.status === 401) {
      this.onError(new Error("Unauthorized"));
      return {} as T;
    } else {
      this.onError(new Error(res.statusText));
      return {} as T;
    }
  }

  async post<T>(
    endpoint: string,
    data?: any,
    queryParams?: V3ApiClientQueryParam[]
  ) {
    const query = queryParams?.map((x) => `${x.key}=${x.value}`).join("&");

    const res = await fetch(
      `${getCookie("apiBaseUrl")}${endpoint}${query ? `?${query}` : ""}`,
      {
        headers: {
          Authorization: "Bearer " + getCookie("token"),
          "Content-Type": "application/json",
        },
        method: "post",
        mode: "cors",
        body: !data ? undefined : JSON.stringify(data),
      }
    );
    if (res.ok) {
      try {
        const json: T = await res.json();
        return json;
      } catch (e) {
        throw new Error(res.statusText);
      }
    } else if (res.status === 204) {
      return {} as T;
    } else if (res.status === 401) {
      this.onError(new Error("Unauthorized"));
      return {} as T;
    } else {
      this.onError(new Error(res.statusText));
      return {} as T;
    }
  }

  async delete(
    endpoint: string,
    data?: any,
    queryParams?: V3ApiClientQueryParam[]
  ) {
    const query = queryParams?.map((x) => `${x.key}=${x.value}`).join("&");

    const res = await fetch(
      `${getCookie("apiBaseUrl")}${endpoint}${query ? `?${query}` : ""}`,
      {
        headers: {
          Authorization: "Bearer " + getCookie("token"),
          "Content-Type": "application/json",
        },
        method: "delete",
        mode: "cors",
        body: !data ? undefined : JSON.stringify(data),
      }
    );
    if (res.ok) {
      return true;
    } else if (res.status === 204) {
      return true;
    } else if (res.status === 401) {
      this.onError(new Error("Unauthorized"));
    } else {
      this.onError(new Error(res.statusText));
    }
  }

  async put() {}
}
