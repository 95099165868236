import { ActiveSelection, Group } from "fabric/fabric-impl";
import useLayers from "../../../hooks/useLayers";
import IPropertiesProps from "../Properties/IPropertiesProps";
import DeleteButton from "./DeleteButton";
import DuplicateButton from "./DuplicateButton";
import toolbarStyles from "./toolbarStyles";
import ToolButton from "../shared/ToolButton";

const SelectionProperties = ({ selectedObject }: IPropertiesProps) => {
  const { groupLayers, ungroupLayers, selectedLayers } = useLayers();

  function testGroupSelection() {
    if (selectedObject.type === "activeSelection") {
    }
  }

  return (
    <div>
      <div css={toolbarStyles.section}>
        <div css={toolbarStyles.property}>
          {selectedLayers && selectedLayers.length > 1 && (
            <ToolButton
              css={toolbarStyles.utilityButton}
              onClick={() => {
                groupLayers(selectedObject as ActiveSelection);
              }}
            >
              Group
            </ToolButton>
          )}
          {selectedLayers &&
            selectedLayers.length === 1 &&
            selectedLayers[0].index.includes("group") && (
              <ToolButton
                css={toolbarStyles.utilityButton}
                onClick={() => {
                  ungroupLayers();
                }}
              >
                Ungroup
              </ToolButton>
            )}
        </div>
        {selectedObject?.name?.includes("qrcode") && (
          <div css={toolbarStyles.property}>
            <DuplicateButton selectedObject={selectedObject} />
          </div>
        )}
        <div css={toolbarStyles.property}>
          <DeleteButton selectedObject={selectedObject} />
        </div>
      </div>
    </div>
  );
};

export default SelectionProperties;
