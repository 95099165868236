import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { PricingWithDescription } from "../models/pricing";

type PricingTableProps = {
  size: string;
  pricing: PricingWithDescription[];
};

const LetterPricing = ({ size, pricing }: PricingTableProps) => {
  const letterPricing = pricing
    .filter((p) => p.size === size && p.envelope)
    .reduce((acc, p) => {
      if (!acc[p.envelope]) {
        acc[p.envelope] = [];
      }
      acc[p.envelope].push(p);
      return acc;
    }, {} as Record<string, PricingWithDescription[]>);

  const formatPrice = (price: number) =>
    price.toFixed(Math.max(price.toString().length - 2, 2));

  return (
    <div>
      {Object.entries(letterPricing).map(([envelope, pricing]) => (
        <div key={envelope}>
          <h3
            style={{
              marginTop: "5px",
              marginBottom: "5px",
            }}
          >
            {envelope}
          </h3>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                {envelope != "Add On" && <TableCell>Mail Class</TableCell>}
                <TableCell
                  sx={{
                    textAlign: "right",
                  }}
                >
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricing
                .sort((a, b) => a.price - b.price)
                .map((p) => (
                  <TableRow key={p.inventoryCD}>
                    <TableCell>{p.description}</TableCell>
                    {envelope != "Add On" && (
                      <TableCell>{p.mailClass}</TableCell>
                    )}
                    <TableCell
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      ${formatPrice(p.price)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      ))}
    </div>
  );
};

const PostcardPricing = ({ size, pricing }: PricingTableProps) => {
  const postcardPricing = pricing
    .filter((x) => x.size === size)
    .reduce(
      (prev, curr) => {
        if (curr.mailClass === "Standard" && curr.inventoryCD.endsWith("S")) {
          prev.standard.push(curr);
        }
        if (curr.mailClass === "FirstClass" && curr.inventoryCD.endsWith("F")) {
          prev.firstClass.push(curr);
        }
        return prev;
      },
      { firstClass: [], standard: [] } as {
        firstClass: PricingWithDescription[];
        standard: PricingWithDescription[];
      }
    );

  return (
    postcardPricing && (
      <Box>
        {postcardPricing &&
          (postcardPricing.firstClass.length > 1 ||
            postcardPricing.standard.length > 1) && (
            <React.Fragment>
              {postcardPricing.firstClass.length > 0 && (
                <Box>
                  <strong>First Class</strong>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Price Per Piece</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {postcardPricing.firstClass.map((x, index) => {
                        let breakPoint = x.breakQty.toString();
                        if (index === 0) {
                          breakPoint = `1-${
                            postcardPricing.firstClass[index + 1].breakQty - 1
                          }`;
                        } else if (
                          index ===
                          postcardPricing.firstClass.length - 1
                        ) {
                          breakPoint = `${x.breakQty}+`;
                        } else {
                          breakPoint = `${x.breakQty}-${
                            postcardPricing.firstClass[index + 1].breakQty
                          }`;
                        }

                        return (
                          <TableRow key={x.breakQty}>
                            <TableCell>{breakPoint}</TableCell>
                            <TableCell>${x.price}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              )}
              {postcardPricing.standard.length > 0 && (
                <Box>
                  <strong>Standard Class</strong>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Price Per Piece</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {postcardPricing.standard.map((x, index) => {
                        let breakPoint = x.breakQty.toString();
                        if (index === 0) {
                          breakPoint = `1-${
                            postcardPricing.standard[index + 1].breakQty - 1
                          }`;
                        } else if (
                          index ===
                          postcardPricing.standard.length - 1
                        ) {
                          breakPoint = `${x.breakQty}+`;
                        } else {
                          breakPoint = `${x.breakQty}-${
                            postcardPricing.standard[index + 1].breakQty
                          }`;
                        }

                        return (
                          <TableRow key={x.breakQty}>
                            <TableCell>{breakPoint}</TableCell>
                            <TableCell>${x.price}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </React.Fragment>
          )}
        {postcardPricing && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Mailing Class</TableCell>
                <TableCell>Price Per Piece</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {postcardPricing.firstClass.length > 0 && (
                <TableRow>
                  <TableCell>First Class</TableCell>
                  <TableCell>${postcardPricing.firstClass[0].price}</TableCell>
                </TableRow>
              )}
              {postcardPricing.standard.length > 0 && (
                <TableRow>
                  <TableCell>Standard</TableCell>
                  <TableCell>${postcardPricing.standard[0].price}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </Box>
    )
  );
};

export const PricingTable = ({ size, pricing }: PricingTableProps) => {
  return (
    <div>
      <h2>Pricing</h2>
      {size === "811" ? (
        <LetterPricing size={size} pricing={pricing} />
      ) : (
        <PostcardPricing size={size} pricing={pricing} />
      )}
    </div>
  );
};
