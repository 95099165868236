import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useSaveData from "../../../hooks/useSaveData";
import DesignerButton from "../shared/DesignerButton";
import Modal, { ModalActions, ModalBody, ModalTitle } from "../shared/Modal";
import tw, { css } from "twin.macro";
import isAdminUser from "../../../../helpers/isAdminUser";
import { useDesignerSelector } from "../../../state/store";
import { selectDesignInformation } from "../../../state/slices/designInformation";
import { postAsync } from "../../../../helpers/asyncFetch";
import LoadingButton from "../../../../shared/LoadingButton";
import { selectLetterSettings } from "../../../state/slices/letterSettings";

interface ISaveDialogProps {
  isOpen: boolean;
  onClose: () => void;
  mode: string;
}

const SaveDialog = ({ isOpen, onClose, mode }: ISaveDialogProps) => {
  const isAdmin = isAdminUser();

  const { designId } = useParams<{ designId: string }>();
  const design = useDesignerSelector(selectDesignInformation);
  const letterSettings = useDesignerSelector(selectLetterSettings);
  const history = useHistory();
  const { saveAsync, getVariables } = useSaveData();
  const [disabled, setDisabled] = useState(false);
  const [fireSave, setFireSave] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function triggerSave() {
    setFireSave(true);
  }

  function handleQuit() {
    history.push(
      design?.bAccountID && window.location.href.includes("admin")
        ? `/admin/integrations/${design?.bAccountID}/designs/`
        : window.location.href.includes("admin")
        ? `/admin/designer/`
        : window.location.href.includes("editor")
        ? `/editor/designer/${designId}`
        : `/designs/${designId}`
    );
  }

  async function saveAndQuit() {
    if (fireSave) {
      setDisabled(true);
      setIsSubmitting(true);
      const json = await saveAsync();

      if (window.location.href.includes("designs/designer")) {
        await generateProof();
      }
      history.push(
        design?.bAccountID && window.location.href.includes("admin")
          ? `/admin/integrations/${design?.bAccountID}/designs/`
          : window.location.href.includes("admin")
          ? `/admin/designer/`
          : window.location.href.includes("editor")
          ? `/editor/designer/${designId}`
          : `/designs/${designId}`
      );
      onClose();
      setFireSave(false);
      setIsSubmitting(false);
    }
  }

  async function generateProof() {
    const variables = getVariables().map((x) => {
      return {
        key: x.name,
        value: `{{${x.name}}}`,
      };
    });
    const recipient = {
      firstName: "{{firstname}}",
      lastName: "{{lastname}}",
      address: "{{address}}",
      address2: "{{address2}}",
      city: "{{city}}",
      state: "{{state}}",
      zipCode: "{{zipcode}}",
      variables,
    };
    const returnAddress = {
      firstName: "{{returnaddressfirstname}}",
      lastName: "{{returnaddresslastname}}",
      address: "{{returnaddress}}",
      address2: "{{returnaddress2}}",
      city: "{{returnaddresscity}}",
      state: "{{returnaddressstate}}",
      zipCode: "{{returnaddresszipcode}}",
    };
    const envelopeConfig =
      design?.canvasSettings?.width === 8.5 &&
      design?.canvasSettings?.height === 11
        ? {
            type: letterSettings?.envelopeType ?? "Regular",
          }
        : null;

    await postAsync(`/designs/${designId}/generate-proof?save=true`, {
      recipient,
      returnAddress,
      htmlVersionId: design?.htmlVersionID,
      envelopeConfig,
    });
  }

  async function saveAndGenerateProof() {
    setDisabled(true);
    const json = await saveAsync();

    onClose();
    if (isAdmin && design?.bAccountID) {
      history.push(
        `/admin/integrations/${design?.bAccountID}/designs/${designId}/proof`
      );
    } else {
      history.push(
        window.location.href.includes("admin")
          ? `/admin/designer/${designId}/proof`
          : window.location.href.includes("editor")
          ? `/editor/designer/${designId}/proof`
          : `/designer/${designId}/proof`
      );
    }
  }

  useEffect(() => {
    saveAndQuit();
  }, [fireSave]);

  return (
    <Modal isOpen={isOpen} onClose={!disabled ? onClose : undefined}>
      <ModalTitle>Save Your Design Before Quitting</ModalTitle>
      <ModalActions>
        <LoadingButton
          loading={isSubmitting}
          onClick={triggerSave}
          disabled={disabled || isSubmitting}
          css={tw`mr-2`}
        >
          Save and Quit
        </LoadingButton>
        {mode === "admin" && (
          <DesignerButton
            onClick={saveAndGenerateProof}
            disabled={disabled}
            css={tw`mr-2 bg-white text-blue hover:opacity-100 hover:bg-blue hover:text-white border border-solid border-blue`}
          >
            Generate A Proof
          </DesignerButton>
        )}
        <DesignerButton
          onClick={handleQuit}
          disabled={isSubmitting}
          css={tw`mr-2 bg-white text-blue hover:opacity-100 hover:bg-blue hover:text-white border border-solid border-blue`}
        >
          Quit Without Saving
        </DesignerButton>
        <DesignerButton
          onClick={onClose}
          disabled={isSubmitting}
          css={tw`bg-white text-blue`}
        >
          Cancel
        </DesignerButton>
      </ModalActions>
    </Modal>
  );
};

export default SaveDialog;
