import { useEffect, useState } from "react";
import nFetchClient, { HttpMethod } from "../helpers/nFetchClient";

interface ErrorCallback {
  (error: any): any;
}

type PostDataStatusValue = "idle" | "loading" | "error";

export interface PostDataStatus<T> {
  success: boolean;
  status: PostDataStatusValue;
  message?: string;
  data?: T;
}

interface AccessorKeyValue {
  accessor: string;
  value: unknown;
}

// prettier-ignore
const useFetch = <T,>(
  path: string,
  method: HttpMethod,
  successResetSeconds?: number
): [
  sendRequest: <B,>(
    body?: B,
    accessorValues?: AccessorKeyValue | AccessorKeyValue[],
    queryString?: string
  ) => Promise<T | undefined>,
  requestStatus: PostDataStatus<T>
] => {
  
  const [dataStatus, setDataStatus] = useState<PostDataStatus<T>>({
    success: false,
    status: "idle",
  });

  
  



  


  /**
   * 
   * @param body Request body if any
   * @param accessorValues Optional pattern and value we are replacing in the path. Ex. ":batchId" would be {accessor: "batchId", value: 10}. Can be an array or a single AccessorKeyValue object
   * @param queryString  Optional query string we are adding to the path. Ex. "isSandbox=true&shouldProccess=false"
   * @returns 
   */
  async function sendRequest<B>(
    body?: B,
    accessorValues?: AccessorKeyValue | AccessorKeyValue[],
    queryString?: string
  ): Promise<T | undefined> {
    if (dataStatus.status !== "loading") {
      setDataStatus({
        ...dataStatus,
        message: undefined,
        success: false,
        status: "loading",
      });
      try {
        let reqPath = path;

        if (accessorValues) {
          if (Array.isArray(accessorValues)) {
            accessorValues.forEach((accessor) => {
              reqPath = reqPath.replaceAll(
                `:${accessor.accessor}`,
                `${accessor.value}`
              );
            });
          } else {
            reqPath = reqPath.replaceAll(
              `:${accessorValues.accessor}`,
              `${accessorValues.value}`
            );
          }
        }
        if(queryString) {
          reqPath += `?${queryString}`;
        }
        
        const res = await nFetchClient<T, B>(reqPath, method, body);
        
        if (res) {
          
          setDataStatus({
            success: true,
            status: "idle",
            data: res,
          });
        }
        if (successResetSeconds) {
          setTimeout(() => {
            setDataStatus({
              ...dataStatus,
              success: false,
            });
          }, successResetSeconds * 1000);
        }
        return res;
      } catch (e) {           
        const err = e as Error;
        if(typeof e === "string") {
          
          setDataStatus({
            success: false,
            status: "error",
            data: undefined,
            message: e
          });
        } else {        
        
        setDataStatus({
          success: false,
          status: "error",
          message: err.message,
          data: undefined,
        });       
        return undefined;
      }
      }
    }
    return undefined;
  }

  return [sendRequest, dataStatus];
};

/**
 *
 * @param path Path to use. Include any accessors like this "/:batchId"
 * @param successResetSeconds If the success needs to reset so users can resubmit something
 * @returns
 */
export const usePostData = <T,>(
  path: string,

  successResetSeconds?: number
) => {
  return useFetch<T>(path, "post", successResetSeconds);
};

export const usePutData = <T,>(
  path: string,

  successResetSeconds?: number
) => {
  return useFetch<T>(path, "put", successResetSeconds);
};

export const useGetData = <T,>(
  path: string,

  successResetSeconds?: number
) => {
  return useFetch<T>(path, "get", successResetSeconds);
};

export const useDeleteData = <T,>(
  path: string,

  successResetSeconds?: number
) => {
  return useFetch<T>(path, "delete", successResetSeconds);
};
