/* eslint-disable */ import React from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    marginRight: "10px",
    display: "inline-block",
    fontSize: "18px",
  },
  bullet: {
    // fontSize: "18px",
    marginBottom: "12px",
  },
}));

const InfoBlock = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h3 className="section--heading">
        Sign up for your <strong>FREE</strong> API account for instant access
        to:
      </h3>
      <div className={classes.bullet}>
        <CheckRoundedIcon className={classes.icon} />
        <span>Setup your postcard designs</span>
      </div>
      <div className={classes.bullet}>
        <CheckRoundedIcon className={classes.icon} />
        <span>Select mailer sizes</span>
      </div>
      <div className={classes.bullet}>
        <CheckRoundedIcon className={classes.icon} />
        <span>Create zapier triggers to automatically send mailers</span>
      </div>
      <div className={classes.bullet}>
        <CheckRoundedIcon className={classes.icon} />
        <span>
          A{" "}
          <a href="https://www.mypostcardmania.com/api/docs" target="_blank">
            Direct Mail API
          </a>{" "}
          for developers
        </span>
      </div>
      {/* <div className="ratio ratio-16x9">
				<iframe
					className="embed-responsive-item"
					src="https://www.youtube.com/embed/qFBuShADfpI"
					frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</div> */}
    </div>
  );
};

export default InfoBlock;
