import { IPathPoint } from "fabric";

export function getPathString(points: IPathPoint[], isCompleting = false) {
  const path = getPath(points, isCompleting);

  const pathString = path.reduce(
    (str, currPoint) => (str += ` ${currPoint.join(" ")}`),
    ""
  );

  return pathString;
}

export default function getPath(points: IPathPoint[], isCompleting = false) {
  const pathPoints = points.map((point, i) => {
    if (i === 0) {
      return ["M", point.x, point.y];
    }
    const prevItem = points[i - 1];
    // if the previous point and this point both have quadratics, we need to create a C line.
    if (prevItem.quadraticForward && point.quadraticBackward) {
      return [
        "C",
        prevItem.quadraticForward.x,
        prevItem.quadraticForward.y,
        point.quadraticBackward.x,
        point.quadraticBackward.y,
        point.x,
        point.y,
      ];
    } else if (prevItem.quadraticForward) {
      // if the previous point has a quadratic but this point doesn't, we need to create a Q line.
      return [
        "Q",
        prevItem.quadraticForward.x,
        prevItem.quadraticForward.y,
        point.x,
        point.y,
      ];
    } else if (point.quadraticBackward) {
      // if the previous point doesn't have a quadratic but this point does, we need to create a Q line.
      return [
        "Q",
        point.quadraticBackward.x,
        point.quadraticBackward.y,
        point.x,
        point.y,
      ];
      // Otherwise, we just need to create a line.
    } else return ["L", point.x, point.y];
  });

  // We need to push the Z command to the canvas if we're closing the path.
  if (isCompleting) {
    pathPoints.push(["Z"]);
  }

  return pathPoints;
}
