import getFontsAsync from "./getFontsAsync";
import getFontsInDocument from "./getFontsInDocument";
import { IFont, IGoogleFont, SortOption } from "./types";

const googleFontsService = {
  getFontsAsync,
  getFontsInDocument,
};

export default googleFontsService;
