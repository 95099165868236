import React, { useRef, useState } from "react";
import tw, { css } from "twin.macro";

interface IEditorTooltipProps {
  label: string | React.ReactFragment;
  children: React.ReactFragment;
  position?: Position;
  enableLock?: boolean;
}

type Position = "top" | "bottom" | "left" | "right";
type ElementDimensions = { width: number; height: number };
const styles = {
  tooltipContainer: (
    position: Position,
    elementDimensions?: ElementDimensions
  ) => [
    tw`absolute`,
    css`
      z-index: 1000;
    `,
    position === "bottom" &&
      elementDimensions &&
      css({ top: elementDimensions.height + 2 + "px", right: 0 }),
    position === "top" &&
      elementDimensions &&
      css({ bottom: elementDimensions.height + 2 + "px", left: 0 }),
  ],
  wrapper: [
    tw`relative`,
    css`
      z-index: 1000;
    `,
  ],
};

const EditorTooltip = ({
  label,
  children,
  position = "bottom",
  enableLock = false,
}: IEditorTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [locked, setLocked] = useState(false);
  const labelRef = useRef<HTMLDivElement>(null);
  function handleMouseOver() {
    if (!locked && !showTooltip) {
      setShowTooltip(true);
    }
  }

  function handleMouseOut() {
    if (!locked && showTooltip) {
      setShowTooltip(false);
    }
  }

  function handleMouseclick() {
    if (enableLock) {
      if (!locked) {
        setLocked(true);
        setShowTooltip(true);
      } else {
        setLocked(false);
        setShowTooltip(false);
      }
    }
  }

  return (
    <div
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      css={styles.wrapper}
    >
      <div onClick={handleMouseclick} ref={labelRef}>
        {label}
      </div>
      {showTooltip && (
        <div
          css={styles.tooltipContainer(
            position,
            labelRef.current
              ? {
                  width: labelRef.current.offsetWidth,
                  height: labelRef.current.offsetHeight,
                }
              : undefined
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default EditorTooltip;
