import * as Yup from "yup";
import valid from "card-validator";

export const CreditCardSchema = (cardType) => Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    cardNumber: Yup.string()
        .required("You must enter a credit card number")
        .test(
            "cardNumber",
            "Credit card number is invalid",
            value => valid.number(value).isValid
        ),
    expiration: Yup.mixed()
        .test(
            "expiration",
            "Enter a valid expiration",
            value => valid.expirationDate(value).isValid
        )
        .required("Enter the expiration"),
    cvc: Yup.string()
        .test("cvc", "Enter a valid cvc", value => {

            return cardType == "amex" ?
                valid.cvv(value, 4).isValid :
                valid.cvv(value).isValid
        })
        .required()
});