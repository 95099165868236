import { ButtonHTMLAttributes, forwardRef } from "react";
import tw, { css } from "twin.macro";
import ButtonReset from "./ButtonReset";

const styles = {
  button: [
    tw`bg-blue uppercase text-white hover:bg-opacity-60 px-4 py-2 rounded font-bold 
    disabled:bg-border
    disabled:cursor-auto`,
  ],
};

interface IDesignerButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "blue";
}

const DesignerButton = forwardRef<HTMLButtonElement, IDesignerButtonProps>(
  ({ color, children, ...props }: IDesignerButtonProps, ref) => {
    return (
      <ButtonReset {...props} css={styles.button} ref={ref}>
        {children}
      </ButtonReset>
    );
  }
);

export default DesignerButton;
